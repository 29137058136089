import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export function getImage(image, width = 500, height = 500) {

    let url = image.variationUrl;

    url = url.replace('{{width}}', width);
    url = url.replace('{{height}}', height);
    url = url.substring(1);

    return process.env.REACT_APP_baseUrl + url;
}


export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
