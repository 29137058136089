import React from 'react';
import { withRouter } from 'react-router-dom';
import { string } from 'prop-types';

import StartDesigningToday from 'src/redesign/components/CustomProductTemplate/components/Personalized';
import SignupLogin from 'src/redesign/components/SignupLogin';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const CustomerStory = ({
  history,
  image,
  imageDescription,
  COO,
  cooContact,
  logo,
  site
}) => (
  <div className='customer-story-container'>
    <div className='customer-story'>
      <div className='customer-story-left-section'>
        <div className='customer-story-image-container'>
          <Image alt={getImageAlt()} src={image}  />
          <div className='customer-story-image-description'>
            {imageDescription}
          </div>
        </div>

        <div className='customer-story-text-title'>
          1. Can you give a brief description of your company? 
        </div>
        <div className='customer-story-text-description'>
          Tee Commerce is the online shop solution for golf courses, country clubs and more. We provide a customizable online storefront that features an extensive inventory of decorated apparel and accessories. All of our products are made on-demand in order to allow our clients to have no investment in upfront inventory while also being able to provide their members/customers with the variety they know and expect from an online shop experience.
        </div>

        <div className='customer-story-text-title'>
          2. What challenges/problems necessitated a change? 
        </div>
        <div className='customer-story-text-description'>
          We needed to adapt as we grew in volume and began to scale the business. The best change for the business was to invest in external help that had the capability to provide parallel quality standards according to the market's needs.
        </div>

        <div className='customer-story-text-title'>
          3. What made our solution stand out over others that you researched?
        </div>
        <div className='customer-story-text-description'>
          What makes this partnership so unique is your willingness to integrate with our existing technology and your being open to making changes as necessary 
        </div>

        <div className='customer-story-text-title'>
          4. How did you implement/introduce our solution?
        </div>
        <div className='customer-story-text-description'>
          After multiple conversations of how best to streamline the process, we landed on Surge Print. The launch of Surge Print was seamless!
        </div>

        <div className='customer-story-text-title'>
          5. How did our team help with implementation? 
        </div>
        <div className='customer-story-text-description'>
          The team championed developing custom solutions for order management and submitting orders 
        </div>

        <div className='customer-story-text-title'>
          6. What have you been most impressed with?  
        </div>
        <div className='customer-story-text-description'>
          We have been most impressed with the responsiveness from Marko & his team in addition to their willingness to adapt to our needs such as quick turnaround times on custom made products 
        </div>

        <div className='customer-story-text-title'>
          7. What surprised you about us? 
        </div>
        <div className='customer-story-text-description'>
          The strong business relationship built with Marko - he is someone who can consistently come up with both original and fresh ideas. He understands the needs of our businesses along with what he and his team can deliver in collaboration
        </div>

        <div className='customer-story-text-title'>
          8. Is there anything else we should know?  
        </div>
        <div className='customer-story-text-description'>
          Nope, we are very happy with Surge and would recommended them to anyone!
        </div>
        <div className='customer-story-bottom-section'>
          <div className='customer-story-bottom-section-text'>
            {`${COO} | COO`} <br/>
            {cooContact}
          </div>
          <Image alt={getImageAlt()} src={logo} className='customer-story-bottom-section-logo-container'/>
          <a className='customer-story-bottom-section-text' target='_blank' href={site}>
            {site}
          </a>
        </div>
      </div>
      <SignupLogin withImage={false} />
    </div>

    <StartDesigningToday
      title='Start Designing Today'
      subtitle={`Empower your online shop to offer a unique and personalized experience. Start featuring custom Mugs in your inventory and discover the joy your customers will experience while sipping from a cup that's uniquely theirs.`}
      onStartDesigningClick={() => history.push('/registration')}
    />
  </div>
);

CustomerStory.propTypes = {
  image: string.isRequired,

}

export default withRouter(CustomerStory);