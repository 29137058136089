import React from 'react';
import { number, string } from 'prop-types';

const IconStores = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3092 7.09599L16.3096 7.09804L17.1424 11.2617C17.1425 11.2621 17.1425 11.2625 17.1426 11.2629C17.1833 11.477 17.0204 11.6666 16.8166 11.6666H16.6666H16.1666V12.1666V16.3333C16.1666 16.5155 16.0155 16.6666 15.8333 16.6666C15.6511 16.6666 15.4999 16.5155 15.4999 16.3333V12.1666V11.6666H14.9999H11.6666H11.1666V12.1666V16.3333C11.1666 16.5155 11.0155 16.6666 10.8333 16.6666H4.16661C3.98441 16.6666 3.83327 16.5155 3.83327 16.3333V12.1666V11.6666H3.33327H3.18327C2.97941 11.6666 2.81658 11.4769 2.85726 11.2629L3.69023 7.09804L3.69023 7.09804L3.69064 7.09599C3.71941 6.94893 3.8541 6.83331 4.01661 6.83331H15.9833C16.1458 6.83331 16.2805 6.94893 16.3092 7.09599ZM9.99994 16H10.4999V15.5V12.1666V11.6666H9.99994H4.99994H4.49994V12.1666V15.5V16H4.99994H9.99994ZM15.8333 4.99998H4.16661C3.98441 4.99998 3.83327 4.84884 3.83327 4.66665C3.83327 4.48446 3.98441 4.33331 4.16661 4.33331H15.8333C16.0155 4.33331 16.1666 4.48446 16.1666 4.66665C16.1666 4.84884 16.0155 4.99998 15.8333 4.99998Z" stroke={color}/>
  </svg>
);

IconStores.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconStores;