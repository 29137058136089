import React from 'react';

import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const ServingOnline = () => (
  <div className='serving-online-container'>
    <div className='serving-online-text-container'>
      <h2 className='serving-online-text-title'>
        Serving Online Shops Globally and Across the United States
      </h2>
      <div className='serving-online-text-description'>
        Our commitment to serving online shops involves decorating our mugs and drinkware products in four different locations across the United States. This ensures timely delivery and customer satisfaction for your online shop's clientele.
      </div>
      <Image alt={getImageAlt()} src='/images/world.png' className='serving-online-image-world' />
    </div>
  </div>
);


export default ServingOnline;