import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function getRootCategories() {
    return await request('/api/get_root_categories', [],  HttpMethod.GET);
}

export async function getCategoryLowHigh(id) {
    return await request('/api/get_category_low_high/' + id, [],  HttpMethod.GET);
}

export async function getCategoryByParent(data) {
    return await request('/api/get_category_by_parent', data, HttpMethod.GET);
}

export async function getAllCategories() {
    return await request('/api/get_all_categories', [], HttpMethod.GET);
}

export async function getSubCategoriesFilters(data) {
    return await request('/api/products/categories/filters', data, HttpMethod.POST);
}

export async function uploadCategoryImage(data) {
    return await request('/api/upload_category_image', data, HttpMethod.POST)
}

export async function uploadCategoryGalleryImage(data) {
    return await request('/api/upload_category_gallery_image', data, HttpMethod.POST)
}

export async function deleteCategoryGalleryImage(data) {
    return await request('/api/delete_category_gallery_image', data, HttpMethod.PUT)
}

export async function getCategoryGalleryImages(data) {
    return await request('/api/get_category_images', data, HttpMethod.GET)
}

export async function addCategory(data) {
    return await request('/api/add_category', data, HttpMethod.POST);
}

export async function categorySearch(data) {
    return await request('/api/category_search', data, HttpMethod.GET);
}

export async function setTrending(data) {
    return await request('/api/category_set_trending', data, HttpMethod.PUT);
}

export async function getTrendingCategory() {
    return await request('/api/get_trending_category', [], HttpMethod.GET);
}

export async function updateCategory(data) {
    return await request('/api/update_category', data, HttpMethod.POST);
}

export async function deleteCategory(data) {
    return await request('/api/delete_category', data, HttpMethod.PUT);
}
export async function getCategories(data) {
    return await request('/api/get_categories' , [],  HttpMethod.GET);
}
export async function getCategoriesTree() {
    return await request('/api/get_categories_tree' , [],  HttpMethod.GET);
}