export const mapBusinessInformation = businessInformation => ({
  id: businessInformation.id,
  nameOnCard: businessInformation?.name_on_card,
  billingSameAsBusiness: businessInformation?.billing_address_same_as_business,
  lastFourDigits: "**** **** **** " + businessInformation?.last_four_digits != undefined ? businessInformation.last_four_digits : "****",
  expiration: (businessInformation?.expiration_month != undefined ? businessInformation?.expiration_month : "") + "/" + (businessInformation?.expiration_year != undefined ? businessInformation?.expiration_year : "") ,
  billingAddress: businessInformation?.billing_address,
  addressLineTwo: businessInformation?.address_line_two,
  city: businessInformation?.city,
  stateProvince: businessInformation?.state_province,
  postal: businessInformation?.postal,
  country: businessInformation?.country?.id
});

export const mapUpdatedBusinessInformation = ({
  id,
  nameOnCard,
  billingSameAsBusiness,
  lastFourDigits,
  expiration,
  billingAddress,
  addressLineTwo,
  city,
  stateProvince,
  postal,
  country
  }) => ({
    id,
    name_on_card: nameOnCard,
    billing_address_same_as_business: billingSameAsBusiness,
    last_four_digits: lastFourDigits,
    expiration_month: expiration,
    billing_address: billingAddress,
    address_line_two: addressLineTwo,
    city,
    state_province: stateProvince,
    postal,
    country
})