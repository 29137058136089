import React from 'react';
import classNames from 'classnames';
import { bool, shape, string } from 'prop-types';

import PriceInput from 'src/redesign/components/Form/Sidepane/PriceInput';
import IconUS from 'src/redesign/components/Icon/US';
import IconInternational from 'src/redesign/components/Icon/International';
import * as Validators from 'src/redesign/util/validators';

import './style.scss';

const PricesInput = ({ containerClassName, titleClassName, contentClassName, inputClassName, isUS, standard, expedited, overnight }) => {
  return (
    <div className={classNames('prices-input-container', containerClassName)}>
      <div className={classNames('prices-input-title', titleClassName)}>
        {isUS ? <IconUS /> : <IconInternational/>}
        {isUS ? 'US' : 'International'}
      </div>

      <div className={classNames('prices-input-content', contentClassName)}>
        <div className='prices-input-labels-column'>
          <div className='prices-input-label'>
            First
          </div>
          <div className='prices-input-label'>
            Each Additional
          </div>
        </div>

        <div className='prices-input-values-column'>
          <div className='prices-input-value-label'>
            Standard
          </div>
          <PriceInput 
            name={standard.first}
            className={classNames('prices-input-value-input', inputClassName)}
            prefixClassName='prices-input-value-prefix'
            // validate={Validators.required(' ')}
          />
          <PriceInput
            name={standard.eachAdditional}
            className={classNames('prices-input-value-input', inputClassName)}
            prefixClassName='prices-input-value-prefix'
            // validate={Validators.required(' ')}
          />
        </div>

        <div className='prices-input-values-column'>
          <div className='prices-input-value-label'>
            Expedited
            {!isUS && <div className='prices-input-value-label-optional'>
              (optional)
            </div>}
          </div>
          <PriceInput 
            name={expedited.first}
            className={classNames('prices-input-value-input', inputClassName)}
            prefixClassName='prices-input-value-prefix'
            // validate={isUS ? Validators.required(' ') : null}
          />
          <PriceInput
            name={expedited.eachAdditional}
            className={classNames('prices-input-value-input', inputClassName)}
            prefixClassName='prices-input-value-prefix'
            // validate={isUS ? Validators.required(' ') : null}
          />
        </div>

        {isUS && (
        <div className='prices-input-values-column'>
          <div className='prices-input-value-label'>
            Overnight
          </div>
          <PriceInput 
            name={overnight.first}
            className={classNames('prices-input-value-input', inputClassName)}
            prefixClassName='prices-input-value-prefix'
            // validate={Validators.required(' ')}
          />
          <PriceInput
            name={overnight.eachAdditional}
            className={classNames('prices-input-value-input', inputClassName)}
            prefixClassName='prices-input-value-prefix'
            // validate={Validators.required(' ')}
          />
        </div>)}
      </div>
    </div>
  )
}

PricesInput.propTypes = {
  isUS: bool.isRequired,
  standard: shape({
    first: string.isRequired,
    eachAdditional: string.isRequired
  }).isRequired,
  expedited: shape({
    first: string,
    eachAdditional: string
  }).isRequired,
  overnight: shape({
    first: string,
    eachAdditional: string
  })
}

export default PricesInput;