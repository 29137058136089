import React from 'react';
import Form from 'src/redesign/components/Form/Form';
import TextInput from 'src/redesign/components/Form/TextInput';
import { mapLoadedUser, mapUpdatedUser } from './helpers';

import * as Validators from 'src/redesign/util/validators';
import SelectInput from 'src/redesign/components/Form/SelectInput';
import { setBusinessInformation } from 'src/redesign/../services/BusinessInformation';
import useSnackbar from 'src/redesign/hooks/useSnackbar';

const BusinessInformation = ({ user, countryOptions, countries, onUpdate }) => {
  const [form, setForm] = React.useState({});

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    setForm(mapLoadedUser(user));
  }, []);

  const save = async (newUser) => {
    try {
      const user = { ...newUser, country: countries.find(x => x.id == newUser.country) };
      await setBusinessInformation(user);
      onUpdate(mapUpdatedUser(user));

      openSnackBar('Successfully saved!');
    } catch (error) {
      openSnackBar('Changing business information failed!', false);
    }
  }

  return <Form initialValues={form} title='Business information'
  onSubmit={save}
  submitButtonText='Save Changes'
  >
    <TextInput label='Company name' name='company' placeholder='Enter your company name' validate={Validators.required('Company name si required')} />
    <TextInput label='Address line 1' name='businessAddress' placeholder='Address line 1' validate={Validators.required('This field is required')} />
    <TextInput label='Address line 2' name='addressLineTwo' placeholder='Address line 2' validate={Validators.required('This field is required')} />
    <SelectInput
      name="country"
      label="Country"
      options={countryOptions}
      placeholder="Choose your country"
    />
    <TextInput label='City' name='city' placeholder='New York' validate={Validators.required('City is required')} />
    <TextInput label='State/Province/Region' name='stateProvince' placeholder='State/Province/Region' validate={Validators.required('State province is required')} />
    <TextInput label='Postal code' name='postalCode' placeholder='Postal code' validate={Validators.required('Postal code is required')} />
    <TextInput label='Email' name='email' placeholder='Your email address' validate={Validators.composeValidators(Validators.required('Email is required'), Validators.emailFormatValid('Email format is not valid'))} />
    <TextInput label='Phone Number' name='phoneNumber' placeholder='Phone Numer' type='number' validate={Validators.required('Phone number is required')} />
  </Form>
}

export default BusinessInformation;