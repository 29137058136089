import React from 'react';
import FilterButton from 'src/redesign/components/Buttons/Filter';
import Searchbox from 'src/redesign/components/Searchbox';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import AddButton from 'src/redesign/components/Buttons/AddButton';
import Dropdown from 'src/redesign/components/Dropdown';
import { func, number, shape } from 'prop-types';
import OrderEnum from 'src/constants/OrderEnum';
import { withRouter } from 'react-router';
import { v4 as uuid } from 'uuid';

const OrderFilters = ({ numberOfOrders: {
    all,
    new: newNumber,
    pending,
    inProduction,
    shipped,
    issues,
    readyForPrint,
    garmentsReceived
  } = {},
  isAdmin,
  customerOptions,
  selectedCustomer,
  onCustomerSelect,
  activeFilters,
  onFilterClick,
  onSearchTermChange,
  history
}) => {

  return (
    <div className="order-filters">
      <div className="actions">
        <div className="left">
          <Searchbox 
            placeholder="Search"
            onChange={onSearchTermChange}/>
          {/* <ClassicButton text="Advanced" onClick={() =>{}} /> */}
          {isAdmin && (
            <Dropdown
              options={customerOptions}
              selectedOption={selectedCustomer}
              onSelect={onCustomerSelect}
              width={200}
            />
          )}
        </div>
        <AddButton text="New Order" onClick={()=> history.push('/place-an-order')} />
      </div>
      <div className="filters">
        <div className="container">
          <FilterButton
            key={uuid()}
            text='All'
            count={all}
            onClick={() => onFilterClick([])}
            filters={[]}
            activeFilters={activeFilters}
            color={colors.darkBlue}
          />
          <FilterButton
            key={uuid()}
            text='New'
            count={newNumber}
            onClick={onFilterClick}
            filters={[OrderEnum.NewOrder]}
            activeFilters={activeFilters}
            color={colors.green}
          />
          <FilterButton
            key={uuid()}
            text='Pending'
            count={pending}
            onClick={onFilterClick}
            filters={[OrderEnum.Pending]}
            activeFilters={activeFilters}
            color={colors.purple}
          />
          <FilterButton
            key={uuid()}
            text='Ready for Print'
            count={readyForPrint}
            onClick={onFilterClick}
            filters={[OrderEnum.ReadyForPrint]}
            activeFilters={activeFilters}
            color={colors.blue}
          />
          <FilterButton
            key={uuid()}
            text='Garments Received'
            count={garmentsReceived}
            onClick={onFilterClick}
            filters={[OrderEnum.GarmentsReceived, OrderEnum.PartiallyReceived]}
            activeFilters={activeFilters}
            color={colors.greenDark}
          />
          <FilterButton
            key={uuid()}
            text='In Production'
            count={inProduction}
            onClick={onFilterClick}
            filters={[OrderEnum.InProduction, OrderEnum.ManuallyInProduction]}
            activeFilters={activeFilters}
            color={colors.orange}
          />
          <FilterButton
            key={uuid()}
            text='Shipped'
            count={shipped}
            onClick={onFilterClick}
            filters={[OrderEnum.Shipped]}
            activeFilters={activeFilters}
            color={colors.yellow}
          />
          <FilterButton
            key={uuid()}
            text='Issues'
            count={issues}
            onClick={onFilterClick}
            filters={[OrderEnum.Issues]}
            activeFilters={activeFilters}
            color={colors.red}
          />
        </div>
      </div>
    </div>
  )
}

OrderFilters.propTypes = {
  numberOfOrders: shape({
    all: number,
    new: number,
    pending: number,
    inProduction: number,
    shipped: number,
    issues: number
  }),
  activeFilter: number,
  onFilterClick: func,
  onSearchTermChange: func
}

export default withRouter(OrderFilters);