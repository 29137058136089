import React from 'react';
import { withRouter } from 'react-router';

import Table from 'src/redesign/components/Table';
import IconExit from 'src/redesign/components/Icon/Exit';
import Counter from 'src/redesign/components/Counter';
import Loader from 'src/redesign/components/Loader';
import Pagination from 'src/redesign/components/Pagination';
import Button from 'src/redesign/components/Buttons/SubmitPrimary';
import DraftDialog from 'src/redesign/components/Dialog/Draft';
import Image from 'src/redesign/components/Image';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getImageAlt } from 'src/redesign/helpers';
import { createDraft } from 'src/services/ShoppingCart';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const CartTable = ({ items, isLoading, onDeleteItem, onChangeQuantity, currentPage, setCurrentPage, totalPages, history }) => {
  const [isDraftDialogOpen, setIsDraftDialogOpen] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  const saveDraft = async ({ name }) => {
    try {
      await createDraft({ draftName: name });

      openSnackBar('Successfully created draft!');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to create draft', false);
    } finally {
      setIsDraftDialogOpen(false);
    }
  }

  const columns = [
    {
      Header: 'Product',
      accessor: 'customer_product_variation_images',
      Cell: ({ row }) => <div style={{ display: 'flex', gap: '12px' }}>
        <Image alt={getImageAlt()} src={row.original.customer_product_variation_images[0] ? process.env.REACT_APP_baseUrl + row.original.customer_product_variation_images[0].merged_image_url : ''} style={{ minWidth: '94px', height: '62px', borderRadius: '8px', objectFit: 'cover' }}/>
        <div style={{display: 'flex', flexDirection: 'column', gap: '12px', textAlign: 'left' }}>
           {row.original.customer_product_variation.product_variation.name}
        </div>
      </div>
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      Cell: ({ row }) => <Counter count={row.original.quantity} onChange={count => count === 0 ? undefined : onChangeQuantity(row.original.id, count)} disabled={false} isLarge={false}/>
    },
    {
      Header: 'Total Price',
      accessor: 'customer_product_variation',
      Cell: ({ row }) => <div style={{ color: colors.green }}>
        {`$${row.original.customer_product_variation.product_variation.price * row.original.quantity}`}
      </div>
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ row }) => <div style={{ cursor: 'pointer' }} onClick={() => onDeleteItem(row.original.id)}>
        <IconExit width={14} height={14} color={colors.primary} />
      </div>
    }
  ]

  return (
    <div className='cart-table-container'>
      {isLoading
      ? <Loader width={200} height={200} color={colors.primary} className='cart-table'/>
      : (
        <div className='cart-table'>
          <Table
            columns={columns}
            data={items}
          />
        </div>
      )}
      <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages} />
      <div className='cart-buttons'>
        <Button text='Make a Draft' onClick={() => setIsDraftDialogOpen(true)} width={126} className='cart-draft-button'/>
        <Button text='< Continue Shopping' onClick={() => history.push('/product-templates/shopify')} width={190} />
      </div>
      {isDraftDialogOpen && (
        <DraftDialog
          text='Make a draft'
          onClose={() => setIsDraftDialogOpen(false)}
          onSubmit={saveDraft}
        />
      )}
    </div>
  )
};

export default withRouter(CartTable);