import React from 'react';
import { number, string } from 'prop-types';

const IconAffiliate = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.49 10C18.49 11.1065 17.6012 12 16.5 12C15.3961 12 14.5 11.1039 14.5 10C14.5 8.89614 15.3961 8 16.5 8C17.6012 8 18.49 8.89353 18.49 10ZM11.49 8.5C11.49 9.88604 10.3817 11 9 11C7.61614 11 6.5 9.88386 6.5 8.5C6.5 7.11614 7.61614 6 9 6C10.3817 6 11.49 7.11396 11.49 8.5ZM11.5 17.25C11.5 16.9763 11.6332 16.6996 11.941 16.4159C12.2521 16.129 12.7069 15.8701 13.2495 15.653C14.3357 15.2183 15.6475 15 16.5 15C17.3525 15 18.6643 15.2183 19.7505 15.653C20.2931 15.8701 20.7479 16.129 21.059 16.4159C21.3668 16.6996 21.5 16.9763 21.5 17.25V18.5C21.5 18.7739 21.2739 19 21 19H12C11.7261 19 11.5 18.7739 11.5 18.5V17.25ZM2.5 17C2.5 16.6012 2.69583 16.2151 3.10507 15.8377C3.51773 15.4571 4.11313 15.1201 4.81201 14.8405C6.21059 14.2808 7.89745 14 9 14C9.31248 14 9.67343 14.0242 10.0639 14.071C8.76875 15.1744 8.5 16.4329 8.5 17.25V19H3C2.72614 19 2.5 18.7739 2.5 18.5V17Z" stroke={color}/>
  </svg>
);

IconAffiliate.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconAffiliate;