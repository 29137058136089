import React from 'react';
import Helmet from 'react-helmet';
import { node } from 'prop-types';
import classNames from 'classnames';
import Navbar from 'src/redesign/components/Navbar';

import './style.scss';

const ApplicationPage = ({ children, selectedTab, className, seoTitle, seoDescription }) => (
  <div className='application'>
    <Helmet>
      <title>{seoTitle ?? 'Surge: Print on Demand'}</title>
      <meta name='description' content={seoDescription}/>
    </Helmet>
    {/* <Header /> */}
    <div className={classNames('application-page', className)}>
      <Navbar selectedTab={selectedTab} />
      {children}
    </div>
  </div>
);

ApplicationPage.propTypes = {
  children: node.isRequired,
}

export default ApplicationPage;