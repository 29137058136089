import React from 'react';
import { getImpersonatedUser, getUserFromLocalStorage, logout, removeImpersonatedUser, setUserToLocalStorage } from 'src/base/OAuth';
import { withRouter } from 'react-router';
import IconSettings from 'src/redesign/components/Icon/Settings';
import IconAvatar from 'src/redesign/components/Icon/Avatar';
import IconChevronRight from 'src/redesign/components/Icon/Chevron/Right';
import { connect } from 'react-redux';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import { loadUser } from 'src/actions/AuthActions';

const Avatar = ({ history, storeUser, updateStorageUser }) => {
  const localStorageUser = getUserFromLocalStorage();
  const profileImage = storeUser?.image ?? localStorageUser?.image;
  const name = (storeUser?.first_name ?? localStorageUser?.first_name) + ' ' + (storeUser?.last_name ?? localStorageUser?.last_name);
  const isAdmin = storeUser?.roles?.includes('SUPER_ADMIN');

  const [impersonate, setImpersonate] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    const handleClickOutside = event => {
      const isInCallout = document.getElementsByClassName('avatar-callout')?.[0]?.contains(event.target) ?? false;
      if(event.target.className !== 'avatar-container' && event.target.className !== 'profile-avatar' && event.target.className !== 'avatar-callout' && !isInCallout && !(event.target instanceof SVGElement)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    () => document.removeEventListener('click', handleClickOutside);
  }, []);

  React.useEffect(() => {
    if(getImpersonatedUser())
      setImpersonate(true);
  }, []);

  const logOut = () => {
    if(impersonate) {
      const impersonated = getImpersonatedUser();
      setUserToLocalStorage(impersonated);
      removeImpersonatedUser();
      updateStorageUser();
      history.push('/users');
    }
    else
      logout();
    setIsOpen(false);
  }

  const onSettingsClick = () => {
    history.push('/settings/my-profile');
    setIsOpen(false);
  }

  return (
    <div className='avatar-container'>
      {impersonate && (
        <div className='avatar-impersonated-text'>Warning: You're logged in as {name}</div>
      )}
      <div onClick={() => setIsOpen(prev => !prev)}>
        {profileImage
          ? <img className='profile-avatar' src={profileImage}/>
          : <IconAvatar
              width={45}
              height={45}
              colorPrimary={colors.primary}
              colorSecondary={colors.secondaryLight}
              style={{ borderRadius: '50%', cursor: 'pointer' }}
            />
        }
      </div>
      {isOpen && (
        <div className='avatar-callout'>
          <div className='callout-header'>
            {profileImage
              ? <img className='callout-image' src={profileImage} />
              : <IconAvatar
                  width={70}
                  height={70}
                  colorPrimary={colors.primary}
                  colorSecondary={colors.secondaryLight}
                  style={{ borderRadius: '50%' }}
                />
            }
            <div className='callout-text'>
              {name}
            </div>
          </div>
          {!isAdmin && (
            <div className='avatar-callout-link' onClick={onSettingsClick}>
              <IconSettings
                width={20}
                height={20}
                color={colors.primary}
                fillColor={colors.primary}
              />
              <div className='avatar-callout-text'>
                Settings
                <IconChevronRight
                  width={4}
                  height={8}
                  color={colors.primary}
                />
              </div>
            </div>
          )}
          <div className='avatar-callout-link' onClick={logOut}>
            <IconSettings
              width={20}
              height={20}
              color={colors.primary}
              fillColor={colors.primary}
            />
            <div className='avatar-callout-text'>
              Log Out
              <IconChevronRight
                width={4}
                height={8}
                color={colors.primary}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateStorageUser: () => dispatch(loadUser())
  }
}

const mapStateToProps = ({ authReducers }) => ({
  storeUser: authReducers.user
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (Avatar));