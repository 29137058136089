import React from 'react';
import { node, string } from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from "@material-ui/core/styles";
import classNames from 'classnames';

import './style.scss';
import fonts from 'src/scss/_fonts.scss';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "#000000",
    color: '#ffffff',
    padding: '2px 4px',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: fonts.main
  },
}));

export const CustomTooltip = ({ text, children, className, placement }) => {
  const classes = useStyles();
  return (
    <Tooltip
    title={text}
    classes={{
      tooltip: classes.customTooltip,
    }}
    placement={placement}
    >
      <div className={classNames('tooltip-children', className)}>
        {children}
      </div>
    </Tooltip>
  )
}

CustomTooltip.propTypes = {
  text: string,
  children: node,
  className: string
}

export default CustomTooltip;