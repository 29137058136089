import {withRouter} from "react-router-dom";
import React from 'react'
import AboutProduct from "../../components/admin/AboutProduct";
import Page from "../../common/Page";


class Products extends Page{

    constructor(props) {
        super(props);

        this.state = {
        };

        this.doesHaveAdminPermissions();
    }

    render() {
        return (
            <div className={'container products'}>
                <div className={'nav'}>
                    <p className={'active'}>About Product</p>
                    <p className={'disabled'}>SKUs</p>
                </div>
                <AboutProduct />
            </div>
        );
    }
}

export default withRouter(Products);