import React, {Component} from 'react'
import Modal from 'react-modal'
import { renderScene } from '../services/MediaModifierService';
import SubmitButton from 'src/redesign/components/Buttons/Submit';

const customStyles = {
    overlay: {
        zIndex: 10,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 10,
      width: '60%'
    },
  };
class Scenes extends Component {


    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

    }
    componentWillMount() {
        Modal.setAppElement('body');
    }

  
  
    toggleModal = ()  => {
      this.setState({ isOpen: !this.state.isOpen }, () => {
        if( this.state.isOpen) {
          this.getScenes()
        }
      });
    }

    getScenes() {
      this.props.media_modifier_mockups[0].scenes.forEach((scene) => {
          renderScene({
            selectedMockupId: scene.id,
            editedImageForMockup: this.props.fullSizeArt,
            variationId: this.props.variationId
          }).then(response => {
            if(!response.ok ) return
            const content = JSON.parse(response.data?.sceneImage?.[0].content);
            const base64 = response.data.base64;
            document.getElementById(scene.id).src=content.base64 ?? base64;
          })
      }) 
    }
    render() {
        const { isOpen } = this.state
    
        return (
            <div >
            <SubmitButton onClick={this.toggleModal} text='See all views' />
            <Modal
              style={customStyles}
              isOpen={isOpen}
              onRequestClose={this.toggleModal}
              contentLabel="See all views"
            >
                <div className="scenes">
                  <div className="scenes__head">
                    <h1> Views</h1>
                    <span className="scenes__close" onClick={this.toggleModal}>x</span>
                  </div>
              <div className="main-scene__container">
                 <h3> Main view </h3>
                  <br/>
                  <img src={this.props.mainMockup} /> 
                  <br/>
                  <span>{ this.props.media_modifier_mockups[0].name }</span>
              </div>
              <div> 
                <h3>Other views </h3>
                <div className="other-scenes__container"> 
              { this.props.media_modifier_mockups[0].scenes.map(
                      (scene) => <div className="other-scenes__item">
                        <img id={scene.id} key={scene.id} src={'/images/loader.gif'} /> 
                        <br/>
                        <span> { scene.name } </span>
                        </div>
                  )}
                  </div>
              </div>
              </div>
            </Modal>
          </div>
        );
    }
}

export default Scenes;