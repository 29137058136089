import React from 'react';
import { string, node, oneOfType } from 'prop-types';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';

import './style.scss';

const Accordion =({ title, description }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className='accordion-container'>
      <div className='accordion-main-content'>
        <div className='accordion-title' onClick={() => setIsExpanded(prev => !prev)}>
          {title}
        </div>
        <SubmitPrimaryButton
          text={isExpanded ? '-' : '+'}
          onClick={() => setIsExpanded(prev => !prev)}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', width: 32, height: 32 }}
        />
      </div>
      {isExpanded && (
        <div className='accordion-description'>
          {description}
        </div>
      )}
    </div>
  )
}

Accordion.propTypes = {
  title: string.isRequired,
  description: oneOfType([string, node]).isRequired
}

export default Accordion;