import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function uploadProductImage(data) {
    return await request('/api/upload_product_image' , data,  HttpMethod.POST);
}
export async function createProduct(data) {
    return await request('/api/create_product' , data,  HttpMethod.POST);
}
export async function setVariationAvailability(id, status) {
    return await request('/api/set_variation_availability/' + id + '/' + status , [],  HttpMethod.GET);
}
export async function getProductByCategory(id) {
    return await request('/api/get_product_by_category/' + id, [],  HttpMethod.GET);
}
export async function updateProduct(data) {
    return await request('/api/update_product' , data,  HttpMethod.POST);
}
export async function getVariationsForTemplate(data) {
    return await request('/api/get_variations_for_template' , data,  HttpMethod.POST);
}
export async function deleteProduct(id) {
    return await request('/api/delete_product/' +id, [],  HttpMethod.DELETE);
}
export async function generateProductVariations(data) {
    return await request('/api/generate_product_variations' , data,  HttpMethod.POST);
}
export async function getProduct(id) {
    return await request('/api/get_product/' + id , [],  HttpMethod.GET);
}
export async function getProductAttributes(id) {
    return await request('/api/get_product_attributes/' + id , [],  HttpMethod.GET);
}
export async function getShippingPricingProduct(id) {
    return await request('/api/get_shipping_pricing_product/' + id , [],  HttpMethod.GET);
}
export async function getImportedProduct(store, id) {
    return await request('/api/user/' + store + '/products/imported/' + id, [],  HttpMethod.GET);
}
export async function getCustomerTemplateWhichAreNotExported(data) {
    return await request('/api/get_customer_template_which_are_not_exported' , data,  HttpMethod.GET);
}
export async function matchImportedVariations(store, data) {
    return await request('/api/user/' + store + '/match-variations' , data,  HttpMethod.POST);
}