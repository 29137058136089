import React from 'react';
import { withRouter } from 'react-router-dom';

import CustomProductTemplate from 'src/redesign/components/CustomProductTemplate';
import IconBox from 'src/redesign/components/Icon/Box';
import IconTool from 'src/redesign/components/Icon/Tool';
import IconCheckmark from 'src/redesign/components/Icon/Checkmark';

import colors from 'src/scss/_colors.scss';

const LandingBlanketsPage = ({ history}) => {

  const onStartDesigningClick = () => {

  }

  const categoryName = 'Blankets';

  const easeOptions = [
    {
      icon: <IconBox width='37.22' height='37.89' color={colors.secondary} />,
      title: 'Large Product Variety',
      description: `Surge’s ever-expanding Product Catalog offers Blankets in different styles, materials, and sizes.  From personalized Minky blankets, to soft Velveteen and Fleece blankets.`
    },
    {
      icon: <IconTool width='36.53' height='36.52' color={colors.secondary} />,
      title: '100% Free Design Tool',
      description: `Design and list personalized blankets online with no up-front costs, risks, or inventory. Make your own blankets easily with access to our free and beginner-friendly Mockup Generator design tool.`
    },
    {
      icon: <IconCheckmark width='35' height='35' color={colors.secondary} />,
      title: 'High-Quality Prints',
      description: `Customize blankets with stylish edge to edge design arrangements. Choose between US and EU printers who specialize in durable, vibrant, and long-lasting prints.`
    }
  ];

  const exploreMainProduct = {
    image: '/images/blankets-header.png',
    title: 'Minky Blankets: \n Cloud Nine Personified',
    description: `Indulge in the luxurious embrace of our Minky blankets. It's not just a blanket; it's a journey to cloud nine, where dreams and comfort intertwine in a plush symphony.`,
    nameToFind: 'Minky Blankets'
  }

  const exploreOtherProducts = [
    {
      image: '/images/custom-blankets-explore-other-1.png',
      title: 'Fleece Blankets: Versatility, Unwrapped',
      description: `Our Fleece blankets are more than warmth; they are the embodiment of versatility. From outdoor adventures to cozy nights in, these blankets are a steadfast companion through every season.`,
      nameToFind: 'Fleece Blankets'
    },
    {
      image: '/images/custom-mugs-explore-other-2.png',
      title: 'Velveteen Blankets: Elegance Unveiled',
      description: `Adorn your space with the elegance of our Velveteen blankets. Each touch exudes sophistication, turning any room into a haven of style and comfort.`,
      nameToFind: 'Fleece Blankets'
    }
  ]

  return (
    <CustomProductTemplate
      onStartDesigningClick={onStartDesigningClick}
      categoryName={categoryName}
      //header
      headerTitle='Custom Blankets'
      headerSubtitle={`Discover the art of warmth with personalized blankets from SurgePrint, where each stitch tells a story. Elevate your product offerings with our Print-on-Demand services. From the cloud-like comfort of MinkyBlankets to the versatile embrace of Fleece Blankets and the opulent softness of Velveteen Blankets, we invite you to explore a world where coziness meets creativity.`}
      headerImage='/images/blankets-header.png'
      //design-with-ease
      easeTitle='Design a Blanket With Ease'
      easeOptions={easeOptions}
      //on-demand
      onDemandTitle='Custom On Demand Blankets'
      onDemandSubtitle={`Surge maintains priority for every order, no matter the quantity. Order one custom blanket with a design for your home, or order multiple for your store, charity, or public events.`}
      //explore-collection
      exploreTitle='Explore Our Blanket Collection'
      exploreMainProduct={exploreMainProduct}
      exploreOtherProducts={exploreOtherProducts}
      //gift-memory
      giftMemoryImage='/images/blankets-gift-memory.png'
      giftMemoryTitle='Gift a Memory, Share a Smile'
      giftMemoryDescription={`SurgePrint blanket makes for unforgettable gifts from your online shop to its end customers. Add a personal touch to birthdays, anniversaries, or holidays with personalized blanket that speaks volumes. Surprise your customers with a thoughtful and practical present they'll cherish every day.`}
      //start-today
      startTodayTitle='Elevate Your Business with SurgePrint'
      startTodaySubtitle={`SurgePrint isn't just about blankets; it's about elevating your business to new heights. Partner with us to offer your customers high-quality, customizable blankets that don't just warm their bodies but also warm their hearts.`}
      startTodayLink={`Step Into the Unique World of SurgePrint Blankets
        Where Every Snuggle Tells a Story!`}
      //personalized
      personalizedTitle='Start Designing Today'
      personalizedSubtitle={`Empower your online shop to offer a unique and personalized experience. Start featuring custom Blankets in your inventory and discover the joy your customers will experience while warming their body with a Blanket that's uniquely theirs.`}
      //seo
      seoTitle='Print On Demand Blankets - Custom Blankets | Surge Print'
      seoDescription={`Explore personalized custom blankets at SurgePrint. Elevate your daily routine with unique designs. Perfect for gifts or keepsakes. Order your custom blanket today!`}
    />
  )
}

export default withRouter(LandingBlanketsPage);