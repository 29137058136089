import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import strings from '../localization';
import { getImage } from '../services/ImageService';
import Validators from "../constants/ValidatorTypes";
import {withSnackbar} from "notistack";
import {dateToString} from "../util/DateUtil";
import {getBusinessInformation} from "../services/BusinessInformation";
import {getOrderStatusString} from "../constants/OrderStatus";
import ReactDOM from 'react-dom';
import { getAdmins,
         createAdmin,
         deleteUser } from "../services/UserService";
import AddAdmin from "../components/AddAdmin";
import Helmet from 'react-helmet';

class Admins extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 12 }];


    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            perPage: 15,
            admins: [],
            dropdownOpen: null,
            siderbar: false,
            display: 'none',
            data: {
                email: null,
                password: null,
                firstName: null,
                lastName: null,
                searchTerm: null
            }
        }

        this.onPageClick = this.onPageClick.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
        this.goBack = this.goBack.bind(this);
        this.createAdmin = this.createAdmin.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    fetchData(){
        this.getAdmins(this.state.page, this.state.perPage);
    }

    keyPress(event) {

        if(event.key == 'Enter') {
            this.getAdmins(1, this.state.perPage);
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
 
        if (this.state.dropdownOpen) {
            if(event.target.className != "opt" && event.target.className != "dropdown-opt"){
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    dropdownOpen: false
                });
            }
        }
    }

    createAdmin(){
        createAdmin(this.state.data).then(response => {

            this.setState({
                ...this.state,
                siderbar: false,
                display: 'none',
                data: {
                    ...this.state.data,
                    email: null,
                    password: null,
                    firstName: null,
                    lastName: null
                },
            });

            setTimeout(() => {  
                this.fetchData();
            }, 1000);
        });
    }

    getAdmins(page, perPage){
        getAdmins({
            page : page, 
            perPage : perPage,
            searchTerm: this.state.data.searchTerm
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                admins: response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page
            })
        });
    }

    openDropdown(id,e) {
        e.stopPropagation();

        if(this.state.dropdownOpen != null){
            this.setState({
                ...this.state,
                dropdownOpen: null
            })
        }else{
            this.setState({
                ...this.state,
                dropdownOpen: id
            })
        }
       
    }

    editItem(item){

        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        // if(this.state.type == "Category"){
        //     this.setState({
        //         ...this.state,
        //         data: {
        //             ...this.state.data,
        //             parentCategory: item.parent_category,
        //             categoryImage: item.category_image,
        //             name: item.name,
        //             id: item.id
        //         },
        //         siderbar: true,
        //         display: 'block',
        //         dropdownOpen: null
        //     });
        // }else{
        //     this.props.history.push('/old/products/' + item.id);
        // }

    }

    deleteItem(id){

        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        deleteUser({
            id: id
        }).then(response => {
    
            if(!response.ok) {
                return;
            }

            this.props.enqueueSnackbar("Admin successfully deleted", {variant: 'success'});

            setTimeout(() => {  
                this.fetchData();
            }, 1000);
        
        });
       
    }

    openSiderbar(){
        this.setState({
            ...this.state,
            siderbar: true,
            display: 'block'
        });
    }

    goBack(){
        this.setState({
            ...this.state,
            siderbar: false,
            display: 'none',
            data: {
                ...this.state.data,
                categoryImage: null,
                parentCategory: null,
                name: null,
                id: null
            }
        });
    }


    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.page = i;
        this.getAdmins(i, this.state.perPage);
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        this.getAdmins(this.state.page, this.state.perPage);
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        this.getAdmins(this.state.page, this.state.perPage);
    }


    renderAdmins() {
        let result = [];
        
        for(let item of this.state.admins)
        {
            console.log(item);
            result.push(

                <div className="item">
                    <div className="admin-id">{ item.id }</div> 
                    <div className="admin-fist-name">{ item.first_name }</div>
                    <div className="admin-last-name">{ item.last_name }</div>
                    <div className="admin-email">{ item.email }</div>
                    <div className="admin-date">{ dateToString(item.date_created) }</div>
                    <div className="options">
                        <div className="opt" onClick={ (e) => this.openDropdown(item.id, e) }><img src="/images/tackice.png"></img></div>
                        <div className={ this.state.dropdownOpen == item.id ? "dropdown active" : "dropdown" }>
                            <div className="dropdown-opt" onClick={ () => this.deleteItem(item.id) }>Delete</div>
                        </div>
                    </div>
                </div>
            );
        }
        return result;
    }

    render() {

        return (
            <div id="orders">
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <div id="category-overlay" className='overlay' style={{display: this.state.display}} onClick={ () => this.goBack() }></div>
                <div id="siderbar" style={{opacity : this.state.display == "block" ? "1" : "0",
                        right : this.state.display == "block" ? "0" : "-492px",
                        height: document.getElementById("root").offsetHeight + 114 + 'px'}}>
                        {
                            this.state.siderbar &&
                            <AddAdmin data={this.state.data} errors = {this.state.errors} onChange={this.changeData} 
                                goBack={this.goBack} submited={this.createAdmin}/>
                        }
                </div>

                <div className={'search'}>
                    <input className={'category-search'}
                        value={this.state.data.searchTerm}
                        onChange={this.changeData}
                        name={'searchTerm'}
                        placeholder={'Type to search...'}
                        onKeyPress={ this.keyPress }
                    />
                    <img src={'/images/search.png'} />
                </div>

                <div className="button-new">
                    <Button className={"new-product"} onClick={ () => this.openSiderbar()}>Add new admin</Button>
                </div>

                <div>

                </div>
                <div className="list">
                    <div className={'list-helper'}>
                        <div className="header">
                            <div className="admin-id">ID</div>
                            <div className="admin-fist-name">First Name</div>
                            <div className="admin-last-name">Last Name</div>
                            <div className="admin-email">Email</div>
                            <div className="admin-date">Date</div>
                        </div>
                        { this.renderAdmins() }
                    </div>
                </div>

                <div className={'pagination'}>
                    {
                        this.state.page > 1 &&
                        <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                    }
                    <div className={'pages'}>
                        {this.renderPagination()}
                    </div>
                    {
                        this.state.page < this.state.totalPages &&
                        <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                    }
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Admins)));