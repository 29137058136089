import { getOrderStatusString } from 'src/constants/OrderStatus';
import colors from 'src/scss/_colors.scss';

const OrderEnum = {
    NewOrder: 0,
    NeedsCustomization: 1,
    Pending: 4,
    ReadyForPrint: 9,
    Cancel: 7,
    InProduction: 10,
    Shipped: 15,
    Issues: -1,
};

export function getStatusForFilters(status) {
  switch(status) {
    case OrderEnum.NewOrder:
      return {
        name: 'New',
        color: colors.green,
      }
    case OrderEnum.NeedsCustomization:
      return {
        name: 'Needs customization',
        color: colors.lightGray,
      }
    case OrderEnum.Pending:
      return {
        name: 'Pending',
        color: colors.purple
      }
    case OrderEnum.ReadyForPrint:
      return {
        name: 'Ready for print',
        color: colors.blue
      }
    case OrderEnum.Cancel:
      return {
        name: 'Canceled',
        color: colors.red
      }
    case OrderEnum.InProduction:
      return {
        name: 'In Production',
        color: colors.orange
      }
    case OrderEnum.Shipped:
      return {
        name: 'Shipped',
        color: colors.yellow
      }
    case OrderEnum.Issues:
      return {
        name: 'Issue',
        color: colors.red
      }
    default:
      return {
        name: getOrderStatusString(status),
        color: colors.secondary
      };
  }
}

export function getStatusForAll(status) {
  const name = getOrderStatusString(status)

  if(status === 7) {
    return {
      name,
      color: colors.red
    }
  }

  if(status === 9) {
    return {
      name,
      color: colors.blue
    }
  }

  //Before production
  if(status <= 9)
    return {
      name,
      color: colors.yellow
    };
  //Case post production
  if(status <= 17 || status >= 30)
    return {
      name,
      color: colors.blue,
    }
  //Case payment and order Issues
  return {
    name,
    color: colors.red,
  }
}