import React from 'react';
import {Button, TextField} from "@material-ui/core";

import strings from '../localization';
import {getError, hasError} from "../functions/Validation";
import SelectControl from './controls/SelectControl';
import Dropzone from 'react-dropzone';
import { getImage } from '../services/ImageService';


const AddVendor = ({
    onChange,
    data,
    errors,
    submited,
    goBack,
    onDropPicture,
    categories
}) => {

    return(
        <div id="add-category">
            <div className="header">
            Add new vendor
            </div>
            <div className="form">
                <div className="content">
                    <TextField
                        name='name'
                        onChange={ onChange }
                        className={"category-name"}
                        type='text'
                        value={ data.name }
                        error={ getError(errors, 'name') }
                        helperText={ getError(errors, 'name') }
                        placeholder = { "Name" }
                        variant="outlined"
                    />

                    <div className="buttons">
                        <Button className="save" onClick={submited}>{ strings.category.save }</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddVendor;