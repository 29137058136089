import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import PricesInput from 'src/redesign/components/Form/Sidepane/PricesInput';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Loader from 'src/redesign/components/Loader';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { createEditProductVariationShipping, getShippingPricingForProductVariation } from 'src/services/admin/ProductVariationService';
import { mapVariationShippingPrices } from '../../../helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ProductVariationShipping = ({ id, productVariationIds, onLoad }) => {
  const [loading, setLoading] = React.useState(false);
  const [variationShippingInitialValues, setVariationShippingInitialValues] = React.useState(null);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadShippingPrices();
  }, [id]);

  const loadShippingPrices = async () => {
    if(!id) return;
    setLoading(true);
    try {
      const { data } = await getShippingPricingForProductVariation(id);
      setVariationShippingInitialValues(mapVariationShippingPrices(data));
    } catch(error) {
      openSnackBar('Failed to load variation shipping', false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const onUpdateShipping = async values => {
    await createEditProductVariationShipping({
      data: values,
      ids: productVariationIds
    });
    onLoad();
  }

  return (
    loading
    ?
    <Loader width={100} height={100} color={colors.primary} className='edit-variation-shipping-loader'/>
    :
    <FinalForm
      onSubmit={onUpdateShipping}
      initialValues={variationShippingInitialValues}
      render={({ handleSubmit, valid, submitting }) => (
        <form onSubmit={handleSubmit} className='edit-variation-shipping'>
          <PricesInput
            containerClassName='edit-variation-prices-input-container'
            titleClassName='edit-variation-prices-input-title'
            contentClassName='edit-variation-prices-content'
            inputClassName='edit-variation-prices-input'
            isUS
            standard={{
              first: 'usFirstStandard',
              eachAdditional: 'usOtherStandard',
            }}
            expedited={{
              first: 'usFirstExpedited',
              eachAdditional: 'usOtherExpedited',
            }}
            overnight={{
              first: 'usFirstOvernight',
              eachAdditional: 'usOtherOvernight',
            }}
          />
          <PricesInput
            containerClassName='edit-variation-prices-input-container'
            titleClassName='edit-variation-prices-input-title'
            contentClassName='edit-variation-prices-content'
            inputClassName='edit-variation-prices-input'
            isUS={false}
            standard={{
              first: 'intFirstStandard',
              eachAdditional: 'intOtherStandard',
            }}
            expedited={{
              first: 'intFirstExpedited',
              eachAdditional: 'intOtherExpedited',
            }}
          />
          <div className='edit-variation-prices-title'>
            Vendor shipping prices
          </div>
          <PricesInput
            containerClassName='edit-variation-prices-input-container'
            titleClassName='edit-variation-prices-input-title'
            contentClassName='edit-variation-prices-content'
            inputClassName='edit-variation-prices-input'
            isUS
            standard={{
              first: 'vendorUsFirstStandard',
              eachAdditional: 'vendorUsOtherStandard',
            }}
            expedited={{
              first: 'vendorUsFirstExpedited',
              eachAdditional: 'vendorUsOtherExpedited',
            }}
            overnight={{
              first: 'vendorUsFirstOvernight',
              eachAdditional: 'vendorUsOtherOvernight',
            }}
          />
          <PricesInput 
            containerClassName='edit-variation-prices-input-container'
            titleClassName='edit-variation-prices-input-title'
            contentClassName='edit-variation-prices-content'
            inputClassName='edit-variation-prices-input'
            isUS={false}
            standard={{
              first: 'vendorIntFirstStandard',
              eachAdditional: 'vendorIntOtherStandard',
            }}
            expedited={{
              first: 'vendorIntFirstExpedited',
              eachAdditional: 'vendorIntOtherExpedited',
            }}
          />
          <SubmitPrimaryButton
            text='Save'
            width={224}
            className='edit-variation-prices-submit'
            type='submit'
            loading={submitting}
            disabled={!valid || submitting || productVariationIds.length == 0}
          />
        </form>
      )}
    />
  );
}

ProductVariationShipping.propTypes = {

}

export default ProductVariationShipping;