import React from 'react';
import { number, string } from 'prop-types';

const IconTag = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M7.8075 0.435L14.5575 7.185C14.8275 7.455 15 7.8375 15 8.25C15 8.6625 14.835 9.0375 14.5575 9.3075L9.3075 14.5575C9.0375 14.835 8.6625 15 8.25 15C7.8375 15 7.4625 14.835 7.1925 14.565L0.4425 7.815C0.165 7.5375 0 7.1625 0 6.75V1.5C0 0.675 0.675 0 1.5 0H6.75C7.1625 0 7.5375 0.165 7.8075 0.435ZM1.5 2.625C1.5 3.2475 2.0025 3.75 2.625 3.75C3.2475 3.75 3.75 3.2475 3.75 2.625C3.75 2.0025 3.2475 1.5 2.625 1.5C2.0025 1.5 1.5 2.0025 1.5 2.625Z" fill={color}/>
  </svg>
);

IconTag.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconTag;