import React from 'react';
import Page from 'src/redesign/components/Page';
import PrintingMethodsContainer from 'src/redesign/components/PrintingMethodsContainer';

import './style.scss';

const PrintingMethodsDTG = () => (
  <Page
    seoTitle='Printing Methods DTG | Surge Print'
  >
    <div className='printing-methods-dtg'>
      <PrintingMethodsContainer
        imageText='Printing Methods DTG'
        title='DTG printing, or Direct-to-Garment printing, is a digital printing process that uses a specialized printer to print designs directly onto a garment or fabric. DTG printing is a relatively new technology that has revolutionized the custom apparel industry, allowing for high-quality, full-color prints on a wide range of materials.'
      >
        <div className='printing-methods-dtg-description'>
          The DTG printing process involves a specialized printer that is designed to print directly onto garments. The printer uses special water-based inks that are absorbed by the fabric, creating vibrant, high-quality prints. Unlike screen printing, which requires a separate screen for each color, DTG printing can print full-color designs in a single pass, making it a faster and more cost-effective solution for small to medium-sized print runs.
          <br/>
          <br/>
          To perform DTG printing, the garment is first pre-treated with a special solution that helps the ink bond to the fabric. The printer then applies the ink directly to the garment, using a series of print heads to create the desired design. Once the printing is complete, the garment is heat pressed to cure the ink, ensuring that the print is durable and long-lasting.
          <br />
          <br />
          DTG printing is a popular choice for custom apparel businesses, as it allows for fast turnaround times, low minimum order quantities, and the ability to print highly detailed, full-color designs. It is also a more eco-friendly printing solution, as it does not require the use of toxic chemicals or excessive water like traditional screen printing methods.
          <br/>
          <br/>
          <br/>
          Overall, DTG printing has revolutionized the custom apparel industry, offering businesses and individuals a fast, cost-effective, and high-quality printing solution for a wide range of garments and fabrics.
        </div>
      </PrintingMethodsContainer>
    </div>
  </Page>
);

export default PrintingMethodsDTG;