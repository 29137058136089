import React from 'react';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { calculateShoppingCartSummary, getShoppingCartItems, removeItem, setQuantity } from 'src/services/ShoppingCart';
import CartSummary from './components/Summary';
import CartTable from './components/Table';
import Helmet from 'react-helmet';

import './style.scss';

const PER_PAGE = 10;

const Cart = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalItems, setTotalItems] = React.useState(0);

  const [totalPrice, setTotalPrice] = React.useState(0);
  const [isPriceLoading, setIsPriceLoading] = React.useState(true);

  const [items, setItems] = React.useState([]);
  const [isListLoading, setIsListLoading] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  const calculateTotalItems = items => items.reduce((acc, x) => acc + x.quantity,0);

  // #region load

  React.useEffect(() => {
    getItems(1);
    getTotal();
  }, []);

  const getTotal = async () => {
    setIsPriceLoading(true);
    try {
      const { data: { total } } = await calculateShoppingCartSummary();

      setTotalPrice(total);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load price', false)
    } finally {
      setIsPriceLoading(false);
    }
  }

  const getItems = async page => {
    setIsListLoading(true);
    try {
      const { data: { result = [], total = 0 } = {} } = await getShoppingCartItems({ page, perPage: PER_PAGE });
      setItems(result);
      setTotalPages(Math.ceil(total/PER_PAGE));
      setTotalItems(calculateTotalItems(result));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load items', false);
    } finally {
      setIsListLoading(false);
    }
  }

  //#endregion load

  //#region save

  const deleteItem = async id => {
    setIsListLoading(true);
    try {
      await removeItem({ id });

      setCurrentPage(1);
      await getItems(1);
      await getTotal();

      const updatedItems = items.filter(x => x.id !== id);

      setItems(updatedItems);
      setTotalItems(calculateTotalItems(updatedItems));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to delete item', false);
    } finally {
      setIsListLoading(false);
    }
  }

  const changeQuantity = async (id, quantity) => {
    setIsListLoading(true);
    try {
      await setQuantity({ id, quantity })

      const updatedItems = items.map(x => x.id === id ? ({ ...x, quantity }) : x);

      setItems(updatedItems);
      setTotalItems(calculateTotalItems(updatedItems));
      getTotal();
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to change quantity of item', false);
    } finally {
      setIsListLoading(false);
    }
  }

  //#endregion save

  return (
    <ApplicationPage selectedTab='place-an-order'>
      <Helmet>
          <meta name="robots" content="noindex, follow" />
      </Helmet>
      <ApplicationPageContent title='Cart'>
        <div className='cart'>
          <CartTable items={items} isLoading={isListLoading} onDeleteItem={deleteItem} onChangeQuantity={changeQuantity} currentPage={currentPage} setCurrentPage={page => {
            setCurrentPage(page);
            getItems(page);
          }} totalPages={totalPages}/>
          <CartSummary totalItems={totalItems} totalPrice={totalPrice} isLoading={isPriceLoading}/>
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default Cart;