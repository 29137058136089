import React from 'react';
import { number, string } from 'prop-types';

const IconYoutube = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={width} height={height} rx="8" fill="none"/>
    <path d="M28.7911 9.60336C28.6392 9.03989 28.3424 8.52603 27.9303 8.11292C27.5181 7.6998 27.0049 7.40184 26.4418 7.24869C24.3538 6.67536 16.0004 6.66603 16.0004 6.66603C16.0004 6.66603 7.64844 6.65669 5.55911 7.20469C4.99634 7.36489 4.4842 7.66706 4.07186 8.0822C3.65952 8.49735 3.36082 9.01152 3.20444 9.57536C2.65377 11.6634 2.64844 15.994 2.64844 15.994C2.64844 15.994 2.64311 20.346 3.18977 22.4127C3.49644 23.5554 4.39644 24.458 5.54044 24.766C7.64977 25.3394 15.9804 25.3487 15.9804 25.3487C15.9804 25.3487 24.3338 25.358 26.4218 24.8114C26.9851 24.6585 27.4988 24.3612 27.912 23.9489C28.3252 23.5367 28.6236 23.0237 28.7778 22.4607C29.3298 20.374 29.3338 16.0447 29.3338 16.0447C29.3338 16.0447 29.3604 11.6914 28.7911 9.60336ZM13.3284 20.006L13.3351 12.006L20.2778 16.0127L13.3284 20.006Z" fill={color}/>
  </svg>
);

IconYoutube.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconYoutube;