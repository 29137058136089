import React from 'react';

import Page from 'src/redesign/components/Page';
import SignupLogin from 'src/redesign/components/SignupLogin';
import WLMLogo from 'src/redesign/components/Icon/WlmLogo';
import WLMIcon from 'src/redesign/components/Icon/Wlm';
import IconAnd from 'src/redesign/components/Icon/And';
import Image from 'src/redesign/components/Image';
import { LogoFooter } from 'src/redesign/components/Logo';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss'
import colors from 'src/scss/_colors.scss';

const WLMPage = () => (
  <Page>
    <div className='wlm-page-container'>
      <div className='wlm-page-content'>
        <div className='wlm-image-container'>
          <Image alt={getImageAlt()} src={'/images/wlm.png'} className='wlm-image' />
          <div className='wlm-text-container'>
            <h1 className='wlm-text'>
              Welcome White Label Manufacturing Customers!
            </h1>
            <WLMLogo width={472} height="72" color={colors.blackDark} />
          </div>
        </div>
        <div className='wlm-partner-container'>
          <LogoFooter />
          <IconAnd width={119} height={162} color={colors.primary} />
          <div className='wlm-icon-wrapper'>
            <WLMIcon width={310} height={45} color={colors.blackDark} strokeWidth={20}/>
          </div>
        </div>
        <div className='wlm-title'>
          We are excited to partner with White Label Manufacturing, the leading printing facility in the print on demand industry. We understand that you are looking for the best in class printing service for your business, and that's why bringing you the best of both worlds.
        </div>
        <div className='wlm-icon-small'>
          <WLMIcon width={76} height={11} color={colors.blackDark} strokeWidth={20} />
        </div>
        <video src="/images/wlm-video.mp4" autoPlay loop muted playsInline className='wlm-video'/>
        <div className='wlm-description'>
          SurgePrint is the leading print on demand platform that provides seamless integrations, product creation, and order routing. Our technology simplifies the entire process of creating and selling your custom products online.
          <br />
          <br />
          To get started, please schedule a call with your dedicated Account Executive by clicking the button below and create your SurgePrint account. As a valued White Label Manufacturing customer, you will receive priority support and discounted pricing.
        </div>
        <div className='wlm-signup-container'>
          <SignupLogin />
        </div>
        <div className='wlm-description-small'>
          Thank you for choosing SurgePrint as your print on demand partner. We look forward to working with you to grow your business!
        </div>
      </div>
    </div>
  </Page>
);

export default WLMPage;