import React, {Component} from 'react'

import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import {Button, TextField} from "@material-ui/core";
import SelectControl from "../controls/SelectControl";
import Validators from "../../constants/ValidatorTypes";

import {getCountryList} from "../../services/CountryService";
import { setCustomerProfileInfo,
        changeUserPassword,
        changeHoldCustomization,
        deactivateUser} from "../../services/UserService";
import FormComponent from "../../common/FormComponent";
import update from "immutability-helper";
import {getUserFromLocalStorage} from "../../base/OAuth";

class MyProfile extends FormComponent {

    validationList = {
        firstName: [ {type: Validators.REQUIRED } ],
        lastName: [ {type: Validators.REQUIRED } ],
        email: [ {type: Validators.REQUIRED } ],
        country: [ {type: Validators.REQUIRED } ],
        storeUrl: [ {type: Validators.REQUIRED} ]
    };


    constructor(props) {
        super(props);

        const user = getUserFromLocalStorage();

        this.state = {
            data: {
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                country: user.customer ? user.customer.country : null,
                currentPassword: null,
                newPassword: null,
                confirmPassword: null,
                customization: user.customer ? user.customer.hold_customization : '',
                storeUrl: user.customer ? user.customer.store_url_profile : '',
                deactivateProfile: false,
            },
            countries: [],
        }

        this.setCustomerProfileInfo = this.setCustomerProfileInfo.bind(this);
        this.getCountryList = this.getCountryList.bind(this);
        this.changeDataStore = this.changeDataStore.bind(this);
    }

    componentDidMount(){
        this.getCountryList();
    }

    getCountryList(){
        getCountryList().then(response => {
           
             this.setState({
                 ...this.state,
                countries: response.data.countries
            })
        });
    }

    deactivateUser(){
        deactivateUser().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    deactivateProfile: true
                }
            })

            this.props.enqueueSnackbar('Your profile is deactivated!', { variant: 'success' });

            this.logout();
        });
    }

    setCustomerProfileInfo(){
        if(!this.validate()) {
            this.props.enqueueSnackbar("Please fill all required fields!", {variant: 'error'});
            return;
        }


        setCustomerProfileInfo(this.state.data).then(response => {

            if(!response.ok) {
                return;
            }

            window.location.reload();
            this.props.enqueueSnackbar('Successfully saved!', { variant: 'success' });
        });

    }

    changeUserPassword(){

        if(this.state.data.newPassword != this.state.data.confirmPassword){
            this.props.enqueueSnackbar('The password confirmation does not match.!', { variant: 'error' });
            return;
        }

        changeUserPassword({
            newPassword: this.state.data.newPassword,
            oldPassword: this.state.data.currentPassword
        }).then(response => {

            if(!response.ok) {
                this.props.enqueueSnackbar('Incorect password!', { variant: 'error' });
                return;
            }
            
            window.location.reload();
            this.props.enqueueSnackbar('Successfully changed password!', { variant: 'success' });
        });
    }


    toggle(){

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
               customization: !this.state.data.customization
            }
           
        })

        changeHoldCustomization().then(response => {

            this.props.enqueueSnackbar('Successfully changed hold customization!', { variant: 'success' });
        });
    }

    changeDataStore(event, data = 'data') {
        let value = event.target.value;
        value = value.replace('https://','')
        value = value.replace('/admin','')

        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: value} })
        });
    }

    render() {

        return (
            <div id="my-profile">
                <div className="left">
                    <div className="my-profile-header">Personal info</div>
                    <div className="row">
                        <div className="name">First name</div>
                        <TextField
                            className={"search-field"}
                            autoFocus
                            name='firstName'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.firstName }
                            placeholder = { "First name" }
                            variant="outlined"
                        />
                    </div>
                    <div className="row">
                        <div className="name">Last name</div>
                        <TextField
                            className={"search-field"}
                            name='lastName'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.lastName }
                            placeholder = { "Last name" }
                            variant="outlined"
                        />
                    </div>
                    <div className="row">
                        <div className="name">Email</div>
                        <TextField
                            className={"search-field"}
                            name='email'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.email }
                            placeholder = { "Email" }
                            variant="outlined"
                        />
                    </div>
                    <div className="row">
                        <div className="name">Country</div>
                        <SelectControl
                            className='payment-country'
                            options={this.state.countries}
                            valueKey = { 'id' }
                            onChange={ this.changeData }
                            nameKey= { 'name' }
                            name = {'country'}
                            selected={this.state?.data?.country?.id}
                            placeholder = {'Country'}
                        />
                    </div>
                    <div className="row">
                        <div className="name">Store URL</div>
                        <TextField
                            className={"search-field"}
                            name='storeUrl'
                            onChange={ this.changeDataStore }
                            margin="normal"
                            value={ this.state.data.storeUrl }
                            placeholder = { "Store URL" }
                            variant="outlined"
                        />
                    </div>
                    <div className="row">
                        <Button onClick={ () => this.setCustomerProfileInfo() }>Save changes</Button>
                    </div>

                    <div className="my-profile-header">Change password</div>
                    <div className="row">
                        <div className="name">Current password</div>
                        <TextField
                            type={"password"}
                            className={"search-field"}
                            name='currentPassword'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.currentPassword }
                            placeholder = { "Current password" }
                            variant="outlined"
                        />
                    </div>
                    <div className="row">
                        <div className="name">New password</div>
                        <TextField
                            className={"search-field"}
                            name='newPassword'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.newPassword }
                            placeholder = { "New password" }
                            variant="outlined"
                            type={"password"}
                        />
                    </div>
                    <div className="row">
                        <div className="name">Confirm password</div>
                        <TextField
                            className={"search-field"}
                            name='confirmPassword'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.confirmPassword }
                            placeholder = { "Confirm password" }
                            variant="outlined"
                            type={"password"}
                        />
                    </div>
                    <div className="row">
                        <Button onClick={ () => this.changeUserPassword() }>Save changes</Button>
                    </div>
                </div>
                <div className="right">

                    <div className="row">
                        <div className="name">Hold for Customization</div>
                        <div className={'table-control'}>
                            <label className="switch">
                                <input checked={this.state.data.customization} onClick={ () => this.toggle()} type="checkbox"/>
                                <span className={this.state.data.customization ? "slider round active" : "slider round"}/>
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="name">Deactivate my account</div>
                        <div className={'table-control'}>
                            <label className="switch">
                                <input checked={this.state.data.deactivateProfile} onClick={ () => this.deactivateUser()} type="checkbox"/>
                                <span className={this.state.data.deactivateProfile ? "slider round active" : "slider round"}/>
                            </label>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        logout: Actions.logout
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProfile)));
