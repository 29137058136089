import React, {Component} from 'react'

import {Grid, IconButton} from "@mui/material";
import  { ChevronRight } from "@mui/icons-material"

class DrawerWrapper extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { children } = this.props;

        return (

            <Grid className='drawer-wrapper'>
                <div className='drawer-wrapper-header'>
                    <IconButton onClick={ () => this.props.onBack() }>
                        <ChevronRight/>
                    </IconButton>
                </div>
                <div className='drawer-wrapper-content'>
                    { children }
                </div>
            </Grid>
        );
    }
}

export default DrawerWrapper;