import React from 'react';
import OrderItem from 'src/redesign/components/OrderItem';
import { v4 as uuid } from 'uuid';

import './style.scss';

const OrderDetailsItems = ({ order, onUpdate }) => {
  const items = order.items ?? [];

  return (
    <div className='order-details-items'>
        {items.map(x => <OrderItem key={uuid()} item={x} onUpdate={item => onUpdate({...order, items: items.map(x => x.id === item.id ? item : x)})} />)}
    </div>
  )
}

export default OrderDetailsItems;