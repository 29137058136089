export const mapLoadedUser = user => ({
  businessAddress : user.customer ? user?.customer?.business_information?.business_address : '',
  company: user.customer ? user?.customer?.company_name : '',
  addressLineTwo: user.customer ? user?.customer?.business_information?.address_line_two : '',
  city: user.customer ? user?.customer?.business_information.city : '',
  stateProvince: user.customer ? user?.customer?.business_information?.state_province : '',
  country: user.customer ? user?.customer?.business_information?.country?.id : '',
  postalCode: user.customer ? user?.customer?.business_information?.postal_code : '',
  phoneNumber: user.customer ? user?.customer?.business_information?.phone_number : '',
  email: user.customer ? user?.customer?.business_information?.email : ''
});

export const mapUpdatedUser = ({ businessAddress, company, addressLineTwo, city, stateProvince, country, postalCode, phoneNumber, email }) => ({
  business_address: businessAddress,
  company_name: company,
  address_line_two: addressLineTwo,
  city,
  state_province: stateProvince,
  country,
  postal_code: postalCode,
  phone_number: phoneNumber,
  email: email
})