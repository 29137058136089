import React from 'react';
import { string } from 'prop-types';

import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const GiftMemory = ({ image, title, description }) => (
  <div className='gift-memory-container'>
    <Image alt={getImageAlt()} src={image} className='gift-memory-image' />
    <div className='gift-memory-text-container'>
      <h2 className='gift-memory-title'>
        {title}
      </h2>
      <div className='gift-memory-description'>
        {description}
      </div>
    </div>
  </div>
);

GiftMemory.propTypes = {
  image: string.isRequired,
  title: string.isRequired,
  description: string.isRequired
}

export default GiftMemory;