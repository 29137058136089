import React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import IconChevronDown from 'src/redesign/components/Icon/Chevron/Down';
import IconChevronUp from 'src/redesign/components/Icon/Chevron/Up';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ListTable = ({ items, columns, isExpandable = false }) => {
  const [expandedIndex, setExpandedIndex] = React.useState(-1);

  const renderData = (column, item) => {
    if(column.isColor) return renderColorData(column, item);

    return (
      <div key={uuid()} className={classNames('list-table-body-item', {'list-table-body-item-price': column.isPrice })} style={{ flex: column.flex }}>
        {item[column.name]}
      </div>
    )
  }

  const renderColorData = (column, item) => {
    const color = item[column.name];

    if(typeof color === 'string')
      return (
        <div className='list-table-body-item' style={{ flex: column.flex }}>
          {color}
        </div>
      );

    return (
      <div key={uuid()} className='list-table-body-color-item' style={{ flex: column.flex }}>
        <div className='list-table-color' style={{ backgroundColor: color.name }}/>
        <div className='list-table-color-name'>
          {color.customerColorName}
        </div>
      </div>
    )
  }

  return (
    <div className='list-table-container'>
      <div className='list-table-row'>
        {columns.map(x => (
          <div key={uuid()} className='list-table-header-item' style={{ flex: x.flex }}>
            {x.text}
          </div>
        ))}
        <div key={uuid()} style={{ width: 14 }} />
      </div>
      {items.map((x, i) => {
        const toggleExpanded = () => setExpandedIndex(expandedIndex == i ? -1 : i);

        return (
          <>
            <div
              key={uuid()}
              className={classNames(
                'list-table-row',
                {'list-table-row-expandable': isExpandable}
              )}
              onClick={toggleExpanded}
            >
              {columns.map(c => renderData(c, x))}
              {isExpandable && (
                <div key={uuid()} className='list-table-expandable'>
                  <ExpandableIcon isExpanded={expandedIndex == i} />
                </div>
              )}
            </div>
            {isExpandable &&
            expandedIndex == i &&
            x.items.map((item) => (
              <div className='list-table-row-expanded-items'>
                {columns.map(c => renderData(c, item))}
              </div>
            ))}
            </>
          )}
        )}
    </div>
  )
};

export default ListTable;

const ExpandableIcon = ({ isExpanded }) => {
  return isExpanded
  ? <IconChevronUp width={14} height={8} color={colors.primary} />
  : <IconChevronDown width={14} height={8} color={colors.primary} />;
}