import React from 'react';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Loader from 'src/redesign/components/Loader';
import GroupBy from 'src/redesign/components/GroupBy';
import { getProductVariationWithAttributes } from 'src/services/admin/ProductVariationService';
import ListTable from 'src/redesign/components/ListTable';

import './style.scss';

const GROUP_BY_SIZE_OPTION = { key: 'size', text: 'Size' };

const ProductDetailsVariants = ({ id }) => {
  const [loading, setLoading] = React.useState(false);
  const [selectedGroupBy, setSelectedGroupBy] = React.useState(GROUP_BY_SIZE_OPTION.key);

  const [sizes, setSizes] = React.useState([]);
  const [colors, setColors] = React.useState([]);
  const [secondAttributeName, setSecondAttributeName] = React.useState('');
  const [isSecondAttributeColor, setIsSecondAttributeColor] = React.useState(true);
  const [groupedVariants, setGroupedVariants] = React.useState([]);

  const isSizeGroupingSelected = selectedGroupBy == GROUP_BY_SIZE_OPTION.key;

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadVariants();
  }, []);

  const loadVariants = async () => {
    setLoading(true);
    try {
      const { data } = await getProductVariationWithAttributes(id);

      processVariants(Object.values(data));
    } catch (error) {
      console.error(error);
      openSnackBar('Failed to load variants!', false);
    } finally {
      setLoading(false);
    }
  }

  const processVariants = (variants) => {
    const sizeAttributeName = Object.values(variants).flatMap(item => item.attributes).find(value => value.attributeName.includes('Size'))?.attributeName;
    let secondAttributeName = Object.values(variants).flatMap(item => item.attributes).find(value => value.attributeName.includes('Color'))?.attributeName;
    const printAreaAttributeName = Object.values(variants).flatMap(item => item.attributes).find(value => value.attributeName.includes('Print'))?.attributeName;
    const groupedVariants = [];
    const uniqueSizes = [];
    const uniqueColors = [];

    if(!secondAttributeName) {
      secondAttributeName = Object.values(variants).flatMap(item => item.attributes).find(value  => value.attributeName != sizeAttributeName)?.attributeName;
      setIsSecondAttributeColor(false);
    }

    for(const variant of variants) {
      const price = variant.price;

      const size = variant.attributes.find((x) => x.attributeName == sizeAttributeName);
      const color = variant.attributes.find((x) => x.attributeName == secondAttributeName);
    
      // has no color or sizes
      if(!color || !size) continue;
      
      if(!uniqueSizes.some((s) => s.name == size.name)) uniqueSizes.push(size);
      if(!uniqueColors.some((c) => c.name == color.name)) uniqueColors.push(color);

      const print = variant.attributes.find((x) => x.attributeName == printAreaAttributeName);
      const isFrontPrintArea = print != null ? print?.name == 'Front' : true;

      if(groupedVariants.some((x) => (x.price == price && x.size.name == size.name && x.color.name == color.name) || !isFrontPrintArea)) continue;
      
      groupedVariants.push({ price, size, color });
    }

    setSizes(uniqueSizes);
    setColors(uniqueColors);
    setSecondAttributeName(secondAttributeName);
    setGroupedVariants(groupedVariants);
  }

  const sizeColumn = {
    name: 'size',
    text: 'Size',
    flex: '25%'
  }

  const colorColumn = {
    name: 'color',
    text: secondAttributeName,
    isColor: isSecondAttributeColor,
    flex: '25%',
  }

  const invertoryColumn = {
    name: 'invertory',
    text: 'Invertory',
    flex: '25%'
  }

  const priceColumn = {
    name: 'price',
    text: 'Price',
    isPrice: true,
    flex: '25%',
  }

  const columns = [
    isSizeGroupingSelected ? sizeColumn : colorColumn,
    isSizeGroupingSelected ? colorColumn : sizeColumn,
    invertoryColumn,
    priceColumn
  ]

  const data = 
    isSizeGroupingSelected
    ? sizes.map((x) => {
      const variationsWithSize = groupedVariants.filter((variation) => variation.size.name == x.name);
      const mappedVariationsWithSize = variationsWithSize.map((variation) => ({
        size: '',
        color: isSecondAttributeColor ? variation.color : variation.color.name,
        invertory: 'In stock',
        price: `${Number.parseFloat(variation.price).toFixed(2)}$`
      }));
      const minPrice = _.min(variationsWithSize.map((x) => Number.parseFloat(x.price)))?.toFixed(2);
      const maxPrice = _.max(variationsWithSize.map((x) => Number.parseFloat(x.price)))?.toFixed(2);

      return {
        size: x.name,
        color: `${variationsWithSize.length} ${secondAttributeName}${variationsWithSize.length > 1 ? 's' : ''}`,
        invertory: 'All in stock',
        price: `${minPrice} - ${maxPrice} $`,
        items: mappedVariationsWithSize
      }
    })
    : colors.map((x) => {
      const variationsWithColor = groupedVariants.filter((variation) => variation.color.name == x.name);
      const mappedVariationsWithColor = variationsWithColor.map((variation) => ({
        size: variation.size.name,
        color: '',
        invertory: 'In stock',
        price: `${Number.parseFloat(variation.price).toFixed(2)}$`
      }));
      const minPrice = _.min(variationsWithColor.map((x) => Number.parseFloat(x.price)))?.toFixed(2);
      const maxPrice = _.max(variationsWithColor.map((x) => Number.parseFloat(x.price)))?.toFixed(2);
      
      return {
        color: isSecondAttributeColor ? x : x.name,
        size: `${variationsWithColor.length} sizes`,
        invertory: 'All in stock',
        price: `${minPrice} - ${maxPrice} $`,
        items: mappedVariationsWithColor
      }
    });

    const GROUP_BY_COLOR_OPTION = { key: 'color', text: isSecondAttributeColor ? 'Color' : secondAttributeName };

  return (
      loading ? (
        <Loader 
          width={100}
          height={150}
          color={colors.primary}
        />
      ) : (
        <div className='product-details-variants'>
          Group by:
          <GroupBy 
            options={[
              GROUP_BY_SIZE_OPTION,
              GROUP_BY_COLOR_OPTION
            ]} 
            selectedGroupBy={selectedGroupBy}
            setSelectedGroupBy={setSelectedGroupBy}
          />
          <ListTable
            columns={columns}
            items={data}
            isExpandable
          />
        </div>
      )
  )
}

export default ProductDetailsVariants;