import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getVariationsForTemplate } from 'src/services/admin/ProductService';
import TextInput from 'src/redesign/components/Form/TextInput';
import IconCheckmark from 'src/redesign/components/Icon/Checkmark';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import Image from 'src/redesign/components/Image';
import { v4 as uuid } from 'uuid';
import { getImageAlt } from 'src/redesign/helpers';
import { getCustomerTemplate, getTemplatesForUser, updateCustomerTemplate } from 'src/services/admin/ProductVariationService';
import { getFromPathLocalStorage, removeFromPathLocalStorage, setFromPathLocalStorage } from 'src/base/OAuth';
import { addItemsToShoppingCart } from 'src/services/ShoppingCart';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const TemplateEdit = ({ history, match, location }) => {
  const id = match.params.id;
  const prodVarIds = location.state?.prodVarIds;
  const importedProduct = location.state?.importedProduct;
  const attributeValues = location.state?.attributeValues;
  const product = location.state?.product;
  const fromPage = getFromPathLocalStorage() === 'placeAnOrder';

  const [variations, setVariations] = React.useState([]);
  const [selectedVariation, setSelectedVariation] = React.useState(null);

  const [initialName, setInitialName] = React.useState('');
  const [initialDescription, setInitialDescription] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(true);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = async () => {
    setIsLoading(true);
    try {
      const { data: variations } = await getVariationsForTemplate({
        id,
        ids: prodVarIds
      });
      setVariations(variations);
      setSelectedVariation(variations[0]);

      const { data: { paginationInfo: { total = 0 }} } = await getTemplatesForUser(1);

      const name = `Product-${total + 1}`;
      const description = variations.length > 0 ? variations[0].variation.customer_template.description ? variations[0].variation.customer_template.description : variations[0].variation.product_variation.product.description : '';

      setInitialName(name);
      setInitialDescription(description);

      updateTemplate({ name, description });
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load templates', false);
    } finally {
      // setIsLoading(false);
    }
  }

  const updateTemplate = async ({ name, description }) => {
    if(!name) {
      openSnackBar('Please add product name', false);
      return;
    }
    if(!description) {
      openSnackBar('Please add product description', false);
      return;
    }
    // if(!selectedVariation) {
    //   openSnackBar('Please select variation', false);
    //   return;
    // }
    try {
      const { data: { arrayIds } } = await updateCustomerTemplate({
        id,
        name,
        description,
        orderTemplate: fromPage
      });

      openSnackBar('Successfully updated');

      setFromPathLocalStorage(null);

      if(fromPage) {
        await addItemsToShoppingCart(arrayIds);
        openSnackBar('Successfully added to shopping cart!');
        removeFromPathLocalStorage();
        // so backend have time to create all images while adding it to cart
        setTimeout(() => history.push('/cart'), 5000);
      } else if(importedProduct !== null) {
        localStorage.setItem('selectedTemplateId', id);
        history.push('/import-products/' + importedProduct?.id);
      } else {
        const { data } = getCustomerTemplate(id);
        if(data?.length !== 0) {
          history.push(`/export-product/${id}`, {
            fullSizeArt: history.location.state.fullSizeArt,
            mockupIds: history.location.state.mockupIds
          });
        } else {
          history.push(`/export-product/${id}`, {
            customerProductVariations: arrayIds,
            lastPage: "template-edit",
            fullSizeArt: history.location.state.fullSizeArt,
            mockupIds: history.location.state.mockupIds
          });
        }
      }

    } catch(error) {
      console.error(error);
      openSnackBar('Failed to publish template', false);
    }
  }

  const onPreviousStepClick = () => history.push('/product-selection-variation', { 
    attributeValues,
    product,
    importedProduct
  });

  const getVariationImage = x => {
    return x?.images ? x.images?.length > 0 ? process.env.REACT_APP_baseUrl + x.images[0].merged_image : '' : '';
  }

  const getVariationName = x => x.variation.product_variation ? x.variation.product_variation.name : x.variation.name;

  const isVariationSelected = x => x?.variation?.id === selectedVariation?.variation?.id;

  return (
    <ApplicationPage selectedTab='product-catalog'>
      <ApplicationPageContent title='Product Details' className='product-template-container'>
    <FinalForm onSubmit={updateTemplate} initialValues={{ description: initialDescription, name: initialName }} render={({ handleSubmit, submitting }) => (
      <>
        <div className='details-container'>
          {isLoading ? (
            <Loader width={150} height={150}  color={colors.primary}/>
          ): (
            <>
                <div className='fields-container'>
                  <div className='field-container'>
                    <div className='field-label'>
                      Internal Name
                    </div>
                    <TextInput name='name' placeholder='' className='field-input'/>
                  </div>
                  <div className='field-container'>
                    <div className='field-label'>
                      Description
                    </div>
                    <TextInput name='description' placeholder='' className='field-input' type='textarea'/>
                  </div>
                </div>
              <div className='variations-container'>
                {variations.map(x => (
                  <div className='template-variation' onClick={() => setSelectedVariation(x)} key={uuid()}>
                    <Image alt={getImageAlt()} className='variation-image' src={getVariationImage(x)}/>
                    <div className={classNames('variation-name', {'variation-name-selected': isVariationSelected(x)})}>
                      {getVariationName(x)}
                      {isVariationSelected(x) && (
                        <div className='selected-variation-checkmark'>
                          <IconCheckmark width={14} height={10} color={colors.white}/>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className='image-container'>
            {isLoading ? (
              <Loader width={200} height={200}  color={colors.primary}/>
            ): (selectedVariation && (
              <>
                <Image alt={getImageAlt()} className='selected-variation-image' src={getVariationImage(selectedVariation)}/>
                <div className='selected-variation-buttons'>
                  <button className='selected-variation-previuos-button' onClick={onPreviousStepClick}>Previous step</button>
                  <SubmitButton text='PUBLISH' type="submit" width={120} disabled={submitting} loading={submitting} onClick={handleSubmit}/>
                </div>
              </>
            )
            )}
        </div>
        </>
        )}
      />
      </ApplicationPageContent>
    </ApplicationPage>
  )
};

export default withRouter(TemplateEdit);