import React from 'react';
import classNames from 'classnames';
import IconCheckmark from 'src/redesign/components/Icon/Checkmark';
import IconExit from 'src/redesign/components/Icon/Exit';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const SelectionButton = ({ selectType = true, selected = false, onClick }) => {
  return (
    <div className={classNames('selection-button', {'select-type': selectType, 'unselect-type': !selectType, 'selection-selected': selected})} onClick={onClick}>
      <div className='selection-text'>
        {selectType ? 'Select All' : 'Unselect All'}
      </div>
      {selectType ? <IconCheckmark width={6} height={8} color={colors.blackDark} /> : <IconExit width={6} height={5} color={colors.blackDark} />}
    </div>
  )
}

export default SelectionButton;