export const mapVariation = (data, allImages) => {
  const images = allImages.find(x => x.product_variation_id == data.id)?.images ?? [];

  return {
    id: data.id,
    name: data.name,
    isAvailable: data.is_available,
    price: data.price,
    vendorPrice: data.vendor_price,
    images: images.map(x => mapProductVariationImage(x.id, x.product_variation_image)),
    mediaModifierMockups: data?.media_modifier_mockups ?? [],
    document: data?.design_template_document,
  }
}

export const mapProductVariationImage = (imageId, productVariationImage) => ({
  imageId,
  id: productVariationImage.id,
  base64: productVariationImage?.base64,
  name: productVariationImage?.public_name,
  dropzoneWidth: productVariationImage?.dropzone_width,
  dropzoneHeight: productVariationImage?.dropzone_height,
  dropzoneX: productVariationImage?.dropzone_x,
  dropzoneY: productVariationImage?.dropzone_y,
  realDropzoneWidth: productVariationImage?.real_dropzone_width,
  realDropzoneHeight: productVariationImage?.real_dropzone_height,
  safeWidth: productVariationImage?.safeWidth,
  safeHeight: productVariationImage?.safeHeight,
  safeX: productVariationImage?.safeX,
  safeY: productVariationImage?.safeY,
  dropzoneY: productVariationImage?.dropzone_y,
  dropzoneX: productVariationImage?.dropzone_x
})

export const mapVariationShippingPrices = data => ({
  usFirstStandard: data.us_standard_first,
  usOtherStandard: data.us_standard_n,
  usFirstExpedited: data.us_expedited_first,
  usOtherExpedited: data.us_expedited_n,
  usFirstOvernight: data.us_overnight_first,
  usOtherOvernight: data.us_overnight_n,
  intFirstStandard: data.int_standard_first,
  intOtherStandard: data.int_standard_n,
  intFirstExpedited: data.int_expedited_first,
  intOtherExpedited: data.int_expedited_n,
  vendorUsFirstStandard: data.vendor_us_standard_first,
  vendorUsOtherStandard: data.vendor_us_standard_n,
  vendorUsFirstExpedited: data.vendor_us_expedited_first,
  vendorUsOtherExpedited: data.vendor_us_expedited_n,
  vendorUsFirstOvernight: data.vendor_us_overnight_first,
  vendorUsOtherOvernight: data.vendor_us_overnight_n,
  vendorIntFirstStandard: data.vendor_int_standard_first,
  vendorIntOtherStandard: data.vendor_int_standard_n,
  vendorIntFirstExpedited: data.vendor_int_expedited_first,
  vendorIntOtherExpedited: data.vendor_int_expedited_n
});