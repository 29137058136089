import React, {Component} from 'react'
import {Alert} from "@mui/lab";

class InfoBarImportedProduct extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'infobar'}>
                <Alert severity="info" icon={<img src={this.props.image} className={'image-icon'}/>}>
                    You are creating template for imported product. <br/>
                    Name: {this.props.name}<br />
                    Variants ({this.props.variants.length}): {this.props.variants.join(', ')}</Alert>
            </div>
        );
    }
}

export default InfoBarImportedProduct;
