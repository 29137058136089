import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import SelectInput from 'src/redesign/components/Form/Sidepane/SelectInput';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Loader from 'src/redesign/components/Loader';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getDocuments } from 'src/services/DocumentService';
import { syncProductVariationsWithDesignTemplate } from 'src/services/admin/ProductVariationService';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ProductVariationDesignTemplates = ({ productVariationIds, selectedDocument, onLoad }) => {
  const [loading, setLoading] = React.useState(false);
  const [designTemplatesOptions, setDesignTemplatesOptions] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadDesignTemplates();
  }, []);

  const loadDesignTemplates = async () => {
    setLoading(true);
    try {
      const { data } = await getDocuments();

      setDesignTemplatesOptions(data.data.filter(x => x.type == 'DESIGN_TEMPLATE').map(x => ({ key: x.id, text: x.name })));
    } catch(error){
      openSnackBar('Failed to load design templates', false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const onUpdateDesignTemplates = async ({ selectedDocument }) => {
    await syncProductVariationsWithDesignTemplate({
      productVariationIds,
      selectedDesignTemplateId: selectedDocument,
    });
    onLoad();
  }

  return (
    loading
    ?
    <Loader width={100} height={100} color={colors.primary} className='edit-variation-design-templates-loader' />
    :
    <FinalForm
      onSubmit={onUpdateDesignTemplates}
      initialValues={{ selectedDocument }}
      render={({ handleSubmit, valid, submitting }) => (
        <form onSubmit={handleSubmit} className='edit-variation-design-templates'>
          <div className='edit-variation-design-templates-title'>
            Design templates
          </div>
          <SelectInput
            name='selectedDocument'
            options={designTemplatesOptions}
          />
          <SubmitPrimaryButton
            text='Save'
            width={224}
            type='submit'
            loading={submitting}
            disabled={!valid || submitting || productVariationIds.length == 0}
          />
        </form>
      )}
    />
  )
}

ProductVariationDesignTemplates.propTypes = {

}

export default ProductVariationDesignTemplates;