import React from 'react';
import IconChevronDown from '../../Icon/Chevron/Down'
import IconChevronUp from '../../Icon/Chevron/Up';
import { v4 as uuid } from 'uuid';

import './style.scss';
import colors from '../../../../scss/_colors.scss';

const StatusDropdown = ({ selectedOption, setSelectedOption, disabled, options, onRenderOption }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const iconProps = {
    width: 12,
    height: 6,
    color: colors.primary
  }

  return (
        <div className='templates-dropdown'>
          <div className='templates-dropdown-label' onClick={() => !disabled && setIsOpen(prev => !prev)} onBlur={() => {
            setIsOpen(false)
          }}>
            {selectedOption ?? 'TEMPLATES'}
            <div className='chevron'>
              {isOpen ? <IconChevronUp {...iconProps} /> : <IconChevronDown {...iconProps} />}
            </div>
          </div>
          {isOpen && (
          <div className='menu'>
            {options.map(x => (
              <div key={uuid()} className='item' onClick={() => {
                setSelectedOption(x);
                setIsOpen(false);
              }}>
                <div className='item-content'>
                  {onRenderOption(x)}
                </div>
              </div>
            ))}
          </div>
          )}
        </div>
  );
}

export default StatusDropdown;