import React, {Component} from 'react';
import Loader from '../components/Loader';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import MenuState from "../constants/MenuState";
import HeaderMobile from "../components/HeaderMobile";

class BaseLayout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobile : false
        }

        this.reportWindowSize = this.reportWindowSize.bind(this);
        this.reportWindowSizeTablet = this.reportWindowSizeTablet.bind(this);
    }

    getContentClass() {

        if(this.props.menu.state === MenuState.SHORT) {
            return 'content-container short';
        }
        else {
            return 'content-container';
        }
    }

    hideHeader(){
        let style = "";

        if(this.props.location.pathname == "/designing" || this.props.location.pathname == "/login" || this.props.location.pathname == "/registration"
            || this.props.location.pathname == "/" || this.props.location.pathname == "/contact" || this.props.location.pathname == "/reset_password"
            || this.props.location.pathname == "/terms-of-service" || this.props.location.pathname == "/privacy-policy" || this.props.location.pathname == "/faq"){
            style = "none";
        }

        return style;
    }

    componentDidMount() {
        window.addEventListener("resize", this.reportWindowSize);
        window.addEventListener("resize", this.reportWindowSizeTablet);
        this.reportWindowSize();
        this.reportWindowSizeTablet();
    }

    reportWindowSize(){
        this.setState({
            mobile : !(window.innerWidth <= 768)
        })
    }

    reportWindowSizeTablet(){
        this.setState({
            tablet : !(window.innerWidth <= 992)
        })
        // if (window.innerWidth <= 992){
        //     this.setState({
        //         tablet : true
        //     })
        // }else {
        //     this.setState({
        //         tablet : false
        //     })
        // }

    }


    render() {

        const {children} = this.props;

        // temporary prevention of old layout render on the redesign pages
        const isOldDesign = window.location.href.includes('old');
        if(!isOldDesign)
            return children;

        return (
            <React.Fragment>            {
                    this.props.loader &&
                    <Loader/>
                }
                {
                    this.hideHeader() != "none" && this.state.tablet &&
                    <Header/>
                }

                {
                    this.hideHeader() != "none" && !this.state.tablet &&
                    <HeaderMobile/>
                }
                {
                    this.hideHeader() != "none" &&
                    <div id='main-container'>
                        <div className={'navigation-container'}>
                            <Navigation/>
                        </div>
                        <div className={ this.getContentClass() }>
                            { children }
                        </div>
                    </div>
                }

                {
                    this.hideHeader() == "none" &&
                    children
                }



            </React.Fragment>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({}, dispatch);
}

function mapStateToProps({ authReducers, siteDataReducers, menuReducers })
{
    return {
        loader: siteDataReducers.loader,
        headerActive: siteDataReducers.headerActive,
        menu: menuReducers
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseLayout));
