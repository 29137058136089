import React from 'react';
import VerticalNavigation from 'src/redesign/components/VerticalNavigation';
import OrderShipmentDetails from './tabs/OrderShipmentDetails';
import { renderTab } from 'src/redesign/helpers';

import './style.scss';
import OrderShipmentVariants from './tabs/OrderShipmentVariants';

const OrderDetailsShipment = ({ order, onUpdate }) => {
  const { id } = order;
  const [selectedTab, setSelectedTab] = React.useState(0);
  return (
    <div className='order-details-shipment'>
      <VerticalNavigation
        tabs={[
          {
            text: `Order ${id}`,
            to: '/order-details'
          },
          {
            text: 'Variants - SKU Name',
            to: '/order-details'
          }
        ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {renderTab([
        <OrderShipmentDetails order={order} onUpdate={onUpdate} />,
        <OrderShipmentVariants order={order} />
      ], selectedTab)}
    </div>
  )
}

export default OrderDetailsShipment;