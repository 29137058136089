
import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import FormSection from 'src/redesign/components/Form/FormSection';

import './style.scss';

const Form = ({ children, initialValues, validate, title, submitButtonText, onSubmit, showSubmit = true }) => (
  <FinalForm
    initialValues={initialValues}
    onSubmit={onSubmit}
    validate={validate}
    render={({ handleSubmit, valid, submitting, form }) => (
      <form onSubmit={e => handleSubmit(e, form)}>
        <FormSection
          title={title}>
            {children}
            {showSubmit && (
              <SubmitButton text={submitButtonText} type="submit" className="submit-button" disabled={!valid || submitting} loading={submitting} width={162}/>
            )}
          </FormSection>
      </form>
    )}
  />
)

Form.propTypes = {
  children: node,
  initialValues: object,
  title: string,
  submitButtonText: string,
  showSubmit: bool,
  onSubmit: func
}

export default Form;