import React from 'react';
import VerticalNavigation from 'src/redesign/components/VerticalNavigation';
import OrderSummaryDetails from './tabs/Details';
import PartnerInfoDetails from './tabs/PartnerInfo';
import ShippingAddressDetails from './tabs/ShippingAddress';
import { renderTab } from 'src/redesign/helpers';

import './style.scss';

const OrderDetailsSummary = ({ order, onUpdate }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <div className='order-details-summary'>
      <VerticalNavigation
        tabs={[
              {
                text: 'Details',
                to: `/order-details`
              },
              {
                text: 'Partner info',
                to: `/order-details`
              },
              {
                text: 'Shipping Address',
                to: `/order-details`
              }
            ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {renderTab([
        <OrderSummaryDetails order={order} onUpdate={onUpdate}/>,
        <PartnerInfoDetails order={order}/>,
        <ShippingAddressDetails order={order} onUpdate={onUpdate}/>
      ], selectedTab)}
    </div>
  )
}

export default OrderDetailsSummary;