import React, {Component} from 'react';

class TermsOfService extends Component{

    constructor(props) {
        super(props)
        this.logo = React.createRef();
    }

    componentDidMount() {
            window.scrollTo(0, this.logo.current.offsetTop);
    }

    render() {

        return (
            <div id="terms-of-service">
                <div className="left"></div>
                <div className="center">
                    <div id="text-contenct">
                        <div className="registration-logo" ref={this.logo}></div>
                        <div className="registration-title">Terms Of Service </div>
                        <div>
                            This contract (the “<b>Contract</b>”) governs all aspects of the relationship between you (the person or entity placing orders through SurgePrint’s Service) and Surge Print LLC, whereby you use SurgePrints’s Service to place orders with us to print designs on blank textiles and other products (“<b>Products</b>” or "<b>blank Products</b>") at your expense and according to the Fulfillment Information provided by you. In this Contract, this arrangement and all associated websites, plugins, applications, and services for placing such orders as provided by Surge Print are described collectively as the “<b>Service</b>.”
                            <br/>
                            <br/>
                            The use of the Service is limited to persons or entities, including through any other person acting in their name or on their behalf, who use the Service for purposes relating to their trade, business, craft or profession. This Contract is established between the parties upon completion of registration for a user account with Surge Print (“<b>User Account</b>”), e.g. at a website of SurgePrint or during the installation of a plugin or application provided by Surge Print. During such registration, your agreement to the terms of this Contract by your express consent is required. The registration is completed when Surge Print grants you access to your Merchant Account.
                            <br/>
                            <br/>
                            Currently, the Contract can be concluded in the following languages: English.
                            <br/>
                            <br/>
                            Surge Print stores the information and the content you provide in your User Account as well as the settings specified by you for the Service and your User Account. You can review such information and settings in your User Account. In addition, SurgePrint stores the terms of this Contract which are accessible to you at SurgePrint’s website also after establishing the Contract.
                        </div>
                        <div className="registration-title">1. Placing Orders with Surge Print</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Individual Order</u>. An “Individual Order” consists of your request for SurgePrint to deliver printed  Products according to the Fulfillment Information you provide. This Contract governs all Individual Orders unless otherwise agreed in writing.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Fulfillment Information</u>. You must provide SurgePrint with all information and data necessary for the production and delivery of Individual Orders. Information and data provided by you are hereafter referred to as “<b>Fulfillment Information</b>”. Required fields are indicated in the Service. For example, you must provide a delivery address for the Products, which will usually be your customer’s (“<b>End Customer</b>”) address.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(c)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Submitting and Canceling Orders</u>. When you submit an order to SurgePrint through SurgePrint’s Service, you are making a binding offer for SurgePrint to fulfill the Individual Order according to the Fulfillment Information and the terms of this Contract. You may not cancel an Individual Order unless the Individual Order has not begun the printing process.
                        </div>
                        <div className="registration-title">2. The Service</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>License to the Service</u>. All intellectual property and other rights, title and interest in and to the Service are reserved. Your use of the Service does not grant you any right, title or interest to these properties, except as follows: SurgePrint grants you a limited, revocable license to access and use the Service for its intended purposes as described in this Contract, for as long as this Contract is in effect. To the extent that SurgePrint makes free fonts and designs available to you, SurgePrint grants you a royalty-free, limited, revocable license to use such content only in connection with the Service and only for as long as this Contract is in effect.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>No Ownership</u>. You represent that you will not use SurgePrint's intellectual property as if it were your own, or in any way which may be confusing, misleading, or deceptive, nor attempt to register such intellectual property in any jurisdiction. You may only use the Service according to SurgePrint's terms, rules, and guidelines found or referred to in this Contract or agreed between the parties otherwise. SurgePrint may revoke this license and limit your access to the Service according to this Contract.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(c)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Your Account Information</u>. If you do not provide complete, accurate, and up-to-date information in your User Account, this is a material breach of the Contract and SurgePrint may suspend or terminate your access to the Service. This includes current, valid payment information, your full name as shown on your income tax return, and any other financial or tax-related information SurgePrint requests. SurgePrint also requires a valid email address from you so that we can communicate with you. You are solely liable for the consequences of providing incorrect or out-of-date information. Before you submit the User Account registration form, you may check the accuracy of the information you have entered and amend it using the correction tools provided.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(d)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Maintenance and Security</u>. You are solely responsible for securing your login credentials against access by third parties, for covering all costs and aspects of the configuration of your software and hardware as needed to use the Service, and for adapting to any later changes to the Service. Do not use the same login credentials of your User Account for other websites and services. If you have reason to believe that third parties have accessed your login credentials, change your password, and notify us immediately.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(e)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>SurgePrint Newsletter</u>. We will email you information and tips with regard to the Service from time to time. You can unsubscribe from the SurgePrint Newsletter at any time. Please note that you will continue to receive contractually relevant information via email.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(f)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Acceptable Use Rules</u>. You may not:
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;interfere with the Service by using viruses or any other programs or technology designed to disrupt or damage any software or hardware;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;interfere with, or disrupt the access of any user, host or network, including by using a virus; by overloading, flooding, or spamming the Service; or by engaging in any activity that places a disproportionate burden on the Service (including by automated means) compared to ordinary use from a single, ordinary user; or
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(3)</b>&nbsp;&nbsp;&nbsp;&nbsp;engage in any activity that interferes with another user's ability to use or enjoy the Service.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(4)</b>&nbsp;&nbsp;&nbsp;&nbsp;use a robot or other automated means to monitor the activity on or copy information or pages from the Service, except search engines, traffic counters, or similar basic performance monitoring technology;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(5)</b>&nbsp;&nbsp;&nbsp;&nbsp;modify, copy, create derivative works from, reverse engineer, decompile or disassemble any technology used to provide the Service;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(6)</b>&nbsp;&nbsp;&nbsp;&nbsp;use any meta tags, search terms, keywords, or similar tags that contain SurgePrint's name or trademarks;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(7)</b>&nbsp;&nbsp;&nbsp;&nbsp;impersonate another person or entity in connection with the Service;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(8)</b>&nbsp;&nbsp;&nbsp;&nbsp;assist or encourage any third party in engaging in any activity prohibited by this Contract; or
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(9)</b>&nbsp;&nbsp;&nbsp;&nbsp;use the Service to promote hate speech, obscenity, or any content that SurgePrint in its sole discretion finds objectionable.
                        </div>
                        <div className="registration-title">3. Usage, Warranties, and Rights to Designs & Fulfillment Information and Your Usage of the Service</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Usage by SurgePrint</u>. You agree that SurgePrint and its subcontractors may use the Fulfillment Information to reproduce, create derivative works from, distribute, process, translate, adapt, publish, publicly perform, publicly display, and edit designs; to decorate blank Products; and to process or change any data to ensure conformity with SurgePrint’s Service and other requirements during the term of this Contract on an irrevocable, non-transferable, worldwide, fully paid & royalty-free, and non-exclusive basis. This <u>Section 3(a)</u> may not be interpreted to grant SurgePrint any further ownership rights in the Fulfillment Information that SurgePrint would not otherwise have. Notwithstanding any other provision in this Contract, SurgePrint may at any time refuse to publish and/or to print your Fulfillment Information or remove the Fulfillment Information from the Service for any or no reason.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Third Party Intellectual Property Rights and Applicable Laws</u>.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>No Violations</u>.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(A)</b>&nbsp;&nbsp;&nbsp;&nbsp;You represent to SurgePrint that you have all rights necessary.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(i)</b>&nbsp;&nbsp;&nbsp;&nbsp;to grant the license above in Section 3(a) (Usage by SurgePrint),
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(ii)</b>&nbsp;&nbsp;&nbsp;&nbsp;to place an order for the printing of Products under this Contract,
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(iii)</b>&nbsp;&nbsp;&nbsp;&nbsp;to offer the Products for sale, and
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(iv)</b>&nbsp;&nbsp;&nbsp;&nbsp;to display, offer, and sell all content, designs, or other Fulfillment Information relating to the Products,
                            <br/>
                            <br/>
                            without infringing the rights of any third parties or any applicable laws.
                            <br/>
                            <br/>
                            For example, these rights can be in trademark, copyright, patent, publicity, personal privacy, and any other applicable usage rights.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(B)</b>&nbsp;&nbsp;&nbsp;&nbsp;You further represent that SurgePrint’s use of the Fulfillment Information will not violate the rights of any third parties or any applicable laws, including criminal laws, privacy laws, and laws protecting minors.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(C)</b>&nbsp;&nbsp;&nbsp;&nbsp;You further represent that
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(i)</b>&nbsp;&nbsp;&nbsp;&nbsp;the websites, shops and services on which you display and/or embed the Products or the Service or any part thereof,
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(ii)</b>&nbsp;&nbsp;&nbsp;&nbsp;your advertising activities for the Products and/or the Service as well as
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(iii)</b>&nbsp;&nbsp;&nbsp;&nbsp;any other use of the Service by you will not violate the rights of any third parties or any applicable laws.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Due Diligence</u>. You are solely responsible for ensuring the designs and other Fulfillment Information you submit are free from any violations of law and infringements of third-party rights.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Notification</u>. You must promptly notify SurgePrint if you receive any claim that any Fulfillment Information or Products infringe or violate third party rights. SurgePrint may also provide such a third-party claimant with information relating to the origin, production, sales, and distribution of affected Individual Orders.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Possible Actions</u>. If SurgePrint believes, in its sole discretion, that you are in breach of any of the foregoing representations in this <u>Section 3(c)(1) to (3)</u>, SurgePrint may – in addition to any other rights or claims SurgePrint may have – immediately suspend or terminate the publication of your Fulfillment Information.
                        </div>
                        <div className="registration-title">4. Processing Individual Orders</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>SurgePrint’s Discretion</u>. SurgePrint may accept or reject Individual Orders in its sole discretion. SurgePrint anticipates that it will accept most Individual Orders, but you hereby acknowledge that SurgePrint may reject an Individual Order for any reason or no reason.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Intellectual Property Violations</u>. In addition to the general discretion above in <u>Section 4(a)</u> (SurgePrint’s Discretion), SurgePrint may reject, cancel, or suspend an Individual Order, or suspend or terminate this Contract immediately without prior notice and without any recourse to you if
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;SurgePrint receives any notification that any Fulfillment Information violates any law or infringes upon third-party rights;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;SurgePrint believes, in its sole discretion, that the Fulfillment Information violates any law or infringes upon third-party rights, or that you have violated <u>Section 2(f)</u> (Acceptable Use Rules); or
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(3)</b>&nbsp;&nbsp;&nbsp;&nbsp;SurgePrint believes, in its sole discretion, that its continued performance under this Contract poses a legal or other risk (including a public relations risk) for SurgePrint.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(c)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Product Availability</u>. SurgePrint makes a selection of blank Products available to you for the fulfillment of Individual Orders. SurgePrint cannot guarantee the availability of products at all times and may change the available products at any time and in SurgePrint’s sole discretion.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(d)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Product Specifications</u>. You understand and agree, that descriptions and information provided by SurgePrint in the Service with respect to material, color, weight, measurements, design or other features of the Products only serve as an approximate indication for the Products, that SurgePrint, when processing an Individual Order, is entitled to deviate from such descriptions and information as far as this can be considered reasonable for you and that such deviations are not considered a Product defect. Reasonable cause for variations in the Product features may result from fluctuations customary in trade and technical production processes.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(e)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Subcontractors</u>. SurgePrint may subcontract any of its duties in this Contract. For example, SurgePrint may use subcontractors to print the blank Products, and SurgePrint or its subcontractors have full discretion to select various shipping providers for deliveries.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(f)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>No End Customer Service</u>. You are solely responsible for providing your End Customers, if any, with customer service, including coordinating their requests for refunds, returns, and replacements of Individual Orders you originally placed with SurgePrint. SurgePrint generally has no direct contact with End Customers. For information about products that are returned to SurgePrint or otherwise allegedly defective products, see <u>Section 6(e)</u> (Wrong & Insufficient Address, Unclaimed Shipping) and <u>Section 9</u> (Defects).
                        </div>
                        <div className="registration-title">5. Prices</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Generally</u>. SurgePrint sets Product prices and displays these in the Service. Shipping costs are shown separately. SurgePrint may change prices from time to time in its sole discretion, and such changes will be reflected in the Service.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Price Reductions for High Volumes</u>. SurgePrint may, in its sole discretion, make different pricing programs available from time to time (for example, SurgePrint may offer lower prices to you if you place orders exceeding a certain USD or EUR amount in a given time frame). SurgePrint does not guarantee the availability of such programs and may suspend or discontinue such programs at any time. Details on such pricing programs can be found on SurgePrint’s website.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(c)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Taxes</u>. All prices and costs described in this Contract and the Services are net prices and do not include sales taxes, value-added tax, import value-added tax, or other taxes and duties which you may have to pay in addition. You are solely responsible for paying any taxes that apply to your sale, supply, and delivery of the Products to the End Customer. To the extent SurgePrint actually pays such costs on your behalf, SurgePrint may charge you for them. You must provide SurgePrint current, valid tax-related information SurgePrint requests, including on your resale certificate or your VAT-ID.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(d)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Invoices</u>. You agree to electronic invoicing only. Invoices will be made available in a format chosen in the sole discretion of SurgePrint and will be downloadable via the Service. If you are in delay with providing tax-related information (including on your resale certificate or your VAT-ID) requested by SurgePrint or provide incorrect or out-of-date tax-related information, you are not entitled to claim corrections to invoices created by SurgePrint.
                        </div>
                        <div className="registration-title">6. Shipping and Delivery</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Generally</u>.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;SurgePrint or its subcontractors will arrange the delivery of Individual Orders to the delivery address you specify in the Fulfillment Information (usually the delivery address of the End Customer). If you do not provide a delivery address with the Fulfillment Information, then SurgePrint may refuse delivery, or deliver the Individual Orders to your address on file, in SurgePrint’s sole discretion.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;SurgePrint has the sole discretion to restrict the available countries for shipping, the mode of transport, the shipping service provider, and the type of packaging available for Individual Orders. You may select only from the shipping options which SurgePrint makes available to you when you place your Individual Order.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(3)</b>&nbsp;&nbsp;&nbsp;&nbsp;You consent to partial deliveries.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(4)</b>&nbsp;&nbsp;&nbsp;&nbsp;If possible, SurgePrint may state you as the sender of the shipping and your address as the return address.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Costs</u>. You may review shipping costs in the Service. SurgePrint may change shipping costs at any time in their sole discretion.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(c)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Risk of Loss and Delay, Insurance</u>. The risk of Individual Order loss, destruction, and delay passes to you when the Individual Order is handed over to the shipping service provider, forwarder, carrier, or other person tasked with facilitating shipping. SurgePrint is not obligated to insure Individual Orders in transit.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(d)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>No Warranties Regarding Delivery Timing</u>. SurgePrint may provide general estimates of delivery times on its website and in the Service. These are estimates only, and SurgePrint expressly disclaims all warranties that it will make Individual Orders available to a shipping service provider within any specific time period or that Individual Orders will arrive at any given destination on a specific date.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(e)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Wrong & Insufficient Address, Unclaimed Shipping</u>. If a Product is returned by the shipping provider to SurgePrint without SurgePrint’s fault (e.g. because you provided a wrong or insufficient address or the shipping went unclaimed), we will contact you and ask you for an updated address and reship the Product to such new address. You will be liable for any costs of a possible storage and/or reshipping of the Product (if and as applicable). If a Product is not reshipped within 90 days after return to SurgePrint without SurgePrint’s fault, a reshipping won’t be available anymore and SurgePrint is entitled, without any further liability to you, to donate the Product to charity or dispose the Product of at your costs.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(f)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Export, Import</u>.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Forms and Information</u>. If your Individual Order is subject to customs, you will provide SurgePrint any necessary shipping information and SurgePrint will make commercially reasonable efforts to prepare the necessary export documentation when packaging the affected Individual Orders. You acknowledge that with respect to any such Individual Orders neither SurgePrint, nor its affiliates, nor its subcontractors are deemed to be the importer of any goods.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Costs</u>. If import duties, (import) VAT, customs clearance costs, or other costs and levies are due or may be incurred in accordance with the statutory provisions in the recipient country of your Individual Order, the recipient of the Products must pay these upon receipt. To the extent that SurgePrint incurs costs related to the export to or import in the recipient country  (including duties, charges, taxes (e.g., VAT and sales taxes), or other fees), SurgePrint may charge you for these costs.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Destinations and Embargoes</u>. You may review the available delivery destinations in the Service. However, if an Individual Order would be delivered to a destination subject to applicable sanctions or an embargo, or to a specifically designated national or blocked person under applicable law, or to any entity, person, or destination that is the subject of a similar restriction, you acknowledge that SurgePrint is authorized to reject or withdraw from that Individual Order.
                        </div>
                        <div className="registration-title">7. Data Protection</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>CCPA “Service Provider” Clause</u>. To the extent that SurgePrint is subject to the regulations of the California Consumer Privacy Act, this <u>Section 8(a)</u> applies. SurgePrint will collect, process, and use End Customers’ personal data only in so far as is necessary to perform its duties under this Contract. SurgePrint may not
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;sell personal information;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;retain, use, or disclose personal information for any purpose other than for the specific purpose of performing the services specified in this Contract; or
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(3)</b>&nbsp;&nbsp;&nbsp;&nbsp;retain, use, or disclose the personal information outside of the direct business relationship between the parties.
                            <br/>
                            <br/>
                            SurgePrint certifies that it understands the restrictions above and in Cal. Civ. Code § 1798.140(w)(2)(A) and that it will comply with them. For purposes of this <u>Section 8(a)</u>, "consumer," "sell," and "personal information" have the definitions found in Cal. Civ. Code § 1798.140.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;End Customer personal data will be destroyed at your request, as long SurgePrint is not subject to legal requirements to store the data in question. More information can be found in SurgePrint’s privacy policy which is available at SurgePrint’s website.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(c)</b>&nbsp;&nbsp;&nbsp;&nbsp;You guarantee to SurgePrint that any of the End Customers’ personal data that you have collected and transmitted to SurgePrint has been collected in accordance with all applicable legal requirements (E.g., the CAN-SPAM Act, COPPA, GDPR, etc.) and you expressly warrant that SurgePrint may use all such information to perform its duties under this Contract without violating any laws. You guarantee that you have obtained End Customer consent for information collection and processing in all circumstances to the extent required by applicable law.
                        </div>
                        <div className="registration-title">8. Defects</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Remedies for defective Products</u>. If a Product is defective, SurgePrint, to the extent permitted under applicable law, has the sole discretion to grant you a refund (including the refund method), credit your account, or replace a Printed Product at SurgePrint’s expense. In order to request a refund, credit, or replacement, you must send a photograph and a brief explanation of the problem to SurgePrint within 10 days of delivery at the latest via support@surgeprint.com.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Defective Products</u>.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;A Product is defective in particular, if, upon passing of the risk (see <u>Section 6(c)</u> (Risk of Loss)), (i) the Product has not agreed quality (especially, if the Product does not match with your Fulfillment Information) or, (ii) to the extent that the quality has not been agreed, the Product is not suitable for the normal use and its quality is usual for products of the same type and which you can expect for such type of products.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;For clarification, SurgePrint will <b>not</b> grant a refund, credit your account, or replace a printed Product solely because
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SurgePrint deviates from Product descriptions and information in accordance with <u>Section 4(d)</u> (Product Specifications);
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Product was unsatisfactory for any reason despite matching the provided Fulfillment Information; or
                            <br/>
                            <br/>
                            In such cases, you must place a new order, or the recipient of the Product must attempt to resolve the delivery dispute with the shipping provider.
                        </div>
                        <div className="registration-title">9. Indemnification</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Generally</u>. You agree to indemnify, defend, release, and hold SurgePrint and its affiliates, employees, officers, agents, and subcontractors harmless, without limitation, from any liabilities and costs (including reasonable attorney’s fees) resulting from any claims that arise out of or relate to
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;your breach of any term, representation, or warranty of this Contract, including for example <u>Section 2(c)</u> (Your Account Information), <u>Section 2(f)</u> (Acceptable Use Rules), <u>Section 3</u> (Usage, Warranties, and Rights to Designs & Fulfillment Information and your Usage of the Service) and <u>Section 5(c)</u> (Taxes);
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;liability for taxes, including sales, use, value-added, import value-added, manufacturing, processing, excise or other similar taxes or (export or import) duties; and
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(3)</b>&nbsp;&nbsp;&nbsp;&nbsp;the violation of any law, in so far as such claims relate to your Individual Orders, your use of SurgePrint’s Service, or other acts relating to this Contract.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Management of Defense</u>. SurgePrint reserves the right to manage its defense of an indemnified claim in its sole discretion, including the selection of its own counsel. You agree to cooperate fully with SurgePrint in the defense of any claims described in this Section.
                        </div>
                        <div className="registration-title">10. Disclaimer of Warranties and Limitation of Liability</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Disclaimer of Warranties</u>.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>As-Is</u>. With respect to this Service, SurgePrint hereby disclaims any and all express or implied warranties, including warranties of fitness for a particular purpose, merchantability, and non-infringement to the fullest extent of the law, except to the extent that this Contract provides otherwise. SurgePrint is also not responsible for express warranties made by the manufacturer(s) of blank Products or other third parties (e.g., in their advertisements). SurgePrint provides the service on an "as-is" and "as available" basis. SurgePrint does not represent or warrant that the Service or its use:
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(A)</b>&nbsp;&nbsp;&nbsp;&nbsp;will be uninterrupted, timely, safe, or secure,
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(B)</b>&nbsp;&nbsp;&nbsp;&nbsp;will be free of inaccuracies, errors, or vulnerabilities,
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(C)</b>&nbsp;&nbsp;&nbsp;&nbsp;will meet your expectations or requirements, or
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(D)</b>&nbsp;&nbsp;&nbsp;&nbsp;will operate in the configuration or with the hardware or software you use.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Assumption of Risk</u>. When SurgePrint grants you access to your User Account or new features, you agree that you will diligently inspect the features of the online Service without undue delay, insofar as this is reasonable in the ordinary course of business. If you find any material defect in the Service, you must notify SurgePrint. If you fail to notify SurgePrint about a material defect, the online Service shall be deemed to be approved by you, unless the defect is one that could not be detected during a diligent inspection. If such material defect becomes apparent at a later date, your notification must be made without undue delay after its discovery; otherwise, the online Service shall be deemed to have been approved also in respect of this defect. With regard to approved features, you agree that (i) SurgePrint has no responsibility for any damages suffered by you in connection with the online Service, and that use of the online Service, including all content, data or software distributed by, downloaded, or accessed from or through the online Service, is at your sole risk and (ii) you will be solely responsible for any damage to your business or your computer system or any loss of data that results from your actions and your use of the online Service. SurgePrint does not control or endorse in any respect any information, products, or services offered by independent third parties in connection with the Service and is not responsible for any possible representations and warranties these third parties may make.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Limitation of Liability</u>.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Unlimited Liability</u>. No provision in this Contract shall exclude or limit Surge Print’s liability (i) in the event of willful misconduct or gross negligence, (ii) in case of an injury to life, body, and health, (iii) within the scope of a guarantee taken over by SurgePrint, (iv) in the event that a defect is maliciously concealed, (v) according to the German Product Liability Act, if applicable.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Other Liability</u>. Unless SurgePrint is liable in accordance with the “Unlimited Liability” section above, SurgePrint’s liability
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(A)</b>&nbsp;&nbsp;&nbsp;&nbsp;from its violation of an essential contractual obligation (an obligation whose fulfillment is a prerequisite for the proper execution of this Contract and the Individual Orders in the first place and whose compliance you regularly trust and may trust) is limited to the compensation of the foreseeable, typically occurring damage to this type of contract, and
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(B)</b>&nbsp;&nbsp;&nbsp;&nbsp;shall, in any case, not exceed the total amount of money you have paid for in connection with the Service in the 12 months preceding a claim.
                            <br/>
                            <br/>
                            In all other respects, SurgePrint’s liability is excluded, and SurgePrint is especially not liable to you for any lost profits or other consequential, special, indirect, punitive, or incidental damages arising out of or relating to the Service, this Contract, or any other agreement or conduct involving you and SurgePrint, regardless of the cause of action (whether in contract, tort, strict product liability, or otherwise), even if advised of the possibility of such damages.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(3)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Scope</u>. In the case of claims for your damages against any of SurgePrint’s affiliates, and SurgePrint’s and SurgePrint’s affiliates’ employees, officers, agents, and subcontractors, the limitation of liability in this Contract shall also apply in favor of such persons.
                        </div>
                        <div className="registration-title">11. Term and Termination</div>
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Generally</u>. This Contract has an indefinite term. The parties may terminate this Contract for any reason or no reason by providing written notice of termination (including via email), which termination becomes effective immediately upon notification. You may also terminate this Contract effective immediately by permanently ending your use of the Service (e.g., by deleting your Merchant Account or uninstalling all applicable SurgePrint applications or plugins). Termination of this Contract does not affect Individual Orders placed before termination.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Termination For Cause</u>. SurgePrint may terminate this Contract without any prior notice if you violate <u>Section 2(f)</u> (Acceptable Use Rules), <u>Section 3</u> (Usage, Warranties, and Rights to Designs & Fulfillment Information and your Usage of the Service), if you materially breach any other agreement (or a surviving duty of an expired agreement) to which you and SurgePrint are parties, if you become insolvent or cease to do business in the ordinary course, or if you engage in any illegal, unfair, or deceptive business practices or unethical conduct whatsoever (in SurgePrint’s sole discretion). If <u>Section 13(d)(1)</u> applies and if SurgePrint terminates this Contract pursuant to this <u>Section 12(b)</u>, you waive all rights, including injunctive relief, to contest SurgePrint’s determination that a termination event has occurred.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(c)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Survival</u>. Notwithstanding the above, <u>Sections 2(b)</u> and <u>2(f)</u> (The Service), <u>Section 3</u> (Usage, Warranties, and Rights to Designs & Fulfillment Information and your Usage of the Service), <u>Section 5(c)</u> (Taxes), <u>Section 6(c)</u> (Risk of Loss and Delay, Insurance), <u>Section 7</u> (Payment; especially for orders placed before termination), Section 10 (Indemnification), Section 11 (Disclaimer of Warranties and Limitation of Liability), <u>Section 12(c)</u> (Survival), and <u>Section 13</u> (Miscellaneous) survive the termination of this Contract.
                        </div>
                        <div className="registration-title">12. Miscellaneous</div>
                        <div style={{marginBottom: "40px"}}>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(a)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Entire Agreement</u>. This Contract contains the entire agreement between the parties and replaces all prior oral and written agreements regarding its subject matter. The parties have not relied on any representations or promises relating to the subject matter of this Contract except those contained within the four corners of this Contract.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>(b)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Amendment</u>.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(1)</b>&nbsp;&nbsp;&nbsp;&nbsp;Any changes of this Contract must be agreed in writing.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(2)</b>&nbsp;&nbsp;&nbsp;&nbsp;Changes to this Contract may also be agreed as follows:
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(A)</b>&nbsp;&nbsp;&nbsp;&nbsp;Provided the change is reasonable for you, SurgePrint is entitled to change the Contract if
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(i)</b>&nbsp;&nbsp;&nbsp;&nbsp;the legal, regulatory, economic or technical conditions on which the Contract is based change, or
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(ii)</b>&nbsp;&nbsp;&nbsp;&nbsp;SurgePrint introduces new features to the Service or existing features of the Service are adjusted.
                            <br/>
                            <br/>
                            A change is reasonable if it would not significantly disturb the contractual balance between the parties and not result in a significant restriction of the Service.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(B)</b>&nbsp;&nbsp;&nbsp;&nbsp;If SurgePrint wishes to change the Contract in the cases specified in <u>Section 12(b)(2)(A)</u>, SurgePrint will inform you of this and will offer you the new contract terms no later than 15 days prior to the scheduled effective date of the contractual change. If you do not object to the validity of the new contract terms within 15 days upon receipt of SurgePrint’s offer by notifying SurgePrint in text form (including email), then once this deadline passes the changes to the contract shall be deemed accepted by you. Should you object to the validity of the new contract terms in the required manner and on time, the Contract shall continue to be effective unchanged. In such cases, SurgePrint does however reserve the right to terminate the Contract with you in order to ensure the uniform treatment of all merchants. At the beginning of the 15 days objection period, SurgePrint will inform you again of your right of objection, the ways in which you can declare your objection, and the intended significance of your behavior.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>(C)</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>Waiver</u>. Waivers are only effective when in writing. If a party waives enforcement of a breach of any term of this Contract, later breaches of the same or other terms are not waived. Accepting late performance of any act or late fulfillment of any condition of this Contract is not a waiver of the act or condition itself.
                        </div>
                    </div>
                </div>
                <div className="right"></div>
            </div>
        );
    }
}

export default TermsOfService;

