import React from 'react';
import { withRouter } from 'react-router';

import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Image from 'src/redesign/components/Image';
import { getUserFromLocalStorage } from 'src/base/HTTP';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const CatalogCategoriesList = ({ history, categories, location: { state: { importedProduct } = {}} }) => {
  const user = getUserFromLocalStorage();
  const isAdmin = user?.roles?.includes('SUPER_ADMIN');
  const { openSnackBar } = useSnackbar();

  const onSelectProduct = id => {
    if(isAdmin) {
      openSnackBar(`Admin can't create customer product variation`, false);
      return;
    }

    history.push(`/product-selection/${id}`, { importedProduct });
  }

  return (
    <div className='catalog-categories-list'>
      {categories.map(({ category_image: { url }, name, min_price, id }) => (
        <div className='catalog-category' key={id}>
          <Image alt={getImageAlt()} className='catalog-image' src={process.env.REACT_APP_baseUrl + url}/>
          <div className='category-name'>
            {name}
          </div>
          <div className='starting-at-container'>
            <div className='starting-at'>
              Starting at
            </div>
            <div className='price'>
              ${min_price}
            </div>
          </div>
          {/* <button className='view-more-details-button' onClick={() => setSelectedCategory(categories.find(c => c.id === id))}>
            View more details
          </button> */}
          <SubmitPrimaryButton text='Start Designing' className='start-designing-button' onClick={() => onSelectProduct(id)}/>
        </div>
      ))}
    </div>
  )
}

export default withRouter(CatalogCategoriesList);