import React from 'react';
import { withRouter } from 'react-router-dom';

import CustomProductTemplate from 'src/redesign/components/CustomProductTemplate';
import IconHoodie from 'src/redesign/components/Icon/Hoodie';
import IconTool from 'src/redesign/components/Icon/Tool';
import IconCheckmark from 'src/redesign/components/Icon/Checkmark';
import IconSettings from 'src/redesign/components/Icon/Settings';

import colors from 'src/scss/_colors.scss';

const LandingHoodiesPage = ({ history }) => {

  const onStartDesigningClick = () => {
    history.push('/product-catalog-in-app/apparel');
  }

  const categoryName = 'Hoodies';

  const easeOptions = [
    {
      icon: <IconHoodie width={36} height={36} color={colors.secondary} />,
      title: 'Choose a garment that tickles your fancy',
      description: 'Select from our diverse collection of custom hoodies tailored to your specific fashion and wearability needs. From cozy cotton blends to premium fleece, our pieces are designed for comfort and durability, ensuring you’ll look and feel your best.'
    },
    {
      icon: <IconTool width='36.52' height='36.52' color={colors.secondary} />,
      title: 'Design your masterpiece',
      description: 'See where your creativity takes you with our user-friendly Design Studio. Whether you’re dreaming up bold graphics or intricate embroidery, bring it to your hoodie with a click. With our print-on-demand technology, the possibilities are as vast as your ideas.'
    },
    {
      icon: <IconCheckmark width='35' height='35' color={colors.secondary} />,
      title: 'Confirm your preferences',
      description: 'Specify order-related information, including your preferred printing method, color options, and sizing specifications. With SurgePrint, you can trust that your custom hoodie will be printed with the utmost precision and attention to detail.'
    },
    {
      icon: <IconSettings width='48' height='48' color={colors.secondary} />,
      title: 'Bring your design to life',
      description: 'Sit back and relax as we handle the printing and fulfillment process. With our quick turnaround time, you’ll be sporting your personalized hoodie in no time, ready to turn heads wherever you go.'
    }
  ];

  const exploreMainProduct = {
    image: '/images/blankets-header.png',
    title: 'Minky Blankets: \n Cloud Nine Personified',
    description: `Indulge in the luxurious embrace of our Minky blankets. It's not just a blanket; it's a journey to cloud nine, where dreams and comfort intertwine in a plush symphony.`,
    nameToFind: 'Minky Blankets'
  }

    const exploreOtherProducts = [
    {
      image: '/images/custom-blankets-explore-other-1.png',
      title: 'Fleece Blankets: Versatility, Unwrapped',
      description: `Our Fleece blankets are more than warmth; they are the embodiment of versatility. From outdoor adventures to cozy nights in, these blankets are a steadfast companion through every season.`,
      nameToFind: 'Fleece Blankets'
    },
    {
      image: '/images/custom-mugs-explore-other-2.png',
      title: 'Velveteen Blankets: Elegance Unveiled',
      description: `Adorn your space with the elegance of our Velveteen blankets. Each touch exudes sophistication, turning any room into a haven of style and comfort.`,
      nameToFind: 'Fleece Blankets'
    }
  ]

  return (
    <CustomProductTemplate
      onStartDesigningClick={onStartDesigningClick}
      categoryName={categoryName}
      //header
      headerTitle='Create your style statement with custom hoodies'
      headerSubtitle={`When it comes to expressing your individuality, mediocre hoodies simply won’t cut it. That’s where SurgePrint steps in with our comprehensive custom hoodie printing service. Whether you’re after a classic pullover or a modern zip-up, nothing works better than creating the perfect fit for your style on your own. `}
      headerImage='/images/blankets-header.png'
      //design-with-ease
      easeTitle='Crafting your unique look in 4 simple steps'
      easeOptions={easeOptions}
      //on-demand
      onDemandTitle='Order custom hoodies today'
      onDemandSubtitle={`Ready to elevate your wardrobe with print-on-demand hoodies? Look no further than SurgePrint. With our affordable prices and hassle-free ordering process, creating your own style statement has never been easier. Whether you need a single hoodie or a bulk order for your team or event, we’ve got you covered. Experience the convenience of print-on-demand and order your custom hoodies today.`}
      //explore-collection
      exploreTitle='Explore Our Blanket Collection'
      exploreMainProduct={exploreMainProduct}
      exploreOtherProducts={exploreOtherProducts}
      //gift-memory
      giftMemoryImage='/images/blankets-gift-memory.png'
      giftMemoryTitle='Gift a Memory, Share a Smile'
      giftMemoryDescription={`SurgePrint blanket makes for unforgettable gifts from your online shop to its end customers. Add a personal touch to birthdays, anniversaries, or holidays with personalized blanket that speaks volumes. Surprise your customers with a thoughtful and practical present they'll cherish every day.`}
      //start-today
      startTodayTitle='Elevate Your Business with SurgePrint'
      startTodaySubtitle={`SurgePrint isn't just about blankets; it's about elevating your business to new heights. Partner with us to offer your customers high-quality, customizable blankets that don't just warm their bodies but also warm their hearts.`}
      startTodayLink={`Step Into the Unique World of SurgePrint Blankets Where Every Snuggle Tells a Story!`}
      //personalized
      personalizedTitle='Start Designing Today'
      personalizedSubtitle={`Empower your online shop to offer a unique and personalized experience. Start featuring custom Blankets in your inventory and discover the joy your customers will experience while warming their body with a Blanket that's uniquely theirs.`}
      //seo
      seoTitle='Print On Demand Hoodies - Custom Hoodies | Surge Print'
      seoDescription={`Discover personalized style with our custom hoodies print-on-demand services. Create unique, high-quality hoodies tailored to your taste, and order yours today!`}
    />
  )
}

export default withRouter(LandingHoodiesPage);