import React from 'react';
import Page from 'src/redesign/components/Page';
import PrintingMethodsContainer from 'src/redesign/components/PrintingMethodsContainer';
import Stepper from 'src/redesign/components/Stepper';

import './style.scss';

const PrintingMethodsScreenPrinting = () => (
  <Page
    seoTitle='Printing Methods Screen Printing | Surge Print'
  >
    <div className='printing-methods-screen-printing'>
      <PrintingMethodsContainer
        imageText='Printing Methods Screen Printing'
        title='The screen printing process involves several steps that are carried out in a specific order to produce high-quality prints.'
      >
        <div className='printing-methods-screen-printing-description-container'>
          <div className='printing-methods-screen-printing-subtitle'>
            Here's a general overview of how the screen printing process works:
          </div>
          <Stepper
            steps={[
              'Design Creation: The first step in screen printing is to create a design using specialized software or by hand. The design is typically created in black and white or grayscale to create a stencil for the printing process.',
              'Preparing the Screen: The next step is to prepare the screen by coating it with a light-sensitive emulsion. The emulsion is then exposed to a UV light source using the prepared design, which burns the design into the emulsion, creating a stencil on the screen.',
              'Preparing the Ink: Once the screen is prepared, the ink is mixed and prepared. This involves selecting the correct ink color, and mixing it with any additives, such as a catalyst or a reducer, to ensure proper consistency and adhesion.',
              'Printing: Once the ink is prepared, it is time to start printing. The screen is placed onto the substrate, such as a t-shirt or poster, and the ink is applied to the screen using a squeegee. The squeegee pushes the ink through the open areas of the stencil and onto the substrate, creating the desired design.',
              'Curing the Ink: Once the design is printed, the ink needs to be cured or dried. This can be done using a heat press or a conveyor dryer, which bakes the ink onto the substrate and ensures that the print is durable and long-lasting.',
              'Clean-up: After the printing is complete, the screen needs to be cleaned to remove any excess ink or emulsion. This can be done using specialized chemicals and tools to ensure that the screen is ready for the next printing job.'
            ]}
          />
          <div className='printing-methods-screen-printing-description'>
            Overall, the screen printing process is a multi-step process that requires specialized equipment and expertise to produce high-quality prints. While it can be time-consuming and labor-intensive, it remains a popular choice for producing custom prints in large quantities.
          </div>
        </div>
      </PrintingMethodsContainer>
    </div>
  </Page>
)

export default PrintingMethodsScreenPrinting;