import React from 'react';
import { number } from 'prop-types';
import { getStatusForAll, getStatusForFilters } from './helpers';

import fonts from 'src/scss/_fonts.scss';
import colors from 'src/scss/_colors.scss';

const Status = ({ statusNumber, large, isFilter = true }) => {
  const [text, setText] = React.useState('');
  const [color, setColor] = React.useState('');

  React.useEffect(() => {
    const { name, color } = isFilter ? getStatusForFilters(statusNumber) : getStatusForAll(statusNumber);

    setText(name);
    setColor(color);
  }, [statusNumber])

  return (
    <div style={{ padding: large ? '6px 26px' : '4px 8px', backgroundColor: color, color: colors.white, borderRadius: 4, fontSize: large ? 14 : 14, lineHeight: large ? '20px' : '8px', fontFamily: fonts.main, fontWeight: 400, whiteSpace: 'nowrap' }}>
      {text}
    </div>
  )
}

Status.propTypes = {
  statusNumber: number
}

export default Status;