import React from 'react';
import { number, string } from 'prop-types';

const IconPlay = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 12 14" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M12 7L3.01142e-07 13.9282L9.06825e-07 0.0717964L12 7Z" />
  </svg>
);

IconPlay.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconPlay;