import React from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';
import Snackbar from 'src/redesign/components/Snackbar';

const DEFAULT_PROPS = {
  isActive: false,
  message: '',
  isSuccess: true,
};

const useSnackbar = () => {
  const [props, setProps] = React.useState(DEFAULT_PROPS);

  React.useEffect(() => {
    const { isActive, message, isSuccess } = props;
    const root = document.getElementById('snackbar-root');

    if(isActive) {
      
      const snackbar = (
        <Snackbar
          key={uuid()}
          isActive
          text={message}
          type={isSuccess ? 'success' : 'error'}
        />
      );

      ReactDOM.render(React.createElement('div', {}, [snackbar]), root);
      
      setTimeout(() => {
        setProps(DEFAULT_PROPS);
      }, 3000);

    } else {
      ReactDOM.unmountComponentAtNode(root);
    }
  }, [props]);

  const openSnackBar = (message = 'Something went wrong...', isSuccess = true) => {
    setProps({ isActive: true, message, isSuccess });
  }

  return { openSnackBar };
}

export default useSnackbar;