import React from 'react';
import classNames from 'classnames';
import IconStorage from 'src/redesign/components/Icon/Storage';
import IconShopify from 'src/redesign/components/Icon/Shopify';
import IconEtsy from 'src/redesign/components/Icon/Etsy';
import { getActiveStores } from 'src/base/OAuth';
import { STORE } from 'src/redesign/constants';
import SubmitButton from 'src/redesign/components/Buttons/Submit';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const IconButtonNavigation = ({ isAdmin, onLoadProductClick, onAddToCartClick, addToCartDisabled, selectedTab, setSelectedTab, isImportedSelected, setIsImportedSelected }) => {
  const stores = getActiveStores();
  const isShopifyConnected = stores?.shopify;
  const isEtsyConnected  = stores?.etsy;

  const isStorageSelected = selectedTab === STORE.storage;
  const isShopifySelected = selectedTab === STORE.shopify;
  const isEtsySelected = selectedTab === STORE.etsy;

  const isExportedProductsSelected = !isImportedSelected;
  const isImportedProductsSelected = isImportedSelected;

  return (
    <div className='product-templates-navigation'>
      <div className='main-navigation-container'>
        <div className='icon-button-navigation'>
          <div className={classNames('icon-button', {'icon-button-selected': isStorageSelected})} onClick={() => setSelectedTab(STORE.storage)}>
            <IconStorage width={22} height={20} color={isStorageSelected ? colors.white : colors.primary} />
              <div className={classNames('icon-button-text', {'icon-button-text-selected': isStorageSelected})}>
                Storage
              </div>
          </div>

          {isShopifyConnected && <div className={classNames('icon-button', {'icon-button-selected': isShopifySelected})} onClick={() => {
            setSelectedTab(STORE.shopify);
            setIsImportedSelected(false);
          }}>
            <IconShopify width={23} height={26}/>
              <div className={classNames('icon-button-text', {'icon-button-text-selected': isShopifySelected})}>
                Shopify
              </div>
          </div>}

          {isEtsyConnected && <div className={classNames('icon-button', {'icon-button-selected': isEtsySelected})} onClick={() => {
            setSelectedTab(STORE.etsy);
            setIsImportedSelected(false);
          }}>
            <IconEtsy width={50} height={28}/>
              <div className={classNames('icon-button-text', {'icon-button-text-selected': isEtsySelected})}>
                Etsy
              </div>
          </div>}

        </div>
          <div className='icon-button-navigation-actions'>
            {!isAdmin && !isStorageSelected && (
              <SubmitButton className='load-product-catalog-button' text='Load Product Catalog' onClick={() => onLoadProductClick()} width={160}/>
            )}
            {!isImportedSelected && (
              <SubmitButton disabled={addToCartDisabled} className='load-product-catalog-button' text='Add To Cart' onClick={onAddToCartClick} width={100} />
            )}
          </div>
      </div>

      {!isStorageSelected && <div className='import-export-navigation-container'>
        <div className='import-export-navigation'>
          <div onClick={() => setIsImportedSelected(false)} className={classNames('import-export', {'import-export-selected': isExportedProductsSelected })}>
            Exported Products
          </div>
          <div onClick={() => setIsImportedSelected(true)} className={classNames('import-export', {'import-export-selected': isImportedProductsSelected })}>
            Imported Products
          </div>
        </div>
      </div>}
    </div>
  )
}

export default IconButtonNavigation;