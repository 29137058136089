import React from 'react';
import { number, string } from 'prop-types';

const IconLeftAlignment = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 19L1 -2.98023e-08" stroke={color} strokeOpacity="0.7"/>
    <rect x="4.5" y="14.6026" width="3.87179" height="7" transform="rotate(-90 4.5 14.6026)" stroke={color} strokeOpacity="0.7"/>
    <rect x="4.5" y="7.78204" width="3.87179" height="11" transform="rotate(-90 4.5 7.78204)" stroke={color} strokeOpacity="0.7"/>
  </svg>
);

IconLeftAlignment.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconLeftAlignment;