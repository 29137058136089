import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import { getShoppingCartItems,
         setQuantity,
         calculateShoppingCartSummary,
         removeItem,
         createDraft } from "../services/ShoppingCart";
import AttributeType from "../constants/AttributeType";
import { Button,TextField } from "@material-ui/core";
import Helmet from 'react-helmet';

class Cart extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 5 }];

    constructor(props) {
        super(props);

        this.state = {
            page : 1,
            perPage : 5,
            items: [],
            shipping: 0,
            total: 0,
            draftPopup: false,
            data: {
                draftName: null
            },
            disabled : false,
            subtotal: 0
        }

        this.getTotal = this.getTotal.bind(this);
        this.getItems = this.getItems.bind(this);
        this.onPageClick = this.onPageClick.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.createDraft = this.createDraft.bind(this);
    }

    componentDidMount(){
        this.getTotal();
    }

    fetchData(){
        this.getItems(this.state.page, this.state.perPage);
    }

    getTotal(){
        calculateShoppingCartSummary().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                shipping: response.data.shipping,
                total : response.data.total
            })
        });
    }

    getItems(page, perPage){
        if (this.state.disabled) {
            return;
        }

        this.state.disabled = true;

        getShoppingCartItems({
            page : page, 
            perPage : perPage,
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                items: response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                subtotal: response.data.total,
                page : response.data.page,
                disabled : false
            })
        });
    }

    openDropdown(id) {
        if(this.state.dropdownOpen != null){
            this.setState({
                ...this.state,
                dropdownOpen: null
            })
        }else{
            this.setState({
                ...this.state,
                dropdownOpen: id
            })
        }
       
    }

    checkout(){
        this.props.history.push('/checkout');
    }

    plus(item){
        item.quantity = item.quantity + 1;
        this.setState({
            ...this.state,
            items : this.state.items
        });

        setQuantity({
            quantity : item.quantity,
            id : item.id
        }).then(response => {

            if(!response.ok) {
                return;
            }

            setTimeout(() => {
                this.getTotal();
            }, 100);
        });
    }

    minus(item){
        if(item.quantity > 0){
            item.quantity = item.quantity - 1;
            this.setState({
                ...this.state,
                items : this.state.items
            });

            setQuantity({
                quantity : item.quantity,
                id : item.id
            }).then(response => {
    
                if(!response.ok) {
                    return;
                }
    
                setTimeout(() => {
                    this.getTotal();
                }, 100);
    
            });

            if(item.quantity == 0) {
                let items = this.state.items;
                let itemForDelete = null;

                let index = 0;
                for(let i of items){
                    if(i.id == item.id){
                        itemForDelete = item;
                        items.splice(index, 1);
                        break;
                    }
                    index++;
                }

                this.setState({
                    ...this.state,
                    items: items
                })
            }
        }


    }

    removeItem(item){

        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        removeItem({
            id: item.id
        }).then(response => {
    
            if(!response.ok) {
                return;
            }

            setTimeout(() => {
                this.getItems(this.state.page, this.state.perPage);
                this.getTotal();
            }, 1000);

        });
    }

    createDraft(){

        createDraft({
            draftName: this.state.data.draftName
        }).then(response => {
    
            if(!response.ok) {
                return;
            }

            this.props.enqueueSnackbar('Successfully created draft!', {variant: 'success'});

            this.setState({
                draftPopup: false,
                data: {
                    draftName: null
                }
            })

        });
    }

    renderAttributes(attributes){
        let result = [];
        for(let item of attributes)
        {  
            result.push(
                <div className="text">
                    <div className="left">{ item.attribute_value.attribute.name }:</div>
                    <div className="right">
                        { item.attribute_value.attribute.attribute_type == AttributeType.STRING  ?  
                        item.attribute_value.name : <div className="rect" style={{backgroundColor: item.attribute_value.name}}></div> }</div>
                </div>
            );
        }

        return result;

    }

    renderCategory() {
        let result = [];
        
        for(let item of this.state.items)
        {   
            result.push(
                <div className="cart-item">
                    <div className="image">
                        <img src={item.customer_product_variation_images[0] ? process.env.REACT_APP_baseUrl + item.customer_product_variation_images[0].merged_image_url : ''} />
                    </div>

                    <div className="product-info">
                        <div className="product-title">{ item.customer_product_variation.product_variation.name }</div>
                        { this.renderAttributes(item.product_variation_attributes) }
                    </div>

                    <div className="product-quantity">
                        <div className="quantity-option" onClick={() => this.plus(item)}>+</div>
                        <div>{ item.quantity }</div>
                        <div className="quantity-option" onClick={() => this.minus(item)}>-</div>
                    </div>

                    <div className="product-price">${(item.customer_product_variation.product_variation.price * item.quantity).toFixed(2)}</div>
                    <img className="product-delete" src="/images/x.png" onClick={() => this.removeItem(item)} />
                </div>
            );
        }
        return result;
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.page = i;
        this.getItems(i, this.state.perPage);
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        this.getItems(this.state.page, this.state.perPage);
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        this.getItems(this.state.page, this.state.perPage);
    }

    openDraftPopup(){
        this.setState({
            ...this.state,
            draftPopup: true
        })
    }

    closePopup(){
        this.setState({
            ...this.state,
            draftPopup: false
        })
    }

    render() {

        return (
            <div id="cart">
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                {
                    this.state.draftPopup && 
                    <div id="category-overlay" className='overlay' onClick={() => this.closePopup()}/>
                }
                {
                    this.state.draftPopup && 
                    <div className="draft-name">
                        <TextField 
                            //className={this.state.attributeId ? '' : 'disabled'}
                            value={this.state.data.draftName}
                            name={"draftName"}
                            onChange={this.changeData}
                            //id="standard-basic"
                            placeholder={"Draft name"}
                            variant={'outlined'}
                        />
                        <Button onClick={ () => this.createDraft()}>Save</Button>
                    </div>
                }

                <div className="title">Cart</div>
                <div className="content">
                    <div className="left-part">
                        <div className="header">
                            <div className="product">Product</div>
                            <div className="quantity">Quantity</div>
                            <div className="total">Total Price</div>
                        </div>

                        { this.renderCategory() }

                        <div className={'pagination'}>
                            {
                                this.state.page > 1 &&
                                <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                            }
                            <div className={'pages'}>
                                {this.renderPagination()}
                            </div>
                            {
                                this.state.page < this.state.totalPages &&
                                <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                            }
                        </div>


                        <div className="continue">
                            <a onClick={ () => this.props.history.push('product-templates') }> 
                                <img src="/images/arrow1.png"></img>
                                Continue Shopping
                            </a>
                        </div>
                        <div className="button">
                            <Button className="draft" onClick={() => this.openDraftPopup()}>Make a draft</Button>
                        </div>

                    </div>
                    <div className="right-part">
                        <div className="header">
                            Order Summary
                        </div>

                        <div className="text">
                            <div className="left">Subtotal ({this.state.subtotal})</div>
                            <div className="right">${ this.state.total.toFixed(2) }</div>
                        </div>

                        {/*<div className="text">*/}
                        {/*    <div className="left">Shipping</div>*/}
                        {/*    <div className="right">${ this.state.shipping.toFixed(2) }</div>*/}
                        {/*</div>*/}

                        <div className="total">
                            <div className="left">Total</div>
                            <div className="right">${ (this.state.total).toFixed(2) }</div>
                        </div>

                        <Button className="proceed" onClick={ () => this.checkout() }>Proceed to checkout</Button>
                    </div>
                </div>
            </div>
        );
    }     
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart)));
