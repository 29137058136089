import React from 'react';

import './style.scss';

const List = ({ items, columns }) => {

  return (
    <div className='list-container'>
      <div className='list-header'>
          {columns.map(x => (
            <div className='list-header-item' style={{ flex: x.flex }}>
              {x.text}
            </div>
          ))}
      </div>
      <div className='list-body'>
        {items.map(x => (
          <div className='list-body-item'>
            {columns.map(c => <div style={{ flex: c.flex, display: 'flex' }}>{c?.onRender(x)}</div>)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default List;