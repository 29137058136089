import React from 'react';
import { changeHoldCustomization, uploadProfileImage } from 'src/services/UserService';
import Dialog from 'src/redesign/components/Dialog';
import ToggleSwitch from 'src/redesign/components/Form/ToggleSwitch';
import UploadFile from 'src/redesign/components/UploadFile';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import FormPersonalInfo from './forms/PersonalInfo';
import FormSavePassword from './forms/SavePasswords';
import { getUserFromLocalStorage } from 'src/base/OAuth';

import './style.scss';

const MyProfile = ({ countryOptions, countries, user, onUpdate }) => {
  const { openSnackBar } = useSnackbar();
  const localStorageUser = getUserFromLocalStorage();

  const [image, setImage] = React.useState(localStorageUser?.image);
  const [isUploadImageInProgress, setIsUploadImageInProgress] = React.useState(false);

  const [holdForCustomization, setHoldForCustomization] = React.useState(false);
  const [isDeactivateMyAccountDialogVisible, setIsDeactivateMyAccountDialogVisible] = React.useState(false);

  React.useEffect(() => {
    if(user?.customer?.hold_customization)
      setHoldForCustomization(true);
    }, []);

  const changeHoldForCustomization = async () => {
    try {
      await changeHoldCustomization();
      setHoldForCustomization(prev => !prev);
      openSnackBar('Successfully changed hold customization!');
    } catch (error) {
      openSnackBar('Changing hold customization failed!', false);
    }
  }

  const onUploadImage = async files => {
    const file = files?.[0];
    if(!file)
      return;

    setIsUploadImageInProgress(true);

    try {
      const formData = new FormData();

      formData.append('image', file);
      const { data: { id, name, type } } = await uploadProfileImage(formData);
      const image = process.env.REACT_APP_baseUrl + `/uploads/files/1/${id}/${name}.${type}`;
      const updatedUser = { ...user, image };

      onUpdate(updatedUser);
      setImage(image);
      openSnackBar('Successfully changed profile image!');
    } catch(error) {
      openSnackBar('Failed to upload profile image!', false);
    } finally {
      setIsUploadImageInProgress(false);
    }
  }

  return <div className="my-profile">
    <div className="forms">
      <FormPersonalInfo user={user} countryOptions={countryOptions} countries={countries} onUpdate={onUpdate}/>
      <FormSavePassword />
    </div>
    <div className="right-section">
      <UploadFile setFiles={onUploadImage} image={isUploadImageInProgress ? '/images/loadersp.gif' : image} className='photo-upload' accept='.png, .jpg'/>
      <div className="toggle-section">
        <ToggleSwitch label='Hold for Customization' checked={holdForCustomization} onChange={changeHoldForCustomization}/>
        <ToggleSwitch label='Deactivate my account' checked={isDeactivateMyAccountDialogVisible} onChange={() => setIsDeactivateMyAccountDialogVisible(prev => !prev)}/>
      </div>
    </div>
    {isDeactivateMyAccountDialogVisible && (
      <Dialog text='Are you sure you want to deactivate your account?' 
        defaultButton={{
          text: 'Cancel',
          onClick: () => setIsDeactivateMyAccountDialogVisible(false)
        }}
        primaryButton={{
          text: 'Deactivate',
          // handle this to deactivate account  
          onClick: () => {},
          width: 174
        }}
        onClose={() => setIsDeactivateMyAccountDialogVisible(false)}
      />
    )}
  </div>
}

export default MyProfile;