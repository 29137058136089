import React from 'react';
import classNames from 'classnames';
import { node, string } from 'prop-types';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import IconChevronDown from 'src/redesign/components/Icon/Chevron/Down';
import IconChevronUp from 'src/redesign/components/Icon/Chevron/Up';

const Expandable = ({ className, labelClassName, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return <div className={classNames('expandable',className)}>
    {isExpanded && children}
    <div className={classNames('label', labelClassName)} onClick={() => setIsExpanded(prev => !prev)}>
      {isExpanded ? 'Show Less' : 'Show More'}
      {isExpanded ? <IconChevronUp width={14} height={8} color={colors.primary} /> : <IconChevronDown width={14} height={8} color={colors.primary} />}
    </div>
  </div>
};

Expandable.propTypes = {
  className: string,
  children: node
}

export default Expandable;