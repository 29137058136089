import React from 'react';
import connect from 'react-redux/es/connect/connect';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import Pagination from 'src/redesign/components/Pagination';
import Table from 'src/redesign/components/Table';
import Searchbox from 'src/redesign/components/Searchbox';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import ToggleSwitch from 'src/redesign/components/Form/ToggleSwitch';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import CustomTooltip from 'src/redesign/components/CustomTooltip';
import IconEmail from 'src/redesign/components/Icon/Email';
import IconVisitLink from 'src/redesign/components/Icon/VisitLink';
import IconCopy from 'src/redesign/components/Icon/CopyLink';
import IconPhone from 'src/redesign/components/Icon/Phone';

import * as Actions from 'src/actions/Actions';

import { deactivateUser, getCurrentUserAction, getCustomers } from 'src/services/UserService';
import { dateToString } from 'src/util/DateUtil';
import { getPlatforms } from './helpers';
import { getUserFromLocalStorage, setImpersonatedUser, setUserToLocalStorage } from 'src/base/OAuth';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { copyToClipboard } from 'src/redesign/helpers/form';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const PER_PAGE = 4;

const UsersPage = ({ updateStorageUser, history }) => {
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState(null);

  const [users, setUsers] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, [currentPage, searchTerm]);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await getCustomers({
        page: currentPage,
        perPage: PER_PAGE,
        searchTerm: searchTerm
      });

      setTotalPages(Math.ceil(data.total/PER_PAGE));
      setUsers(data.result);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load users', false);
    } finally {
      setLoading(false);
    }
  }
  
  const toggleActiveUser = async (id, active) => {
    try {
      await deactivateUser({ id });

      setUsers(prev => prev.map(x => x.id == id ? {...x, active: !active } : x));
    } catch(error) {
      console.error(error);
      openSnackBar(`Failed to ${active ? 'deactivate' : 'activate'} user`, false);
    }
  }

  const impersonateUser = async user => {
    try {
      setImpersonatedUser(getUserFromLocalStorage());
      setUserToLocalStorage(user);

      const { data: { image }} = await getCurrentUserAction();
      const profileImage = image != "" ? process.env.REACT_APP_baseUrl + image : null;

      setUserToLocalStorage({ ...user, image: profileImage });
      updateStorageUser();
      history.push('/home');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to login as user', false);
    }
  }
  
  //#region table

  const columns = [
    {
      Header: 'User',
      accessor: 'id',
      Cell: ({ row: { original: { first_name, last_name, email, customer: { store_url_profile, business_information: { phone_number } } }}}) => (
        <div className='users-user-details'>
          <div className='users-username'>
            {first_name} {last_name}
          </div>
          <div className='details-row'>
            <IconEmail width={24} height={20} color={colors.primary} /> 
            <div className='user-email-text' style={{ marginTop: 4 }}>
              Email:
            </div>
            <div className='user-click-container' onClick={() => copyToClipboard(email)}>
              <IconCopy width={20} height={20} color={colors.primary}/> 
            </div>
            <a className='user-click-container' href={`mailto:${email}`}>
              <IconVisitLink color={colors.primary} width={20} height={20} />
            </a>
          </div>
          <div className='phone-row'>
            <IconPhone color={colors.primary} width={14} height={16} />
            <div className='user-phone-label'>
              Phone:
            </div>
            <div className='user-phone'>{phone_number}</div>
          </div>
          <div className='details-row'>
            <div className='user-email-text'>
              Store URL:
            </div>
            <div className='user-click-container' onClick={() => copyToClipboard(store_url_profile)}>
              <IconCopy width={20} height={20} color={colors.primary} />
            </div>
            <a className='user-click-container' target="_blank" href={`//${store_url_profile}`}>
              <IconVisitLink color={colors.primary} width={20} height={20} />
            </a>
          </div>
        </div>
      )
    },
    {
      Header: 'Platform',
      accessor: 'roles',
      Cell: ({ row: { original: { customer }}}) => <CustomTooltip text={getPlatforms(customer)}>{getPlatforms(customer)}</CustomTooltip>
    },
    {
      Header: 'Company Name',
      accessor: 'customer',
      Cell: ({ row: { original: { customer }}}) => <CustomTooltip text={customer.company_name}>{customer.company_name}</CustomTooltip>
    },
    {
      Header: 'Date',
      accessor: 'date_created',
      Cell: ({ row: { original: { date_created }}}) => <>{dateToString(date_created)}</>
    },
    {
      Header: 'Activated',
      accessor: 'enabled',
      Cell: ({ row: { original: { id, active }}}) => <div onClick={() => toggleActiveUser(id, active)}>
        <ToggleSwitch className='users-toggle-switch' checked={active} onChange={()=>{}}/>
      </div>
    },
    {
      Header: 'Login',
      accessor: 'user_type',
      Cell: ({ row: { original }}) => <PrimarySubmitButton text='Login' onClick={()=>impersonateUser(original)} width={65} />
    }
  ];

  //#endregion table

  return (
    <ApplicationPage selectedTab='admin'>
      <ApplicationPageContent title='Users'>
        <div className='users-page'>
          <Searchbox
            placeholder='Search'
            className='users-searchbox-container'
            onChange={setSearchTerm}
            disabled={loading}
          />
          {loading
          ? <Loader width={200} height={200} />
          : (
            <div>
              <Table
                columns={columns}
                data={users}
                colorfulRows
              />
              <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages}/>
            </div>
          )}
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateStorageUser: Actions.loadUser
  }, dispatch);
}

export default withRouter(connect(null, mapDispatchToProps)(UsersPage));