import React from 'react';
import { withRouter } from 'react-router';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import { CATEGORIES_TABS } from 'src/redesign/constants';
import AllCategories from './components/AllCategories';
import TabsCatalog from './components/TabsCatalog';
import Loader from 'src/redesign/components/Loader';
import CategoriesList from './components/CategoriesList';
import Searchbox from 'src/redesign/components/Searchbox';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getCategoriesTree } from 'src/services/CategoryService';
import { getBrandsForCategory } from 'src/services/admin/BrandService';
import InfoImportedProduct from 'src/redesign/components/InfoImportedProduct';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ProductCatalog = ({ match: { params: { tab }}, location: { state: { importedProduct } = {}} }) => {
  const [loading, setLoading] = React.useState(false);
  const [searchboxText, setSearchboxText] = React.useState('');

  const [filteredCategories, setFilteredCategories] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [topCategories, setTopCategories] = React.useState([]);
  
  const selectedTab = CATEGORIES_TABS.find(x => x.key === tab);
  const isAllSelected = selectedTab?.key === 'all';
  const selectedCategoryId = topCategories.find(x => x.name === selectedTab.text)?.id;
  const selectedCategoiresByTab = filteredCategories.filter(x => x?.parent_category?.id === selectedCategoryId);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadCategories();
  }, []);

  React.useEffect(() => {
    if(selectedCategoryId)
      getBrands();
  }, [selectedCategoryId]);

  React.useEffect(() => {
    setFilteredCategories(categories.filter(x => {
      const name = x?.name?.toLowerCase();
      return name.includes(searchboxText);
    }));
  }, [searchboxText]);

  const loadCategories = async () => {
    setLoading(true);
    try {
      const { data } = await getCategoriesTree();
      const topCategories = data.filter(x => !x.parent_category);
      
      setCategories(data);
      setFilteredCategories(data);
      setTopCategories(topCategories);
    } catch {
      openSnackBar('Failed to load categories', false);
    } finally {
      setLoading(false);
    }
  }

  const getBrands = async () => {
    setLoading(true);
    try {
      const { data } = await getBrandsForCategory(selectedCategoryId);

      // uvek je prazno
      // a nema razlike u staging i lokalno
      
    } catch(error){
      openSnackBar('Failed to load brands for category', false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ApplicationPage selectedTab="product-catalog">
      <ApplicationPageContent title='Product Catalog'>
        <div className='product-catalog'>
          {importedProduct && (
            <InfoImportedProduct {...importedProduct} />
          )}
          <Searchbox 
            placeholder="Type to search"
            inputClassName='catalog-searchbox'
            onChange={v => setSearchboxText(v)}
          />
          <TabsCatalog selectedTab={selectedTab?.key}/>
          {isAllSelected
          ? <AllCategories searchboxText={searchboxText} />
          : loading
            ? <Loader width={400} height={400} color={colors.primary} />
            : (
              <CategoriesList
                categories={selectedCategoiresByTab}
              />
          )}
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(ProductCatalog);