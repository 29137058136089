export const OrderEnum = {
    NewOrder: 0,
    NeedsCustomization: 1,
    Pending: 4,
    ReadyForPrint: 9,
    Cancel: 7,
    InProduction: 10,
    Shipped: 15,
    Issues: -1,
};

export const SHIPPING_METHOD_OPTIONS = [
    {
        key: 0,
        text: 'Standard'
    },
    {
        key: 1,
        text: 'Expedited'
    },
    {
        key: 2,
        text: 'Overnight'
    }
];

export const STORE = {
    all: 'all',
    shopify: 'shopify',
    etsy: 'etsy',
    storage: 'storage'
}

export const CATEGORIES_TABS = [
    {
        key: 'all',
        text: 'All Categories',
        to: '/product-catalog-in-app/all'
    },
    {
        key: 'drinkware',
        text: 'Drinkware',
        image: '/images/category-drinkware.png',
        to: '/product-catalog-in-app/drinkware',
        description: (
            <>
                <div className='catalog-category-description-title'>
                    Sublimation printing is ideal for drinkware products because it allows for full-color, photographic quality prints that are scratch-resistant and durable. It's also a cost-effective method for printing small to medium-sized runs of drinkware products. In addition, sublimation printing is a versatile process that can be used on a wide range of drinkware products, including ceramic mugs, travel mugs, water bottles, and more.
                </div>
            </>
        ),
        seoTitle: 'Print On Demand Drinkware - Custom Drinkware | Surge Print',
        seoDescription: 'Print On Demand Drinkware. SurgePrint offers premium printing solutions for vibrant, impactful results. Explore our services now!'
    },
    {
        key: 'apparel',
        text: 'Apparel',
        image: '/images/category-apparel.png',
        to: '/product-catalog-in-app/apparel',
        description: (
            <>
                <div className='catalog-category-description-title'>
                    There are several apparel printing methods available today, each with its unique features and advantages. Here are some of the most common apparel printing methods:
                </div>

                <li className='catalog-category-description-list'>
                    Screen Printing: This is the most popular method for printing designs onto apparel, such as t-shirts and hoodies. It involves creating a stencil (or "screen") of the design and then using it to transfer ink onto the fabric.
                </li>

                <li className='catalog-category-description-list'>
                    Direct-to-Garment (DTG) Printing: This method uses a digital printer to print designs directly onto the fabric. It's best suited for smaller quantities or intricate designs that are difficult to produce with screen printing.
                </li>

                <li className='catalog-category-description-list'>
                    Heat Transfer Printing: This method involves printing the design onto transfer paper, which is then transferred to the fabric using heat and pressure. It's best suited for small runs or custom designs with multiple colors.
                </li>

                <li className='catalog-category-description-list'>
                    Dye Sublimation Printing: This method involves using heat and pressure to transfer dye onto synthetic fabrics. It's best suited for full-color designs or designs with a lot of shading.
                </li>

                <li className='catalog-category-description-list'>
                    Embroidery: This method involves stitching the design onto the fabric using thread. It's best suited for designs with a lot of detail or logos that need to be precise.
                </li>

                <div className='catalog-category-description-title'>
                    Each of these printing methods has its own unique advantages and limitations, so it's important to consider factors such as the design complexity, fabric type, and quantity needed before selecting a printing method.
                    <br/>
                    SurgePrint offers all 5 methods depending on your business needs. We can also store your items in two warehouses located in the United States and Europe.
                </div>
            </>
        ),
        seoTitle: 'Print On Demand Apparel - Custom Clothing | Surge Print',
        seoDescription: 'Print On Demand Apparel. SurgePrint offers premium printing solutions for vibrant, impactful results. Explore our services now!'
    },
    {
        key: 'wall-art',
        text: 'Wall Art',
        image: '/images/category-wall-art.png',
        to: '/product-catalog-in-app/wall-art',
        seoTitle: 'Print On Demand Wall Art - Custom Wall Art | Surge Print',
        seoDescription: 'Print On Demand Wall Art. SurgePrint offers premium printing solutions for vibrant, impactful results. Explore our services now!'
    },
    {
        key: 'home-decor',
        text: 'Home Decor',
        image: '/images/category-home-decor.png',
        to: '/product-catalog-in-app/home-decor',
        seoTitle: 'Print On Demand Home Decor - Custom Home Decor | Surge Print',
        seoDescription: 'Print On Demand Home Decor. SurgePrint offers premium printing solutions for vibrant, impactful results. Explore our services now!'
    },
    {
        key: 'accessories',
        text: 'Accessories',
        image: '/images/category-accessories.png',
        to: '/product-catalog-in-app/accessories',
        seoTitle: 'Print On Demand Accessories - Custom Accessories | Surge Print',
        seoDescription: `Print On Demand Accessories. SurgePrint offers premium printing solutions for vibrant, impactful results. Explore our services now!`
    }
]

export const ADMIN_TABS = [
    {
        text: 'Users',
        to: '/users'
    },
    {
        text: 'Admins',
        to: '/admins'
    },
    {
        text: 'Vendors',
        to: '/vendors'
    },
    {
        text: 'MM Mockups',
        to: '/media-modifier-mockups'
    },
    {
        text: 'Documents',
        to: '/sp-documents'
    },
    {
        text: 'Products',
        to: '/category/-1'
    }
]

export const IMAGE_ALT = [
    'affordable print on demand',
    'custom design printing',
    'custom printing',
    'on demand printing services',
    'personalized printing',
    'print on demand',
    'print on demand company',
    'print on demand firm'
];