import React from 'react';
import Page from 'src/redesign/components/Page';
import PrintingMethodsContainer from 'src/redesign/components/PrintingMethodsContainer';
import Stepper from 'src/redesign/components/Stepper';

import './style.scss';

const PrintingMethodsBulkOrders = () => (
  <Page
    seoTitle='Printing Methods Bulk Orders | Surge Print'
  >
    <div className='printing-methods-bulk-orders'>
      <PrintingMethodsContainer
        imageText='Printing Methods Bulk Orders'
        title='While print-on-demand (POD) is typically associated with small to medium-sized orders, SurgePrint also offes bulk order options for customers who need larger quantities of products. Bulk orders in the POD industry typically involve the same process as smaller orders, but with some differences in production and pricing. For apparel orders, both DTG and Embroidery bulk orders are possible and yes, we do offer discounts for bulk orders depending on item quantity.'
      >
        <div className='printing-methods-bulk-orders-container'>
          <div className='printing-methods-bulk-orders-subtitle'>
            Here are some key considerations for bulk orders in the POD industry:
          </div>
          <Stepper
            steps={[
              'Production Time: Bulk orders often require longer production times than smaller orders, due to the larger quantities involved. At SurgePrint, he minimal item quantity is 50 units going to the same shipping address. Turnaround time is 5-7 business days for DTG orders and for 1000+ items in an order the best practise is to provide all order details at least 7 business days before the deadline.',
              'Pricing: Pricing for bulk orders is typically lower than for smaller orders, due to economies of scale. We offer volume discounts for bulk orders, which can be attractive to businesses and organizations that need large quantities of products for a specific event.',
              'Quality Control: With larger quantities of products, quality control becomes even more important to ensure consistency and accuracy.We offer additional quality control measures in place for bulk orders, such as spot-checking or batch testing, to ensure the products meet the required standards. We also send you images/ videos of first printed item / batch.',
              'Shipping and Fulfillment: Shipping and fulfillment for bulk orders may require different logistics than smaller orders, due to the larger quantities and potentially different packaging requirements. We allow you to choose the shipping method and carrier* ( * available only for US domestic orders).'
            ]}
          />
          <div className='printing-methods-bulk-orders-description'>
            Overall, bulk orders in the POD industry can be a cost-effective and efficient way to produce large quantities of customized products. By working with SurgePrint and communicating any special requirements upfront, businesses and organizations can ensure their bulk orders are produced to the required standards and delivered on time.
          </div>
        </div>
      </PrintingMethodsContainer>
    </div>
  </Page>
);

export default PrintingMethodsBulkOrders;