import React from 'react';
import classNames from 'classnames';
import { any, func, number } from 'prop-types';

import IconChevronLeft from 'src/redesign/components/Icon/Chevron/Left';
import IconChevronRight from 'src/redesign/components/Icon/Chevron/Right';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ImagePicker = ({ onSelect, images = [], selectedImage: initiallySelectedImageId }) => {
  const selectedImage = images.find(x => x.id === initiallySelectedImageId) ?? images[0];
  const selectedImageIndex = images.indexOf(selectedImage);

  const previousImage = images.length > 1 ? images[selectedImageIndex === 0 ? images.length - 1 : selectedImageIndex - 1] : undefined;

  const nextImage = images.length > 1 && images[(Math.abs(selectedImageIndex + 1))%images.length];

  return <div className='image-picker-container' style={{ justifyContent: (previousImage && nextImage) || (!previousImage && !nextImage) ? 'center' : previousImage ? 'start' : 'end'}}>
    {previousImage && (
      <div className={classNames('side-image-container', 'side-image-container-left')} onClick={() => onSelect(previousImage.id)}>
        <Image alt={getImageAlt()} className='side-image' src={process.env.REACT_APP_baseUrl + previousImage.image_path} />
        <div className='picker-button-container'>
          <IconChevronLeft width={25} height={20} color={colors.blackDark} />
        </div>
      </div>
    )}
    <div className='main-image-container'>
      <Image alt={getImageAlt()} className='main-image' src={process.env.REACT_APP_baseUrl + selectedImage.image_path} />
      <div className='main-text'>
        {selectedImage.name}
      </div>
    </div>
    {nextImage && (
      <div className='side-image-container' onClick={() => onSelect(nextImage.id)}>
        <Image alt={getImageAlt()} className='side-image' src={process.env.REACT_APP_baseUrl + nextImage.image_path} />
        <div className='picker-button-container'>
          <IconChevronRight width={25} height={20} color={colors.blackDark} />
        </div>
      </div>
    )}
  </div>
}

ImagePicker.propTypes = {
  onSelect: func,
  selectedImage: number,
  images: any
}

export default ImagePicker;