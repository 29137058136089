import React from 'react';
import TextInput from 'src/redesign/components/Form/TextInput';
import SelectInput from 'src/redesign/components/Form/SelectInput';
import Overview from 'src/redesign/components/Overview';
import * as Validators from 'src/redesign/util/validators';
import { mapShippingData, mapUpdatedOrder } from './helpers';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getCountryList } from 'src/redesign/../services/CountryService';
import { changeShippingInfo } from 'src/redesign/../services/OrderService';

const ShippingAddressDetails = ({ order, onUpdate }) => {
  const [form, setForm] = React.useState({});

  const [countryOptions, setCountryOptions] = React.useState([]);
  const [countries, setCountries] = React.useState([]);

  const { openSnackBar } = useSnackbar();
  // Disable change of shipping address for orders in production status
  const isInProduction = order.status === 10;

  React.useEffect(() => {
    load();
  }, [order]);

  const load = async () => {
    try {
      const { data: { countries }} = await getCountryList();

      setForm(mapShippingData(order));
      setCountries(countries);
      setCountryOptions(countries.map(x => ({ key: x.id, text: x.name })));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to laod countries!', false);
    }
  }

  const save = async (newShippingInfo) => {
    try {
      const shippingInfo = { ...newShippingInfo, shippingCountry: countries.find(x => x.id === newShippingInfo.shippingCountry )};
      await changeShippingInfo(shippingInfo);

      onUpdate(mapUpdatedOrder(order, shippingInfo));

      openSnackBar('Successfully updated shipping info!');
    } catch (error){
      console.error(error);
      openSnackBar('Failed to change shipping info!', false);
    }
  }

  return (
    <Overview
      items={[
        {
          name: 'Address Line 1',
          value: form.shippingAddress,
          edit: <TextInput name='shippingAddress' placeholder='Addres Line 1' validate={Validators.required(' ')} />
        },
        {
          name: 'Address Line 2',
          value: form.shippingAddres2 ?? '-',
          edit: <TextInput name='shippingAddres2' placeholder='Addres Line 2' />
        },
        {
          name: 'City',
          value: form.shippingCity,
          edit: <TextInput name='shippingCity' placeholder='City' validate={Validators.required(' ')} />
        },
        {
          name: 'Country',
          value: form.shippingCountry,
          edit: <SelectInput name='shippingCountry' options={countryOptions}/>
        },
        {
          name: 'State/Region',
          value: form.shippingState,
          edit: <TextInput name='shippingState' placeholder='State/Region' />
        },
        {
          name: 'ZIP/Postal Code',
          value: form.shippingPostalCode,
          edit: <TextInput name='shippingPostalCode' placeholder='ZIP/Postal Code' validate={Validators.required(' ')}/>
        },
        {
          name: 'Email',
          value: form.shippingEmail,
          edit: <TextInput name='shippingEmail' placeholder='Email' validate={Validators.required(' ')} />
        },
        {
          name: 'First Name',
          value: form.shippingFirstName,
          edit: <TextInput name='shippingFirstName' placeholder='First Name' validate={Validators.required(' ')} />
        },
        {
          name: 'Last Name',
          value: form.shippingLastName,
          edit: <TextInput name='shippingLastName' placeholder='Last Name' validate={Validators.required(' ')} />
        },
        {
          name: 'Phone',
          value: form.shippingPhoneNumber,
          edit: <TextInput name='shippingPhoneNumber' placeholder='Phone Number' validate={Validators.required(' ')} />
        }
      ]}
      initialValues={form}
      showEditButton={!isInProduction}
      onSubmit={save}
    />
  )
}

export default ShippingAddressDetails;