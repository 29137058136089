export const mapProduct = (data, shippingPrices) => ({
  id: data.id,
  name: data.name,
  isAvailable: data.is_available,
  categoryId: data.category?.id,
  brandId: data.brand?.id,
  description: data.description,
  price: data.price,
  changePrice: false,
  changeVendorPrice: false,
  changeShipping: false,
  changeVendorShipping: false,
  // TODO: uncomment when it is handled on backend
  // vendorId: data.vendor?.id,
  vendorPrice: data.vendor_price,
  guidelinesLink: '',
  seoTitle: data.seo_title,
  seoDescription: data.seo_description,
  ...shippingPrices
});

export const mapAttributeValues = data => {
  const attributes = [];

  data.forEach(x => {
    const attribute = x.attribute_value?.attribute;
    if(attributes.find(a => a.id == attribute.id)) return;
    attributes.push(attribute);
  })

  const attributeValues = _.uniq(data.map(x => x.attribute_value));

  return attributes.map(x => ({
    new: false,
    type: x.attribute_type,
    attributeValues: attributeValues.filter(v => v.attribute.id == x.id)
  }))
}

export const mapShippingPrices = data => ({
  usFirstStandard: data.us_standard_first,
  usFirstExpedited: data.us_expedited_first,
  usFirstOvernight: data.us_overnight_first,
  usOtherStandard: data.us_standard_n,
  usOtherExpedited: data.us_expedited_n,
  usOtherOvernight: data.us_overnight_n,
  intFirstStandard: data.int_standard_first,
  intFirstExpedited: data.int_expedited_first,
  intOtherStandard: data.int_standard_n,
  intOtherExpedited: data.int_expedited_n,
  vendorUsFirstStandard: data.vendor_us_standard_first,
  vendorUsFirstExpedited: data.vendor_us_expedited_first,
  vendorUsFirstOvernight: data.vendor_us_overnight_first,
  vendorUsOtherStandard: data.vendor_us_standard_n,
  vendorUsOtherExpedited: data.vendor_us_expedited_n,
  vendorUsOtherOvernight: data.vendor_us_overnight_n,
  vendorIntFirstStandard: data.vendor_int_standard_first,
  vendorIntFirstExpedited: data.vendor_int_expedited_first,
  vendorIntOtherStandard: data.vendor_int_standard_n,
  vendorIntOtherExpedited: data.vendor_int_expedited_n,
});