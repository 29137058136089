import React from 'react';

import './style.scss';

const Checkbox = ({ name, value, checked = false, disabled = false, onChange, style = {} }) => {
  return (
    <input className='custom-checkbox' type='checkbox' name={name} value={value} checked={checked} disabled={disabled} onChange={onChange} style={style}/>
  )
}

export default Checkbox;