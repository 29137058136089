import Page from "../common/Page";
import {withRouter} from "react-router-dom";
import React from 'react'
import {Button} from "@material-ui/core";
import {
    getCustomerTemplate,
    updateCustomerTemplate
} from "../services/admin/ProductVariationService";
import {withSnackbar} from "notistack";
import {addItemsToShoppingCart} from "../services/ShoppingCart";
import {getVariationsForTemplate} from "../services/admin/ProductService";
import {getFromPathLocalStorage, setFromPathLocalStorage} from "../base/OAuth";


class TemplateEdit extends Page{

    constructor(props) {
        super(props);

        this.state = {
            variations : [],
            data : {
                name : getFromPathLocalStorage() === "placeAnOrder" ? this.makeid(10) : '',
                description : getFromPathLocalStorage() === "placeAnOrder" ? this.makeid(20) : ''
            },
            template : {},
            arrayIds: [],
            loaderActive : false,
            fromPage: getFromPathLocalStorage() === "placeAnOrder",
            prodVarIds : props.history.location.state?.prodVarIds,
            importedProduct: null,
            selectedVariation: null
        };
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            loaderActive : true
        })
        getVariationsForTemplate({id : this.props.match.params.id, ids : this.state.prodVarIds}).then(response =>{
            if (!response.ok){
                return
            }

            let variations = [];
            let variationIds = [];

            response.data.forEach(item => {
                item.variation.customerImages = item.images
                variations.push(item.variation);
                variationIds.push(item.variation.id);
            })

            let importedProduct = null;

            if (this.props.location.state !== undefined) {
                if ('importedProduct' in this.props.location.state) {
                    importedProduct = this.props.location.state.importedProduct;
                }
            }

            this.setState({
               ...this.state,
                data : {
                   ...this.state.data,
                    name : response.data[0].variation.customer_template.name,
                    description : response.data[0].variation.customer_template.description ? response.data[0].variation.customer_template.description : response.data[0].variation.product_variation.product.description,
                    id : response.data[0].variation.customer_template.id
                },
                loaderActive : false,
               variations : response.data,
               arrayIds : variationIds,
                importedProduct: importedProduct
            });
        });
    }

    renderSkus(){
        let render = [];

        this.state.variations.forEach(item => {
            let name = item.variation.product_variation ? item.variation.product_variation.name : item.variation.name;

            render.push(
                <div onClick={() => this.setSelectedVariation(item)} className={`sku ${this.isSelectedVariation(item) ? 'sku--highlighted': ''}`}>
                    <img src={item.variation.customerImages? item.variation.customerImages.length > 0 ? process.env.REACT_APP_baseUrl + item.variation.customerImages[0].merged_image : '' : ''}/>
                    <span>{name}</span>
                </div>
            );
        })

        return render;
    }

    renderImage() {
        const variations = this.state.variations;
        let selectedVariationImagePath = '';

        if (!variations || !variations[0]?.images) {
            return;
        }
        if(this.state.selectedVariation) {
            selectedVariationImagePath = this.state.selectedVariation.images[0].merged_image
        } else {
            selectedVariationImagePath = this.state.variations[0].images[0].merged_image;

        }

        return process.env.REACT_APP_baseUrl + selectedVariationImagePath
    }

    setSelectedVariation = (selectedVariation) =>  {
        this.setState({selectedVariation: selectedVariation})
    }
    isSelectedVariation = (selectedVariation) =>  {
        if (!this.state.selectedVariation) {
            return this.state.variations[0] && this.state.variations[0].variation.id == selectedVariation.variation.id
        } 
        return this.state.selectedVariation.variation.id == selectedVariation.variation.id
    }

    updateTemplate(){
        updateCustomerTemplate({
            id : this.state.data.id,
            name : this.state.data.name,
            description : this.state.data.description,
            orderTemplate : this.state.fromPage
        }).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                template : response.data
            })

            this.props.enqueueSnackbar('Successfully updated', { variant: 'success' });

            setFromPathLocalStorage(null);

            if (this.state.fromPage) {
                addItemsToShoppingCart(response.data.arrayIds).then(response => {
                    if (!response.ok) {
                        return
                    }

                    this.props.enqueueSnackbar("Successfully added to shopping cart!", {variant: 'success'});

                    setTimeout(() => {
                        this.props.history.push('/cart');
                    }, 500);

                })
            } else if (this.state.importedProduct !== null) {
                localStorage.setItem('selectedTemplateId', this.state.data.id)
                this.props.history.push('/import-products/' + this.state.importedProduct.id);
            } else {
                getCustomerTemplate(this.state.data.id).then(response => {
                    if (!response.ok){
                        return
                    }

                    if (response.data.length !== 0){
                        this.props.history.push('/export-product/' + this.state.data.id);
                    } else {
                        this.props.history.push('/export-product/' + this.state.data.id, {
                            customerProductVariations: this.state.arrayIds,
                            lastPage: "template-edit"
                        });
                    }
                })
            }

        })
    }

    render() {
        return (
            <div className={'temple-edit container'}>
                {
                    this.state.loaderActive &&
                    <div className={'load-helper'}>
                        <div className={'bg'}/>
                        <img src={'/images/loadersp.gif'} />
                    </div>
                }
                <div className={'left'}>
                    <div>
                        <label className={'bold var'}>Product Details</label>
                        <div className={'line blue'}></div>
                    </div>
                    <div className={'render-image-container'}>
                        <img src={this.renderImage()} />
                    </div>
                </div>
                <div className={'right'}>
                    <label className={'bold var'}>Variants</label>
                    <div className={'line blue'}></div>
                    {
                        !this.state.fromPage &&
                        <label>Name</label>
                    }
                    {
                        !this.state.fromPage &&
                        <input name={'name'} onChange={this.changeData} value={this.state.data.name}/>
                    }
                    {
                        !this.state.fromPage &&
                        <label className={'desc'}>Description</label>
                    }
                    {
                        !this.state.fromPage &&
                        <textarea name={'description'} onChange={this.changeData} value={this.state.data.description}/>
                    }
                    <label className={'bold'}>Select the SKU to see full size mockup image </label>
                    <div className={'line'} />
                    <div className={'skus'}>
                        {this.renderSkus()}
                    </div>
                    {
                        !this.state.fromPage &&
                        <Button variant="contained" color="primary" className={'primary'} onClick={() => {
                            if (!this.state.data.name) {
                                this.props.enqueueSnackbar("Please add product name", { variant: 'error', autoHideDuration: 6000 });
                            } else {
                                this.updateTemplate();
                            }
                        }}>
                            Publish
                        </Button>
                    }
                    {
                        this.state.fromPage &&
                        <Button variant="contained" color="primary" style={{textTransform: "unset"}} className={'primary'} onClick={() => {
                            this.updateTemplate();
                        }}>
                            Place an Order
                        </Button>
                    }
                </div>
            </div>
        );
    }
}

export default withSnackbar(withRouter(TemplateEdit));
