import React from 'react';
import { withRouter } from 'react-router';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Searchbox from 'src/redesign/components/Searchbox';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import IconButtonNavigation from './components/Navigation';
import { deleteCustomerTemplate, getImportedProduct, getTemplatesForUser, importProductsFromEtsy, importProductsFromShopify } from 'src/services/admin/ProductVariationService';
import { STORE } from 'src/redesign/constants';
import { getAllCustomers } from 'src/services/UserService';
import { getUserFromLocalStorage } from 'src/base/OAuth';
import { addItemsToShoppingCart } from 'src/services/ShoppingCart';
import Pagination from 'src/redesign/components/Pagination';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Loader from 'src/redesign/components/Loader';
import Dropdown from 'src/redesign/components/Dropdown';
import TemplateList from './components/List';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ProductLibraryPage = ({ history, match: { params: { store }} }) => {
  const selectedTab = store ?? STORE.storage;
  const [isImportedProductsSelected, setIsImportedProductsSelected] = React.useState(false);
  
  const [loading, setLoading] = React.useState(false);
  const [customersLoading, setCustomersLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [templates, setTemplates] = React.useState([]);

  const [importedCurrentPage, setImportedCurrentPage] = React.useState(1);
  const [importedTotalPages, setImportedTotalPages] = React.useState(1);
  const [importedTemplates, setImportedTemplates] = React.useState([]);
  
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);

  const [checkedTemplates, setCheckedTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  const user = getUserFromLocalStorage();
  const isAdmin = user?.roles?.includes('SUPER_ADMIN');

  const { openSnackBar } = useSnackbar();

  const PER_PAGE = 24;

  //#region load
  
  React.useEffect(() => {
    if(user)
      loadAllCustomers();
  }, []);

  React.useEffect(() => {
    loadTemplates(currentPage, selectedTab, selectedCustomer, searchTerm);
  }, []);

  const loadAllCustomers = async () => {
    setCustomersLoading(true);
    try {
      const { data } = await getAllCustomers();

      const customer = data.find(x => x.id === user.customerId);
      setCustomerOptions(data.map(x => ({ key: x.id ?? null, text: x.displayName })));
      setSelectedCustomer({ key: customer?.id ?? null, text: customer?.displayName ?? 'All'});
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load customers', false);
    } finally {
      setCustomersLoading(false);
    }
  }

  const loadTemplates = async (page, tab, customer, text) => {
    setLoading(true);
    try {

      const { data: { paginationInfo: { total }, templateArr } } = await getTemplatesForUser(
        page, {
          shopifyExported: tab === STORE.shopify,
          etsyExported: tab === STORE.etsy,
          storage: tab === STORE.storage,
          customerId: customer?.key ?? null,
          keywords: text,
          perPage: PER_PAGE
        },
      );

      setTotalPages(Math.ceil(total/PER_PAGE));
      setTemplates(templateArr);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load templates', false);
    } finally {
      setLoading(false);
    }
  }

  const loadImportedTemplates = async (page, tab, keywords) => {
    setLoading(true);
    try {
      const { data, headers } = await getImportedProduct({
        page,
        limit: 24,
        keywords
      }, tab);

      setImportedCurrentPage(Number.parseInt(headers['pagination-page']));
      setImportedTotalPages(Number.parseInt(headers['pagination-total-pages']));
      setImportedTemplates(data);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load imported templates', false);
    } finally {
      setLoading(false);
    }
  }

  const importShopifyProducts = async () => {
    try {
      const { data } = await importProductsFromShopify();
      await loadImportedTemplates(1, selectedTab, searchTerm);

      if(data)
        openSnackBar(`Loading is in progress, we found ${data} products in your Shopify store!`);
      else
        openSnackBar('Failed to import Shopify products!', false);
    } catch(error) {
      console.error(error);
      openSnackBar('You need to connect your Shopify store!', false);
    }
  }

  const importEtsyProducts = async () => {
    try {
      const { data } = await importProductsFromEtsy();
      await loadImportedTemplates(1, selectedTab, searchTerm);
      
      if(data)
        openSnackBar(`Loading is in progress, we found ${data} products in your Etsy store!`);
      else
        openSnackBar('Failed to import Etsy products!', false);
    } catch(error) {
      console.error(error);
      openSnackBar('You need to connect your Etsy store!', false);
    }
  }

  //#endregion load

  //#region save

  const editItem = id => {
    history.push(`/export-product/${id}`);
  }

  const deleteItem = async id => {
    try {
      await deleteCustomerTemplate({ templateId: id });

      if(currentPage === 1) {
        await loadTemplates(1, selectedTab);
        setTemplates(prev => prev.filter(x => x?.template?.id !== id));
      } else
        setCurrentPage(1);

      openSnackBar('Successfully deleted template');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to delete template', false);
    }
  }

  const addToCart = async ids => {
    if(isAdmin) {
      openSnackBar(`Admin can't make an order!`, false);
      return;
    }
    try {
      await addItemsToShoppingCart(ids);
      
      setCheckedTemplates([]);
      openSnackBar('Successfully added to shopping cart!');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to add to cart', false);
    }
  }

  const importItem = async ({ importedId }) => {
    localStorage.setItem('storeImportedFrom', selectedTab);
    history.push('/import-products/' + selectedTab + '/' + importedId);
  }

  //#endregion save

  return (
    <ApplicationPage selectedTab='product-templates'>
      <ApplicationPageContent title='Product Library'>
        <div className='product-library-container'>
          <div className='row-container'>
            <Searchbox inputClassName='searchbox' placeholder='Search' onChange={newSearchTerm => {
                setSearchTerm(newSearchTerm);
                isImportedProductsSelected ? loadImportedTemplates(1, selectedTab, newSearchTerm) : loadTemplates(1, selectedTab, selectedCustomer, newSearchTerm);
              }}
              loadDeps={[isImportedProductsSelected, selectedTab]}
            />
            <SubmitButton className='create-product-button' text='+ Create Product' onClick={()=> history.push('/product-catalog-in-app/all')} width={200}/>
          </div>
          {isAdmin && (
            <div style={{ marginTop: 26 }}>
              <Dropdown
                options={customerOptions}
                selectedOption={selectedCustomer}
                onSelect={newCustomer => {
                  setSelectedCustomer(newCustomer);
                  loadTemplates(1, selectedTab, newCustomer, searchTerm);
                }}
                width={200}
                itemClassName='customer-option'
              />
            </div>
          )}
          <IconButtonNavigation
            isAdmin={isAdmin}
            onLoadProductClick={() => selectedTab === STORE.shopify ? importShopifyProducts() : importEtsyProducts()}
            onAddToCartClick={() => addToCart(checkedTemplates)}
            addToCartDisabled={checkedTemplates.length === 0}
            selectedTab={selectedTab}
            setSelectedTab={newTab => {
              setSelectedTemplate(null);
              setCurrentPage(1);
              setIsImportedProductsSelected(false);
              loadTemplates(1, newTab, selectedCustomer, searchTerm);
              history.push(`/product-templates/${newTab}`)
            }}
            isImportedSelected={isImportedProductsSelected}
            setIsImportedSelected={newIsImportedSelected => {
              setIsImportedProductsSelected(newIsImportedSelected);
              setSelectedTemplate(null);
              if(selectedTab !== STORE.storage)
                newIsImportedSelected ? loadImportedTemplates(1, selectedTab, searchTerm) : loadTemplates(1, selectedTab, selectedCustomer, searchTerm);
            }}
          />
          {loading || customersLoading
          ? <Loader width={250} height={283} color={colors.primary} className='product-library-loader' />
          : (
            <>
              <TemplateList
                items={isImportedProductsSelected ? importedTemplates : templates}
                isImported={isImportedProductsSelected}
                checkedItemsIds={checkedTemplates}
                selectedTemplate={selectedTemplate}
                editItem={editItem}
                deleteItem={deleteItem}
                addToCart={(ids) => addToCart(ids)}
                importItem={importItem}
                onCheck={(ids, checked) => setCheckedTemplates(prev => checked ? [...prev, ...ids] : prev.filter(x => ids.every(i => i !== x)))}
                onClick={x => setSelectedTemplate(x)}
              />
              <Pagination className='pagination-container' page={isImportedProductsSelected ? importedCurrentPage : currentPage} setPage={newPage => {
                isImportedProductsSelected ? setImportedCurrentPage(newPage) : setCurrentPage(newPage);
                isImportedProductsSelected ? loadImportedTemplates(newPage, selectedTab, searchTerm) : loadTemplates(newPage, selectedTab, selectedCustomer, searchTerm);
                setSelectedTemplate(null);
              }} totalPages={isImportedProductsSelected ? importedTotalPages : totalPages} />
            </>
          )}
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(ProductLibraryPage);