import React from 'react';
import { number, string } from 'prop-types';

const IconLibrary = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill="white" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_154_4711)">
    <path d="M9.01104 9.30301C8.94479 9.30301 8.87853 9.28112 8.83436 9.25923L0.220859 4.59679C0.0662577 4.53112 0 4.39978 0 4.24655C0 4.09333 0.0883436 3.96199 0.220859 3.91821L8.83436 0.0437899C8.94479 1.11107e-05 9.05521 1.11107e-05 9.16564 0.0437899L17.7791 3.91821C17.9117 3.98388 18 4.11522 18 4.24655C18 4.39978 17.9337 4.53112 17.8012 4.59679L9.18773 9.25923C9.12147 9.30301 9.0773 9.30301 9.01104 9.30301ZM1.2589 4.29033L8.98896 8.47121L16.719 4.29033L8.98896 0.809919L1.2589 4.29033Z" fill={color}/>
    <path d="M13.3178 6.98272C13.2516 6.98272 13.1853 6.96083 13.1411 6.93894L4.90311 2.6705C4.70434 2.56106 4.63808 2.34216 4.74851 2.14516C4.85894 1.94815 5.0798 1.88249 5.27857 1.99193L13.4945 6.26037C13.6933 6.36981 13.7596 6.58871 13.6491 6.78571C13.5829 6.91705 13.4503 6.98272 13.3178 6.98272Z" fill={color}/>
    <path d="M9.01107 19C8.94481 19 8.87856 18.9781 8.8123 18.9562C8.70187 18.8906 8.61353 18.7592 8.61353 18.6279V8.93088C8.61353 8.79954 8.70187 8.66821 8.8123 8.58065L17.4258 3.91821C17.5362 3.85254 17.6908 3.85254 17.8233 3.91821C17.9338 3.98388 18.0221 4.11521 18.0221 4.24655V13.9436C18.0221 14.0749 17.9338 14.2062 17.8233 14.2938L9.20984 18.9562C9.1215 19 9.07733 19 9.01107 19ZM9.38653 9.14978V17.9712L17.227 13.7247V4.92512L9.38653 9.14978Z" fill={color}/>
    <path d="M9.01104 19C8.94479 19 8.87853 18.9781 8.83436 18.9562L0.220859 14.2938C0.0883435 14.2281 0 14.0968 0 13.9654V4.26843C0 4.13709 0.0662577 4.00576 0.198773 3.94009C0.309202 3.87442 0.463804 3.87442 0.596319 3.94009L9.20982 8.60253C9.34233 8.6682 9.40859 8.79954 9.40859 8.95276V18.6498C9.40859 18.7811 9.34233 18.9124 9.20982 18.9781C9.14356 19 9.0773 19 9.01104 19ZM0.773006 13.7247L8.6135 17.9712V9.14977L0.773006 4.92511V13.7247Z" fill={color}/>
    <path d="M6.27239 14.3594C6.20613 14.3594 6.16196 14.3375 6.0957 14.3157L2.95951 12.7615C2.82699 12.6958 2.73865 12.5645 2.73865 12.4113V8.93087C2.73865 8.79953 2.80491 8.66819 2.91533 8.60252C3.02576 8.53686 3.18037 8.51497 3.29079 8.58064L6.42699 10.1348C6.55951 10.2005 6.64785 10.3318 6.64785 10.485V13.9873C6.64785 14.1187 6.58159 14.25 6.47116 14.3157C6.4049 14.3375 6.33865 14.3594 6.27239 14.3594ZM3.53374 12.1705L5.87484 13.3306V10.7039L3.53374 9.54377V12.1705Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_154_4711">
    <rect width="18" height="19" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

IconLibrary.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconLibrary;