import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import strings from '../localization';
import TextField from '@material-ui/core/TextField';
import AddVendor from "../components/AddVendor";
import { getRootCategories, getCategoryByParent, getAllCategories, 
         uploadCategoryImage, addCategory, setTrending,
         updateCategory, deleteCategory } from "../services/CategoryService";
import { getImage } from '../services/ImageService';
import Validators from "../constants/ValidatorTypes";
import {withSnackbar} from "notistack";
import { getVendors,
        createVendor,
        updateVendor,
        deleteVendor } from "../services/VendorService";
import {dateToString} from "../util/DateUtil";
import ReactDOM from 'react-dom';

class Vendors extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 12 }];

    validationList = {
        name: [ {type: Validators.REQUIRED } ]
    };

    constructor(props) {
        super(props);

        this.state = {
            siderbar: false,
            display: 'none',
            data: {
                name: null,
                id: null
            },
            vendors: [],
            selectedCategory: props.match.params.id ? props.match.params.id : -1,
            page : 1,
            perPage : 12,
            categories: [],
            type: "Category",
            allCategories: [],
            dropdownOpen: null
        }

        this.submited = this.submited.bind(this);
        this.doesHaveAdminPermissions();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
 
        if (this.state.dropdownOpen) {
            if(event.target.className != "opt" && event.target.className != "dropdown-opt"){
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    dropdownOpen: false
                });
            }
        }
    }

    fetchData(){
        this.getVendors(this.state.page, this.state.perPage);
    }

    getVendors(page, perPage){
        getVendors({
            page : page, 
            perPage : perPage
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                vendors: response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page
            })
        });
    }

    openSiderbar(){
        this.setState({
            ...this.state,
            siderbar: true,
            display: 'block'
        });
    }

    goBack(){
        this.setState({
            ...this.state,
            siderbar: false,
            display: 'none',
            data: {
                ...this.state.data,
                categoryImage: null,
                parentCategory: null,
                name: null,
                id: null
            }
        });
    }


    onDropPicture(acceptedFile) {
        let formData = new FormData();
        formData.append('image', acceptedFile[0]);

        uploadCategoryImage(formData).then(response => {

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    categoryImage: response.data,
                }
            })


        });
    }

    renderRootCategories(){
        let result = [];

        for(let item of this.state.rootCategories)
        {   result.push(
                <div className={this.state.selectedCategory == item.id ? "category active" : "category"} onClick={ () => this.getCategoryByParent(item.id) }>{ item.name }</div>
            );
        }

        return result;
    }

    showImage(item){
        if(this.state.type == "Category"){
            return  <img src={getImage(item.category_image)}></img>;
        }else{
            return <img src={getImage(item.product_image)}></img>;
        }
    }

    getParentNameOrProductCategory(item) {

        if(this.state.type == "Category"){
            return <div className="category">{ item.parent_category ? item.parent_category.name : "" }</div>;
        }else{
            return  <div className="category">{ item.category.name }</div>;
        }
    }

    setTrending(item){

        if(window.event.srcElement.nodeName != "IMG") { return; }

        setTrending({
            id: item.id
        }).then(response => {

            item.in_trending = !item.in_trending;
             
            this.setState({
                ...this.state
            });

        });
    }

    openDropdown(id) {
        if(this.state.dropdownOpen != null){
            this.setState({
                ...this.state,
                dropdownOpen: null
            })
        }else{
            this.setState({
                ...this.state,
                dropdownOpen: id
            })
        }
       
    }

    editItem(item){
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                name: item.name,
                id: item.id
            },
            siderbar: true,
            display: 'block',
            dropdownOpen: null
        });
    }

    deleteItem(id){
        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        if(this.state.type == "Category"){
            deleteVendor({
                id: id
            }).then(response => {
    
                if(!response.ok) {
                    return;
                }
    
                setTimeout(() => {  
                    this.fetchData();
                }, 1000);
    
            });
        }
       
    }

    submited(){
        if(!this.validate()) {
            return;
        }

        if(this.state.data.id == null){
            this.createVendor();
        }else{
            this.updateVendor();
        }
    }

    updateVendor(){
        updateVendor(this.state.data).then(response => {

            this.setState({
                ...this.state,
                siderbar: false,
                display: 'none',
                data: {
                    ...this.state.date,
                    name: null,
                    id: null
                },
            });

            setTimeout(() => {  
                this.fetchData();
            }, 1000);
        });
    }

    createVendor(){
        createVendor(this.state.data).then(response => {

            this.setState({
                ...this.state,
                siderbar: false,
                display: 'none',
                data: {
                    ...this.state.data,
                    name: null,
                    id: null
                },
            });

            setTimeout(() => {  
                this.getVendors(1, this.state.perPage);
            }, 1000);
        });
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }



    renderVendors() {
        let result = [];
        var i = 0;
        
        for(let item of this.state.vendors)
        {   
            i++;
            result.push(
                <div className="category-details">
                    <div className="vendor-name">
                        { item.name }
                    </div>
                    <div className="vendor-created">{ dateToString(item.date_created) }</div>
                    <div className="options">
                        <div className="opt" onClick={ () => this.openDropdown(item.id) }><img src="/images/tackice.png"></img></div>
                        <div className={ this.state.dropdownOpen == item.id ? "dropdown active" : "dropdown" }>
                            <div className="dropdown-opt" onClick={ () => this.editItem(item) }>Edit</div>
                            <div className="dropdown-opt" onClick={ () => this.deleteItem(item.id) }>Delete</div>
                        </div>
                    </div>
                </div>
            );
        }

        return result;
    }

   

    render() {

        return (
            <div id="category">

                <div id="category-overlay" className='overlay' style={{display: this.state.display}} onClick={ () => this.goBack() }></div>
                <div id="siderbar" style={{opacity : this.state.display == "block" ? "1" : "0",
                        right : this.state.display == "block" ? "0" : "-492px",
                        height: document.getElementById("root").offsetHeight + 114 + 'px'}}>
                        {
                            this.state.siderbar &&
                            <AddVendor data={this.state.data} errors = {this.state.errors} onChange={this.changeData} 
                                goBack={this.goBack} submited={this.submited}/>
                        }
                </div>

                <div className="button-new">
                    <Button className={"new-category"} onClick={ () => this.openSiderbar()}>Create new vendor</Button>
                </div>

                <div className="category-list">
                    <div className="header">
                        <div className="vendor-name">Name</div>
                        <div className="vendor-created">Created</div>
                    </div>

                    { this.renderVendors() }
                </div>

                <div className={'pagination'}>
                    {
                        this.state.page > 1 &&
                        <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                    }
                    <div className={'pages'}>
                        {this.renderPagination()}
                    </div>
                    {
                        this.state.page < this.state.totalPages &&
                        <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                    }
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Vendors)));
