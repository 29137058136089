import React from 'react';

const BlueLoader = () => (
    <img style={{
        width: '300px',
        height: '300px',
        margin: 'auto',
        display: 'flex'
    }} src={"/images/loadersp.gif"}  alt="loader" />
);

export default BlueLoader;
