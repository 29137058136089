import React from 'react';
import { oneOfType, number, string } from 'prop-types';

const IconQuote = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2545 2.3194L18.25 5.33797C18.6998 6.7351 19.9957 7.68489 21.4626 7.69246H24.5998C26.0711 7.6831 27.3793 8.62768 27.8342 10.0279C28.2891 11.4281 27.7862 12.9619 26.5907 13.8202L24.0116 15.6917C22.8243 16.5542 22.3254 18.0824 22.7748 19.48L23.7702 22.4985C24.2626 23.9009 23.7819 25.4605 22.5858 26.3417C21.3896 27.223 19.7586 27.2191 18.5667 26.3321L16.0328 24.4455C14.845 23.5845 13.2388 23.5845 12.051 24.4455L9.51708 26.3321C8.33179 27.2002 6.72179 27.2025 5.53403 26.3378C4.34628 25.4731 3.85294 23.9395 4.31354 22.5438L5.309 19.5253C5.75841 18.1277 5.25947 16.5994 4.07222 15.737L1.43275 13.8353C0.213007 12.9752 -0.298478 11.4163 0.174418 10.0002C0.647314 8.58399 1.99259 7.64596 3.484 7.69246H6.6212C8.07999 7.68919 9.37348 6.75336 9.83381 5.36816L10.8293 2.34959C11.2755 0.955321 12.5679 0.00691092 14.0309 3.75934e-05C15.494 -0.00683573 16.7952 0.929389 17.2545 2.3194Z" fill={color}/>
  </svg>
);

IconQuote.propTypes = {
  width: oneOfType([number, string]).isRequired,
  height: oneOfType([number, string]).isRequired,
  color: string.isRequired,
}

export default IconQuote;