import React, {Component} from 'react'
import Helmet from 'react-helmet';

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from '../localization';
import {Button} from "@material-ui/core";
import LoginForm from "../components/forms/user/LoginForm";
import Validators from "../constants/ValidatorTypes";
import {getImpersonatedUser, login, removeImpersonatedUser} from "../base/OAuth";
import {getUserFromLocalStorage} from "../base/OAuth";

class Login extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            errors: {},
            redirectUrl: props.location.state ? props.location.state.redirectUrl : '/home'
        }

        this.props.changeFullScreen(true);
        this.keyPress = this.keyPress.bind(this);
    }

    componentDidMount() {

        if (this.props.auth.user) {
            if (getUserFromLocalStorage() == null) {
                this.props.auth.user = null
            } else {
                this.props.history.push('/home');
            }
        }
    }

    keyPress(event) {

        if (event.key === 'Enter') {
            this.login()
        }
    }

    login() {

        this.validationList = {
            email: [{type: Validators.REQUIRED}],
            password: [{type: Validators.REQUIRED}]
        };
        if (!this.validate()) {
            return;
        }

        login(this.state.data.email, this.state.data.password).then(response => {

            if (!response.ok) {

                this.setError('email', 'Wrong Credentials');
                return;
            }

            if (getImpersonatedUser()) {
                removeImpersonatedUser();
            }

            this.props.history.push({
                pathname: this.state.redirectUrl
            })
        });
    }

    goToSignUp() {
        this.props.history.push('/registration');
    }

    goToHomePage() {
        this.props.history.push('/');
    }

    render() {

        return (
            <>
                <Helmet>
                    <title>Personal Account | Surge Print</title>
                    <meta
                        name='description'
                        content='Personal Account. SurgePrint offers high-quality, customizable printing solutions for a lasting impression. Unleash the power of print excellence!'
                    />
                </Helmet>
                <div id="login">
                    <div className="left"></div>
                    <div className="center" style={{paddingTop: "0"}}>
                        <div className="logo" style={{marginTop: "0"}} onClick={() => this.goToHomePage()}></div>
                        <h1 className="title">{strings.login.title}</h1>
                        <LoginForm onSubmit={() => this.login()} onChange={this.changeData}
                                    keyPress={this.keyPress}
                                    data={this.state.data} errors={this.state.errors}/>
                    </div>
                    <div className="right">
                        <Button onClick={() => this.goToSignUp()}>{strings.login.registrationButton}</Button>
                    </div>
                </div>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        login: Actions.login
    }, dispatch);
}

function mapStateToProps({menuReducers, authReducers}) {
    return {menu: menuReducers, auth: authReducers};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
