import React from 'react';

import './style.scss';

const Stepper = ({ steps }) => (
  <div className='vertical-stepper-container'>
    {steps.map((x, i) => (
      <>
        <div className='vertical-stepper-step'>
          <div className='vertical-stepper-step-text'>
            {i+1}.
          </div>
          <div className='vertical-stepper-step-text'>
            {x}
          </div>
        </div>
        {i !== steps.length - 1 && (
          <div className='vertical-stepper-divider'>
            <div className='vertical-stepper-divider-line'/>
            <div className='vertical-stepper-divider-plus'>
              <div className='vertical-stepper-divider-plus'>
                +
              </div>
            </div>
            <div className='vertical-stepper-divider-line'/>
          </div>
        )}
      </>
    ))}
  </div>
)

export default Stepper;