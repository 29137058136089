import React from 'react';
import classNames from 'classnames';

import './style.scss';

const Counter = ({ count, onChange, disabled, isLarge = true }) => {
  const isMinimal = count === 0 || disabled;
  return (
    <div className="counter">
      <div className={classNames('minus', {'disabled': isMinimal, 'large': isLarge })} onClick={() => isMinimal ? undefined : onChange(count - 1)}>
        -
      </div>
      <div className={classNames('label', {'label-large': isLarge})}>
        {count}
      </div>
      <div className={classNames('minus','plus', {'disabled': disabled, 'large': isLarge })} onClick={() => disabled ? undefined : onChange(count + 1)}>
        +
      </div>
    </div>
  );
}


export default Counter;