import strings from "../localization";

const ShippingMethod = [
    {
        value: 0,
        name: 'Standard'
    },
    {
        value: 1,
        name: 'Expedited'
    },
    {
        value: 2,
        name: 'Overnight'
    }
];

export function getShippingMethodString(type) {

    switch(type) {
        case 0: return strings.shippingMethod.standard;
        case 1: return strings.shippingMethod.expedited;
        case 2: return strings.shippingMethod.evernight;
    }
}


export default ShippingMethod;
