import React from 'react';
import { Field } from 'react-final-form';
import { func, string } from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const TextInput = ({ prefix, label, name, placeholder, validate, type, disabled, labelClassName, containerClassName, className }) => {

  return (
    <Field name={name} validate={validate} type={type ?? 'text'}>
      {({ input, meta }) => (
        <div className={classNames("text-input", containerClassName)}>
          {label && (
            <div className={classNames("label", labelClassName)}>
              {label}
            </div>
          )}
          <div className="field">
            {prefix}
            {type === 'textarea'
            ? <textarea className={classNames("input",'textarea', {'input-error': meta.touched && meta.error}, className)} {...input} placeholder={placeholder} disabled={disabled}/>
            :
            <input className={classNames("input", {'input-error': meta.touched && meta.error}, className)} type={type ?? 'text'} {...input} placeholder={placeholder} disabled={disabled}/>
            }
            {meta.touched && meta.error && meta.error !== ' ' && <span className="error">{meta.error}</span>}
          </div>
        </div>
      )}
    </Field>
  )
}

TextInput.propTypes = {
  label: string,
  name: string.isRequired,
  placeholder: string,
  validate: func,
  className: string
}

export default TextInput;

export const StateTextInput = ({ label, placeholder, type, disabled, value, containerClassName, className, onChange }) => {
  return (
    <div className={classNames("text-input", containerClassName)}>
      {label && (
        <div className="label">
          {label}
        </div>
      )}
      <div className="field">
        {type === 'textarea'
        ? <textarea className={classNames("input",'textarea', className)} value={value} placeholder={placeholder} disabled={disabled} onChange={(e) => onChange(e.target.value)}/>
        :
        <input className={classNames("input", className)} value={value} type={type ?? 'text'} placeholder={placeholder} disabled={disabled} onChange={(e) => onChange(e.target.value)}/>
        }
      </div>
    </div>
  )
}