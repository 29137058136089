import React from 'react';
import { object } from 'prop-types';

const StructuredData = ({ data }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(data)
    }}
  />
)

StructuredData.propTypes = {
  data: object.isRequired
}

export default StructuredData;