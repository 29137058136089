import React from 'react';
import { string } from 'prop-types';

const YoutubeVideo = ({ className, embedId }) => (
  <iframe
    src={`https://www.youtube.com/embed/${embedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
    className={className}
  />
)

YoutubeVideo.propTypes = {
  className: string,
  embedId: string.isRequired
}

export default YoutubeVideo;