import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function getShoppingCartItems(data) {
    return await request('/api/shopping_cart/get_shopping_cart_items', data, HttpMethod.GET);
}

export async function setQuantity(data) {
    return await request('/api/shopping_cart/set_quantity', data, HttpMethod.PUT);
}

export async function calculateShoppingCartSummary(data) {
    return await request('/api/shopping_cart/get_summary', data, HttpMethod.GET);
}

export async function calculateDynamicShoppingCartSummary(id) {
    return await request('/api/shopping_cart/calculate_dynamical_shopping_summary/' + id, [], HttpMethod.GET);
}

export async function removeItem(data) {
    return await request('/api/shopping_cart/remove_item', data, HttpMethod.PUT);
}

export async function createDraft(data) {
    return await request('/api/shopping_cart/create_draft', data, HttpMethod.POST);
}

export async function addItemsToShoppingCart(data) {
    return await request('/api/shopping_cart/add_items_to_shopping_cart', data, HttpMethod.POST);
}

export async function getUserDrafts(data) {
    return await request('/api/draft/get_user_drafts', data, HttpMethod.GET);
}

export async function deleteDraft(data) {
    return await request('/api/draft/delete_draft', data, HttpMethod.PUT);
}

export async function getDraftItems(data) {
    return await request('/api/draft/get_draft_items', data, HttpMethod.GET);
}

export async function addDraftToShoppingCart(data) {
    return await request('/api/draft/add_draft_to_shopping_cart', data, HttpMethod.POST);
}