import React from 'react';
import { array, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';

import Menu from 'src/redesign/components/Menu';
import Checkbox from 'src/redesign/components/Checkbox';
import IconCart from 'src/redesign/components/Icon/Cart';
import IceditItem from 'src/redesign/components/Icon/Edit';
import IconTrash from 'src/redesign/components/Icon/Trash';
import IconSync from 'src/redesign/components/Icon/Sync';
import CustomTooltip from 'src/redesign/components/CustomTooltip';
import AttributeType from 'src/constants/AttributeType';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const LibraryItem = ({ item, editItem, deleteItem, addToCart, isImported, importItem, checkedItemsIds, onCheck, selected, onClick, isSubVariation = false }) => {
  const { name, id } = isSubVariation ? item.customerVariation.product_variation : (isImported ? item : item.template);
  const { sync, etsy_sync, shopify_exported, etsy_exported } = isSubVariation ? {} : (isImported ? item : item.template);
  const image = isSubVariation 
    ? item.image.merged_image_url
    : (isImported
      ? item.image
      : item.templateVariations?.length > 0
        ? item.templateVariations?.[0].image?.merged_image_url
        : item?.template?.image);

  const imageUrl = isImported ? image : (image ? (process.env.REACT_APP_baseUrl + image) : '');

  const customerVariationId = isSubVariation ? item?.customerVariation?.id : undefined;
  const customerVariationIds = !isSubVariation ? item.templateVariations?.map(x => x.customerVariation?.id) : [];
  const checked = isSubVariation ? checkedItemsIds.some(x => x === customerVariationId) : customerVariationIds?.every(x => checkedItemsIds.some(y => y === x));

  const checkItem = () => {
    if(isSubVariation) 
      return onCheck([customerVariationId], !checked);

    return onCheck(customerVariationIds, !checked);
  }

  const addToCartMaybe = isImported ? [] : [{
    key: 'addToCart',
    text: <>
      Add to Cart
      <IconCart width={24} height={24} color={colors.primary}/>
    </>,
    onSelect: () => addToCart(customerVariationIds)
  }];

  const sortAttributes = (a, b) => {
    const nameA = a?.[0]?.attribute_value?.attribute?.name;
    const nameB = b?.[0]?.attribute_value?.attribute?.name;
    return nameA?.toLowerCase() < nameB?.toLowerCase() ? -1 : 1;
  }

  return (
    <div className={classNames('library-item', {'library-item-selected': selected })} style={{ width: isSubVariation ? '150px' : '180px' }}>
      {!isImported && (
        <div className='library-item-checkbox'>
          <Checkbox checked={checked} onChange={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            e.preventDefault();
            e.nativeEvent.preventDefault();
            checkItem();
          }} />
        </div>
      )}
      {!isSubVariation && (
        <Menu
          width={4}
          height={18}
          className={classNames('library-item-menu',{'library-item-menu-imported': isImported})}
          contentClassName={classNames('library-item-menu-content', {'library-item-menu-content-imported': isImported })}
          itemClassName='library-item-menu-item'
          options={[
            {
              key: 'edit',
              text: <>
                  Edit
                <IceditItem width={24} height={24} color={colors.primary}/>
              </>,
              onSelect: () => editItem(id)
            },
            {
              key: 'delete',
              text: <>
                  Delete
                <IconTrash width={24} height={24} color={colors.primary}/>
              </>,
              onSelect: () => deleteItem(id)
            },
            ...addToCartMaybe,
          ]}
        />
      )}
      <img className='library-item-image' loading='lazy' src={imageUrl} onClick={() => isImported ? undefined : onClick(selected ? null : item)}/>
      <div className='library-item-text'>
        <CustomTooltip className='library-item-title' text={name}>
          {name}
        </CustomTooltip>
        {isImported && (
          <b className='library-attribute'>
            Connected SKUs {item.numberOfMappedVariations}/{item.numberOfVariations}
          </b>
        )}
        {!isImported && !isSubVariation && (
          <b className='library-see-more' onClick={() => onClick(selected ? null : item)}>
            See more info
          </b>
        )}
      </div>
      {isSubVariation && (
          item.values.sort(sortAttributes).map(v => (
            v.map(x => (
              <div className='library-sub-variation-row'>
                <CustomTooltip className='library-sub-variation-title' text={x?.attribute_value?.attribute?.name}>
                  {x?.attribute_value?.attribute?.name}
                </CustomTooltip>
                <CustomTooltip className='library-sub-variation-description' text={x?.attribute_value?.attribute?.attribute_type === AttributeType.COLOR ? x?.attribute_value?.customer_color_name : x?.attribute_value?.name}>
                  {x?.attribute_value?.attribute?.attribute_type === AttributeType.COLOR ? x?.attribute_value?.customer_color_name : x?.attribute_value?.name}
                </CustomTooltip>
              </div>
            ))
          )
        )
      )}
      {isImported && (
        <div className='library-item-sync-button' onClick={() => importItem(item)}>
          Start Syncing
          <IconSync width={14} height={18} color={colors.white}/>
        </div>
      )}
      {(sync || etsy_sync || shopify_exported || etsy_exported) && (
        <div className='import-export-container'>
          {(etsy_sync || etsy_exported) && (
            <div className='import-export-etsy'>
              Etsy
            </div>
          )}
          {(sync || shopify_exported) && (
            <div className='import-export-shopify'>
              Shopify
            </div>
          )}
        </div>
      )}
    </div>
  )
}

LibraryItem.propTypes = {
  template: shape({
    template: shape({
      id: number,
      image: string,
      name: string
    }),
    values: array
  }),
  editItem: func.isRequired,
  deleteItem: func.isRequired,
  addToCart: func.isRequired
}

export default LibraryItem;