import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PrimaryButton from 'src/redesign/components/Buttons/Primary';
import CustomButton from 'src/redesign/components/Buttons/Custom';
import Page from 'src/redesign/components/Page';
import ProductList from 'src/redesign/components/ProductList';
import { WaveUp, WaveDown } from 'src/redesign/components/Wave/Up';
import CreateGif from './assets/create-and-sell.gif';
import MockupGIF from './assets/mockup-API.gif';
import PremiumMockupGIF from './assets/premium-mockups.gif';
import IconPlay from 'src/redesign/components/Icon/Play';
import WhyChooseUs from 'src/redesign/components/WhyChooseUs';
import SignupLogin from 'src/redesign/components/SignupLogin';
import Testemonial from 'src/redesign/components/Testemonial';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import IconChevronRight from 'src/redesign/components/Icon/Chevron/Right';
import Image from 'src/redesign/components/Image';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import HowItWorksDialog from './components/HowItWorksDialog';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import { getTrendingCategory } from 'src/services/CategoryService';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Loader from 'src/components/Loader';
import { getImageAlt, mapNameToUrl } from 'src/redesign/helpers';

const LandingPage = ({ history }) => {
  const [isHowItWorksOpen, setIsHowItWorksOpen] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    setLoading(true);

    try {
      const { data } = await getTrendingCategory();
      const products = [];

      data.categories.forEach(x => {
        products.push({
          name: x.name,
          price: '$ ' + x.min_price,
          url: process.env.REACT_APP_baseUrl + x?.category_image?.url,
          to: `/product-catalog-category/${mapNameToUrl(x.name)}/${x.id}`
        })
      });

      setProducts(products);
    } catch(error) {
      console.error(error);
      openSnackBar('An error occured while loading products!', false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page>
      <div className="landing-page">
          <div className='landing-hero-section'>
            <h1 className="title">
              SurgePrint print-on-demand services: Print, sell, repeat
            </h1>
            <div className="main-content">
              <div className="text-section">
                <div className='sub-title'>
                  100% Free to Use
                </div>
                <div className='description'>
                  So, what does it take to kickstart a new product line or start selling branded merchandise? If you’re thinking you need a lot of capital and preparation time to plunge into such an endeavor, think again. It only takes SurgePrint, an all-in-one print-on-demand company.
                  <br/>
                  <br/>
                  Amplify your creative vision as you choose from over 300 products, bring your prints to life, and ship your orders to your customers without lifting a finger. You can even do this from home!
                </div>
                <div className="buttons">
                  <PrimaryButton text="Start Selling" onClick={()=> history.push('/registration')} isResponsive/>
                  <CustomButton
                    icon={
                    <div className="icon-play">
                      <IconPlay
                        width={16}
                        height={16}
                        color={colors.secondary}
                        />
                    </div>
                    }
                    buttonProps={{
                      isResponsive: true,
                      text:"How it works",
                      onClick: () => setIsHowItWorksOpen(true)
                    }}
                  />
                </div>
              </div>
              <Image src={CreateGif} alt={getImageAlt()} className="image"/>
            </div>
          </div>

          <video src="/images/landing-video.mp4" autoPlay loop muted playsInline className='landing-video'/>

          <div className='good-company'>
            <h2 className='good-company-title'>
              Be in Good Company
            </h2>
            <div className='good-company-content'>
              <Image src="/images/tjmax.png" alt={getImageAlt()} style={{ width: 183, height: 183 }}/>
              <Image src="/images/nordstorm.png" alt={getImageAlt()} style={{ width: 183, height: 183 }}/>
              <Image src="/images/fanatics.png" alt={getImageAlt()} style={{ width: 183, height: 139 }} />
              <Image src="/images/pascun.png" alt={getImageAlt()} style={{ width: 183, height: 183 }} />
              <Image src="/images/tillys.png" alt={getImageAlt()} style={{ width: 183, height: 183 }} />
              <Image src="/images/disnep.png" alt={getImageAlt()} style={{ width: 183, height: 183 }} />
              <Image src="/images/burlington.png" alt={getImageAlt()} style={{ width: 183, height: 183 }} />
              <Image src="/images/ross.png" alt={getImageAlt()} style={{ width: 183, height: 183 }} />
              <Image src="/images/zumiez.png" alt={getImageAlt()} style={{ width: 183, height: 183 }} />
              <Image src="/images/buckle.png" alt={getImageAlt()} style={{ width: 183, height: 145 }} />
              <Image src="/images/nike.png" alt={getImageAlt()} style={{ width: 183, height: 136 }} />
              <Image src="/images/walmart.png" alt={getImageAlt()} style={{ width: 183, height: 122 }} />
            </div>
          </div>

          <div className="most-popular">
            <WaveUp width="100%" height="105" color={colors.primary}/>
            <div className="content">
              {loading ? (
                <Loader width={200} height={200} color={colors.white} />
              ) : (
                <ProductList products={products}/>
              )}
              <SubmitPrimaryButton
                text='See Full Catalog'
                onClick={() => history.push('/product-catalog-website')}
                className='see-full-catalog'
                isResponsive
              />
            </div>
            <WaveDown width="100%" height="105" color={colors.primary} />
          </div>

          <div className='design-speak-container'>
            <div className='design-speak-images-outer-container'>
              <div className='design-speak-images-inner-container'>
                <Image src='/images/design-speak-1.png' alt={getImageAlt()} className='design-speak-image' />
                <Image src='/images/design-speak-2.png' alt={getImageAlt()} className='design-speak-image' />
              </div>
              <div className='design-speak-images-inner-container'>
                <Image src='/images/design-speak-3.png' alt={getImageAlt()} className='design-speak-image' />
              </div>
            </div>
            <div className='design-speak-text-container'>
              <div className='design-speak-text-container'>
                <h2 className='design-speak-text-title'>
                  Let your designs speak volumes
                </h2>
                <div className='design-speak-text-description'>
                  Our print-on-demand services are trusted by 10,000 businesses worldwide. Follow us to start creating appealing designs and selling your products with the least hassle.
                  <br/>
                  <Link className='design-speak-text-logo' to='/registration'>Sign up for SurgePrint</Link> for free to access our mockups, , wholesale pricing, and all-inclusive dropshipping done for you services.
                </div>
              </div>
            </div>
          </div>

          <div className='all-in-one-container'>
            <div className='all-in-one-text-container'>
              <h2 className='all-in-one-title'>
                <span className='all-in-one-title-secondary'>All-in-one</span>
                <br/>
                custom
                <br/>
                printing
                <br/>
                powerhouse
              </h2>
              <div className='all-in-one-description'>
                Discover the ease of doing business with SurgePrint and our comprehensive range of services:
              </div>
              <div className='all-in-one-buttons'>
                <SecondaryButton
                  text='Get Started'
                  onClick={() => history.push('/registration')}
                />
                <div className='all-in-one-signup-button' onClick={() => history.push('/registration')}>
                  Sign up <IconChevronRight width={24} height={24} color={colors.primary} />
                </div>
              </div>
            </div>
            <div className='all-in-one-boxes'>
              <div className='all-in-one-box'>
                <h3 className='all-in-one-box-title'>
                  Production
                </h3>
                <div className='all-in-one-box-description'>
                  Customize your products, decide on the quantity, and let us do the rest. There’s no MOQ, but you can enjoy fatter discounts and personalized pricing for larger orders.
                </div>
              </div>
              <div className='all-in-one-box'>
                <h3 className='all-in-one-box-title'>
                  Warehousing
                </h3>
                <div className='all-in-one-box-description'>
                  Don’t have space to store your merchandise? Leave it to us. Sync your e-commerce shops and get your products fulfilled as soon as your customers buy them. Watch out for stock-outs with our integrated Inventory Manager.
                </div>
              </div>
              <div className='all-in-one-box'>
                <h3 className='all-in-one-box-title'>
                  Packaging
                </h3>
                <div className='all-in-one-box-description'>
                  The unboxing experience matters. Our white-label printing service and fulfillment allow you to have your products packaged by our team, including as many branded elements as you wish.
                </div>
              </div>
              <div className='all-in-one-box'>
                <h3 className='all-in-one-box-title'>
                  Dropshipping
                </h3>
                <div className='all-in-one-box-description'>
                  Hate all those logistics processes? We can handle them for you while ensuring your customers think it’s you who has shipped their products, locally or internationally. Besides, you’ll love our dropshipping fees!
                </div>
              </div>
            </div>
          </div>

          <div className='premium-mockups-container'>
            <WaveUp width="100%" height="105" color={colors.greenDark}/>
            <div className='premium-mockup-content'>
              <h2 className='premium-mockups-title'>
                Premium Mockups
              </h2>
              <div className='premium-mockups-row'>
                <div className='premium-mockups-description'>
                  With SurgePrint, you’re not getting just the best global print network and wholesale pricing, you’re also getting <span className='premium-mockups-description-primary'>3000</span> best in the industry mockups, <span className='premium-mockups-description-primary'>completely free of charge</span>.
                </div>
                <Image className='premium-image' alt={getImageAlt()} src={PremiumMockupGIF} />
              </div>
              <img className='premium-api-image' alt={getImageAlt()} src={MockupGIF} />
            </div>
            <WaveDown width="100%" height="105" color={colors.greenDark} />
          </div>

          <div className='doesnt-just-print-container'>
            <h3 className='doesnt-just-print-title'>
              SurgePrint doesn’t just print. We revolutionize the POD experience for social media influencers, e-commerce businesses, and other entrepreneurs.
            </h3>
            <div className='doesnt-just-print-description'>
              With in-house production complemented by 40 partner custom printing facilities and strategically positioned warehouses across three continents, we redefine speed and maintain the highest quality. This translates into a 4-day turnaround time for any design and shipping within 24 hours for products stored at our warehouses, no matter how busy the season is.
              <br/>
              <br/>
              With SurgePrint, you can sell custom items that span across the following categories:
              <br/>
              <br/>
              <ul>
                <li>Drinkware (mugs, tumblers, water bottles)</li>
                <li>Apparel (clothing for kids and adults)</li>
                <li>Wall art (canvases and photo tiles)</li>
                <li>Home decor (pillows, towels, blankets)</li>
                <li>Accessories (totes, bags, and more)</li>
              </ul>
              <br/>
              <br/>
              To nail your custom design printing, make the most of our built-in Mockup Generator to experiment with shapes and colors, or go with ready-to-use mockups. There are over 3,000 options for all product categories at SurgePrint – take one and bring it to print!
            </div>
          </div>

          <div className='printing-technique-container'>
            <h2 className='printing-technique-title'>
              No printing technique is too complex
            </h2>
            <div className='printing-technique-description'>
                Our global personalized printing network means we can print anything in the way you select. Whether you want your logo to stay visible longer or speed up the process for thousands of items, our capabilities are beyond compare for:
                <div className='printing-techique-description-large'>
                  Screen printing
                  <br/>
                  Direct-to-garment printing
                  <br/>
                  Heat transfer printing
                  <br/>
                  Sublimation printing
                  <br/>
                  Embroidery
                </div>
                We know you want your brand to be synonymous with superior quality. That’s why we’ll run quality inspections for every order and make your designs look their best across various materials.
            </div>
          </div>

          <div className='testemonial-slider-container'>
            <Testemonial
              image='/images/tee-commerce.png'
              title={`“Surge Print has allows us to improve our turnaround times for clients and offer a way larger catalog of On-Demand products”`}
              subtitle={`Collin Lynch | Tee Commerce`}
              onReadStoryClick={()=> history.push('/customer-story-teecommerce')}
            />
          </div>

          <WhyChooseUs />

          <SignupLogin />

          {isHowItWorksOpen && (
            <HowItWorksDialog
              onClose={() => setIsHowItWorksOpen(false)}
            />
          )}
      </div>
    </Page>
  )
}

export default withRouter(LandingPage);