import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import Loader from 'src/redesign/components/Loader';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const CartSummary = ({ history, totalItems, totalPrice, isLoading }) => {
  const linkDisabled = totalItems === 0;
  return (
    <div className='cart-summary'>
          <div className='main'>
            <div className='header'>
              Order Summary
            </div>
            {isLoading
            ? <Loader width={200} height={200} color={colors.primary}/>
            : (
              <>
                <div className='subtotal'>
                  <div className='subtotal-text'>
                    {`Subtotal (${totalItems})`}
                  </div>
                  <div className='subtotal-text'>
                    {totalPrice?.toFixed(2)}
                  </div>
                </div>
                <div className='summary-total'>
                  <div className='summary-total-text'>
                    Total Product Price
                  </div>
                  <div className='summary-total-price'>
                    {`$${totalPrice?.toFixed(2)}`}
                  </div>
                </div>
              </>
            )
            }
          </div>
          <div className={classNames('checkout-link', {'checkout-link-disabled': linkDisabled})} onClick={() => linkDisabled ? undefined : history.push('/checkout')}>
            {`Proceed to Checkout >`}
          </div>
    </div>
  )
};

export default withRouter(CartSummary);