import { oneOfType, string, node, bool, func, number } from 'prop-types';

export const ButtonProps = {
  text: oneOfType([string, node]),
  onClick: func,
  disabled: bool,
}

export const ButtonFilterProps = {
  text: oneOfType([string, node]),
  count: number,
  onClick: func,
  color: string,
  isActive: bool,
}

export const ButtonAddProps = {
  text: oneOfType([string, node]),
  onClick: func
}