import React from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import Dialog from 'src/redesign/components/Dialog';
import TextInput from 'src/redesign/components/Form/TextInput';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import * as Validators from 'src/redesign/util/validators';

const DraftDialog = ({ text, onSubmit, onClose }) => (
  <Dialog
  text={text}
  showExclamation={false}
  onClose={onClose}
>
  <FinalForm onSubmit={onSubmit} render={({ handleSubmit, valid, submitting }) => (
    <form className='draft-form' onSubmit={handleSubmit}>
      <TextInput
        name='name'
        placeholder='Draft name'
        validate={Validators.required(' ')}
      />
      <SubmitButton text='Save' type='submit' className='submit-button' width={77} disabled={!valid || submitting} loading={submitting}/>
    </form>
  )}
  />
</Dialog>
)

DraftDialog.propTypes = {
  text: string,
  onSubmit: func,
  onClose: func
}

export default DraftDialog;