import React from 'react';
import { withRouter } from 'react-router';
import Breadscrumb from 'src/redesign/components/Breadcrumb';
import Loader from 'src/redesign/components/Loader';
import Page from 'src/redesign/components/Page';
import ProductDescription from 'src/redesign/components/ProductDescription';
import ProductDetails from 'src/redesign/components/ProductDetails';
import ProductDetailsDialog from 'src/redesign/components/ProductDetailsDialog';
import StructuredData from 'src/redesign/components/StructuredData';
import { getProduct } from 'src/services/admin/ProductService';

import './style.scss';
import colors from 'src/scss/_colors.scss';

import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { mapNameToUrl } from 'src/redesign/helpers';

const ProductPage = ({ history, match: { params: { brandId, id } } }) => {
  const [loading, setLoading] = React.useState(false);

  const [parentCategory, setParentCategory] = React.useState({});
  const [subCategory, setSubCategory] = React.useState({});
  const [product, setProduct] = React.useState({});

  const [isMoreDetailsVisible, setIsMoreDetailsVisible] = React.useState(false);
  const [preselectedDetailsTab, setPreselectedDetailsTab] = React.useState(0);

  const { openSnackBar } = useSnackbar();

  const imageUrl = process.env.REACT_APP_baseUrl + product?.product_image?.url;

  React.useEffect(() => {
    loadProducts();
  }, []);
  
  const loadProducts = async () => {
    setLoading(true);
    try {
      const { data } = await getProduct(id);

      setProduct(data);
      setSubCategory(data?.category);
      setParentCategory(data?.category?.parent_category);
    } catch (error) {
      openSnackBar('Failed to load product!', false);
    } finally {
      setLoading(false);
    }
  }

  const productStrucutedData = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    "name": product?.name,
    "description": product?.description,
    "brand": {
      "@type": 'Brand',
      "name": product?.brand?.name
    },
    "offers": {
      "@type": 'Offer',
      "url": window.location.href,
      "priceCurrency": "USD",
      "price": product?.price,
      "availability": 'https://schema.org/InStock'
    },
    "image": [
      imageUrl
    ]
  };

  const onStartSellingClick = () => {
    history.push(`/product-selection/${brandId}`, { selectedBrandId: id });
  }

  return (
    <Page
      seoTitle={product?.seo_title}
      seoDescription={product?.seo_description}
    >
      <div className="product">
        {loading ? (
          <Loader 
            width={300}
            height={400}
            color={colors.primary}
          />
        ) : (
          <>
            <div className="breadscrumb-container">
              <Breadscrumb links={[
                {
                  to: '/product-catalog-website',
                  text: 'Catalog'
                },
                {
                  to: `/product-catalog-website/${parentCategory?.name?.toLowerCase()?.replaceAll(' ','-')}`,
                  text: parentCategory?.name ?? '',
                },
                {
                  to: `/product-catalog-category/${mapNameToUrl(subCategory?.name)}/${subCategory?.id}`,
                  text: subCategory?.name ?? ''
                },
                {
                  to: `/product/${mapNameToUrl(product?.name)}/${subCategory?.id}/${product?.id}`,
                  text: product?.name ?? ''
                }
              ]}/>
            </div>
            <StructuredData
              data={productStrucutedData}
            />
             <ProductDescription
              name={product?.name ?? ''}
              price={`$${Number.parseInt(product?.price)?.toFixed(2)}`}
              description={product?.description}
              url={imageUrl}
              setDetailsVisible={() => {
                setPreselectedDetailsTab(0);
                setIsMoreDetailsVisible(true);
              }}
              onStartSellingClick={onStartSellingClick}
            />
            <div className='product-product-details-container'>
               <ProductDetails
                id={id}
                categoryId={brandId}
                setDetailsVisible={() => {
                  setPreselectedDetailsTab(0);
                  setIsMoreDetailsVisible(true);
                }}
                onStartSellingClick={onStartSellingClick}
              />
            </div>
            {/* <div className="shipping-container">
              <PrimaryButton text="Shipping" onClick={()=>{
                setPreselectedDetailsTab(0);
                setIsMoreDetailsVisible(true);
              }}/>
              <SecondaryButton text="Description" onClick={()=>{
                setPreselectedDetailsTab(1);
                setIsMoreDetailsVisible(true);
              }}/>
            </div>
            <div className="text-container">
              <div className="text-section">
                <div className="title">
                  Which Shipping Methods Are Available?
                </div>
                <div className="description">
                  Standard Shipping <br />
                  Fast Shipping <br />
                  Express Shipping
                </div>
              </div>
              <div className="text-section">
                <div className="title">
                  Do You Ship Internationally?
                </div>
                <div className="description">
                  Nicky's dry jello battering ram apple martini #2 firebomb passion punch #2 mint chocolate chip peachface sexy blue eyed boy shriner cocktail hacksaw citrus coke clara clover leaf cocktail cosmopolitan martini alexi's spicy bloody mary french connection frozen matador skin and bones cracked chandelier douglas.
                </div>
              </div>
              <div className="text-section">
                <div className="title">
                  How Do I Return A Product?
                </div>
                <div className="description">
                  A caramelization dark eu plunger seasonal strong gummi marzipan oat pie brooklyn ennui gluten-free irony master odd thundercats vegan you probably haven't heard of them sea salt halloween pea coats. Affogato black irish java mazagran mug origin pumpkin roast so strong turkish bonbon pastry sesame snaps tart gastropub salvia america bumper stickers michel gondry the sunday new york times organic food. Bar instant lungo sugar trifecta
                </div>
              </div>
            </div> */}
            {isMoreDetailsVisible && (
              <ProductDetailsDialog
                id={id}
                preselectedTab={preselectedDetailsTab}
                onClose={() => setIsMoreDetailsVisible(false)}
              />
            )}
          </>
        )}
      </div>
    </Page>
  )
}

export default withRouter(ProductPage);