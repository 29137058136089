import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function getMediaModifierMockups(data) {
    return await request('/api/media-modifier-mockups', data, HttpMethod.GET);
}
export async function createMockup(data) {
    return await request('/api/media-modifier-mockups', data, HttpMethod.POST);
}
export async function deleteMockup(id) {
    return await request('/api/media-modifier-mockups/'+id, {}, HttpMethod.DELETE);
}

export async function editMockup(id, data) {
    return await request('/api/media-modifier-mockups/'+id+'?_method=PUT', data, HttpMethod.POST);
}

export async function renderScene(data) {
    return await request('/api/render-scene',  data, HttpMethod.POST);
}