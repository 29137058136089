import React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { withRouter } from 'react-router';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Dropdown from 'src/redesign/components/Dropdown';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Loader from 'src/redesign/components/Loader';
import InfoImportedProduct from 'src/redesign/components/InfoImportedProduct';
import Attributes from './components/Attributes';
import BrandDetailsDialog from './components/BrandDetailsDialog';
import Image from 'src/redesign/components/Image';
import { getImageAlt, mapAttributes } from 'src/redesign/helpers';
import { getProductAttributes } from 'src/services/admin/ProductService';
import { checkProductVariationsForAttributes } from 'src/services/admin/ProductVariationService';
import { getBrandsForCategory, getProductsForCategoryAndBrand } from 'src/services/admin/BrandService';
import { getCategoryGalleryImages } from 'src/services/CategoryService';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ProductSelection = ({ history, match, location }) => {
  const categoryId = match.params.id;

  const [isLoading, setIsLoading] = React.useState(false);
  
  const [brandOptions, setBrandOptions] = React.useState([]);
  const [selectedBrandOption, setSelectedBrandOption] = React.useState(undefined);

  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  const [isAttributesLoading, setIsAttributesLoading] = React.useState(false);
  const [attributes, setAttributes] = React.useState([]);
  const [selectedAttributes, setSelectedAttributes] = React.useState([]);

  const [category, setCategory] = React.useState({});
  const [categoryGallery, setCategoryGallery] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  
  const [isStartDesigningInProgress, setIsStartDesigningInProgress] = React.useState(false);

  const importedProduct = location.state?.importedProduct ?? null;
  const selectedBrandId = location.state?.selectedBrandId ?? null;
  const image = process.env.REACT_APP_baseUrl + (selectedProduct ? selectedProduct?.product_image?.url : category?.category_image?.url);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadCategoryGaleryImages();
    loadBrands();
  }, []);

  React.useEffect(() => {
    loadProductsForCategoryAndBrand();
    setSelectedProduct(null);
    setSelectedAttributes([]);
  }, [selectedBrandOption]);

  React.useEffect(() => {
    if(selectedProduct) {
      loadProductAttributes();
      setSelectedAttributes([]);
    }
  }, [selectedProduct]);

  // #region load

  const loadCategoryGaleryImages = async () => {
    setIsLoading(true);
    try {
      const { data } = await getCategoryGalleryImages({id: categoryId});

      setCategoryGallery(data);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load galery images!', false);
    } finally {
      setIsLoading(false);
    }
  } 

  const loadBrands = async () => {
    setIsLoading(true);
    try {
      const { data } = await getBrandsForCategory(categoryId);
      const brands = data.map(x => ({ key: x?.brand?.id, text: x?.brand?.name }));
      const preselectedCategory = data.find(x => x.id === Number.parseInt(selectedBrandId));

      setBrandOptions(brands);
      setSelectedBrandOption(preselectedCategory ? brands.find(x => x.key === preselectedCategory?.brand.id) : brands[0]);
      setCategory(preselectedCategory ? preselectedCategory?.category : data[0]?.category);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load brands', false);
    } finally {
      setIsLoading(false);
    }
  }

  const loadProductsForCategoryAndBrand = async () => {
    try {
      const { data } = await getProductsForCategoryAndBrand(categoryId, selectedBrandOption?.key);
      setProducts(data);
      setSelectedProduct(data[0]);
    } catch(error) {
      openSnackBar('Failed to load products for category and brand', false);
    } finally {

    }
  }

  const loadProductAttributes = async () => {
    setIsAttributesLoading(true);
    try {
      const { data: productAttributes } = await getProductAttributes(selectedProduct?.id);

      const mappedAttributes = mapAttributes(productAttributes);

      setAttributes(mappedAttributes);
    } catch(error) {
      openSnackBar('Failed to load product attributes', false);
    } finally {
      setIsAttributesLoading(false);
    }
  }

  // #endregion load

  const onPreviousStepClick = () => history.push(`/product-catalog-in-app/all`);

  const onStartDesigningClick = async (numberOfVariations) => {
    const isStartDesigningDisabled = !attributes.every(x => x.values.some(v => selectedAttributes.some(a => a === v.id)));
    if(isStartDesigningDisabled) {
      openSnackBar('Please select at least one variation from every Product Option', false);
      return;
    }
    if(numberOfVariations > 99) {
      openSnackBar('Maximum number of variations is 99', false);
      return;
    }
    setIsStartDesigningInProgress(true);
    try {
      const { data } = await checkProductVariationsForAttributes({
        attributes: selectedAttributes,
        productId: selectedProduct?.id,
        templateId: null,
        check: true,
        importedProduct: importedProduct?.id ?? null
      });
      if(data.approved.length === 0) {
        openSnackBar('Currently out of stock!', false);
        setIsStartDesigningInProgress(false);
        return;
      }

      history.push('/product-selection-variation', {
        attributeValues: selectedAttributes,
        product: selectedProduct,
        importedProduct
      })
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to start designing', false);
    } finally {
      setIsStartDesigningInProgress(false);
    }
  }

  return (
    <ApplicationPage selectedTab='product-catalog'>
      <ApplicationPageContent title='' className='product-selection-container'>
        <div className='images-container'>
          <div className='selection-title'>
            {category?.name}
          </div>
          {isLoading ? (
            <Loader width={200} height={200} color={colors.primary}/>
          ) : (
            <Image alt={getImageAlt()} className='selection-image' src={image} />
          )}
        </div>
        <div className='description-container'>
          {importedProduct && (
            <InfoImportedProduct {...importedProduct} />
          )}
          <div className='description-text'>
            Select the options you wish to sell
          </div>
          <div className='description-text'>
            Choose a brand:
          </div>
          <Dropdown
            options={brandOptions}
            selectedOption={selectedBrandOption}
            onSelect={x => x && x?.key !== selectedBrandOption?.key && setSelectedBrandOption(x)}
            className='brand-options-dropdown'
            disabled={isLoading}
          />
          <div className='brands-container'>
            {products.map(x => (
              <div className={classNames('brand-product', {'brand-product-selected': x?.id === selectedProduct?.id})} onClick={() => setSelectedProduct(x)} key={uuid()}>
                <img alt={getImageAlt()} className='product-image' src={process.env.REACT_APP_baseUrl + x?.product_image?.url} />
                <div className='product-title'>
                  {x.name}
                </div>
                <div className='view-product-info' onClick={() => setSelectedCategory(x?.category)}>
                  View Product Info
                </div>
              </div>
            ))}
          </div>
          <Attributes isLoading={isAttributesLoading} attributes={attributes} selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes} onPreviousStepClick={onPreviousStepClick} 
          onStartDesigningClick={onStartDesigningClick}
          isStartDesigningInProgress={isStartDesigningInProgress}/>
          {selectedCategory && (
            <BrandDetailsDialog
              onClose={() => setSelectedCategory(undefined)}
              product={selectedProduct}
            />
          )}
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(ProductSelection);