import HttpMethod from '../constants/HttpMethod';
import { request } from './HTTP';
import history from '../history';
import {isUserOneOfRoles} from "../util/UserUtil";
import {getCurrentUserAction} from "../services/UserService";
import UserType from "../constants/UserType";

/** OAUTH **/

export async function login(username, password) {

    clearUserData();

    let data = {
        client_id: process.env.REACT_APP_clientId,
        client_secret: process.env.REACT_APP_clientSecret,
        grant_type: 'password',
        username: username,
        password: password
    };

    return await request('/oauth/v2/token', data, HttpMethod.GET).then((response) => {

            if(!response.ok) {
                return response;
            }

            setTokenToLocalStorage(response.data.access_token, response.data.refresh_token);

            return request('/api/user/current').then((response) => {

                if (!response.ok) {
                    return response;
                }

                if (response.data.user) {
                    const image = response.data.image ? process.env.REACT_APP_baseUrl + response.data.image : '';
                    setUserToLocalStorage({...response.data.user, image });
                } else {
                    clearUserData();
                    response.ok = false;
                }

                return response;
            });
        }
    );
}

export async function unlock(username, password) {

    clearUserDataLock();

    let data = {
        client_id: process.env.REACT_APP_clientId,
        client_secret: process.env.REACT_APP_clientSecret,
        grant_type: 'password',
        username: username,
        password: password
    };

    return await request('/oauth/v2/token', data, HttpMethod.GET).then((response) => {

            if(!response.ok) {
                return response;
            }

            setTokenToLocalStorage(response.data.access_token, response.data.refresh_token);

            return response;
        }
    );
}

export async function socialLogin(provider, email, firstName, lastName, socialId, accessToken, expiresAt) {

    let data = {
        provider: provider,
        email: email,
        firstName: firstName ? firstName : '',
        lastName: lastName ? lastName : '',
        socialId: socialId,
        accessToken: accessToken,
        expiresAt: expiresAt
    };

    return await request('/social/authenticate', data, HttpMethod.POST).then((response) => {

        if(!response.ok) {
            return;
        }

        setSocialTokenToLocalStorage(response.data.access_token);

        return request('/user/current').then((response) => {

            if (response.ok) {

                if(isUserOneOfRoles(response.data, process.env.rolesAllowed)) {
                    const image = response.data.image ? process.env.REACT_APP_baseUrl + response.data.image : '';
                    setUserToLocalStorage({...response.data.user, image });
                } else {
                    clearUserData();
                    response.ok = false;
                }
            }

            return response;
        });
    });
}

export async function refreshToken(refreshToken) {

    let data = {
        client_id: process.env.REACT_APP_clientId,
        client_secret: process.env.REACT_APP_clientSecret,
        grant_type: 'refresh_token',
        refresh_token: refreshToken
    };

    return await request('/oauth/v2/token', data, HttpMethod.GET).then((response) => {

            if(response.data && response.data.access_token && response.data.refresh_token) {
                setTokenToLocalStorage(response.data.access_token, response.data.refresh_token);
            }

            return true;
        }
    );
}

export function logout() {
    clearUserData();
    history.push("/");
}

export function lock() {
    clearUserDataLock();
    history.push("/");
}

/** LOCAL STORAGE  **/

export function setUserToLocalStorage(user) {
    localStorage.setItem('user', JSON.stringify(user));

    let stores = {
        etsy: true,
        shopify: true
    };

    if (user.user_type !== UserType.ADMIN) {
        stores = {
            etsy: user.customer?.user_customer_etsy?.store_name != null,
            shopify: user.customer?.shopify_access_token != null
        };
    }

    localStorage.setItem('stores', JSON.stringify(stores));
}

export  async function refreshLocalStorage() {
    getCurrentUserAction().then(response => {
        if (!response.ok){
            return;
        }
        
        const image = response.data.image ? process.env.REACT_APP_baseUrl + response.data.image : '';
        setUserToLocalStorage({...response.data.user, image });
    })
}

export function getUserFromLocalStorage() {

    let user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
}

export function getActiveStores() {
    let stores = localStorage.getItem('stores');
    return stores ? JSON.parse(stores) : null;
}

export function setImpersonatedUser(user) {
    localStorage.setItem('impersonated', JSON.stringify(user));
}

export function getImpersonatedUser() {
    let user = localStorage.getItem('impersonated');
    return user ? JSON.parse(user) : null;
}

export function removeImpersonatedUser() {
    localStorage.removeItem('impersonated');
}

export function setFromPathLocalStorage(fromPath) {
    localStorage.setItem('fromPath', fromPath);
}

export function getFromPathLocalStorage() {
    return localStorage.getItem('fromPath');
}

export function removeFromPathLocalStorage() {
    localStorage.removeItem('fromPath');
}

export function setDataForRegistrationLocalStorage(dataForRegistration) {
    localStorage.setItem('dataForRegistration', JSON.stringify(dataForRegistration));
}

export function getDataForRegistrationLocalStorage() {
    let dataForRegistration = localStorage.getItem('dataForRegistration');
    return dataForRegistration ? JSON.parse(dataForRegistration) : null;
}

export function clearDataForRegistrationLocalStorage() {

    localStorage.removeItem('dataForRegistration');
}

export function setTokenToLocalStorage(access_token, refresh_token) {
    localStorage.setItem(process.env. REACT_APP_tokenKey, access_token);
    localStorage.setItem(process.env.REACT_APP_refreshTokenKey, refresh_token);
}

export function getRefreshToken() {
    return localStorage.getItem(process.env.REACT_APP_refreshTokenKey);
}

export function getToken() {
    return localStorage.getItem(process.env. REACT_APP_tokenKey);
}

export function setSocialTokenToLocalStorage(access_token) {
    localStorage.setItem(process.env.socialTokenKey, access_token);
}

export function clearUserData() {
    localStorage.removeItem('user');
    localStorage.removeItem('fromPath');
    localStorage.removeItem('dataForRegistration');
    localStorage.removeItem('stores');
    clearUserDataLock();
}

function clearUserDataLock() {
    localStorage.removeItem(process.env. REACT_APP_tokenKey);
    localStorage.removeItem(process.env.REACT_APP_refreshTokenKey);
}

export function isUserLoggedIn() {
    return getUserFromLocalStorage() != null && getToken();
}

export function isUserLocked() {
    return getUserFromLocalStorage() && !getToken();
}
