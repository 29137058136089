import React from 'react';
import { number, string } from 'prop-types';

const IconArrowUp = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 12 8" fill={color} xmlns="http://www.w3.org/2000/svg">
<path d="M-1.39359e-08 6.68387L0.309222 6.98178L5.99458 1.50443L11.6908 7.00269L12 6.70478L6.14105 1.06018C6.05425 0.976555 5.91863 0.976555 5.83183 1.06018L-1.39359e-08 6.68387Z" fill={color}/>
</svg>
);

IconArrowUp.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconArrowUp;