import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { WaveUp, WaveDown } from 'src/redesign/components/Wave/Up';
import { submitContactForm } from 'src/services/UserService';
import PrimaryButton from 'src/redesign/components/Buttons/Primary';
import TextInput from 'src/redesign/components/Form/TextInput';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import * as Validators from 'src/redesign/util/validators';
import { getImageAlt } from 'src/redesign/helpers';
import Image from 'src/redesign/components/Image';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ContactUsForm = () => {
  const { openSnackBar } = useSnackbar();

  const submitContact = async ({ firstName, lastName, email, message }, form) => {
    try {
      await submitContactForm({
        name: firstName + ' ' + lastName,
        email,
        message
      });

      // reset form
      form.reset();
      form.resetFieldState('firstName');
      form.resetFieldState('lastName');
      form.resetFieldState('email');
      form.resetFieldState('message');

      openSnackBar('Thank you for contacting us, we will get back to you as soon as possible.');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to send your question, please try again later', false);
    }
  }

  return (
    <div className='contact-us-form'>
      <h1 className='contact-us-title'>
        Contact Us
      </h1>
      <div className='contact-us-form-main-content'>
        <WaveUp width="100%" height="105" color={colors.primary}/>
        <div className='contact-us-form-content'>
          <div className='contact-us-form-main-content'>
            <div className='contact-us-left-section'>
              <h2 className='contact-us-left-section-title'>
                Have some questions?
              </h2>
              <div className='contact-us-left-section-desc'>
                Send us a message and we'll get back to you as soon as possible. Our average response time is less than 1 hour!
              </div>
            </div>
            <div className='contact-us-right-section'>
              <h3 className='contact-us-right-title'>
                Get in touch
              </h3>
              <div className='contact-us-right-desc'>
                24/7 We will answer your questions and problems
              </div>
              <div className='contact-us-right-icon'>
                <Image alt={getImageAlt()} src='/images/plane.png' className="contact-us-right-image" />
              </div>
              <FinalForm
                onSubmit={submitContact}
                render={({ handleSubmit, valid, submitting, form }) => (
                  <form onSubmit={e => handleSubmit(e, form)}>
                    <TextInput label="First Name" name="firstName" placeholder="Jane" validate={Validators.required(' ')} containerClassName='contact-text-input-container' className='contact-text-input'/>
                    <TextInput label="Last Name" name="lastName" placeholder="Smith" validate={Validators.required(' ')} containerClassName='contact-text-input-container' className='contact-text-input'/>
                    <TextInput label="Email" name="email" placeholder="jane@example.com" validate={Validators.composeValidators(Validators.required(' '), Validators.emailFormatValid(' '))} containerClassName='contact-text-input-container' className='contact-text-input'/>
                    <TextInput label="Message" name="message" placeholder="Type your message here" validate={Validators.required(' ')} type="textarea" containerClassName='contact-text-area-input-container' className='contact-textarea-input'/>

                    <PrimaryButton className='contact-us-submit' text='Send' type="submit" disabled={!valid || submitting} loading={submitting}/>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
        <WaveDown width="100%" height="105" color={colors.primary}/>
      </div>
    </div>
  )
}

export default ContactUsForm;