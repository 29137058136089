import React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import './style.scss';

const NumberContainer = ({ title, description, index }) => {
  const isPair = index % 2 === 0;

  return <div key={uuid()} className={classNames('number-container', {'number-container-pair': isPair})}>
      <div className={classNames('number-container-number', {'number-container-number-pair': isPair})}>
      {index}
    </div>
    <div className={classNames('number-container-title', {'number-container-title-pair': isPair})}>
      {title}
    </div>
    <div className={classNames('number-container-description', {'number-container-description-pair': isPair})}>
      {description}
    </div>
  </div>
}

export default NumberContainer;