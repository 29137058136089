import React from 'react';
import { Field } from 'react-final-form';

import Checkbox from 'src/redesign/components/Checkbox';

const CheckboxInput = ({ name, validate }) => {

  return (
    <Field 
      name={name}
      validate={validate}
      type='checkbox'
    >
      {({ input }) => (
        <Checkbox {...input} />
      )}
    </Field>
  )
}

export default CheckboxInput;