import Page from "../common/Page";
import {withRouter} from "react-router-dom";
import React from 'react'
import {getProductByCategory} from "../services/admin/ProductService";
import AttributeType from "../constants/AttributeType";
import {Button} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {getUserFromLocalStorage} from "../base/OAuth";
import UserType from "../constants/UserType";
import {getCategoryLowHigh} from "../services/CategoryService";


class CategoryPopup extends Page{

    constructor(props) {
        super(props);

        this.state = {
            product : {},
            productAttributes : [],
            attributeNames : [],
            attributeValues : [],
            userType: getUserFromLocalStorage().user_type
        };

        this.getCategoryLowHigh = this.getCategoryLowHigh.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.category.id !== prevProps.category.id){
            this.getProductByCategory();
        }
    }

    componentDidMount() {
        this.getProductByCategory();
        this.getCategoryLowHigh();
    }

    getCategoryLowHigh(){
        getCategoryLowHigh(this.props.category.id).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                min : response.data.min,
                max : response.data.max
            })
        })
    }

    getProductByCategory(){
        getProductByCategory(this.props.category.id).then(response => {
            if (!response.ok || response.data.product === 'undefined' || response.data.productAttributes === 'undefined') {
                this.props.enqueueSnackbar("There are no products for this category", {variant: 'error'});
                return
            }

            let productAttributes = response.data.productAttributes;

            let attributeNames = [];
            let attributeValues = [];

            productAttributes.forEach(item => {
                let name = item.attribute_value.attribute.name;

                if (!attributeNames.includes(name)){
                    attributeNames.push(name);
                    attributeValues.push(
                        {attributeValues : [], type : 0}
                    );
                }
            });

            attributeNames.forEach((item, index) => {

                productAttributes.forEach(itemAttr => {
                    let name = itemAttr.attribute_value.attribute.name;

                    if (item === name){
                        attributeValues[index].attributeValues.push(itemAttr.attribute_value);
                        attributeValues[index].type = itemAttr.attribute_value.attribute.attribute_type;
                    }
                })
            });

            this.setState({
                ...this.state,
                attributeNames : attributeNames,
                attributeValues : attributeValues,
                product : response.data.product
            })
        })
    }

    renderAttributeValues(index){
        let render = [];

        this.state.attributeValues[index].attributeValues.forEach((value, count) => {
            render.push(
                this.state.attributeValues[index].type === AttributeType.STRING
                ?
                <span key={count} className={'attribute-value string'}>
                    {value.name}
                </span>
                :
                <div key={count} className={'attribute-value color'} style={{backgroundColor : value.name}} />
            )
        })

        return render;
    }

    renderAttributes(){
        let render = [];

        this.state.attributeNames.forEach((name, index) => {
            render.push(
                <div key={name} className={'attribute'}>
                    <span className={'attribute-name'}>{name}</span>
                    <div className={'attribute-values'}>
                        {this.renderAttributeValues(index)}
                    </div>
                </div>
            )
        });

        return render;
    }

    render() {
        if (!this.state.product.id){
            return ('');
        }

        return (
            <div className={'popup'}>
                <div className={'category-popup'}>
                    <div className={'top'}>
                        <span className={'title'}>{this.props.category.name}</span>
                        <img className={'x'} src={'/images/x.png'} alt={'x'} onClick={this.props.closePopup} />
                    </div>
                    <div className={'bottom'}>
                        <img src={this.state.product.product_image ? (process.env.REACT_APP_baseUrl + this.state.product.product_image.url) : ''} alt={'Product Image'} />
                        <div className={'info'}>
                            {this.renderAttributes()}
                            <div className={'attribute'}>
                                <span className={'attribute-name'}>Prices</span>
                                <div className={'attribute-values'}>
                                    ${(+this.state.min).toFixed(2)} - ${(+this.state.max).toFixed(2)}
                                </div>
                            </div>
                            <div className={'attribute'}>
                                <span className={'attribute-name'}>Description</span>
                                <div className={'attribute-values'}>
                                    {this.state.product.description}
                                </div>
                            </div>
                            {
                                this.state.product.brand &&
                                <div className={'attribute'}>
                                    <span className={'attribute-name'}>Brand</span>
                                    <div className={'attribute-values'}>
                                        {this.state.product.brand.name}
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            this.state.userType == UserType.CUSTOMER &&
                            <Button variant="contained" className={'select'} onClick={() => {
                                this.props.history.push('/product-selection/' + this.props.category.id) }}>
                                Select Product
                            </Button>
                        }
                    </div>
                </div>
                <div className={'dark'}></div>
            </div>
        );
    }
}

export default withSnackbar(withRouter(CategoryPopup));