import React from 'react';
import classNames from 'classnames';
import { Link as ReactLink } from 'react-router-dom';
import { func, node, number, string } from 'prop-types';

import './style.scss';

const Link = ({ text, children, to, fontSize, lineHeight, className: baseClassName, onMouseEnter, onMouseLeave, disabled }) => {
  const className = classNames('link', baseClassName, {'link-disabled': disabled});
  return <ReactLink onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ fontSize, lineHeight }} className={className} to={to}>{children ? children : text}</ReactLink>
}

Link.propTypes = {
  text: string,
  children: node,
  to: string.isRequired,
  fontSize: number,
  lineHeight: string,
  className: string,
  onMouseEnter: func,
  onMouseLeave: func,
}

export const LinkHeader = props => <Link fontSize={16} lineHeight="18px" {...props}/>;
export const LinkFooter = props => <Link fontSize={24} lineHeight="24px" {...props}/>;

export default Link;