import React from 'react';
import Page from 'src/redesign/components/Page';

import './style.scss';

const WhyUsPage = () => (
  <Page
    seoTitle='Why Us? | Surge Print'
  >
    <div className='why-us-page-container'>
      <div className='why-us-page-content'>
        <h1 className='why-us-page-title'>
          Why Us?
        </h1>
        <div className='why-us-page-description'>
            Print on demand has become an increasingly popular way for businesses and individuals to create and sell custom products online. And now, there's a new player in the game that is quickly becoming a favorite among customers: SurgePrint.
          <br/>
          <br/>
            What sets SurgePrint apart from other print on demand companies is its commitment to providing the greatest user experience with it’s  Done-For-You approach as well as providing attractive pricing, great mock up generator, free migration service, dedicated support, fast production time, and even warehouse storage options. Let's take a closer look at these benefits and see why SurgePrint is a top choice for anyone looking for a print on demand partner.
          <br/>
          <br/>
            First and foremost, SurgePrint is unique Print on Demand platform because all customers receive red carpet support. Our Sales  Executives are industry experts and can provide insights regarding trending products and help you with marketing campaigns. On another hand, our support team will create products for you or sync your existing store products to SurgePrint.
          <br/>
          <br/>
            offers some of the best prices in the industry, making it a cost-effective solution for businesses of all sizes. With competitive pricing, businesses can increase their profit margins while still offering their customers high-quality products.
          <br/>
          <br/>
            Another great feature is the SurgePrint mock up generator. This tool allows businesses to see what their products will look like before they are printed, ensuring that they get exactly what they want. This can save time and money in the long run by avoiding costly mistakes and revisions.
          <br/>
          <br/>
            In addition, SurgePrint offers a free migration service to make it easy for businesses to switch from their current print on demand provider. This service ensures a smooth transition with no downtime or loss of sales.
          <br/>
          <br/>
            Customer support is also a top priority for SurgePrint. The dedicated support team is available to answer any questions or concerns that customers may have, ensuring that they have a positive experience from start to finish.
          <br/>
          <br/>
            One of the most impressive features of SurgePrint is its fast production time. With a production time of only 1-2 business days, customers can receive their products quickly and efficiently, allowing them to get their products to market faster.
          <br/>
          <br/>
            For businesses that need a place to store their garments, SurgePrint offers warehouse storage options. This means that customers can store their products at the SurgePrint warehouse and have them printed or decorated once an order is received. This can save time and money by avoiding the need to store and manage inventory.
          <br/>
          <br/>
            Finally, SurgePrint offers great discounts for bulk orders or enterprise clients, making it a great choice for businesses of all sizes.
          <br/>
          <br/>
            In conclusion, SurgePrint is a new and innovative print on demand company that is quickly becoming a top choice for businesses and individuals alike. With its commitment to providing the best prices, great mock up generator, free migration service, dedicated support, fast production time, warehouse storage options, and discounts for bulk orders, SurgePrint is a must-try for anyone looking for a reliable print on demand partner.
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </Page>
);

export default WhyUsPage;