import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function createOrder(data) {
    return await request('/api/order/create_order', data, HttpMethod.POST);
}

export async function checkoutOrder(data) {
    return await request('/api/order/checkout_order', data, HttpMethod.POST);
}

export async function getOrders(data) {
    return await request('/api/orders', data, HttpMethod.GET);
}

export async function getOrdersDeprecated(data) {
    return await request('/api/order/get_orders', data, HttpMethod.GET);
}
export async function chargeOrderDeclined(data) {
    return await request('/api/order/charge_order_declined', data, HttpMethod.GET);
}

export async function getOrderById(orderId) {
    return await request('/api/order/' + orderId, [], HttpMethod.GET);
}

export async function changeOrderStatus(data) {
    return await request('/api/order/change_status', data, HttpMethod.POST);
}

export async function changeOrderItemStatus(data) {
    return await request('/api/order/change_order_item_status', data, HttpMethod.POST);
}

export async function updateShopifyStatus(data) {
    return await request('/api/order/update_shopify_status', data, HttpMethod.POST);
}

export async function setTrackingLinkToOrderItem(data) {
    return await request('/api/order/set_tracking_link_to_order_item', data, HttpMethod.POST);
}

export async function getOrderStatus(data) {
    return await request('/api/order/get_order_status', data, HttpMethod.GET);
}

export async function changeOrderItemSKU(data) {
    return await request('/api/order/change_order_item_sku', data, HttpMethod.PUT);
}

export async function changeOrderItemQuantity(data) {
    return await request('/api/order/change_order_item_quantity', data, HttpMethod.PUT);
}

export async function setTrackingLinkToOrder(data) {
    return await request('/api/order/set_tracking_link_to_order', data, HttpMethod.POST);
}

export async function createDraftFromOrder(data) {
    return await request('/api/order/create_draft_from_order', data, HttpMethod.POST);
}

export async function changeShippingInfo(data) {
    return await request('/api/order/change_shipping_info', data, HttpMethod.POST);
}

export async function changeShippingMethod(data) {
    return await request('/api/order/change_shipping_method', data, HttpMethod.PUT);
}

export async function addNewItemsToOrder(data) {
    return await request('/api/order/add_new_items_to_order', data, HttpMethod.POST);
}

export async function deleteItemFromOrder(data) {
    return await request('/api/order/delete_item_from_order', data, HttpMethod.PUT)
}

export async function saveVendor(data) {
    return await request('/api/order/save_vendor', data, HttpMethod.PUT)
}

export async function getNumberOfOrders(data) {
    return await request('/api/order/get_number_of_orders', data, HttpMethod.GET)
}

export async function advancedSearch(data) {
    return await request('/api/order/advanced_search', data, HttpMethod.POST)
}
