
export const centerAlign = (width, height, zoneWidth, zoneHeight, rotation, multiplier) => {
  switch(rotation) {
    case 0:
      return {
        positionX: zoneWidth/2 - (width/2)*multiplier,
        positionY: zoneHeight/2 - (height/2)*multiplier
      }
    case 90:
      return {
        positionX: zoneWidth/2 + (height/2)*multiplier,
        positionY: zoneHeight/2 - (width/2)*multiplier
      }
    case 180:
      return {
        positionX: zoneWidth/2 + (width/2)*multiplier,
        positionY: zoneHeight/2 + (height/2)*multiplier
      }
    case 270:
      return {
        positionX: zoneWidth/2 - (height/2)*multiplier,
        positionY: zoneHeight/2 + (width/2)*multiplier
      }
  }
}

export const topAlign = (width, height, rotation, multiplier) => {
  switch(rotation) {
    case 0:
      return 0;
    case 90:
      return 0
    case 180:
      return height*multiplier;
    case 270:
      return width*multiplier;
  }
}

export const bottomAlign = (width, height, zoneHeight, rotation, multiplier) => {
  switch(rotation) {
    case 0:
      return zoneHeight - height*multiplier;
    case 90:
      return zoneHeight - width*multiplier;
    case 180:
      return zoneHeight;
    case 270:
      return zoneHeight;
  }
}

export const rightAlign = (width, height, zoneWidth, rotation, multiplier) => {
  switch(rotation) {
    case 0:
      return zoneWidth - width*multiplier;
    case 90:
      return zoneWidth;
    case 180:
      return zoneWidth;
    case 270:
      return zoneWidth - height*multiplier;
  }
}

export const leftAlign = (width, height, rotation, multiplier) => {
  switch(rotation) {
    case 0:
      return 0;
    case 90:
      return height*multiplier;
    case 180:
      return width*multiplier;
    case 270:
      return 0;
  }
}

export const verticalAlign = (width, height, zoneWidth, rotation, multiplier) => {
  switch(rotation) {
    case 0:
      return zoneWidth/2 - (width/2)*multiplier;
    case 90:
      return zoneWidth/2 + (height/2)*multiplier;
    case 180:
      return zoneWidth/2 + (width/2)*multiplier;
    case 270:
      return zoneWidth/2 - (height/2)*multiplier;
    }
}

export const horizontalAlign = (width, height, zoneHeight, rotation, multiplier) => {
  switch(rotation) {
    case 0:
      return zoneHeight/2 - (height/2)*multiplier;
    case 90:
      return zoneHeight/2 - (width/2)*multiplier;
    case 180:
      return zoneHeight/2 + (height/2)*multiplier;
    case 270:
      return zoneHeight/2 + (width/2)*multiplier;
  }
}

export const crop = (cropRef, imageRef, xIsY, rotation) => {
  const cropX = cropRef.attrs.x;
  const cropY = cropRef.attrs.y;

  const imageX = imageRef.attrs.y;
  const imageY = imageRef.attrs.y;
  const imageCropX = imageRef.attrs.cropX ?? 0;
  const imageCropY = imageRef.attrs.cropY ?? 0;

  switch(rotation) {
    case 0:
      return {
        positionX: Math.abs(cropX - imageX + (xIsY ? imageCropY : imageCropX)),
        positionY: Math.abs(cropY - imageY + (xIsY ? imageCropX : imageCropY))
      };
    case 90:
      return {
        positionY: Math.abs(cropX - imageX + (xIsY ? imageCropY : imageCropX)),
        positionX: Math.abs(cropY - imageY + (xIsY ? imageCropX : imageCropY))
      };
    case 180:
      return {
        positionX: Math.abs(imageX - cropX + (xIsY ? imageCropY : imageCropX)),
        positionY: Math.abs(imageY - cropY + (xIsY ? imageCropX : imageCropY))
      };
    case 270:
      return {
        positionY: Math.abs(imageX - cropX + (xIsY ? imageCropY : imageCropX)),
        positionX: Math.abs(imageY - cropY + (xIsY ? imageCropX : imageCropY))
      };
  }
}

export const realCrop = (cropRef, imageRef, xIsY, rotation, width, height) => {
  const imageAttrs = imageRef.attrs;
  const originalX = imageAttrs.x;
  const originalY = imageAttrs.y;
  const originalWidth = imageAttrs.width;
  const originalHeight = imageAttrs.height;

  const scaleW = width/originalWidth;
  const scaleH = height/originalHeight;

  const cropAttrs = cropRef.attrs;
  const cropX = cropAttrs.x;
  const cropY = cropAttrs.y;
  const cropWidth = cropAttrs.width * (cropAttrs.scaleX ?? 1);
  const cropHeight = cropAttrs.height * (cropAttrs.scaleY ?? 1);

  const originalCropW = (xIsY ? imageAttrs.cropY : imageAttrs.cropX) ?? 0;
  const originalCropH = (xIsY ? imageAttrs.cropX : imageAttrs.cropY) ?? 0;

  switch(rotation) {
    case 0:
      return {
        positionX: (cropX - originalX + originalCropW) * scaleW,
        positionY: (cropY - originalY + originalCropH) * scaleH
      }
    case 90:
      return {
        positionX: (cropY - originalY + originalCropH) * scaleH,
        positionY: (cropX - originalX + originalCropW) * scaleW
      }
    case 180:
      return {
        positionX: (originalX - cropX + originalCropW) * scaleW,
        positionY: (originalY - cropY + originalCropH) * scaleH
      }
    case 270:
      return {
        positionX: (originalY - cropY + originalCropH) * scaleH,
        positionY: (originalX - cropX + originalCropW) * scaleW
      }
  }
}

export const canvasToFile = (canvas) => {
  const dataURL = canvas.toDataURL({
    mimeType: "image/png",
    quality: 1,
    pixelRatio: 1
  });

  if(!dataURL) return '';

  const arr = dataURL?.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
      
  return new File([u8arr], filename, {type:'image/png'});
}