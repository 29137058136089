import React from 'react';
import ReactDOM from 'react-dom';

import IconExit from 'src/redesign/components/Icon/Exit';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const FullScreenImageDialog = ({ image, onClose }) => (
  ReactDOM.createPortal(<div className='fullscreen-image-dialog'>
    <div className='fullscreen-image-dialog-content'>

      <div className='fullscreen-image-dialog-icon-close' onClick={onClose}>
        <IconExit width={13.5} height={13.5} color={colors.primary}/>
      </div>

      <Image alt={getImageAlt()} className='full-screen-image-dialog-image' src={image} />
    </div>
  </div>, document.getElementById('dialog-root'))
);

export default FullScreenImageDialog;