import { func, number, string } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import IconChevronLeft from 'src/redesign/components/Icon/Chevron/Left';
import IconChevronRight from 'src/redesign/components/Icon/Chevron/Right';
import { v4 as uuid } from 'uuid';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const Pagination = ({ page, setPage, totalPages, className }) => {

  function renderPages() {
    let start, end;

    if(page < 4) {
        start = 1;
        end = totalPages < 5 ? totalPages : 5;
    }
    else {
        start = page - 2;
        end = page + 2 > totalPages ? totalPages : page + 2;
    }

    return (
      Array.from({ length: (end - start + 1) }).map((_page, i) => {
        const pageNumber = i + start;

        return(
          <div key={uuid()} className={classNames("page", {'page-active': (pageNumber) === page})} onClick={() => setPage(pageNumber)}>
            {pageNumber}
          </div>
        )
      })
    )
  }

  if((!page && !totalPages) || totalPages <= 1) return <></>;

  return (
    <div className={classNames("pagination", className)}>
        <div className="container">
          {page !== 1 && <div className="arrow" onClick={() => setPage(1)}>
            <IconChevronLeft  width={16} height={14} color={colors.primary}/>
          </div>
          }
          <div className="pages">
            {renderPages()}
          </div>
          {page !== totalPages && <div className="arrow" onClick={() => setPage(totalPages)}>
            <IconChevronRight width={16} height={14} color={colors.primary}/>
            </div>}
        </div>
    </div>
  )
}

Pagination.propTypes = {
  page: number,
  setPage: func,
  totalPages: number,
  className: string
}

export default Pagination;