import { DEFAULT_VARIATION_PRICE } from '../constants';

export const mapStorage = (name, description, image, variations = []) => {
  return { name, description, image, variations: variations.map(x => x.earned ? x : { ...x, earned: DEFAULT_VARIATION_PRICE }), earned: DEFAULT_VARIATION_PRICE };
};

export const mapShopify = (shopify, name, description, image, variations, attributes = []) => {
  return shopify ? {
    ...shopify,
    options: shopify?.options?.map(x => ({
      id: x?.attribute?.id,
      name: x?.attribute?.name
    })),
    variations: shopify?.customerProductVariationShopify.map(element => ({
      id: element?.id,
      productVariation: {
          id: element?.customerProductVariation?.productVariation?.id,
          name: element?.customerProductVariation?.productVariation?.name
      },
      price: element?.price,
      earned: element?.earned,
      image: element?.image
    })) ?? mapVariations(variations),
    tags: shopify?.tags ?? [],
    collection: null,
  }
  :
  {
    collection: null,
    id: null,
    name,
    description,
    tags: [],
    options: attributes.slice(0, 3),
    variations: mapVariations(variations),
    // image,
    earned: DEFAULT_VARIATION_PRICE,
    isDraft: false,
    isPublished: false,
    isImported: false,
  };
}

export const mapEtsy = (etsy, name, description, image, variations, attributes = []) => {
  return etsy ? {
    ...etsy,
    options: etsy?.options?.map(x => ({
      id: x?.attribute?.id,
      name: x?.attribute?.name
    })),
    variations: etsy?.customerProductVariationEtsy?.map(element => ({
      id: element?.id,
      productVariation: {
          id: element?.customerProductVariation?.productVariation?.id,
          name: element?.customerProductVariation?.productVariation?.name
      },
      price: element?.price,
      earned: element?.earned,
      image: element?.image
    })) ?? mapVariations(variations),
    tags: etsy?.tags ?? []
  }
  :
  {
    id: null,
    name,
    description,
    tags: [],
    options: attributes.slice(0, 2),
    variations: mapVariations(variations),
    earned: DEFAULT_VARIATION_PRICE,
    isDraft: false,
    isPublished: false,
    isImported: false,
    image,
  };
}

export const findMinPrice = variations => Math.min(...variations.map(x => x.price));
export const findMaxPrice = variations => Math.max(...variations.map(x => x.price));

export const mapTags = tags => tags.map(x => x.name);
export const mapVariations = variations => variations.map(x => ({ ...x, earned: DEFAULT_VARIATION_PRICE }));