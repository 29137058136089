import React from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import {withSnackbar} from "notistack";
import Summary from "../components/order/Summary";
import Item from "../components/order/Item";
import Status from "../components/order/Status";
import Billing from "../components/order/Billing";
import Shipment from "../components/order/Shipment";
import PrintReady from "../components/order/PrintReady";
import OrderDetailPagefrom from "../constants/OrderDetailPage";
import History from "../components/order/History";
import {createDraftFromOrder, getOrderById} from "../services/OrderService";
import UserType from '../constants/UserType';
import {getUserFromLocalStorage} from "../base/OAuth";
import Helmet from 'react-helmet';

class OrderDetails extends Page {

    constructor(props) {
        super(props);

        this.state = {
            orderId : props.match.params.id,
            currentPage: OrderDetailPagefrom.SUMMARY,
            draftPopup: false,
            data: {
                draftName: null
            },
            userType: getUserFromLocalStorage().user_type,
            loaderActive: false
        }

        this.changePage = this.changePage.bind(this);
        this.createDraft = this.createDraft.bind(this);
        this.getOrderById = this.getOrderById.bind(this);
    }

    componentDidMount(){
        this.getOrderById();
    }

    getOrderById() {
        this.setState({
            ...this.state,
            loaderActive: true
        });

        getOrderById(this.state.orderId).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                order: response.data,
                // orderItems: response.data.orderItems,
                customerId: response.data.user.id,
                loaderActive: false,
            })
        });
    }

    changePage(page){
        this.setState({
            ...this.state,
            currentPage: page
        });
    }


    createDraft(){

        createDraftFromOrder({
            orderId: this.state.orderId,
            name: this.state.data.draftName 
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.props.enqueueSnackbar('Successfully created draft!', {variant: 'success'});

            this.setState({
                draftPopup: false,
                data: {
                    draftName: null
                }
            })
        });
    }

    openDraftPopup(){
        this.setState({
            ...this.state,
            draftPopup: true
        })
    }

    closePopup(){
        this.setState({
            ...this.state,
            draftPopup: false
        })
    }

    goToAddNewItems(){
        this.props.history.push('/product-templates/' + this.state.orderId + "/" + this.state.customerId);
    }

    render() {

        if(!this.state.order){
            return(
                <div id="order-details">
                    {
                        this.state.loaderActive &&
                        <img className={"my-div-loader"} src={"/images/loadersp.gif"}></img>
                    }
                </div>
            );
        }

        return (
            <div id="order-details">
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                {
                    this.state.draftPopup && 
                    <div id="category-overlay" className='overlay' onClick={ () => this.closePopup() }></div>
                }
                {
                    this.state.draftPopup && 
                    <div className="draft-name">
                        <TextField 
                            //className={this.state.attributeId ? '' : 'disabled'}
                            value={this.state.data.draftName}
                            name={"draftName"}
                            onChange={this.changeData}
                            //id="standard-basic"
                            placeholder={"Draft name"}
                            variant={'outlined'}
                        />
                        <Button onClick={ () => this.createDraft()}>Save</Button>
                    </div>
                }
                {
                    !this.state.loaderActive &&
                        <div>
                            <div className="order-id">
                                ORDER: {this.state.order.id}
                                {
                                    this.state.userType == UserType.CUSTOMER &&
                                    <Button className="make-draft"
                                            onClick={() => this.openDraftPopup()}>Make a draft</Button>
                                }
                            </div>

                            {
                                this.state.order.status == 0 &&  //only if NewOrder to do enum order status
                                <div className="order-id">
                                <Button className="make-draft"
                                style={{marginTop: "15px"}} onClick={() => this.goToAddNewItems()}>Add new items</Button>
                                </div>
                            }


                            <div className="header">
                            <div className={this.state.currentPage == OrderDetailPagefrom.SUMMARY ? "header-item active" : "header-item"} onClick={() => this.changePage(OrderDetailPagefrom.SUMMARY)}>Summary</div>
                            <div className={this.state.currentPage == OrderDetailPagefrom.ITEM ? "header-item active" : "header-item"} onClick={() => this.changePage(OrderDetailPagefrom.ITEM)}>Items</div>
                            <div className={this.state.currentPage == OrderDetailPagefrom.STATUS ? "header-item active" : "header-item"} onClick={() => this.changePage(OrderDetailPagefrom.STATUS)}>Status</div>
                            {/*<div className={this.state.currentPage == OrderDetailPagefrom.BILLING ? "header-item active" : "header-item"} onClick={() => this.changePage(OrderDetailPagefrom.BILLING)}>Billing</div>*/}
                            <div className={this.state.currentPage == OrderDetailPagefrom.SHIPMENT ? "header-item active" : "header-item"} onClick={() => this.changePage(OrderDetailPagefrom.SHIPMENT)}>Shipment</div>
                            {
                                this.state.userType == UserType.ADMIN &&
                                <div
                                    className={this.state.currentPage == OrderDetailPagefrom.HISTORY ? "header-item active" : "header-item"}
                                    onClick={() => this.changePage(OrderDetailPagefrom.HISTORY)}>History</div>
                            }
                            {
                                this.state.userType == UserType.ADMIN &&
                                <div
                                    className={this.state.currentPage == OrderDetailPagefrom.PRINT_READY ? "header-item active" : "header-item"}
                                    onClick={() => this.changePage(OrderDetailPagefrom.PRINT_READY)}>Print-Ready</div>
                            }
                            </div>

                            {
                                this.state.currentPage == OrderDetailPagefrom.SUMMARY &&
                                <Summary order={this.state.order} />
                            }
                            {
                                this.state.currentPage == OrderDetailPagefrom.ITEM &&
                                <Item isAdmin={this.state.userType == UserType.ADMIN} order={this.state.order} />
                            }
                            {
                                this.state.currentPage == OrderDetailPagefrom.STATUS &&
                                <Status order={this.state.order} />
                            }
                            {/*{*/}
                            {/*    this.state.currentPage == OrderDetailPagefrom.BILLING &&*/}
                            {/*    <Billing order={this.state.order} />*/}
                            {/*}*/}
                            {
                                this.state.currentPage == OrderDetailPagefrom.SHIPMENT &&
                                <Shipment order={this.state.order} refreshOrder={this.getOrderById} data={this.state.data}/>
                            }
                            {
                                (this.state.currentPage == OrderDetailPagefrom.HISTORY && this.state.userType == UserType.ADMIN) &&
                                <History order={this.state.order}/>
                            }
                            {
                                (this.state.currentPage == OrderDetailPagefrom.PRINT_READY && this.state.userType == UserType.ADMIN) &&
                                <PrintReady order={this.state.order} />
                            }
                        </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails)));
