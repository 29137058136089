import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import ImageAnimated from 'src/redesign/components/ImageAnimated';
import Link from 'src/redesign/components/Link';

import './style.scss';

const ProductItem = ({ name, price, brandName, url, to = '/product-catalog', disableLink = false }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  // props
  const linkProps = {
    lineHeight: "16px",
    fontSize: 16,
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
  }

  return (
    <Link {...linkProps} className="product-item" text='View product' to={to} disabled={disableLink}>
        <div>
          <ImageAnimated className={classNames('image',{'image-hovered': isHovered })} src={url} />
        </div>
        <div className='product-item-text-container'>
          <div className="product-item-text">
            {name}
            {brandName && <div className='product-item-brand'>
              {brandName}
            </div>}
          </div>
          <div className='product-item-price'>
            {price}
          </div>
        </div>
        {!disableLink && (
          <div className="view-product">
            View product
          </div>
        )}
    </Link>
  )
}


ProductItem.propTypes = {
  name: string.isRequired,
  price: string.isRequired,
  url: string.isRequired
};

export default ProductItem;


