
export const getPlatforms = (customer) => {
  const { 
    shopify,
    etsy,
    woo_commerce,
    magento,
    amazon,
    ebay,
    big_commerce,
    wayfair,
    wix,
    squarespace,
    physical_retail,
    other
  } = customer;
  var platforms = [];

  if(shopify) {
    platforms.push('Shopify');
  }
  if(etsy) {
    platforms.push('Etsy');
  }
  if(woo_commerce) {
    platforms.push('WooCommerce');
  }
  if(magento) {
    platforms.push('Magento');
  }
  if(amazon) {
    platforms.push('Amazon');
  }
  if(ebay) {
    platforms.push('eBay');
  }
  if(big_commerce) {
    platforms.push('BigCommerce');
  }
  if(wayfair) {
    platforms.push('Wayfair');
  }
  if(wix) {
    platforms.push('Wix');
  }
  if(squarespace) {
    platforms.push('Squarespace');
  }
  if(physical_retail) {
    platforms.push('Physical Retail');
  }
  if(other) {
    platforms.push('Other');
  }

  return platforms.join('/');
}