import React from 'react';
import { number, string } from 'prop-types';

const IconCatalog = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4794 5.13123H0.414917C0.276668 5.13123 0.13842 5.0643 0.0692951 4.95276C0.000170709 4.84121 -0.0228708 4.70735 0.0462537 4.57349L2.0739 0.223097C2.14303 0.0892388 2.28128 0 2.44257 0H11.8435C12.0048 0 12.1661 0.111549 12.2352 0.267717L13.8711 4.5958C13.8942 4.64042 13.8942 4.70735 13.8942 4.75197C13.8942 4.95276 13.7098 5.13123 13.4794 5.13123ZM1.03704 4.35039H12.9034L11.567 0.78084H2.71906L1.03704 4.35039Z" fill={color}/>
    <path d="M6.9355 5.13123C6.70508 5.13123 6.52075 4.95276 6.52075 4.72966V0.401575C6.52075 0.178478 6.70508 0 6.9355 0C7.16591 0 7.35025 0.178478 7.35025 0.401575V4.75197C7.35025 4.95276 7.16591 5.13123 6.9355 5.13123Z" fill={color}/>
    <path d="M13.4793 17H0.414747C0.184332 17 0 16.8215 0 16.5984V4.75198C0 4.52888 0.184332 4.3504 0.414747 4.3504H13.4793C13.7097 4.3504 13.894 4.52888 13.894 4.75198V6.4029C13.894 6.60368 13.7558 6.75985 13.5484 6.80447C11.1751 7.16143 9.40092 9.1693 9.40092 11.4895C9.40092 13.8097 11.1751 15.8176 13.5484 16.1746C13.7558 16.1969 13.894 16.3753 13.894 16.5761V16.6431C13.894 16.8215 13.7097 17 13.4793 17ZM0.806452 16.2192H11.3364C10.8295 15.9291 10.3456 15.5499 9.95392 15.1037C9.0553 14.0997 8.57143 12.8058 8.57143 11.4895C8.57143 10.1509 9.0553 8.87927 9.95392 7.87534C10.7604 6.96064 11.8664 6.33597 13.0645 6.09056V5.13124H0.806452V16.2192Z" fill={color}/>
    <path d="M14.2857 17C11.129 17 8.57141 14.5236 8.57141 11.4672C8.57141 8.41077 11.129 5.93439 14.2857 5.93439C17.4424 5.93439 20 8.41077 20 11.4672C20 14.5236 17.4424 17 14.2857 17ZM14.2857 6.71523C11.5898 6.71523 9.37786 8.83465 9.37786 11.4672C9.37786 14.0774 11.5668 16.2192 14.2857 16.2192C16.9815 16.2192 19.1935 14.0997 19.1935 11.4672C19.1935 8.85696 17.0046 6.71523 14.2857 6.71523Z" fill={color}/>
    <path d="M16.4286 14.6575C16.3364 14.6575 16.2673 14.6351 16.1982 14.5905L14.3088 13.2519L12.4194 14.5905C12.2811 14.7021 12.0738 14.7021 11.9355 14.5905C11.7973 14.479 11.7281 14.3005 11.7973 14.1443L12.5116 11.9803L10.6222 10.6417C10.4839 10.5302 10.4148 10.3517 10.4839 10.1955C10.53 10.0393 10.6913 9.92779 10.8756 9.92779H13.2258L13.9401 7.76375C13.9862 7.60758 14.1475 7.49603 14.3318 7.49603C14.5162 7.49603 14.6544 7.60758 14.7235 7.76375L15.4378 9.92779H17.742C17.9263 9.92779 18.0645 10.0393 18.1337 10.1955C18.1798 10.3517 18.1337 10.5302 17.9954 10.6417L16.106 11.9803L16.8203 14.1443C16.8664 14.3005 16.8203 14.479 16.6821 14.5905C16.5899 14.6351 16.5208 14.6575 16.4286 14.6575ZM14.2857 12.3819C14.3779 12.3819 14.447 12.4042 14.5162 12.4488L15.6452 13.2296L15.2074 11.958C15.1613 11.8018 15.2074 11.6233 15.3457 11.5118L16.4747 10.7309H15.0922C14.9079 10.7309 14.7696 10.6194 14.7005 10.4632L14.2857 9.19157L13.848 10.4632C13.8019 10.6194 13.6406 10.7309 13.4563 10.7309H12.0738L13.2028 11.5118C13.341 11.6233 13.4102 11.8018 13.341 11.958L12.9033 13.2296L14.0323 12.4488C14.1245 12.4042 14.2166 12.3819 14.2857 12.3819Z" fill="#00F2E2"/>
  </svg>
);

IconCatalog.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired
}

export default IconCatalog;