import React from 'react';
import classNames from 'classnames';
import { func, string } from 'prop-types';

import { TABS } from '../EditVariation';

import './style.scss';

const EditVariationTabs = ({ selectedTab, setSelectedTab }) => {

  return (
    <div className='edit-variation-tab-buttons'>
      {Object.entries(TABS).map(x => (
        <div className={classNames(
          'edit-variation-tab-button',
          {'edit-variation-tab-button-selected': selectedTab == x[1]}
          )}
          onClick={() => setSelectedTab(x[1])}>
            {x[1]}
        </div>
      ))}
    </div>
  )
}

EditVariationTabs.propTypes = {
  selectedTab: string.isRequired,
  setSelectedTab: func.isRequired
}

export default EditVariationTabs;