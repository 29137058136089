import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { getNumberOfOrders, getOrders } from 'src/services/OrderService';
import { dateToString } from 'src/util/DateUtil';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Table from 'src/redesign/components/Table';
import IconVisibility from 'src/redesign/components/Icon/Visibility';
import IconCopyLink from 'src/redesign/components/Icon/CopyLink';
import Status from 'src/redesign/components/Status';
import CustomTooltip from 'src/redesign/components/CustomTooltip';
import { copyToClipboard } from 'src/redesign/helpers/form';
import OrderFilters from './components/filters';
import Loader from 'src/redesign/components/Loader';
import Pagination from 'src/redesign/components/Pagination';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getUserFromLocalStorage } from 'src/base/HTTP';
import Helmet from 'react-helmet';

import colors from 'src/scss/_colors.scss';
import { getAllCustomers } from 'src/services/UserService';

const PER_PAGE = 50;

const OrdersPage = ({ history }) => {
  const [loading, setLoading] = React.useState(false);
  const [orders, setOrders] = React.useState([]);

  const user = getUserFromLocalStorage();
  const isAdmin = user?.roles?.includes('SUPER_ADMIN');
  const { openSnackBar } = useSnackbar();

  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState(undefined);

  const [numberOfOrders, setNumberOfOrders] = React.useState({
    all: 0,
    new: 0,
    pending: 0,
    inProduction: 0,
    shipped: 0,
    issues: 0,
    readyForPrint: 0,
    garmentsReceived: 0
  })

  const [orderStatuses, setOrderStatuses] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  // #region load

  async function loadOrders(customerId = undefined) {
    setLoading(true);

    try {
      const customerMaybe = customerId ? { customerId } : {};
      const { data: { result, page, total } } = await getOrders({
        page: currentPage,
        perPage: PER_PAGE,
        status: orderStatuses,
        searchTerm,
        ...customerMaybe
      });
  
      setOrders(result);
      setCurrentPage(Number.parseInt(page));
      setTotalPages(Math.ceil(total / PER_PAGE));
    } catch (error) {
      openSnackBar("Failed to load orders.", false);
    } finally {
      setLoading(false);
    }
    
  }

  async function loadNumberOfOrders(customerId = undefined) {
    try {
      const customerMaybe = customerId ? { customerId } : {};
      const { data: numberOfOrders } = await getNumberOfOrders(customerMaybe);
      setNumberOfOrders({
        ...numberOfOrders,
        garmentsReceived: (numberOfOrders.garmentsReceived ?? 0) + (numberOfOrders.partiallyReceived ?? 0),
        inProduction: (numberOfOrders.inProduction ?? 0) + (numberOfOrders.manuallyInProduction ?? 0)
      });
    } catch (error) {
      openSnackBar("Failed to load number of orders.", false);
    }
  }

  async function loadUsers() {
    try {
      const { data } = await getAllCustomers();

      setCustomerOptions(data.map(x => ({ key: x.id, text: x.displayName })));
    } catch (error) {
      console.error(error);
      openSnackBar("Failed to load users.", false);
    }
  }

  React.useEffect(() => {
    loadNumberOfOrders();
    isAdmin && loadUsers();
  }, []);

  React.useEffect(() => {
    loadOrders();
  }, [orderStatuses, searchTerm, currentPage]);

  //#endregion load

  //#region table

  const columns = [
    {
      Header: 'Order',
      accessor: 'id',
      Cell: ({ row }) => <div style={{ display: 'flex', gap: 14, alignItems: 'center', justifyContent: 'center' }}>
      <Link to={`/order-details/${row?.original?.id}/summary`}>
        <CustomTooltip text="Visit order">
          <IconVisibility width={18.33} height={12.5} color={colors.primary}/>
        </CustomTooltip>
      </Link>
      {row?.original?.id}
      </div>
    },
    {
      Header: 'Partner Order ID',
      accessor: 'externalOrderNumber'
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: ({ row }) => <>{dateToString(row.original.createdAt)}</>
    },
    {
      Header: '#of items',
      accessor: 'quantity'
    },
    {
      Header: 'Partner',
      accessor: '',
      Cell: ({ row }) => 
        <>{row.original?.fullName}</>
    },
    {
      Header: 'Name',
      accessor: 'email',
      Cell: ({ row }) => <>{row.original?.user?.firstName} {row.original?.user?.lastName}</>
    },
    {
      Header: 'Payment',
      accessor: 'totalPrice',
      Cell: ({ row }) => <>USD <span style={{ color: colors.green }}>{row?.original?.totalPrice?.toFixed(2)}</span></>
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => <div className='status-container'>
            {_.uniq(row?.original?.items.map(x => x?.status)).map(x => <Status key={uuid()} statusNumber={x} />)}
          </div>
    },
    {
      Header: 'Tracking',
      accessor: 'trackingLink',
      Cell: ({ row }) => row?.original?.trackingLink ? (
      <div style={{ display: 'flex', gap: 14, alignItems: 'center', justifyContent: 'center' }}>
          <div onClick={e => {
            e.stopPropagation();
            copyToClipboard(row?.original?.trackingLink);
          }}>
            <CustomTooltip text="Copy link">
              <IconCopyLink width={13.5} height={13.5} color={colors.primary}/>
            </CustomTooltip>
          </div>
      </div>
        ) : null,
    },
  ]

  //#endregion table
  
  //da dodam advanced pretragu ordera

  return (
    <ApplicationPage selectedTab='orders'>
       <Helmet>
          <meta name="robots" content="noindex, follow" />
       </Helmet>
      <ApplicationPageContent title="Orders">
        <OrderFilters
          isAdmin={isAdmin}
          customerOptions={customerOptions}
          selectedCustomer={selectedCustomer}
          onCustomerSelect={option => {
            const customerId = option.key;
            setSelectedCustomer(option);

            loadNumberOfOrders(customerId);
            loadOrders(customerId);
          }}
          numberOfOrders={numberOfOrders}
          activeFilters={orderStatuses}
          onFilterClick={statuses => {
            setCurrentPage(1);
            setOrderStatuses(statuses);
          }}
          onSearchTermChange={term => setSearchTerm(term)}/>
          <div>
            {loading 
            ? <Loader width={200} height={200}/>
            : (
              <div style={{ overflow: 'auto' }}>
                <Table
                  columns={columns}
                  data={orders}
                  onRowClick={row => history.push(`/order-details/${row?.id}/summary`)}
                />
              </div>
            )}
          </div>
        <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages}/>
      </ApplicationPageContent>
    </ApplicationPage>
  )
};

export default withRouter(OrdersPage);