import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Loader from 'src/redesign/components/Loader';
import ArrowLeft from 'src/redesign/components/Icon/Arrow/ArrowLeft';
import ArrowRight from 'src/redesign/components/Icon/Arrow/ArrowRight';
import Image from 'src/redesign/components/Image';
import { getCategoriesTree, getCategoryByParent } from 'src/services/CategoryService';
import { getImageAlt, mapNameToUrl } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ExploreCollection = ({ title, categoryName, mainProductToFind, otherProductsToFind = [] }) => {
  const [loading, setLoading] = React.useState(false);

  const [categories, setCategories] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    setLoading(true);
    try {
      const { data } = await getCategoriesTree();
      
      const categoryId = data.find(x => x.name == categoryName).id;
      
      const { data: subCategories } = await getCategoryByParent({
        id: categoryId,
        page : 1, 
        perPage : 1000,
      });

      setCategories(subCategories.result);
    } catch (error) {
      console.error(error);
      openSnackBar('Failed to load categories', false);
    } finally {
      setLoading(false);
    }
  }

  const mainProduct = { ...categories.find(x => x.name == mainProductToFind.nameToFind), ...mainProductToFind };

  const otherProducts = categories.reduce((acc, x) => {
    const otherProductFound = otherProductsToFind.find(o => o.nameToFind == x.name);
    if(otherProductFound) {
      return [...acc, { ...x, ...otherProductFound }];
    }

    return acc;
  }, []);

  return (
    <div className='explore-collection'>
      {loading ? (
        <Loader width={200} height={200} color={colors.primary} />
      ) : (
        <>
          <h2 className='explore-collection-title'>
            {title}
          </h2>
          <ExploreProductPreview
            {...mainProduct}
          />
          <ExploreProductSlider
            products={categories}
          />
          {otherProducts.map(x => (
            <ExploreProductPreview
              key={uuid()}
              {...x}
            />
          ))}
        </>
      )}
    </div>
  )
}


ExploreCollection.propTypes = {
  title: string.isRequired,
  categoryName: string.isRequired
}

export default ExploreCollection;

const ExploreProductPreview = ({ image, title, description, name, id, min_price, price }) => (
  <div key={uuid()} className='explore-preview'>
    <Image alt={getImageAlt()} src={image} className='explore-preview-image'/>
    <div className='explore-preview-text-container'>
      <div className='explore-preview-text-title'>
        {title}
      </div>
      <div className='explore-preview-text-description'>
        {description}
      </div>
      <div className='explore-product-text-min-price'>
        Starting at: <span className='explore-product-text-price'>{min_price ?? price}$</span>
      </div>
      <div className='explore-preview-text-description'>
        Shipped From United States
      </div>
      <Link className='explore-preview-text-link' to={`/product-catalog-category/${mapNameToUrl(name)}/${id}`}>
        View Products
      </Link>
    </div>
  </div>
);

const PER_PAGE = 3;

const ExploreProductSlider = ({ products }) => {
  const [page, setPage] = React.useState(1);
  const totalPages = Math.round(products.length/PER_PAGE);

  const productsToShow = products.filter((_, i) => i < page * PER_PAGE).splice(-PER_PAGE);

  return (
    <div className='explore-product-slider-container'>
      <div className='explore-product-slider-row'>
        {productsToShow.map(x => (
          <div key={uuid()} className='explore-products-slider-product'>
            <Image alt={getImageAlt()} src={process.env.REACT_APP_baseUrl + (x?.category_image?.url ?? x?.product_image?.url)} className='explore-products-slider-product-image' />
            <div className='explore-products-slider-product-text'>
              <div className='explore-products-slider-product-text-title'>
                {x.name}
              </div>
              <div className='explore-products-slider-product-text-subtitle'>
                by Generic Brand
              </div>
            </div>
          </div>
        ))}
      </div>
      {totalPages > 1 && (
        <>
          <div className='explore-products-slider-pages'>
              {Array.from({ length: totalPages }).map((_, i) => (
                <div key={uuid()} className={
                  classNames('explore-products-slider-page',
                    {'explore-products-slider-page-selected': i + 1 == page })
                  }
                  onClick={() => setPage(i + 1)}
                  />
              ))}
          </div>
          <div className='explore-products-slider-arrows'>
            <div
              className='explore-products-slider-arrow'
              onClick={() => setPage(page - 1 == 0 ? totalPages : page - 1)}
            >
              <ArrowLeft width="48" height="48" color={colors.primary} />
            </div>
            <div
              className='explore-products-slider-arrow'
              onClick={() => setPage(page == totalPages ? 1 : page + 1)}
            >
              <ArrowRight width="48" height="48" color={colors.primary} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}