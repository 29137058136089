import Page from "../common/Page";
import {withRouter} from "react-router-dom";
import React from 'react'
import {getBrandsForCategory, getProductsForCategoryAndBrand} from "../services/admin/BrandService";
import SelectControl from "../components/controls/SelectControl";
import update from "immutability-helper";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {getProductAttributes} from "../services/admin/ProductService";
import AttributeType from "../constants/AttributeType";
import {Button} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {checkProductVariationsForAttributes} from "../services/admin/ProductVariationService";
import {getCategoryGalleryImages} from "../services/CategoryService";
import InfoBarImportedProduct from "../components/InfoBarImportedProduct";

class ProductSelection extends Page{

    constructor(props) {
        super(props);

        this.state = {
            categoryId : props.match.params.id,
            brands : [],
            products : [],
            selectedValues : [],
            attributeNames : [],
            attributeValues : [],
            category : {},
            selectedProduct : {},
            data : {},
            startPosition : 0,
            categoryStartPosition: 0,
            owlItems : 4,
            categoryGallery: [],
            fromPage: props.history.location.state?.fromPage === "placeAnOrder",
            importedProduct: null
        };

        this.selectProduct = this.selectProduct.bind(this);
        this.selectValue = this.selectValue.bind(this);
        this.reportWindowSize = this.reportWindowSize.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.scrollTo(0, this.state.scrollTop);
    }

    componentDidMount() {
        let that = this;
        window.onscroll = function() {
            that.state.scrollTop = document.documentElement.scrollTop;
        };

        this.reportWindowSize();
        window.addEventListener("resize", this.reportWindowSize);

        getCategoryGalleryImages({id: this.state.categoryId}).then((response) => {

            this.setState({
                categoryGallery: response.data
            }, () => setTimeout(() => this.forceUpdate(), 500))
        });

        getBrandsForCategory(this.state.categoryId).then(response => {
            if (!response.ok){
                return
            }

            let brands = [];
            let category = undefined;

            if(response.data.length == 0){
                this.props.enqueueSnackbar("There are no products for this category!", {variant: 'error'});
                this.props.history.push('/product-catalog');
            }

            response.data.forEach(item => {
                brands.push(item.brand);

                if (!category){
                    category = item.category;
                }
            });

            this.setState({
                ...this.state,
                brands : brands,
                category : category
            })
        });

        if (this.props.location.state !== undefined) {
            if ('importedProduct' in this.props.location.state) {
                const importedProduct = this.props.location.state.importedProduct;

                this.setState({
                    ...this.state,
                    importedProduct: importedProduct
                })
            }
        }
    }

    changeData(event, data = 'data') {
        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} })
        }, () => {
            getProductsForCategoryAndBrand(this.state.category.id, this.state.data.brand.id).then(response => {
                if (!response.ok){
                    return
                }

                this.setState({
                    ...this.state,
                    products : response.data
                }, () => {
                    this.setState({
                        ...this.state,
                        products : response.data
                    })
                })
            })
        });
    }

    selectProduct(item, index){
        this.state.selectedValues = [];
        let calc;

        if (window.innerWidth < 768) {
            calc = Math.floor(index / 1) * 1;
        }else if (window.innerWidth >= 768 && window.innerWidth < 992){
            calc = Math.floor(index / 2) * 2;
        }else if (window.innerWidth >= 992 && window.innerWidth < 1220){
            calc = Math.floor(index / 2) * 2;
        }else if (window.innerWidth >= 1220 && window.innerWidth < 1660){
            calc = Math.floor(index / 3) * 3;
        }else if (window.innerWidth >= 1660){
            calc = Math.floor(index / 4) * 4;
        }

        this.setState({
            ...this.state,
            selectedProduct : item,
            startPosition : calc
        }, () => {

                getProductAttributes(item.id).then(response => {
                    if (!response.ok){
                        return
                    }

                    let productAttributes = response.data;

                    let attributeNames = [];
                    let attributeValues = [];


                    productAttributes.forEach(item => {
                        let name = item.attribute_value.attribute.name;

                        if (!attributeNames.includes(name)){
                            attributeNames.push(name);
                            attributeValues.push(
                                {attributeValues : [], type : 0}
                            );
                        }
                    });

                    attributeNames.forEach((item, index) => {

                        productAttributes.forEach(itemAttr => {
                            let name = itemAttr.attribute_value.attribute.name;

                            if (item === name){
                                attributeValues[index].attributeValues.push(itemAttr.attribute_value);
                                attributeValues[index].type = itemAttr.attribute_value.attribute.attribute_type;
                            }
                        })
                    });

                    this.setState({
                        ...this.state,
                        attributeNames : attributeNames,
                        attributeValues : attributeValues
                    })
                })
        })
    }

    selectValue(value){
        let selected = this.state.selectedValues;
        let render = [];

        if (selected.includes(value)){
            selected.forEach(item => {
                if (item === value){
                    return
                }

                render.push(item);
            })
        }else {
            render = this.state.selectedValues;
            render.push(value);
        }

        this.setState({
            ...this.state,
            selectedValues : render
        })
    }

    renderAttributeValues(index){
        let render = [];
        this.state.attributeValues[index].attributeValues.forEach((value, count) => {
            render.push(
                this.state.attributeValues[index].type === AttributeType.STRING
                    ?
                    <span key={count} className={this.state.selectedValues.includes(value.id) ? 'attribute-value string active' : 'attribute-value string'} onClick={() => this.selectValue(value.id)}>
                        {value.name}
                    </span>
                    :
                    <div className={"color-attribute"} onClick={() => this.selectValue(value.id)}>
                        <div key={count} className={'attribute-value color'} style={{backgroundColor : value.name}}>
                            {
                                this.state.selectedValues.includes(value.id) &&
                                <img src={'/images/checked.png'} />
                            }

                        </div>
                        <span className={"attribute-value"}>{ value.customer_color_name }</span>
                    </div>
            )
        });

        render.push(
            <>
            <span className="btn-light" onClick={() => this.selectAllAttributes(index)}>
                Select All
            </span>
            <span className="btn-light" onClick={() => this.unselectAllAttributes(index)}>
                Unselect All
            </span>
            </>
        );

        return render;
    }

    selectAllAttributes = (index) => {
        const selected = this.state.selectedValues;
        const render = [];

        const allAttributes = this.state.attributeValues[index].attributeValues.map(attribute => {

            return attribute.id;
        });

        render.push(...selected, ...allAttributes);
        const uniqueRender = [...new Set(render)];

        this.setState({
            ...this.state,
            selectedValues: uniqueRender
        });
    }

    unselectAllAttributes = (index) => {
        const selected = this.state.selectedValues;
        let render = [];

        const attributesToUnselect = this.state.attributeValues[index].attributeValues.map(attribute => {
            if (selected.includes(attribute.id)) {
                return attribute.id;
            }
        });

        render = selected.filter(item => !attributesToUnselect.includes(item));

        this.setState({
            ...this.state,
            selectedValues: render
        });
    }

    calculateNumberOfVariations() {
        let attributes = {};
        let count = 0;

        for (let selectedValueId of this.state.selectedValues) {
            let attributeName = this.findAttributeValue(selectedValueId);
            if (!attributes.hasOwnProperty(attributeName)) {
                attributes[attributeName] = 1;
            } else {
                attributes[attributeName] += 1;
            }
        }

        let cc = 0;
        for (let attributeKey in attributes) {
            if (cc === 0) {
                count = attributes[attributeKey];
            } else {
                count = count * attributes[attributeKey];
            }
            cc += 1;
        }

        this.count = count;

        return count;
    }

    findAttributeValue(valueId) {
        for (let i = 0; i < this.state.attributeNames.length; i++) {
            for (let j = 0; j < this.state.attributeValues[i].attributeValues.length; j++) {
                if (this.state.attributeValues[i].attributeValues[j].id === valueId) {
                    return this.state.attributeNames[i];
                }
            }
        }
    }

    renderAttributes(){
        let render = [];

        this.state.attributeNames.forEach((name, index) => {
            render.push(
                <div key={name} className={'attribute'}>
                    <span className={'attribute-name'}>{name}</span>
                    <div className={'attribute-values'}>
                        {this.renderAttributeValues(index)}
                    </div>
                </div>
            )
        });

        return render;
    }

    renderProducts(){
        let render = [];

        this.state.products.forEach((item, index) => {
            render.push(
                <div key={item.id}
                     className={this.state.selectedProduct.id === item.id ? 'product active' : 'product'}
                     onClick={() => this.selectProduct(item, index)}>
                    <span className={'name'}>{item.name.substring(0, 40) + (item.name.length > 40 ? '...' : '')}</span>
                    <img src={item.product_image ? (process.env.REACT_APP_baseUrl + item.product_image.url) : ''} alt={'image'} />
                </div>
            )
        })

        return render;
    }

    renderCategoryGalleryImages(){
        let render = [];

        this.state.categoryGallery.forEach((item, index) => {
            render.push(
                <div key={'gallery-' + item.id} className={'category-gallery-image'}>
                    <img src={process.env.REACT_APP_baseUrl + item.url} alt={'image'} />
                </div>
            )
        });

        return render;
    }

    updateCarouselPosition(object){
        if(object.item.index != this.state.startPosition) {
            this.setState({ startPosition: object.item.index });
        }
    }

    updateCategoryCarouselPosition(object){
        if(object.item.index != this.state.categoryStartPosition) {
            this.setState({ categoryStartPosition: object.item.index });
        }
    }

    onGalleryTranslated(event) {
        if (event.item.index != this.state.categoryStartPosition) {
            this.setState({categoryStartPosition: event.item.index})
        }
    }

    reportWindowSize(){
        if (window.innerWidth < 768 && this.state.owlItems !== 1) {
            this.setState({
                ...this.state,
                owlItems: 1
            })
        }else if (window.innerWidth >= 768 && window.innerWidth < 992 && this.state.owlItems !== 2){
            this.setState({
                ...this.state,
                owlItems : 2
            })
        }else if (window.innerWidth >= 992 && window.innerWidth < 1220 && this.state.owlItems !== 2){
            this.setState({
                ...this.state,
                owlItems : 2
            })
        }else if (window.innerWidth >= 1220 && window.innerWidth < 1660 && this.state.owlItems !== 3){
            this.setState({
                ...this.state,
                owlItems : 3
            })
        }else if (window.innerWidth >= 1660 && this.state.owlItems !== 4){
            this.setState({
                ...this.state,
                owlItems : 4
            })
        }
    }

    isProductSelected() {
        return Object.keys(this.state.selectedProduct).length === 0;
    }

    render() {
        let selectedVariationsCount = this.calculateNumberOfVariations();
        return (
            <div>
                {
                    this.state.importedProduct !== null &&
                        <InfoBarImportedProduct image={this.state.importedProduct.image} name={this.state.importedProduct.name} variants={this.state.importedProduct.variants.map(element => element.attributes)}/>
                }
                <div className={'product-selection container'}>
                    <div className={'left'}>
                        <div className={'top'}>
                            <span className={'title'}>{this.state.category.name}</span>
                        </div>
                        <div className={'bottom'}>
                            <img src={this.state.selectedProduct.product_image ? (process.env.REACT_APP_baseUrl + this.state.selectedProduct.product_image.url) : ''}/>

                            {
                                this.isProductSelected() ?
                                    <div className={'category-gallery'}>
                                        <OwlCarousel
                                            margin={10}
                                            items={1}
                                            nav
                                            startPosition={this.state.categoryStartPosition}
                                            onDragged={(object) => this.updateCategoryCarouselPosition(object)}
                                            onTranslated={(event) => this.onGalleryTranslated(event)}
                                        >
                                            {this.renderCategoryGalleryImages()}
                                        </OwlCarousel>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                    <div className={'right'}>
                        <div className={'top'}>
                            <span className={'title'}>Select the options you wish to sell</span>
                        </div>
                        <div className={'bottom'}>
                            <p className={"category-description"} dangerouslySetInnerHTML={{__html: this.state.category.description}}></p>
                            <SelectControl
                                className='select'
                                options={this.state.brands}
                                valueKey = { 'id' }
                                onChange={ this.changeData }
                                nameKey= { 'name' }
                                name = {'brand'}
                                selected={this.state.data.brand}
                                placeholder={'Brand'}
                            />
                            <div className={'brand-products'}>
                                <OwlCarousel
                                    margin={10}
                                    items={this.state.owlItems}
                                    nav
                                    startPosition={this.state.startPosition}
                                    onDragged={(object) => this.updateCarouselPosition(object)}
                                >
                                    {this.renderProducts()}
                                </OwlCarousel>
                            </div>
                            <div className={'attributes'}>
                                {this.renderAttributes()}
                            </div>
                        </div>
                        <Button variant="contained" className={'previous'} onClick={() => {
                            this.props.history.push('/product-catalog', this.props.location.state);
                        }}>Previous step</Button>
                        <Button variant="contained" className={'next'} onClick={() => {
                            if (this.state.selectedProduct.id == undefined){
                                this.props.enqueueSnackbar("You must select a product", {variant: 'error'});
                                return;
                            }

                            if (this.state.selectedValues.length === 0){
                                this.props.enqueueSnackbar("You must select attributes", {variant: 'error'});
                                return;
                            }

                            const attributes = this.state.attributeValues.map(attribute => {
                                return attribute.attributeValues.filter(word => {
                                    return this.state.selectedValues.includes(word.id);
                                });
                            })
                            const atLeastOneAttributeSelected = (currentValue) => currentValue.length > 0;

                            if (!attributes.every(atLeastOneAttributeSelected)) {
                                this.props.enqueueSnackbar("Please select at least one variation from every Product Option", {variant: 'error'});

                                return;
                            }

                            checkProductVariationsForAttributes({
                                attributes : this.state.selectedValues,
                                productId : this.state.selectedProduct.id,
                                templateId : null,
                                check: true,
                                importedProduct: this.state.importedProduct?.id ?? null
                            }).then(response => {
                                if (!response.ok){
                                    return
                                }

                                if (response.data.approved.length === 0){
                                    this.props.enqueueSnackbar("Currently out of stock", {variant: 'error'});
                                    return;
                                }

                                if (this.state.selectedValues.length === 0){
                                    return
                                }

                                this.props.history.push('/product-selection-variation', {
                                    attributeValues: this.state.selectedValues,
                                    product : this.state.selectedProduct,
                                    importedProduct : this.state.importedProduct,
                                });
                            });
                        }}>Next {selectedVariationsCount ? '(' + selectedVariationsCount + ')' : ''}</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(withRouter(ProductSelection));
