import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function createVendor(data) {
    return await request('/api/create_vendor', data, HttpMethod.POST);
}

export async function getVendors(data) {
    return await request('/api/get_vendors', data, HttpMethod.GET);
}

export async function updateVendor(data) {
    return await request('/api/update_vendor', data, HttpMethod.POST);
}

export async function deleteVendor(data) {
    return await request('/api/delete_vendor', data, HttpMethod.PUT);
}

export async function getAllVendors() {
    return await request('/api/get_all_vendors', [], HttpMethod.GET);
}