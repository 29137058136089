import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function getDocuments(data) {
    return await request('/api/documents', data, HttpMethod.GET);
}

export async function createDocument(data) {
    return await request('/api/documents', data, HttpMethod.POST);
}
export async function deleteDocument(id) {
    return await request('/api/documents/'+id, {}, HttpMethod.DELETE);
}

export async function editDocument(id, data) {
    return await request('/api/documents/'+id+'?_method=PUT', data, HttpMethod.POST);
}
