import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import strings from '../localization';
import TextField from '@material-ui/core/TextField';
import AddVendor from "../components/AddVendor";
import { getRootCategories, getCategoryByParent, getAllCategories, 
         uploadCategoryImage, addCategory, setTrending,
         updateCategory, deleteCategory } from "../services/CategoryService";
import { getImage } from '../services/ImageService';
import Validators from "../constants/ValidatorTypes";
import {withSnackbar} from "notistack";
import { getVendors,
        createVendor,
        updateVendor,
        deleteVendor } from "../services/VendorService";
import {dateToString} from "../util/DateUtil";
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';

class Associate extends Page {


    constructor(props) {
        super(props);

        this.state = {
    
        }
    }

    render() {

        return (
            <div id="warehouse">
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <div className="header">
                    Let's partner and earn together! Surge Print will send you a commission for every order your referrals create. We create custom commissions for every partner and every product in order to ensure that no other print on demand company can match our percentages!
                </div>
                
                <ul>
                    <li className="paragraf">There is no cap! Refer as much as you want as there is no ceiling on how much you can earn.</li>
                    <li className="paragraf">ZERO upfront cost! No investment needed.</li>
                    <li className="paragraf">Simple registration, takes 5 min to get set up - please email support@surgeprint.com for details.</li>
                    <li className="paragraf">Earn on every order your customers make.</li>
                    <li className="paragraf">Keep earning for 12 straight months from each customer you refer.</li>
                </ul> 
                
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Associate)));
