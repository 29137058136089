import Page from "../common/Page";
import {withRouter} from "react-router-dom";
import React from 'react'
import {Button} from "@material-ui/core";
import {withSnackbar} from "notistack";
import Validators from "../constants/ValidatorTypes";
import { submitContactForm } from "../services/UserService";
import Helmet from 'react-helmet';


class Contact extends Page{

    validationList = {
        email: [ {type: Validators.EMAIL } ],
        name: [ {type: Validators.REQUIRED } ],
        message: [ {type: Validators.REQUIRED }]
    };

    constructor(props) {
        super(props);

        this.state = {
            disabledButton: false,
            data: {
                name: null,
                email: null,
                message: null,
                submit: 'SEND'
            }
        };

        this.reportWindowSize = this.reportWindowSize.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        this.reportWindowSize();
        window.addEventListener("resize", this.reportWindowSize);
    }

    toggleMenu(){
        this.setState({
            ...this.state,
            menuOpen : !this.state.menuOpen
        })
    }

    reportWindowSize(){
        this.setState({
            ...this.state,
            mobile: window.innerWidth < 768
        })
    }

    goToHowItWorks(){
        this.props.history.push('/', {goTo : '#how-it-works'});
    }

    goToHowAbout(){
        this.props.history.push('/', {goTo : '#about'});
    }

    goToRegistration(){
        this.props.history.push('/registration');
    }

    clearForm() {
        this.setState(() => ({
            disabledButton: false,
            data: {
                name: '',
                email: '',
                message: '',
                submit: 'SEND'
            },
        }));
    }

    toggleButton() {
        this.setState((state) => ({
            disabledButton: !state.disabledButton,
            data: {
                ...state.data,
                submit: !state.disabledButton ? 'SENDING...' : 'SEND'
            }
        }));
    }

    submit() {
        this.toggleButton();

        if (!this.validate()) {
            this.toggleButton();

            this.props.enqueueSnackbar("You must enter required fields!", { variant: 'error' });
            return;
        }

        submitContactForm(this.state.data).then(response => {
            if (!response.ok) {
                this.toggleButton();
                return;
            }

            this.clearForm();
            this.props.enqueueSnackbar("Thank you for contacting us, we will get back to you as soon as possible.", { variant: 'success' });
        });
    }

    render() {
        return (
            <div className={'container contact'}>
                                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <img className={'top-left'} src={'/images/top-left-contact.png'} />
                <div id={"top-header"} className="header">
                    <div className={'header-help'}>
                        <img className={'logo'} onClick={() => {
                            this.props.history.push('/');
                        }} src="./images/SurgePrintLogo1.png"></img>
                        {
                            !this.state.mobile &&
                            <div className="menu">
                                <div className="item" onClick={ () => this.goToHowItWorks()}>How it works</div>
                                <div className="item" onClick={ () => this.goToHowAbout()}>About</div>
                                <div className="item" onClick={ () => this.goToRegistration() }>Start Designing</div>
                                <div className="item" onClick={()  => {
                                    this.props.history.push('/contact');
                                }}>Contact</div>
                            </div>
                        }
                        {
                            !this.state.mobile &&
                            <div className="buttons">
                                <Button className="login" onClick={ () => this.props.history.push('/login') }>LOGIN</Button>
                                <Button className="sign-up" onClick={ () => this.goToRegistration()}>SIGN UP</Button>
                            </div>
                        }
                        {
                            this.state.mobile &&
                            <button onClick={()=> {
                                this.toggleMenu();
                            }} className={this.state.menuOpen ? "hamburger hamburger--collapse is-active" : "hamburger hamburger--collapse"} type="button">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        }
                        {
                            this.state.mobile &&
                            <div className={this.state.menuOpen ? 'mobile-home-menu active' : 'mobile-home-menu'}>
                                <div className="menu">
                                    <div className="item" onClick={ () => this.goToHowItWorks()}>How it works</div>
                                    <div className="item" onClick={ () => this.goToHowAbout()}>About</div>
                                    <div className="item" onClick={ () => this.goToRegistration() }>Start Designing</div>
                                    <div className="item" onClick={()  => {
                                        this.props.history.push('/contact');
                                    }}>Contact</div>
                                </div>
                                <div className="buttons">
                                    <Button className="sign-up" onClick={ () => this.goToRegistration()}>SIGN UP</Button>
                                    <Button className="login" onClick={ () => this.props.history.push('/login') }>LOGIN</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <img className={'bottom-right'} src={'/images/bottom-right-corner.png'} />
                <div className={'content'}>
                    <div className={'center'}>
                        <img src={'/images/contact-group.png'} />
                        <p>Get in touch with us with any question and we will get back to you as soon as possible.</p>
                        <div className={'inputs'}>
                            <div className={'input'}>
                                <label>Your Name:<span className={'required'}>*</span></label>
                                <input
                                    name='name'
                                    onChange={ this.changeData }
                                    value={ this.state.data.name }
                                />
                            </div>
                            <div className={'input'}>
                                <label>Your Email:<span className={'required'}>*</span></label>
                                <input
                                    name='email'
                                    onChange={ this.changeData }
                                    value={ this.state.data.email }
                                />
                            </div>
                            <div className={'input'}>
                                <label>Your Message:<span className={'required'}>*</span></label>
                                <input
                                    name='message'
                                    onChange={ this.changeData }
                                    value={ this.state.data.message }
                                />
                            </div>
                            <Button variant="contained" className={'contact-btn'} disabled={ this.state.disabledButton } onClick={ () => this.submit() }>
                                { this.state.data.submit }
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(withRouter(Contact));
