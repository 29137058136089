import React from 'react';
import { number } from 'prop-types';

const IconDHL = ({ width, height }) => (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 36 24">
    <defs>
        <path id="a" d="M.003.04h18.932v1.505H.003z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <rect width={width} height={height} fill="#FFF" rx="4"/>
        <g transform="translate(3 3)">
            <rect width="30" height="18" fill="#FC0" rx="1"/>
            <path fill="#C52038" d="M8.243 8.725c.264 0 .26.106.131.292l-.487.703c-.068.097-.19.274.215.274h3.2c-.266.387-1.129 1.505-2.68 1.505H3.93l1.925-2.774h2.389zM5.275 7h5.527c1.404 0 1.763 1.176 1.37 1.741l-.147.212-.479.69-.007.01H9.403c-.406 0-.283-.176-.216-.274.134-.19.353-.518.484-.706.115-.165.13-.267-.053-.288L9.54 8.38H4.317L5.275 7h5.527zm11.756 0l-1.056 1.521h1.229L18.26 7h2.753L19.17 9.653h-6.735L14.278 7h2.753z"/>
            <g transform="translate(0 10.5)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a"/>
                </mask>
                <path fill="#C52038" d="M18.935.04l-1.044 1.505h-2.753L16.182.04h2.753M0 .633h4.056l-.221.32H0v-.32M0 .04h4.469l-.222.319H0v-.32M0 1.227h3.644l-.22.318H0v-.318" mask="url(#b)"/>
            </g>
            <path fill="#C52038" d="M29.996 11.181v.318h-4.451l.22-.318h4.231zm-3.911-1.187L25.04 11.5H20.62c-1.129 0-1.434-.577-1.089-1.073l.29-.42.01-.012h6.254zm-11.132 0l-1.044 1.505h-2.753l.044-.065 1-1.44h2.753zm15.043.594v.32h-4.04l.221-.32h3.82zm0-.594v.32h-3.629l.222-.32h3.407zM24.825 7l-1.842 2.653h-2.916L21.909 7h2.916z"/>
        </g>
    </g>
</svg>

);

IconDHL.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
}

export default IconDHL;