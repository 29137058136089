import { useEffect, useState } from 'react';
import medias from 'src/scss/_media.scss';

export default function () {
    const [isMobile, setIsMobile] = useState();
    const breakpointSmall = medias.breakpointSmall?.slice(0, -2);

    useEffect(() => {    
        setIsMobile(window.innerWidth < breakpointSmall);
        window.addEventListener("resize", () => {
            if (window.innerWidth > breakpointSmall) {
                setIsMobile(false);
            } else {
                setIsMobile(true);
            }
          });
        return () => window.removeEventListener("resize", () => {
            if (window.innerWidth > breakpointSmall) {
                setIsMobile(false);
            } else {
                setIsMobile(true);
            }
          });
      }, []);

      return {
          isMobile,
      };
};