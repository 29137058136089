import React from 'react';
import {Button, TextField, TextareaAutosize, Checkbox} from "@material-ui/core";
import Select from 'react-select'

import strings from '../localization';
import {getError, hasError} from "../functions/Validation";
import { getImage } from '../services/ImageService';


const addMockup = ({
    onChange,
    data,
    errors,
    submited,
    goBack,
    changeCheckBox,
    mockups,
    changeScenesSelectedOptions
}) => {
    const previewImage = () => {
        var oFReader = new FileReader();
        oFReader.readAsDataURL(document.getElementById("mockupMainImage").files[0]);

        oFReader.onload = function (oFREvent) {
            document.getElementById("uploadPreview").src = oFREvent.target.result;
        };
    };

    const onImageChange = (event) => {
        onChange(event)
        previewImage()
        
    }


    return(
        <div id="add-category">
            <div className="header">
            { data.id ? 'Edit Mockup' :'Add new mockup' }
                <span className={'exit'} onClick={goBack}>✕</span>
            </div>
            <div className="form">
                <div className="content">


                    <TextField
                        name='mediaModifierId'
                        onChange={ onChange }
                        className={"category-name"}
                        type='text'
                        value={ data.mediaModifierId }
                        error={ getError(errors, 'mediaModifierId') }
                        helperText={ getError(errors, 'mediaModifierId') }
                        placeholder = { "Media Modifier Id" }
                        variant="outlined"
                    />

                    <TextField
                        name='name'
                        onChange={ onChange }
                        className={"category-name"}
                        type='text'
                        value={ data.name }
                        error={ getError(errors, 'name') }
                        helperText={ getError(errors, 'name') }
                        placeholder = { "Name" }
                        variant="outlined"
                    />  
                    <div>
                        Use variation color
                    <Checkbox
                        name='useColorFromVariation'
                        onChange={changeCheckBox}
                        className={"category-name"}
                        checked={data.useColorFromVariation}
                        value={data.useColorFromVariation}
                        error={ getError(errors, 'useColorFromVariation') }
                        helperText={ getError(errors, 'useColorFromVariation') }
                    />  
                    </div>
                    <div>
                        Select media modifier related scenes
                    <Select
                        name="scenes"
                        options={mockups.map(mockup => { return { label: mockup.name, value: mockup.id}})}
                        onChange={ changeScenesSelectedOptions }
                        value={data.scenes}
                        isMulti
                    />
                                <br/><br/>
                    </div>

                    
                    <TextareaAutosize
                        name='layers'
                        onChange={ onChange }
                        className={"category-name"}
                        type='textarea'
                        value={ data.layers }
                        error={ getError(errors, 'layers') }
                        helperText={ getError(errors, 'layers') }
                        placeholder = { "Layers"}
                        variant="outlined"
                        style={{ width: 300, height: 200 }}
                    />
                    <span> Upload Mockup Main image</span>
                    <input type="file" id="mockupMainImage" name="mockupMainImage" onChange={onImageChange} />
                    <img  id="uploadPreview" src={data.imagePath ? process.env.REACT_APP_baseUrl + data.imagePath : ''} style={{width: '100%', height:'300px'}}/>
                    <div className="buttons">
                        <Button className="save" onClick={submited}>{ strings.category.save }</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default addMockup;