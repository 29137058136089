import React from 'react';
import { number, string } from 'prop-types';

const IconStorage = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_466_11169)">
    <path d="M21.5347 20H0.465335C0.206815 20 0 19.812 0 19.577V5.40545C0 5.17044 0.206815 4.98242 0.465335 4.98242H21.5605C21.819 4.98242 22.0259 5.17044 22.0259 5.40545V19.577C22 19.812 21.7932 20 21.5347 20ZM0.904818 19.154H21.0693V5.82849H0.904818V19.154Z" fill={color}/>
    <path d="M21.5604 5.82844C21.5346 5.82844 21.5346 5.82844 21.5604 5.82844H0.465233C0.310121 5.82844 0.15501 5.75793 0.077454 5.61692C-0.000101846 5.47591 -0.000101846 5.3349 0.077454 5.19389L3.25724 0.211516C3.36065 0.0705053 3.48991 0 3.67087 0H18.7943C18.9752 0 19.1303 0.0940071 19.2079 0.235018L21.9482 5.21739C21.9999 5.2879 21.9999 5.3584 21.9999 5.42891C21.9999 5.64042 21.7931 5.82844 21.5604 5.82844ZM1.26664 5.00588H20.8107L18.5099 0.822562H3.92939L1.26664 5.00588Z" fill={color}/>
    <path d="M10.9871 5.82844C10.7285 5.82844 10.5217 5.64042 10.5217 5.40541V0.423032C10.5217 0.188014 10.7285 0 10.9871 0C11.2456 0 11.4524 0.188014 11.4524 0.423032V5.40541C11.4524 5.64042 11.2456 5.82844 10.9871 5.82844Z" fill={color}/>
    <path d="M19.6992 18.3314H11.4524C11.1939 18.3314 10.9871 18.1434 10.9871 17.9084V12.9025C10.9871 12.6675 11.1939 12.4795 11.4524 12.4795H19.6992C19.9577 12.4795 20.1645 12.6675 20.1645 12.9025V17.9084C20.1645 18.1434 19.9577 18.3314 19.6992 18.3314ZM11.9177 17.4854H19.2597V13.3256H11.9177V17.4854Z" fill={color}/>
    <path d="M17.8636 14.994H13.2878C13.0293 14.994 12.8225 14.806 12.8225 14.571C12.8225 14.336 13.0293 14.1479 13.2878 14.1479H17.8636C18.1222 14.1479 18.329 14.336 18.329 14.571C18.329 14.806 18.1222 14.994 17.8636 14.994Z" fill={color}/>
    <path d="M17.8636 16.663H13.2878C13.0293 16.663 12.8225 16.4749 12.8225 16.2399C12.8225 16.0049 13.0293 15.8169 13.2878 15.8169H17.8636C18.1222 15.8169 18.329 16.0049 18.329 16.2399C18.329 16.4749 18.1222 16.663 17.8636 16.663Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_466_11169">
    <rect width={width} height={height} fill="white"/>
    </clipPath>
    </defs>
</svg>
);

IconStorage.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconStorage;