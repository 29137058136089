import React, {Component} from 'react'

import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import ShippingMethodValues from "../../constants/ShippingMethodValues";
import {getUserFromLocalStorage} from "../../base/OAuth";
import UserType from "../../constants/UserType";
import {LOGIN} from "../../actions/Actions";

const US = "United States";

class Billing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order,
            orderItems: this.props.orderItems,
            costSubTotal: 0,
            costShipping: 0,
            partnerTotal: 0,
            customerTotal: 0,
            surgeProfit: 0,
            shippingProfit: 0,
            partnerShipping: 0,
            costShippingVendor: 0,
            partnerProfit: 0,
            profitProductProfit: 0,
            profitShipProfit: 0,
            userType: getUserFromLocalStorage().user_type,
        }
    }

    componentDidMount() {
        this.setOrderTotal();

    }

    setOrderTotal() {
        let shippingTotal = 0;
        let costSubTotal = 0;
        let costShipping = 0;
        let partnerTotal = 0;
        let customerTotal = 0;
        let surgeProfit = 0;
        let shippingProfit = 0;
        let partnerProfit = 0;
        let isFirst = true;

        for (let item of this.state.orderItems) {

            const shippingPrices = item.product_variation_shipping_price;
            const customerProductVariation = item.customer_product_variation;
            const productVariation = customerProductVariation.product_variation;

            costSubTotal = costSubTotal + (productVariation.vendor_price ? productVariation.vendor_price : 0) * item.quantity;
            partnerTotal = partnerTotal + productVariation.price * item.quantity;
            surgeProfit = surgeProfit + (productVariation.price - (productVariation.vendor_price ? productVariation.vendor_price : 0)) * item.quantity;

            if (customerProductVariation.customer_product_variation_shopifies) {
                if (customerTotal === "*") {
                    customerTotal = 0;
                }

                customerTotal = customerTotal + (productVariation.price + customerProductVariation.customer_product_variation_shopifies[0].earned) * item.quantity;
                partnerProfit = customerProductVariation.customer_product_variation_shopifies[0].earned * item.quantity
            } else {
                customerTotal = "*";
                partnerProfit = 0;
            }

            if (!this.state.order.country){
                return;
            }

            if (this.state.order.country.name === US && this.state.order.shipping_method === ShippingMethodValues.STANDARD) {
                if (isFirst){
                    isFirst = false;

                    if (item.quantity > 1) {
                        shippingTotal += shippingPrices.us_standard_first;
                        shippingTotal += (shippingPrices.us_standard_n) * (item.quantity - 1);

                        shippingProfit += shippingPrices.us_standard_first;
                        shippingProfit += (shippingPrices.us_standard_n) * (item.quantity - 1);

                        costShipping += shippingPrices.vendor_us_standard_first;
                        costShipping += shippingPrices.vendor_us_standard_n
                    } else {
                        shippingTotal += shippingPrices.us_standard_first;
                        shippingProfit += shippingPrices.us_standard_first;
                        costShipping += shippingPrices.vendor_us_standard_first
                    }

                }else {
                    if (item.quantity > 1) {
                        shippingTotal += (shippingPrices.us_standard_n) * (item.quantity - 1);
                        shippingProfit += (shippingPrices.us_standard_n) * (item.quantity - 1);
                        costShipping += shippingPrices.vendor_us_standard_n
                    } else {
                        shippingTotal += (shippingPrices.us_standard_n);
                        shippingProfit += (shippingPrices.us_standard_n);
                        costShipping += shippingPrices.vendor_us_standard_n
                    }
                }
            } else if (this.state.order.country.name === US && this.state.order.shipping_method === ShippingMethodValues.EXPEDITED)  {
                if (isFirst){
                    isFirst = false;

                    if (item.quantity > 1) {
                        shippingTotal += shippingPrices.us_expedited_first;
                        shippingTotal += (shippingPrices.us_expedited_n) * (item.quantity - 1);

                        shippingProfit += shippingPrices.us_expedited_first;
                        shippingProfit += (shippingPrices.us_expedited_n) * (item.quantity - 1);

                        costShipping += shippingPrices.us_expedited_first;
                        costShipping += shippingPrices.vendor_us_expedited_n
                    } else {
                        shippingTotal += shippingPrices.us_expedited_first;
                        shippingProfit += shippingPrices.us_expedited_first;
                        costShipping += shippingPrices.vendor_us_expedited_n
                    }

                }else {
                    if (item.quantity > 1) {
                        shippingTotal += (shippingPrices.us_expedited_n) * (item.quantity - 1);
                        shippingProfit += (shippingPrices.us_expedited_n) * (item.quantity - 1);
                        costShipping += shippingPrices.vendor_us_expedited_n
                    } else {
                        shippingTotal += (shippingPrices.us_expedited_n);
                        shippingProfit += (shippingPrices.us_expedited_n);
                        costShipping += shippingPrices.vendor_us_expedited_n
                    }
                }

            } else if (this.state.order.country.name === US && this.state.order.shipping_method === ShippingMethodValues.OVERNIGHT) {
                if (isFirst){
                    isFirst = false;

                    if (item.quantity > 1) {
                        shippingTotal += shippingPrices.us_overnight_first;
                        shippingTotal += (shippingPrices.us_overnight_n) * (item.quantity - 1);

                        shippingProfit += shippingPrices.us_overnight_first;
                        shippingProfit += (shippingPrices.us_overnight_n) * (item.quantity - 1);

                        costShipping += shippingPrices.us_overnight_first;
                        costShipping += shippingPrices.vendor_us_overnight_n
                    } else {
                        shippingTotal += shippingPrices.us_overnight_first;
                        shippingProfit += shippingPrices.us_overnight_first;
                        costShipping += shippingPrices.vendor_us_overnight_n
                    }

                }else {
                    if (item.quantity > 1) {
                        shippingTotal += (shippingPrices.us_overnight_n) * (item.quantity - 1);
                        shippingProfit += (shippingPrices.us_overnight_n) * (item.quantity - 1);
                        costShipping += shippingPrices.vendor_us_overnight_n
                    } else {
                        shippingTotal += (shippingPrices.us_overnight_n);
                        shippingProfit += (shippingPrices.us_overnight_n);
                        costShipping += shippingPrices.vendor_us_overnight_n
                    }
                }

            } else if (this.state.order.country.name !== US && this.state.order.shipping_method === ShippingMethodValues.STANDARD) {
                if (isFirst){
                    isFirst = false;

                    if (item.quantity > 1) {
                        shippingTotal += shippingPrices.int_standard_first;
                        shippingTotal += (shippingPrices.int_standard_n) * (item.quantity - 1);

                        shippingProfit += shippingPrices.int_standard_first;
                        shippingProfit += (shippingPrices.int_standard_n) * (item.quantity - 1);

                        costShipping += shippingPrices.vendor_int_standard_first;
                        costShipping += shippingPrices.vendor_int_standard_n
                    } else {
                        shippingTotal += shippingPrices.int_standard_first;
                        shippingProfit += shippingPrices.int_standard_first;
                        costShipping += shippingPrices.vendor_int_standard_first
                    }

                }else {
                    if (item.quantity > 1) {
                        shippingTotal += (shippingPrices.int_standard_n) * (item.quantity - 1);
                        shippingProfit += (shippingPrices.int_standard_n) * (item.quantity - 1);
                        costShipping += shippingPrices.vendor_int_standard_n
                    } else {
                        shippingTotal += (shippingPrices.int_standard_n);
                        shippingProfit += (shippingPrices.int_standard_n);
                        costShipping += shippingPrices.vendor_int_standard_n
                    }
                }
            } else if (this.state.order.country.name !== US && this.state.order.shipping_method === ShippingMethodValues.EXPEDITED) {
                if (isFirst){
                    isFirst = false;

                    if (item.quantity > 1) {
                        shippingTotal += shippingPrices.int_expedited_first;
                        shippingTotal += (shippingPrices.int_expedited_n) * (item.quantity - 1);

                        shippingProfit += shippingPrices.int_expedited_first;
                        shippingProfit += (shippingPrices.int_expedited_n) * (item.quantity - 1);

                        costShipping += shippingPrices.vendor_int_expedited_first;
                        costShipping += shippingPrices.vendor_int_expedited_n
                    } else {
                        shippingTotal += shippingPrices.int_expedited_first;
                        shippingProfit += shippingPrices.int_expedited_first;
                        costShipping += shippingPrices.vendor_int_expedited_first
                    }

                }else {
                    if (item.quantity > 1) {
                        shippingTotal += (shippingPrices.int_expedited_n) * (item.quantity - 1);
                        shippingProfit += (shippingPrices.int_expedited_n) * (item.quantity - 1);
                        costShipping += shippingPrices.vendor_int_expedited_n
                    } else {
                        shippingTotal += (shippingPrices.int_expedited_n);
                        shippingProfit += (shippingPrices.int_expedited_n);
                        costShipping += shippingPrices.vendor_int_expedited_n
                    }
                }
            } else if (this.state.order.country.name !== US && this.state.order.shipping_method === ShippingMethodValues.OVERNIGHT) {
                if (isFirst){
                    isFirst = false;

                    if (item.quantity > 1) {
                        shippingTotal += shippingPrices.int_overnight_first;
                        shippingTotal += (shippingPrices.int_overnight_n) * (item.quantity - 1);

                        shippingProfit += shippingPrices.int_overnight_first;
                        shippingProfit += (shippingPrices.int_overnight_n) * (item.quantity - 1);

                        costShipping += shippingPrices.vendor_int_overnight_first;
                        costShipping += shippingPrices.vendor_int_overnight_n
                    } else {
                        shippingTotal += shippingPrices.int_overnight_first;
                        shippingProfit += shippingPrices.int_overnight_first;
                        costShipping += shippingPrices.vendor_int_overnight_first
                    }

                }else {
                    if (item.quantity > 1) {
                        shippingTotal += (shippingPrices.int_overnight_n) * (item.quantity - 1);
                        shippingProfit += (shippingPrices.int_overnight_n) * (item.quantity - 1);
                        costShipping += shippingPrices.vendor_int_overnight_n
                    } else {
                        shippingTotal += (shippingPrices.int_overnight_n);
                        shippingProfit += (shippingPrices.int_overnight_n);
                        costShipping += shippingPrices.vendor_int_overnight_n
                    }
                }
            }
        }

        let profitProductProfit = partnerTotal - costSubTotal;
        let profitShipProfit =  (shippingTotal+shippingProfit) / 2 - costShipping;

        this.setState({
            ...this.state,
            costShipping: shippingTotal,
            costSubTotal: costSubTotal,
            partnerTotal: partnerTotal,
            customerTotal: customerTotal,
            surgeProfit: surgeProfit,
            shippingProfit: shippingProfit,
            partnerShipping: (shippingTotal+shippingProfit) / 2,
            costShippingVendor: costShipping,
            partnerProfit: partnerProfit,
            profitProductProfit: profitProductProfit,
            profitShipProfit: profitShipProfit
        })
    }

    getPartnerProductProfit() {
        if (this.state.costSubTotal === "*" || this.state.customerTotal === "*" ) {
            return "*";
        }

        return (this.state.customerTotal - this.state.costSubTotal);
    }

    getPartnerShipProfit() {
        if (this.state.costShippingVendor === "*" || this.state.partnerShipping === "*" ) {
            return "*";
        }


        return (this.state.partnerShipping - this.state.costShippingVendor);
    }

    getPartnerTotalProfit() {
        if (this.getPartnerProductProfit() === "*" || this.getPartnerShipProfit() === "*" ) {
            return "*";
        }

        return (this.getPartnerProductProfit() + this.getPartnerShipProfit());
    }

    getPartnerTotalProfitPercentage() {
        if (this.getPartnerProductProfit() === "*") {
            return "*";
        }

        return this.getPartnerProductProfit() / ((this.state.partnerTotal + this.state.partnerShipping) / 100)
    }

    render() {

        return (
            <div>
                <div className="row">
                    <div className="details">
                        <div className="details-header">
                            Partner Costs
                        </div>
                        <div className="info">
                            <div className="left">
                                Sub Total
                            </div>
                            <div className="right">
                                ${ this.state.partnerTotal.toFixed(2) }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Shipping
                            </div>
                            <div className="right">
                                ${ (this.state.partnerShipping).toFixed(2) }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Total
                            </div>
                            <div className="right">
                                ${ (this.state.partnerTotal + this.state.partnerShipping).toFixed(2) }
                            </div>
                        </div>
                    </div>

                    {
                        this.state.userType == UserType.ADMIN &&
                        <div className="details">
                            <div className="details-header">
                                Surgeprint costs
                            </div>
                            <div className="info">
                                <div className="left">
                                    Sub Total
                                </div>
                                <div className="right">
                                    ${this.state.costSubTotal.toFixed(2)}
                                </div>
                            </div>
                            <div className="info">
                                <div className="left">
                                    Shipping
                                </div>
                                <div className="right">
                                    ${this.state.costShippingVendor.toFixed(2)}
                                </div>
                            </div>
                            <div className="info">
                                <div className="left">
                                    Total
                                </div>
                                <div className="right">
                                    ${ (this.state.costSubTotal + this.state.costShippingVendor).toFixed(2) }
                                </div>
                            </div>
                        </div>
                    }
                        <div className="details">
                            <div className="details-header">
                                Customer Costs
                            </div>

                            <div className="info">
                                <div className="left">
                                    Sub Total
                                </div>
                                <div className="right">
                                    ${this.state.customerTotal != "*" ? this.state.customerTotal.toFixed(2) : "*"}
                                </div>
                            </div>

                            <div className="info">
                                <div className="left">
                                    Shipping
                                </div>
                                <div className="right">
                                    ${this.state.partnerShipping.toFixed(2)}
                                </div>
                            </div>

                            <div className="info">
                                <div className="left">
                                    Total
                                </div>
                                <div className="right">
                                    ${this.state.customerTotal != "*" ? ((this.state.customerTotal + this.state.partnerShipping).toFixed(2)) : "*"}
                                </div>
                            </div>

                            {/*<div className="info">*/}
                            {/*    <div className="left">*/}
                            {/*        Payment Method*/}
                            {/*    </div>*/}
                            {/*    <div className="right">*/}
                            {/*        NET30*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                        </div>

                </div>

                {
                    this.state.userType == UserType.ADMIN &&
                    <div className="row">
                        <div className="details">
                            <div className="details-header">
                                Profit
                            </div>

                            <div className="info">
                                <div className="left">
                                    Product Profit
                                </div>
                                <div className="right">
                                    {/*${this.state.surgeProfit.toFixed(2)}*/}
                                    ${(this.state.profitProductProfit).toFixed(2)}
                                </div>
                            </div>

                            <div className="info">
                                <div className="left">
                                    Ship Profit
                                </div>
                                <div className="right">
                                    {(this.state.profitShipProfit).toFixed(2)}
                                    {/*${this.state.shippingProfit.toFixed(2)}*/}
                                </div>
                            </div>

                            <div className="info">
                                <div className="left">
                                    Total Profit
                                </div>
                                <div className="right">
                                    ${(this.state.profitProductProfit + this.state.profitShipProfit).toFixed(2) + " / " + ((this.state.profitProductProfit + this.state.profitShipProfit) / ((this.state.costSubTotal + this.state.costShippingVendor) / 100)).toFixed(2) + "%"}
                                    {/*${(this.state.surgeProfit + this.state.shippingProfit).toFixed(2) + " / " + ((this.state.surgeProfit + this.state.shippingProfit) / ((this.state.costSubTotal + this.state.costShippingVendor) / 100)).toFixed(2) + "%"}*/}
                                </div>
                            </div>

                            {/*<div className="info">*/}
                            {/*    <div className="left">*/}
                            {/*        Profit Margin*/}
                            {/*    </div>*/}
                            {/*    <div className="right">*/}
                            {/*        11%*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        <div className="details">
                            <div className="details-header">
                                Partner Profit
                            </div>

                            <div className="info">
                                <div className="left">
                                    Product Profit
                                </div>
                                <div className="right">
                                    ${this.getPartnerProductProfit() !== "*" ? this.getPartnerProductProfit().toFixed(2) : this.getPartnerProductProfit()}
                                </div>
                            </div>

                            <div className="info">
                                <div className="left">
                                    Shipping Profit
                                </div>
                                <div className="right">
                                    ${this.getPartnerShipProfit() !== "*" ? this.getPartnerShipProfit().toFixed(2) : this.getPartnerShipProfit()}
                                </div>
                            </div>

                            <div className="info">
                                <div className="left">
                                    Total Profit
                                </div>
                                <div className="right">
                                    ${(this.getPartnerTotalProfit() && this.getPartnerTotalProfit() !== "*") ? this.getPartnerTotalProfit().toFixed(2) +
                                    " / " + this.getPartnerTotalProfitPercentage().toFixed(2) + "%"
                                    : "*"}
                                </div>
                            </div>

                            {/*<div className="info">*/}
                            {/*    <div className="left">*/}
                            {/*        Profit Margin*/}
                            {/*    </div>*/}
                            {/*    <div className="right">*/}
                            {/*        11%*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                }

            </div>
        );
    }


}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Billing)));
