import React from 'react';
import { string } from 'prop-types';

import PrimaryButton from 'src/redesign/components/Buttons/Primary';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import IconCheckmark from 'src/redesign/components/Icon/Checkmark';
import Image from 'src/redesign/components/Image';
import { WaveDown, WaveUp } from 'src/redesign/components/Wave/Up';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss'

const ProductDescription = ({ name, price, description, url, setDetailsVisible, onStartSellingClick }) => {
  return <div className='product-description'>
    <WaveUp width={window.innerWidth * 1.5} height="144.584" color={colors.secondaryLight}/>
    <div className="content-container">
      <div className="content">
        <Image alt={getImageAlt()} className="image" src={url} />
        <div className="card">
          <h1 className="title">
            {name}
          </h1>
          <div className="sub-title">
            {price}
          </div>
          <div className="description">
            {description}
          </div>
          <div className="instock-container">
            <IconCheckmark width={9} height={6} color={colors.secondary}/>
            In Stock
          </div>
          <div className="buttons">
            <SecondaryButton text="View Details" onClick={setDetailsVisible}/>
            <PrimaryButton text="Start Selling" onClick={onStartSellingClick}/>
          </div>
        </div>
      </div>
    </div>
    <WaveDown width={window.innerWidth * 1.5} height="144.584" color={colors.secondaryLight}/>
  </div>
}

ProductDescription.propTypes = {
  name: string.isRequired,
  price: string.isRequired,
  url: string.isRequired
}

export default ProductDescription;