import Page from "../common/Page";
import {withRouter} from "react-router-dom";
import React from 'react'
import {getCategoriesTree} from "../services/CategoryService";
import {Button, TextField} from "@material-ui/core";
import update from "immutability-helper";
import CategoryPopup from "../components/CategoryPopup";
import {getBrandsForCategory} from "../services/admin/BrandService";
import {withSnackbar} from "notistack";
import {getUserFromLocalStorage} from "../base/OAuth";
import InfoBarImportedProduct from "../components/InfoBarImportedProduct";

class ProductCatalog extends Page{

    constructor(props) {
        super(props);

        this.state = {
            viewCategory : {},
            categories : [],
            childlessChildren : [],
            childlessChildrenDisplay : [],
            savedChildlessChildren : [],
            toggle : false,
            data : {
                categoryName : ''
            },
            fromPage: props.history.location.state?.fromPage === "placeAnOrder",
            importedProduct: null,
        };

        this.closePopup = this.closePopup.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();

        if (this.props.location.state !== undefined) {
            if ('importedProduct' in this.props.location.state) {
                const importedProduct = this.props.location.state.importedProduct;

                this.setState({
                    ...this.state,
                    importedProduct: importedProduct
                })
            }
        }

    }

    fetchData() {
        getCategoriesTree().then(response => {
            if (!response.ok){
                return ;
            }

            let render = this.tree(response.data);

            this.setState({
                ...this.state,
                categories : render,
                toggle: false,
                childlessChildrenDisplay: render,
                savedChildlessChildren: render
            })
        })
    }

    topLevel(data) {
        return data.filter(node => !node.parent_category);
    }

    tree(data) {
        return this.topLevel(data).map(each => {
            each.subcategories = this.traverse(data, each.id);
            return each;
        });
    }

    traverse(data, parentId) {
        const children = data.filter(each => (each.parent_category ? each.parent_category.id : null) === parentId);
        children.forEach(child => {
            child.subcategories = this.traverse(data, child.id);
        });
        return children;
    }

    searchChildren(subcategories, id){
        let ret;

        if (!subcategories){
            return;
        }

        subcategories.forEach(item => {
            if (item.id !== id){
                return
            }

            ret = item;
        })

        if (!ret){
            ret = this.searchChildren(subcategories.subcategories, id);
        }

        return ret;
    }

    getChildlessChildren(parentId, parent){
        let childlessChildren = [];

        this.state.childlessChildren.forEach(item => {
            if (item.parent !== parentId){
                return ;
            }

            childlessChildren = item.childlessChildren;
        })

        this.setState({
            ...this.state,
            childlessChildrenDisplay : childlessChildren,
            savedChildlessChildren : childlessChildren,
            selectedParent : parent
        })
    }

    toggleChildren(e, id, parent){
        if (e){
            e.stopPropagation();
        }

        let categories = this.state.categories;
        let gotIt = false;
        this.state.toggle = true;

        categories.forEach(item => {
            if (item.id !== id){
                return
            }

            gotIt = true;
            item.showChildren = !item.showChildren;
        });

        let answer;

        if (!gotIt){
            categories.forEach(item => {
                if (answer){
                    return ;
                }

                answer = this.searchChildren(item.subcategories, id);

                if (answer){
                    if (answer.showChildren === undefined){
                        answer.showChildren = true;
                    }else {
                        answer.showChildren = !answer.showChildren;
                    }

                    return ;
                }
            });
        }

        this.setState({
            ...this.state,
            categories : categories
        }, () => {
            this.getChildlessChildren(id, parent);
        })
    }

    renderChildren(subcategories){
        let render = [];
        let childlessChildren = this.state.childlessChildren;


        subcategories.forEach(item => {
            if (item.subcategories.length === 0 && !this.state.toggle){
                if (childlessChildren.length === 0){
                    childlessChildren.push(
                        {
                            parent : item.parent_category.id,
                            childlessChildren : [item]
                        }
                    );
                }else{
                    let hasIt = false;

                    childlessChildren.forEach(childlessChild => {
                        if(item.parent_category == undefined){
                            let go = true;

                            childlessChild.childlessChildren.forEach(check => {
                                if (check.id === item.id){
                                    go = false;
                                }
                            })

                            if (!go){
                                return
                            }

                            childlessChild.childlessChildren.push(item);

                            hasIt = true;

                        }else if (childlessChild.parent === item.parent_category.id){

                            let go = true;

                            childlessChild.childlessChildren.forEach(check => {
                                if (check.id === item.id){
                                    go = false;
                                }
                            })

                            if (!go){
                                return
                            }

                            childlessChild.childlessChildren.push(item);

                            hasIt = true;
                        }
                    })

                    if (!hasIt){
                        childlessChildren.push(
                            {
                                parent : item.parent_category.id,
                                childlessChildren : [item]
                            }
                        );
                    }
                }
            }

            render.push(
                <div key={item.id} className={item.subcategories.length > 0 ? 'child' : 'child hide'} onClick={(e) => this.toggleChildren(e, item.id, item)}>
                    {item.name}
                    <div className={item.showChildren ? 'children' : 'children hidden'}>
                    {
                        item.subcategories.length > 0 &&
                        this.renderChildren(item.subcategories)
                    }
                    </div>
                </div>
            )
        });


        this.state.childlessChildren = childlessChildren;

        return render;
    }

    checkIfSubcategoriesHaveSubcategories(subcategories){
        let has = false;

        subcategories.forEach(item => {
            if (item.subcategories.length === 0){
                return ;
            }

            has = true;
        })

        return has;
    }

    renderCategories(){
        let render = [];

        render.push(
            <div key={'all categories'} className={'category'} onClick={this.fetchData}>
                <span>All Categories</span>
            </div>
        );

        this.state.categories.forEach(item => {
            render.push(
                <div key={item.id} className={'category'} onClick={(e) => this.toggleChildren(e, item.id, item)}>
                    <span>{item.name}</span>
                    <div className={item.showChildren ? 'children' : 'children hidden'}>
                        {
                            item.subcategories.length > 0 &&
                            this.renderChildren(item.subcategories)
                        }
                    </div>
                    {
                        this.checkIfSubcategoriesHaveSubcategories(item.subcategories) &&
                        <img className={item.showChildren ? 'rotate' : ''} src={'/images/downArrow.png'} />
                    }
                </div>
            );
        })

        return render;
    }

    renderChildlessChildren(){
        let render = [];

        if (this.state.childlessChildrenDisplay.length === 0 && this.state.selectedParent){
            render.push(
                <div key={this.state.selectedParent.id} className={'childless-category'}>
                    <span className={'name'}>{this.state.selectedParent.name}</span>
                    <span className={'start-price'}>${this.state.selectedParent.min_price}</span>
                    <img src={this.state.selectedParent.category_image ? (process.env.REACT_APP_baseUrl + this.state.selectedParent.category_image.url) : ''} alt={'categoryImage'}/>
                    <Button className={'details'} variant="contained" onClick={() => {
                        this.setState({
                            ...this.state,
                            viewCategory : this.state.selectedParent
                        })
                    }}>
                        View Details
                    </Button>
                    <Button className={'select'} variant="contained" onClick={() => {
                        getBrandsForCategory(this.state.selectedParent.id).then(response => {
                            if (!response.ok){
                                return
                            }

                            if (getUserFromLocalStorage().roles[0] === "ROLE_CUSTOMER"){
                                if (response.data.length !== 0) {
                                    this.props.history.push('/product-selection/' + this.state.selectedParent.id, {
                                        ...this.props.location.state,
                                        importedProduct: this.state.importedProduct
                                    });
                                } else {
                                    this.toggleChildren(null, this.state.selectedParent.id, this.state.selectedParent)
                                }
                            }else{
                                this.props.enqueueSnackbar("Admin can't create customer product variation", {variant: 'error'});
                            }

                        })
                    }}
                    >
                        Select Product
                    </Button>
                </div>
            )

            return render
        }

        this.state.childlessChildrenDisplay.forEach(item => {
            render.push(
                <div key={item.id} className={'childless-category'}>
                    <span className={'name'}>{item.name}</span>
                    <span className={'start-price'}>Starting at ${this.checkPrice(item)}</span>
                    <img src={item.category_image ? (process.env.REACT_APP_baseUrl + item.category_image.url) : ''} alt={'categoryImage'}/>
                    {
                        item.subcategories.length === 0 &&
                        <Button className={'details'} variant="contained" onClick={() => {
                            this.setState({
                                ...this.state,
                                viewCategory : item
                            })
                        }}>
                            View Details
                        </Button>
                    }
                    <Button className={'select'} variant="contained" onClick={() => {
                        getBrandsForCategory(item.id).then(response => {
                            if (!response.ok){
                                return
                            }

                            if(getUserFromLocalStorage().roles[0] === "ROLE_CUSTOMER"){
                                if (response.data.length !== 0) {
                                    this.props.history.push('/product-selection/' + item.id, this.props.location.state);
                                } else {
                                    this.toggleChildren(null, item.id, item)
                                }
                            }else{
                                this.props.enqueueSnackbar("Admin can't create customer product variation", {variant: 'error'});
                            }

                        })
                        }}
                    >
                        Select Product
                    </Button>
                </div>
            )
        })

        return render;
    }

    checkPrice(item){
        if (item.min_price !== 0){
            return item.min_price;
        }

        let lowest;

        item.subcategories.forEach(sub => {
            if (!this.isNumeric(lowest)){
                lowest = sub.min_price;
            }

            if (lowest < sub.min_price){
                return
            }

            lowest = sub.min_price;
        })

        return lowest;
    }

    filterByName(){
        let display = []

        if (this.state.data.categoryName.toLowerCase() == '') { 
            this.setState({
                ...this.state,
                childlessChildrenDisplay : this.state.savedChildlessChildren,
            })
            return
        }

        if (!this.state.toggle) {
            this.state.categories.forEach(category => {
                category.subcategories.forEach(metaCategory => {
                    let name = metaCategory.name.toLowerCase();
                    let search = this.state.data.categoryName.toLowerCase();
        
                    if (!name.includes(search)){
                        return
                    }
        
                    display.push(metaCategory);  
                })
            });
        } else {
            this.state.savedChildlessChildren.forEach(item => {
                let name = item.name.toLowerCase();
                let search = this.state.data.categoryName.toLowerCase();
    
                if (!name.includes(search)){
                    return
                }
    
                display.push(item);
            });
        }
   
        this.setState({
            ...this.state,
            childlessChildrenDisplay : display
        })
    }

    changeData(event, data = 'data') {

        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} })
        }, () => {
            this.filterByName();
        });
    }

    closePopup(){
        this.setState({
            ...this.state,
            viewCategory : {}
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.importedProduct !== null &&
                    <InfoBarImportedProduct image={this.state.importedProduct.image} name={this.state.importedProduct.name} variants={this.state.importedProduct.variants.map(element => element.attributes)}/>
                }
                <div className={'product-catalog container'}>
                    <div className={'search'}>
                        <input className={'category-search'}
                               value={this.state.data.categoryName}
                               onChange={this.changeData}
                               name={'categoryName'}
                               placeholder={'Type to search...'}
                               disabled={this.state.childlessChildrenDisplay.length === 0 && this.state.data.categoryName === ''}
                        />
                        <img src={'/images/search.png'} />
                    </div>
                    <div className={'bottom'}>
                        <div className={'categories'}>
                            {this.renderCategories()}
                        </div>
                        <div className={'childless-categories'}>
                            {this.renderChildlessChildren()}
                        </div>
                    </div>
                    {
                        this.state.viewCategory.id &&
                        <CategoryPopup closePopup={this.closePopup} category={this.state.viewCategory}/>
                    }
                </div>
            </div>
        );
    }
}

export default withSnackbar(withRouter(ProductCatalog))
