import FormComponent from "../common/FormComponent";
import {withRouter} from "react-router-dom";
import React from "react";
import {Button, TextField} from "@material-ui/core";
import {getCurrentUserAction, setStoreUrl} from "../services/UserService";
import update from "immutability-helper";
import {withSnackbar} from "notistack";
import {setUserToLocalStorage} from "../base/OAuth";

class SetupInstructions extends FormComponent {
    constructor() {
        super();

        this.state = {
            data : {}
        }

        this.changeDataStore = this.changeDataStore.bind(this)
    }

    componentDidMount() {
        getCurrentUserAction().then(response => {
            if (!response.ok){
                return
            }

            setUserToLocalStorage(response.data.user);

            this.setState({
                connectedShopify : response.data.user.customer.shopify_access_token,
                storeUrl : response.data.user.customer.store_url,
                data : {
                    storeUrl : response.data.user.customer.store_url
                }
            })
        })
    }

    changeDataStore(event, data = 'data') {
        let value = event.target.value;
        value = value.replace('https://','');
        value = value.replace('/admin','');
        if(value.substr(-1) === '/') {
            value = value.substr(0, value.length - 1);
        }

        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: value} })
        });
    }

    saveStoreUrl(){
        setStoreUrl({storeUrl : this.state.data.storeUrl}).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                storeUrl : this.state.data.storeUrl
            })
            this.props.enqueueSnackbar('Successfully saved', { variant: 'success' });
        })
    }

    render() {
        return <div className={'setup-instructions'}>
            <h3>Setup Instructions</h3>
            <h4>Let’s get started by following this guide</h4>
            <div className={'steps'}>
                <div className={'item'}>
                    <div className={'step'}>
                        <label className={'stepLabel'}>Step 1</label>
                        <img src={'/images/splogo.png'}/>
                    </div>
                    <div className={'checkpoint special'}>
                        {
                            this.state.storeUrl &&
                            <img src={'/images/checked-step.png'}/>
                        }
                        {
                            !this.state.storeUrl &&
                            <img src={'/images/unchecked-step.png'}/>

                        }
                        <div className={'helper'}>
                            <label>Add your Shopify store URL here:</label>
                            <div className={'text-help'}>
                                <TextField
                                    className={"search-field"}
                                    autoFocus
                                    name='storeUrl'
                                    onChange={ this.changeDataStore }
                                    margin="normal"
                                    value={ this.state.data.storeUrl }
                                    placeholder = { "Store URL" }
                                    variant="outlined"
                                />
                                <Button variant="contained" color="primary" onClick={()=> this.saveStoreUrl()}>Save</Button>
                            </div>
                            <label>
                                If there is any problem with connecting your store, make sure you have entered the right format of the store URL - example.myshopify.com or example.com (any additional character in URL beside the address may cause an issue).
                            </label>
                            <br/>
                            <label>If you're being redirected here after you've added Shopify store URL and completed Step 2 please check again and update your Shopify store field with the correct URL.
                                If you need further assistance, please contact us and our lovely support team will help you immediately - <a href="mailto: support@surgeprint.com">support@surgeprint.com</a>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={'item'}>
                    <div className={'step'}>
                        <label className={'stepLabel'}>Step 2</label>
                        <img src={'/images/shopifylogo.png'} />
                    </div>
                    <div className={'checkpoint getApp'}>
                        {
                            this.state.connectedShopify &&
                            <img src={'/images/checked-step.png'}/>
                        }
                        {
                            !this.state.connectedShopify &&
                            <img src={'/images/unchecked-step.png'}/>
                        }
                        <label>Click the Get the SurgePrint: </label> <br />
                        <label>
                            <a href="https://apps.shopify.com/surge-print" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" color="primary">Get the SurgePrint</Button>
                            </a>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    }
}


export default withSnackbar(withRouter(SetupInstructions));
