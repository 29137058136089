import React from 'react';
import { withRouter } from 'react-router-dom';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Tabs from 'src/redesign/components/Tabs';
import AboutProduct from './AboutProduct';
import SkuProduct from './Sku';

import './style.scss';

const TABS = {
  ABOUT: 'about',
  SKU: 'sku'
}

const ProductDetailsPage = ({ history, match: { params: { tab, id }} }) => {
  if(tab != TABS.ABOUT && tab != TABS.SKU) {
    history.push('/category/-1')
  }

  const [selectedTab, setSelectedTab] = React.useState(tab);

  const onTabClick = tab => {
    setSelectedTab(tab);
    history.push(`/product/${tab}/${id}`);
  }

  return (
    <ApplicationPage selectedTab='admin'>
      <ApplicationPageContent title='Products'>
        <div className='product-details-page'>
          <Tabs
            first={{
              text: 'About Product',
              isSelected: selectedTab == TABS.ABOUT,
              onClick: () => onTabClick(TABS.ABOUT)
            }}
            second={{
              text: `SKU's`,
              isSelected: selectedTab == TABS.SKU,
              onClick: () => onTabClick(TABS.SKU)
            }}
          />
          {tab == TABS.ABOUT
          ? <AboutProduct />
          : <SkuProduct />}
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(ProductDetailsPage);