import React from 'react';
import classNames from 'classnames';
import { getUserFromLocalStorage } from 'src/base/HTTP';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import { v4 as uuid } from 'uuid';

import './style.scss';
import YoutubeVideo from 'src/redesign/components/YoutubeVideo';

const HomePage = ({ }) => {
  const [selectedStep, setSelectedStep] = React.useState(0);

  const user = getUserFromLocalStorage();
  const maxSteps = 5;

  const steps = [
    'Design your first product',
    'Connect your Store',
    'Add Product to your store',
    'Set Up billing',
    'Order Samples'
  ];

  return (
    <ApplicationPage
      selectedTab='home'
      className='home-page-container'
      seoTitle='Personal Account | Surge Print'
      seoDescription={`Personal Account. SurgePrint offers high-quality, customizable printing solutions for a lasting impression. Unleash the power of print excellence!`}
    >
      <ApplicationPageContent title='Home'>
        <div className='home-page-content'>
          <div className='home-page-title'>
            Welcome, {user?.['first_name']} {user?.['last_name']}!
          </div>
          <div className='home-page-steps-title'>
            Your next steps
          </div>
          <div className='home-page-setup-title'>
            Set up your business, earn rewards, and start making money with Surge Print
          </div>
          <div className='home-page-steps-row'>
            {Array.from({ length: maxSteps }).map((_x, i) => (
              <div key={uuid()} className={classNames('step', {'step-selected': i <= selectedStep})} onClick={() => setSelectedStep(i)}>
              </div>
            ))}
          </div>
          <div className='home-page-steps-description'>
            <div className='home-page-steps-column'>
              {steps.map((x, i) => (
                <div key={uuid()} className='home-page-step-description' onClick={() => setSelectedStep(i)}>
                  <div className={classNames('home-page-step-number', {'home-page-step-number-selected': i <= selectedStep })}>
                    {i + 1}
                  </div>
                  <div className='home-page-step-description-text'>
                    {x}
                  </div>
                </div>
              ))}
            </div>
            <YoutubeVideo
              embedId="zgIRE8N6zDE"
              className='home-page-video'
            />
          </div>
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default HomePage;