import React from 'react';
import classNames from 'classnames';
import { oneOfType, string, node, func, bool, number, object } from 'prop-types';
import Loader from 'src/redesign/components/Loader';
import CustomTooltip from 'src/redesign/components/CustomTooltip';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const SubmitPrimaryButton = ({ text, onClick, disabled, className, loading, width, tooltipText, tooltipPlacement, style = { }, isResponsive = false}) => {
  return <button className={classNames("submit-button-primary", className, { 'submit-button-disabled-primary': disabled, 'submit-button-primary-responsive': isResponsive })} style={{ width, minWidth: width, ...style }} disabled={disabled} onClick={onClick}>

    {loading ? <Loader width={20} height={20} color={colors.white}/> : (
      tooltipText
        ? 
        <CustomTooltip text={tooltipText} placement={tooltipPlacement}>
          {text}
        </CustomTooltip>
        : text
    )}
  </button>
};

SubmitPrimaryButton.propTypes = {
  text: oneOfType([string, node]),
  onClick: func,
  className: string,
  disabled: bool,
  loading: bool,
  width: number,
  style: object
}

export default SubmitPrimaryButton;