import React, {Component} from 'react'

import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import { getOrderStatusString } from "../../constants/OrderStatus";
import AttributeType from "../../constants/AttributeType";

class PrintReady extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order,
            orderItems: this.props.order.items,
            itemSKUs: {}
        }
    }

    componentDidMount(){
        let itemSKUs = {};

        this.state.orderItems.forEach(item => {
            itemSKUs[item.id] = item.sku;
        });

        this.setState({
            ...this.state,
            itemSKUs: itemSKUs
        });
    }



    renderAttributes(attributes) {
        let result = [];
        for (let item of attributes) {
            result.push(
                <div className="info">
                    <div className="left">
                        { item.name }:
                    </div>
                    <div className="right">
                        { item.value }
                    </div>
                </div>
            );
        }

        return result;

    }

    renderMergedImage(item){

        let mockup = item.image ?? "/images/no-image.png";
        let artwork = item.artwork ?? "/images/no-image.png";

        let render = [];

        render.push(
            <>
                <div className="image-merged">
                    <div className="img" style={{ background: "url(" + mockup + ") no-repeat center center"}} />
                    <a  href={mockup}
                        className="download-link"
                        download
                    >Download Mockup</a>
                </div>
                <div className="image-merged">
                    <div className="img" style={{ background: "url(" + artwork + ") no-repeat center center"}} />
                    <a  href={artwork}
                        download
                        className="download-link"
                    >Download Design</a>
                </div>
            </>
        )

        return render;
    }

    renderItems() {
        let result = [];

        let i = 0;
        for(let item of this.state.orderItems) {
            result.push(

                <div className="row">
                    <div className="details">
                        <div className="details-header">
                            Item { item.id }
                        </div>
                        <div className="image-merged-and-artwork">
                            <div className={'help'}>
                                {this.renderMergedImage(item)}
                            </div>
                        </div>
                    </div>

                    <div className="details">
                        <div className="details-header">
                            Item { item.id }
                        </div>
                        <div className="partner-id">Partner Order Item ID: { item.id }</div>
                        <div className="partner-id">Vendor Order ID:N/A</div>
                        <div className="info" style={{marginTop:"25px"}}>
                            <div className="left">
                                Status:
                            </div>
                            <div className="right">
                                { getOrderStatusString(item.status) } 
                            </div>
                        </div>

                        <div className="info">
                            <div className="left">
                                SKU:
                            </div>
                            <div className="right">
                                {this.state.itemSKUs[item.id]}
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Dimension:
                            </div>
                            <div className="right">
                                {/*TODO: add dropzone in endpoint*/}
                                {/*{ item.customer_product_variation.product_variation.dropzone_width }X{ item.customer_product_variation.product_variation.dropzone_height }*/}
                            </div>
                        </div>
                        { this.renderAttributes(item.attributes) }
                    </div>
                </div>
            );

            i++;
        }
        return result;
    }

    render() {

        return (
            <div id="item">
                { this.renderItems() }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintReady)));
