import strings from "../localization";

const OrderStatus = [
    {
        value: 0,
        name: 'New'
    },
    {
        value: 1,
        name: 'Needs Customization'
    },
    {
        value: 2,
        name: 'Ready For Image DI'
    },
    {
        value: 3,
        name: 'Needs Manual Approval'
    },
    {
        value: 4,
        name: 'Pending'
    },
    {
        value: 5,
        name: 'Test'
    },
    {
        value: 6,
        name: 'Hold'
    },
    {
        value: 7,
        name: 'Canceled'
    },
    {
        value: 8,
        name: 'Ready For Accounting'
    },
    {
        value: 9,
        name: 'Ready For Print'
    },
    {
        value: 10,
        name: 'In Production'
    },
    {
        value: 11,
        name: 'Manually In Production'
    },
    {
        value: 12,
        name: 'Printing'
    },
    {
        value: 13,
        name: 'Cancel Refunded'
    },
    {
        value: 14,
        name: 'Billed Refunded'
    },
    {
        value: 15,
        name: 'Shipped'
    },
    {
        value: 16,
        name: 'Out For Delivery'
    },
    {
        value: 17,
        name: 'Delivered'
    },
    {
        value: 18,
        name: 'Pre Payment'
    },
    {
        value: 19,
        name: 'Payment Issue'
    },
    {
        value: 20,
        name: 'Order Status'
    },
    {
        value: 21,
        name: 'Image Issue'
    },
    {
        value: 22,
        name: 'Hold By Image Issue'
    },
    {
        value: 23,
        name: 'Address Issue'
    },
    {
        value: 24,
        name: 'Order Data Issue'
    },
    {
        value: 25,
        name: 'Vendor API Issue'
    },
    {
        value: 26,
        name: 'Shipping Issue'
    },
    {
        value: 27,
        name: 'Unfinished'
    },
    {
        value: 28,
        name: 'Draft'
    },
    {
        value: 29,
        name: "Payment Failed"
    },
    {
        value: 30,
        name: "Garments Received"
    },
    {
        value: 31,
        name: "Partially Received"
    }
];

export function getOrderStatusString(type) {

    switch(type) {
        case 0: return strings.orderStatus.newOrder;
        case 1: return strings.orderStatus.needsCustomization;
        case 2: return strings.orderStatus.readyForImageDI;
        case 3: return strings.orderStatus.needsManualApproval;
        case 4: return strings.orderStatus.pending;
        case 5: return strings.orderStatus.test;
        case 6: return strings.orderStatus.hold;
        case 7: return strings.orderStatus.canceled;
        case 8: return strings.orderStatus.readyForAccounting;
        case 9: return strings.orderStatus.readyForPrint;
        case 10: return strings.orderStatus.inProduction;
        case 11: return strings.orderStatus.manuallyInProduction;
        case 12: return strings.orderStatus.printing;
        case 13: return strings.orderStatus.cancelRefunded;
        case 14: return strings.orderStatus.billedRefunded;
        case 15: return strings.orderStatus.shipped;
        case 16: return strings.orderStatus.outForDelivery;
        case 17: return strings.orderStatus.delivered;
        case 18: return strings.orderStatus.prePayment;
        case 19: return strings.orderStatus.paymentIssue;
        case 20: return strings.orderStatus.orderStatus;
        case 21: return strings.orderStatus.imageIssue;
        case 22: return strings.orderStatus.holdByImageIssue;
        case 23: return strings.orderStatus.addressIssue;
        case 24: return strings.orderStatus.orderDataIssue;
        case 25: return strings.orderStatus.vendorApiIssue;
        case 26: return strings.orderStatus.shippingIssue;

        case 27: return strings.orderStatus.unfinished;
        case 28: return strings.orderStatus.draft;
        case 29: return strings.orderStatus.cardDeclined;
        case 30: return strings.orderStatus.garmentsReceived;
        case 31: return strings.orderStatus.partiallyReceived;
    }
}

export function isNewOrder(status) {
   return status === 0;
}

export default OrderStatus;