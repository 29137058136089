import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import TextInput from 'src/redesign/components/Form/Sidepane/TextInput';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import Pagination from 'src/redesign/components/Pagination';
import Table from 'src/redesign/components/Table';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Sidepane from 'src/redesign/components/Sidepane';
import Menu from 'src/redesign/components/Menu';
import IconTrash from 'src/redesign/components/Icon/Trash';
import IconEdit from 'src/redesign/components/Icon/Edit';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import * as Validators from 'src/redesign/util/validators';

import { createVendor, deleteVendor, getVendors, updateVendor } from 'src/services/VendorService';
import { dateToString } from 'src/util/DateUtil';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const PER_PAGE = 8;

const VendorsPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [vendors, setVendors] = React.useState([]);

  const [isAddSidepaneOpen, setIsAddSidepaneOpen] = React.useState(false);
  const [vendorToEdit, setVendorToEdit] = React.useState(null);
  const [saving, setSaving] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, [currentPage]);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await getVendors({ page: currentPage, perPage: PER_PAGE });

      setVendors(data.result);
      setTotalPages(Math.ceil(data.total/PER_PAGE));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load vendors', false);
    } finally {
      setLoading(false);
    }
  }

  const onAddEditVendor = async ({ name }) => {
    setSaving(true);

    try {
      await ( isAddSidepaneOpen ? createVendor({ name }) : updateVendor({ name, id: vendorToEdit.id }));

      openSnackBar(`Vendor successfully ${isAddSidepaneOpen ? 'added' : 'edited'}`);
      setIsAddSidepaneOpen(false);
      setVendorToEdit(null);
      setTimeout(load, 1000);
    } catch(error) {
      console.error(error);
      openSnackBar(`Failed to ${isAddSidepaneOpen ? 'add' : 'edit'} vendor`, false);
    } finally {
      setSaving(false);
    }
  }

  const onDeleteVendor = async (id) => {
    try {
      await deleteVendor({ id });
      
      openSnackBar('Vendor sucessfully deleted');
      setTimeout(load, 1000);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to delete vendor', false);
    }
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Created',
      accessor: 'date_created',
      Cell: ({ row: { original: { date_created }}}) => <>{dateToString(date_created)}</>
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ row: { original }}) => (
        <Menu
          width={4}
          height={16}
          options={[
            {
              key: 'edit',
              text: <>
                Edit
                <IconEdit width={24} height={24} color={colors.primary} />
              </>,
              onSelect: () => setVendorToEdit(original)
            },
            {
              key: 'delete',
              text: <>
                Delete
                <IconTrash width={24} height={24} color={colors.primary} />
              </>,
              onSelect: () => onDeleteVendor(original.id)
            }
          ]}
        />
      )
    }
  ]

  return (
    <ApplicationPage selectedTab='admin'>
      <ApplicationPageContent title='Vendors'>
        <div className='vendors-page'>
          <PrimarySubmitButton
            text='Create new vendor'
            onClick={() => setIsAddSidepaneOpen(true)}
            disabled={loading}
          />
          {loading
          ? <Loader width={200} height={200} />
          : (
            <div>
              <Table
                columns={columns}
                data={vendors}
              />
              <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages}/>
            </div>
          )}
        </div>
        {(isAddSidepaneOpen || vendorToEdit != null) && (
          <Sidepane
            title={isAddSidepaneOpen ? 'Add new vendor' : 'Edit vendor'}
            content={
              <FinalForm
                onSubmit={onAddEditVendor}
                initialValues={{
                  name: vendorToEdit?.name
                }}
                render={({ handleSubmit }) => (
                  <form id="addEditVendor" onSubmit={handleSubmit}>
                    <TextInput
                      name="name"
                      placeholder="Name"
                      validate={Validators.required(' ')}
                    />
                  </form>
                )}              
              
              />
            }
            actions={
              <PrimarySubmitButton
                text='Save'
                onClick={() =>
                  document.getElementById('addEditVendor')
                  .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                }
                width={160}
                height={40}
                disabled={saving}
                loading={saving}
              />
            }
            onClose={() => 
              isAddSidepaneOpen
              ? setIsAddSidepaneOpen(false)
              : setVendorToEdit(null)
            }
          />
        )}
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default VendorsPage;