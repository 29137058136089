import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getImportedProduct, matchImportedVariations } from 'src/services/admin/ProductService';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import StepNumber from 'src/redesign/components/StepNumber';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import TemplatesDropdown from 'src/redesign/components/Dropdown/TemplatesDropdown';
import { getCustomerTemplate, getUnMappedTemplates } from 'src/services/admin/ProductVariationService';
import List from 'src/redesign/components/List';
import Checkbox from 'src/redesign/components/Checkbox';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import fonts from 'src/scss/_fonts.scss';

const ImportProducts = ({ match: historyMatch, history }) => {
  const store = historyMatch.params.store;
  const id = historyMatch.params.id;

  const [loading, setLoading] = React.useState(true);
  const [product, setProduct] = React.useState(null);
  const [unusedTemplates, setUnusedTemplates] = React.useState([]);

  const [selectedUnusedTemplateId, setSelectedUnusedTemplateId] = React.useState(null);
  const [selectedUnusedTemplateData, setSelectedUnusedTemplateData] = React.useState(null);
  const [loadingCustomerTemplate, setLoadingCustomerTemplate] = React.useState(false);
  const [selectedVariant, setSelectedVariant] = React.useState(null);

  const [matching, setMatching] = React.useState({});
  const [matchingInProgress, setMatchingInProgress] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    if(selectedUnusedTemplateId)
      loadCustomerTemplate();
  }, [selectedUnusedTemplateId]);

  const load = async () => {
    setLoading(true);
    try {
      const { data: importedProduct } = await getImportedProduct(store, id);
      setProduct(importedProduct);

      const { data: unmappedTemplates } = await getUnMappedTemplates();
      setUnusedTemplates(unmappedTemplates);

      const templateId = localStorage.getItem('selectedTemplateId');

      if(templateId) {
        setSelectedUnusedTemplateId(templateId);
        localStorage.removeItem('selectedTemplateId');
      }
      
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load products!', false);
    } finally {
      setLoading(false);
    }
  }

  const loadCustomerTemplate = async () => {
    setLoadingCustomerTemplate(true);
    try {
      const { data } = await getCustomerTemplate(selectedUnusedTemplateId);

      setSelectedUnusedTemplateData(data);
    } catch(error) {
      openSnackBar('Failed to load customer template!', false);
    } finally {
      setLoadingCustomerTemplate(false);
    }
  }

  const finalizeMatching = () => {
    setMatchingInProgress(true);
    try {
      matchImportedVariations(store, {
        id,
        matching
      });

      openSnackBar('Successfully matched!');
      history.push("/product-templates/storage");
    } catch(error) {
      openSnackBar('Failed to finalize matching', false);
    } finally {
      setMatchingInProgress(false);
    }
  }

  const match = id => {
    const selectedVariantId = selectedVariant.id;

    setMatching(prev => ({
      ...prev,
      [id]: selectedVariantId
    }));
    setSelectedVariant(null);
  }

  const isVariationMatched = id => Object.values(matching).some(x => x === id);

  const isTemplateMatched = id => Object.keys(matching).some(x => x === id?.toString());

  const isMatchingEmpty = Object.keys(matching)?.length === 0;

  return (
    <ApplicationPage selectedTab='product-library'>
      <ApplicationPageContent title='Product Library'>
        {loading || !product ? <Loader width={300} height={300} color={colors.primary}/>
        : (
          <div className='import-products-container'>
            <div className='import-products-product-container'>
              <div className='import-products-product'>
                <div className='product-title'>
                  Product
                </div>
                <Image alt={getImageAlt()} className='product-image' src={product.image}/>
              </div>
              <div className='import-products-steps'>

                <div className='import-products-row' style={{ alignItems: 'baseline' }}>
                  <StepNumber number={1} completed={false} />
                  <div className='import-products-text'>
                    <div className='import-products-text-16'>
                      Link your product to one of our products for automatic order fulfilment
                    </div>
                    <div className='import-products-text-14'>
                      You can create a new SurgePrint product to match it with Shopify product or select one of the SurgePrint products you have already created in Product Library by selecting its product template.
                    </div>
                  </div>
                  
                </div>

                <div className='import-products-actions'>
                  <SubmitButton text='+ Create Product' className='import-products-button' onClick={() => history.push('/product-catalog-in-app/all',{
                    importedProduct: {
                      id,
                      variants: product.variations,
                      name: product.name,
                      image: product.image
                    }
                  } 
                  )} widht={473}/>
                  OR
                  <TemplatesDropdown
                    selectedOption={selectedUnusedTemplateData?.name}
                    setSelectedOption={(x) => setSelectedUnusedTemplateId(x?.id)}
                    options={unusedTemplates}
                    onRenderOption={({ id, image, name, sku, mappedVariations, totalVariations }) => {
                      const isSelected = selectedUnusedTemplateId === id;
                      return (
                        <div className='templates-dropdown-option'>
                          <Image alt={getImageAlt()} className='templates-dropdown-image' src={image} />
                          <div className='templates-dropdown-text-container'>
                            {isSelected 
                            ? <b className='template-dropdown-text'>{name}</b>
                            : <div className='templates-dropdown-text'>
                                {name}
                              </div>
                            }
                            {isSelected
                            ? <b className='template-dropdown-text'>{name}</b>
                            : <div className='templates-dropdown-text'>
                              {sku}
                              </div>
                            }
                            <div className='templates-dropdown-sku-container'>
                              <div className='templates-dropdown-sku-text'>
                                SKUs
                              </div>
                              <div className='templates-dropdown-sku-text'>
                              {mappedVariations}/{totalVariations}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />
                </div>

                <div className='import-products-row'>
                  <StepNumber number={2} completed={false} />
                  Next: Sync your Variants
                </div>

                <div className='import-products-details'>
                  <div className='import-products-details-row'>
                    <div className='import-products-details-text'>
                      Product name
                    </div>
                    <div className='import-products-details-text'>
                      {product.name}
                    </div>
                  </div>
                  <div className='import-products-details-row'>
                    <div className='import-products-details-text'>
                      SKU's
                    </div>
                    <div className='import-products-details-text'>
                      {product.numberOfMappedVariations}/{product.numberOfVariations}
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className='import-products-variants-title'>
              Variants:
            </div>

            <div className='import-products-variants-list'>
              {product.variations.map(x => (
                <div key={x.id} className={classNames('import-products-variant', {'import-products-variant-selected': x === selectedVariant})} onClick={() => x.mapped || isVariationMatched(x.id) ? undefined : setSelectedVariant(x)} style={{ cursor: x.mapped || isVariationMatched(x.id) ? 'default' : 'pointer' }}>
                  <Image alt={getImageAlt()} className='import-products-image' src={x.image}/>
                  <div className='import-products-title'>
                    {x.sku}
                  </div>
                  <div className='import-product-content'>
                    <div className='import-products-description'>
                      {x.attributes}
                    </div>
                    <div className='import-products-details-syncd' style={{ backgroundColor: x.mapped || isVariationMatched(x.id) ? colors.primary : colors.white }}>
                      {x.mapped || isVariationMatched(x.id) ? `Sync'd` : ''}
                    </div>
                  </div>
                </div>
              ))}
            </div>

              <div className='import-products-selected-unused-template'>
                <div className='import-products-selected-title'>
                  <div className='import-products-selected-title-main'>
                    Select a match for: 
                  </div>
                  <div className='import-products-selected-title-description'>
                    {selectedVariant?.sku}
                  </div>
                </div>

              {loadingCustomerTemplate ? (
                <Loader width={200} height={200} color={colors.primary} />
              ) : selectedUnusedTemplateId && selectedUnusedTemplateData && (
                <List
                  columns={[
                    {
                      text: '',
                      flex: '15%',
                      onRender: x => {
                        const templateId = x?.customerProductVariation?.id;
                        const isMapped = x?.mapped;

                        return (<Checkbox checked={isMapped || isTemplateMatched(templateId)} disabled={isMapped || isTemplateMatched(templateId)} onChange={()=> match(templateId)} style={{ marginLeft: 22 }}/>);
                      }
                    },
                    {
                      text: 'Mockup image',
                      flex: '20%',
                      onRender: x => <Image alt={getImageAlt()} src={x?.image} style={{ width: 60, height: 90, objectFit: 'cover' }} />
                    },
                    {
                      text: 'Product Name',
                      flex: '30%',
                      onRender: x => <div style={{ fontSize: 16, lineHeight: '16px', color: colors.black07 }}>{x.name}</div>
                    },
                    {
                      text: 'Attributes',
                      flex: '20%',
                      onRender: x => <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {x?.attributes?.map(({ name, value }) => (
                          <div style={{ fontSize: 16, lineHeight: '16px', color: colors.black07 }}>
                            {name} - {value}
                          </div>
                        ))}
                      </div>
                    },
                    {
                      text: 'Price',
                      flex: '15%',
                      onRender: x => <span style={{ color: colors.green, fontFamily: fonts.main, fontWeight: 600 }}>${x?.customerProductVariation?.price}</span>
                    }
                  ]}
                  items={selectedUnusedTemplateData?.variations ?? []}
                />
              )}

              <SubmitButton text='Finalize Matching' disabled={isMatchingEmpty} loading={matchingInProgress} onClick={finalizeMatching} width={200}/>
              </div>
          </div>
        )}
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(ImportProducts);