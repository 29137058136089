import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import Sidepane from 'src/redesign/components/Sidepane';
import TextInput from 'src/redesign/components/Form/Sidepane/TextInput';
import CheckboxInput from 'src/redesign/components/Form/Checkbox';
import MultiSelectInput from 'src/redesign/components/Form/Sidepane/MultiSelectInput';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Loader from 'src/redesign/components/Loader';
import Image from 'src/redesign/components/Image';
import * as Validators from 'src/redesign/util/validators';
import { getImageAlt } from 'src/redesign/helpers';

import { getMediaModifierMockups } from 'src/services/MediaModifierService';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const CreateEditMockupSidepane = ({ title, onClose, onSubmit, initialValues, saving, }) => {
  const [loading, setLoading] = React.useState(false);
  const [scenes, setScenes] = React.useState([]);
  const [image, setImage] = React.useState(initialValues?.imagePath);

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await getMediaModifierMockups({
        page: 1,
        perPage: 1000
      });

      setScenes(data.data.map(x => ({ key: x.id, text: x.name })));
    } catch(error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const onFilePick = event => {
    const file = event.target.files[0];
    setImage(file);
    var oFReader = new FileReader();
    oFReader.readAsDataURL(document.getElementById("mockupMainImage").files[0]);

    oFReader.onload = function (oFREvent) {
        document.getElementById("mockupPreview").src = oFREvent.target.result;
    };
  }

  return (
    <Sidepane
      title={title}
      content={
        loading 
        ? <Loader width={200} height={200} color={colors.primary} />
        : 
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form id="addEditMockup" className='mockup-form' onSubmit={handleSubmit}>
              <TextInput
                name="name"
                label='Mockup Name'
                className='mockup-form-input'
                validate={Validators.required(' ')}
              />
              <TextInput
                name="mediaModifierId"
                label='Media Modifier ID'
                className='mockup-form-input'
                type='number'
                validate={Validators.required(' ')}
              />
              <div className='mockup-form-use-color'>
                <div className='mockup-form-checkbox-label'>
                  Use variation color
                </div>
                <CheckboxInput
                  name='useColorFromVariation'
                />
              </div>
              <div className='mockup-form-scenes-column'>
                <div className='mockup-form-scenes-label'>
                  Select media modifier related scenes
                </div>
                <MultiSelectInput
                    name='scenes'
                    options={scenes}
                    placeholder='Select...'
                />
              </div>
              <TextInput
                name='layers'
                className='mockup-form-textarea'
                type='textarea'
                placeholder='Layers'
              />
              <div className='upload-image-container'>
                <div className='upload-image-label'>
                  Upload Mockup image
                </div>
                <input type="file" id="mockupMainImage" name="mockupMainImage" onChange={onFilePick} />
                <Image alt={getImageAlt()} id="mockupPreview" className='mockup-form-image' src={image ? process.env.REACT_APP_baseUrl + image : '/images/image-placeholder.png'} />
              </div>
            </form>
          )}
        />
      }
      actions={
        <PrimarySubmitButton
          text='Save'
          onClick={() => document.getElementById('addEditMockup')
          .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
          }
          width={160}
          height={40}
          disabled={saving}
          loading={saving}
        />
      }
      onClose={onClose}
    />
  )
}

export default CreateEditMockupSidepane;