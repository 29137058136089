import React from 'react';
import {Button, TextField} from "@mui/material";
import { Editor } from '@tinymce/tinymce-react';

import strings from '../localization';
import {getError} from "../functions/Validation";
import SelectControl from './controls/SelectControl';
import Dropzone from 'react-dropzone';
import { getImage } from '../services/ImageService';
import {Cancel as CancelIcon} from '@mui/icons-material';


const AddCategory = ({
    onChange,
    data,
    errors,
    submited,
    goBack,
    onDropPicture,
    categories,
    addGalleryImage,
    onDropGalleryPicture,
    deleteGalleryPicture
}) => {

    return(
        <div id="add-category">
            <div className="header">
            { strings.category.addProductCategory }
            <span className={'exit'} onClick={goBack}>
                ✕
            </span>
            </div>
            <div className="form">
                <div className="content">
                    <TextField
                        name='name'
                        onChange={ onChange }
                        className={"category-name"}
                        type='text'
                        value={ data.name }
                        error={ getError(errors, 'name') }
                        helperText={ getError(errors, 'name') }
                        placeholder = { "Name" }
                        variant="outlined"
                    />
                    <br/>

                    <Editor
                        multiline
                        name='description'
                        className={'description p'}
                        rows={4}
                        variant="outlined"
                        value={data.description}
                        onEditorChange={ value => onChange({target: { name: 'description', value }})}
                        apiKey="48o9r3u3ya3nb7ngsqx2b1c3oq4xui6hjskuqpss8nkb233e"
                        init={{
                          menubar: false,
                          toolbar: false,
                          placeholder: 'Enter description'
                        }}
                    />
                    <p className="error">{getError(errors, 'categoryImage')}</p>

                  
                    <SelectControl
                        options={categories}
                        valueKey = { 'id' }
                        onChange={ onChange }
                        nameKey= { 'name' }
                        name = {'parentCategory'}
                        selected={ data.parentCategory }
                        placeholder={"Parent Category"}
                        isClearable={true}
                    />

                    <div className="category-image">
                        <Dropzone onDrop={(accepted) => onDropPicture(accepted)} >
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()} className="img-img">
                                        <input {...getInputProps()}/>
                                        <div className={'img-contain'}>
                                            {
                                                data.categoryImage && 
                                                <img src={getImage(data.categoryImage)}></img>
                                            }
                                            {
                                                !data.categoryImage &&
                                                <img src="/images/picture.png" style={{width: "unset", height: "unset"}}></img>
                                            }
                                        </div> 
                                        {
                                            !data.emailImage && 
                                            <p>{ strings.category.uploadMainImage }</p>
                                        }         
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <p className="error">{getError(errors, 'categoryImage')}</p>
                    </div>

                    {data.gallery.map((slot, i) => (
                        <div className="category-image">
                            <Dropzone onDrop={(accepted) => onDropGalleryPicture(accepted, i)} >
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()} className="img-img">
                                            <input {...getInputProps()}/>
                                            <div className={'img-contain'}>
                                                {
                                                    data.gallery[i] &&
                                                    <img src={getImage(data.gallery[i])}></img>
                                                }
                                                {
                                                    data.gallery[i] &&
                                                    <CancelIcon className={'delete-gallery-image'}
                                                                onClick={(event) => deleteGalleryPicture(event, data.gallery[i], i)}>
                                                    </CancelIcon>
                                                }
                                                {
                                                    !data.gallery[i] &&
                                                    <img src="/images/picture.png" style={{width: "unset", height: "unset"}}></img>
                                                }

                                            </div>
                                            {
                                                !data.emailImage &&
                                                <p>{ strings.category.uploadGalleryImage }</p>
                                            }
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    ))}

                    <div className="buttons">
                        <Button className="add-gallery" onClick={addGalleryImage}>{ strings.category.addGalleryImage }</Button>
                        <Button className="save" onClick={submited}>{ strings.category.save }</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCategory;