import React from 'react';
import classNames from 'classnames';
import TextInput from 'src/redesign/components/Form/TextInput';

import './style.scss';

const PriceContainer = ({ price, description, withBorder = false, editMode }) => {

  return (
    <div className={classNames('price-container', { 'price-container-bordered': withBorder })}>
      {editMode ? (
        <TextInput className={classNames('price', 'price-input')} name='price' type='number'/>
      ) : (
      <div className='price'>
        ${price?.toFixed(2)}
      </div>
      )}
      <div className='price-description'>
        {description}
      </div>
    </div>
  )
}

export default PriceContainer;