import React from 'react';
import classNames from 'classnames';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import TextInput from 'src/redesign/components/Form/TextInput';
import SelectInput from 'src/redesign/components/Form/SelectInput';
import * as Validators from 'src/redesign/util/validators';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Loader from 'src/redesign/components/Loader';
import { getCountryList } from 'src/services/CountryService';
import { calculateDynamicShoppingCartSummary, getShoppingCartItems } from 'src/services/ShoppingCart';
import SummaryItem from './components/SummaryItem';
import Pagination from 'src/redesign/components/Pagination';
import Button from 'src/redesign/components/Buttons/SubmitPrimary';
import Helmet from 'react-helmet';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import { checkoutOrder, createOrder } from 'src/services/OrderService';
import { withRouter } from 'react-router';
import { getPrimaryMethod } from 'src/services/BillingInformation';
import { mapPaymentInformation } from './helpers';

const PER_PAGE = 5;

const Checkout = ({ history }) => {
  const [countries, setCountries] = React.useState([]);
  const [countryOptions, setCountryOptions] = React.useState([]);

  const [isListLoading, setIsListLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [items, setItems] = React.useState([]);

  const [isTotalLoading, setIsTotalLoading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [shipping, setShipping] = React.useState(0);
  const [standard, setStandard] = React.useState(0);
  const [expedited, setExpedite] = React.useState(0);
  const [overnight, setOvernight] = React.useState(0);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    getCountries();
    getTotal();
  }, []);

  React.useEffect(() => {
    getItems(currentPage);
  }, [currentPage]);

  const getCountries = async () => {

    try {
      const { data: { countries }} = await getCountryList();

      setCountries(countries);
      setCountryOptions(countries.map(x => ({ key: x.id, text: x.name })));
    } catch (error) {
      console.error(error);
      openSnackBar('Failed to load countries', false);
    }
  }

  // default country to US
  const getTotal = async (countryCodeId = 228) => {
    setIsTotalLoading(true);
    try {
      const { data: { total, shipping, standard, expedite, overnight } } = await calculateDynamicShoppingCartSummary(countryCodeId);
      
      setTotal(total);
      setShipping(shipping);
      setStandard(standard);
      setExpedite(expedite);
      setOvernight(overnight);
    } catch(error){
      console.error(error);
      openSnackBar('Failed to load total price', false);
    } finally {
      setIsTotalLoading(false);
    }
  }

  const getItems = async page => {
    setIsListLoading(true);
    try {
      const { data: { result, total } } = await getShoppingCartItems({ page, perPage: PER_PAGE });

      setItems(result);
      setTotalPages(Math.ceil(total/PER_PAGE));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load items', false);
    } finally {
      setIsListLoading(false);
    }
  }

  const changeShippingType = value => {
    switch(value) {
      case 'standard':
        return setShipping(standard);
      case 'expedited':
        return setShipping(expedited);
      default: return setShipping(overnight);
    }
  }

  const isShippingSelected = ship => ship === shipping;

  const placeAnOrder = async (values) => {
    try {
      const { data: paymentInformation } = await getPrimaryMethod();
      const shippingInfo = {...values, shippingCountry: countries.find(x => x.id === parseInt(values.shippingCountry))};

      const shippingType = shipping === standard ? 0 : shipping === expedited ? 1 : 2;
      const shippingName = shipping === standard ? 'Standard' : shipping === expedited ? 'Expedited' : 'Overnight';
      const paymentShippingMethod = { name: shippingName, value: shippingType };
      const paymentInfo = {
        ...mapPaymentInformation(paymentInformation),
        paymentShippingMethod
      }

      const { data: { id }} = await createOrder({shippingType});

      const checkoutOrderPayload = {
        orderId: id,
        ...paymentInfo,
        ...shippingInfo
      }    
      await checkoutOrder(checkoutOrderPayload);

      history.push(`/order-details/${id}/summary`);
    } catch (error){
      console.error(error);
      openSnackBar('Failed to create order', false);
    }
  }

  return (
    <ApplicationPage selectedTab='place-an-order'>
      <Helmet>
          <meta name="robots" content="noindex, follow" />
      </Helmet>
      <ApplicationPageContent title='Checkout'>
        <div className='checkout-page-container'>
          <FinalForm
            onSubmit={placeAnOrder}
            initialValues={{ shippingCountry: countries.find(x => x.country_code === 'US')?.id }}
            render={({ handleSubmit, valid, submitting }) => (
            <>
              <form className='checkout-page' onSubmit={handleSubmit}>
                <FormSpy subscription={{ values: true, active: true }} onChange={({ values: { shippingCountry }, active }) => active === 'shippingCountry' ?  getTotal(shippingCountry) : undefined
                }/>
                <div className='checkout-form'>
                  <div className='checkout-label'>
                    Shipping Address
                  </div>
                  <div className='checkout-input-wrapper'>
                    <TextInput name='shippingFirstName' placeholder='First Name' className='checkout-input' validate={Validators.required('First name is required')}/>
                    <TextInput name='shippingLastName' placeholder='Last Name' className='checkout-input' validate={Validators.required('Last name is required')}/>
                    <TextInput name='shippingAddress1' placeholder='Address Line 1' className='checkout-input' validate={Validators.required('Addres is required')}/>
                    <TextInput name='shippingAddress2' placeholder='Address Line 2' className='checkout-input'/>
                    <TextInput name='shippingCity' placeholder='City' className='checkout-input' validate={Validators.required('City is required')}/>
                    <TextInput name='shippingState' placeholder='State/Province/Region' className='checkout-input' validate={Validators.required('State is required')}/>
                    <SelectInput
                      name='shippingCountry'
                      options={countryOptions}
                      placeholder='Country'
                      className='checkout-select'
                    />
                    <TextInput name='shippingPostalCode' placeholder='Postal Code' className='checkout-input' validate={Validators.required('Postal code is required')}/>
                    <TextInput name='shippingPhoneNumber' placeholder='Phone Number' className='checkout-input' validate={Validators.required('Phone number is required')}/>
                    <TextInput name='shippingEmail' placeholder='Your Email Address' className='checkout-input' validate={Validators.composeValidators(Validators.required('Email address is required'), Validators.emailFormatValid('Email format is not valid'))}/>
                  </div>
                  <div className='refund'>
                    Returns and refunds are subject to our Terms of Service.International Shipping: Please note that your country may charge Import duties, taxes and fees that you may have to pay ahead of delivery.
                  </div>
                </div>
                <div className='checkout-summary'>
                  <div className='summary-main'>
                    <div className='summary-header'>
                      Order Summary
                    </div>
                    <div className='summary-content'>
                      <div className='summary-list'>
                        {isListLoading
                        ? <Loader width={100} height={100} color={colors.primary}/>
                        : (
                          <>
                            {items.map(x => (
                              <SummaryItem item={x}/>
                              ))}
                            <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages} />
                          </>
                        )}
                      </div>
                        <div className='summary-details'>
                        {isTotalLoading
                        ? <Loader width={100} height={100} color={colors.primary}/>
                        : (
                          <>
                            <div className='summary-shipping'>
                              <div className='summary-shipping-row'>
                                <div className='summary-shipping-title'>
                                  Product Total Price
                                </div>
                                <div className='summary-shipping-title'>
                                  ${total.toFixed(2)}
                                </div>
                              </div>
                              <div className='summary-shipping-row'>
                                <div className='summary-shipping-title'>
                                  Shipping
                                </div>
                                <div className='summary-shipping-title'>
                                  ${shipping.toFixed(2)}
                                </div>
                              </div>
                              <div className='summary-shipping-row'>
                                <div className='summary-shipping-title'>
                                  Shipping Type
                                </div>
                                <div className='summary-shipping-options'>
                                  <div className={classNames('summary-shipping-option', {'summary-shipping-option-selected': isShippingSelected(standard), 'summary-shipping-option-disabled': standard === 0})} onClick={() => standard === 0 ? undefined : changeShippingType('standard')}>
                                    Standard
                                  </div>
                                  <div className={classNames('summary-shipping-option', {'summary-shipping-option-selected': isShippingSelected(expedited), 'summary-shipping-option-disabled': expedited === 0})} onClick={() => expedited === 0 ? undefined : changeShippingType('expedited')}>
                                    Expedited
                                  </div>
                                  <div className={classNames('summary-shipping-option', {'summary-shipping-option-selected': isShippingSelected(overnight), 'summary-shipping-option-disabled': overnight === 0})} onClick={() => overnight === 0 ? undefined : changeShippingType('overnight')}>
                                    Overnight
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='summary-total'>
                              <div className='summary-total-title'>
                                Total Price
                              </div>
                              <div className='summary-total-price'>
                                ${(shipping + total).toFixed(2)}
                              </div>
                            </div>
                          </>
                        )}
                        </div>
                    </div>
                    <button className='summary-submit' type='submit' disabled={!valid}>
                      {submitting
                      ? (
                        <Loader width={25} height={25} color={colors.white} />
                        )
                      : 'Place an Order'}
                    </button>
                  </div>
                </div>
              </form>
            </>
            )}
          />
          <Button text='< Previous step' onClick={() => history.goBack()} className='previous-step-button' />
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
};

export default withRouter(Checkout);
