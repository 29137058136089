import React from 'react';
import { v4 as uuid } from 'uuid';
import NumberContainer from 'src/redesign/pages/Landing/components/NumberContainer';

import './style.scss';

const WhyChooseUs = () => {
    const WHY_US_TEXT = [
    {
      title: 'No Sign Up or Monthly fees',
      description: 'Surge Print is free and always will be! There are no hidden costs.'
    },
    {
      title: 'Print od Demand drop shipping',
      description: `Sell your designs on 100s of products. We'll print, pack, and ship everything on demand - you only pay when an order comes in.`
    },
    {
      title: 'No Order minimums and Volume Discounts!',
      description: `We can print 1 or 10,000 items per order, there are no limits! If you are selling 100s of items per month, let us know and we'll create custom discounted pricing just for you.`
    },
    {
      title: 'Products and Mockups',
      description: `Link existing products and stores to us or choose from more than 200 products. Use our state-of-the-art mock up generator and create amazing product previews.`
    },
    {
      title: 'Warehousing and Fulfillment',
      description: `Store your products at our warehouses. When you get an order, we will ship it in less than 24 hours!`
    },
    {
      title: 'Support and tools',
      description: `Tired of waiting hours, if not days, for a simple answer? We're not that kind of a company. With us you'll get 24/7 customer support and a dedicated Account Manager to help you scale up. Connect existing products, Manual order form, CSV import, Flat Shipping Rates, Inventory Manager are some of the tools our partners can use.`
    },
  ];

  return (
    <div className='why-us-container'>
      <h2 className='why-us-title'>
        Why Choose US?
      </h2>
      <div className='why-us-grid'>
        {WHY_US_TEXT.map((x, i) => (
          <NumberContainer key={uuid()} {...x} index={i + 1}/>
        ))}
      </div>
    </div>
  )
}

export default WhyChooseUs;