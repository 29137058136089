import React from 'react';
import classNames from 'classnames';
import ImageMenu from './menu.png';
import { v4 as uuid } from 'uuid';

import './style.scss';

const Menu = ({ width, height, options, className, contentClassName, itemClassName }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const imageRef = React.useRef();

  React.useEffect(() => {
    const handleClickOutside = event => {
      const isMenuImage = event.target.src === ImageMenu && event.target.id === imageRef?.current?.id;
      if(event.target.className !== 'menu-icon-wrapper' && !isMenuImage) {
        setIsOpen(false);
      }
      if(isMenuImage) {
        setIsOpen(prev => !prev);
      }
    }
    
    document.addEventListener('click', handleClickOutside);

    () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return <div className={classNames('menu-container', className)}>
    <div className='menu-icon-wrapper'
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(prev => !prev);
      }}
    >
      <img id={uuid()} src={ImageMenu} style={{ width, height }} onClick={() => setIsOpen(prev => !prev)} ref={imageRef}/>
    </div>
    {isOpen && (
      <div className={classNames('menu-content', contentClassName)}>
        {options?.map(x => (
          <div
            key={x.key}
            className={classNames('item', itemClassName)}
            onClick={(e) => {
              e.stopPropagation();
              x.onSelect();
            }}
          >
            {x.text}
          </div>
        ))}
      </div>
    )}
  </div>
}

export default Menu;