import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button} from "@material-ui/core";
import strings from '../localization';
import {withSnackbar} from "notistack";
import ReactDOM from 'react-dom';
import AddMockup from "../components/AddMockup";
import { createMockup, editMockup, getMediaModifierMockups, deleteMockup } from "../services/MediaModifierService";

class MediaModifierMockups extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 12 }];


    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            perPage: 15,
            mockups: [],
            dropdownOpen: null,
            siderbar: false,
            display: 'none',
            loading: false,
            data: {
                name: null,
                layers: null,
                mediaModifierId: null,
                imagePath: null,
                useColorFromVariation: false,
                searchTerm: null,
                scenes: [],
            }
        }

        this.onPageClick = this.onPageClick.bind(this);
        this.createMockup = this.createMockup.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onForward = this.onForward.bind(this);
        this.changeCheckBoxUseColorFromVariation = this.changeCheckBoxUseColorFromVariation.bind(this);
        this.onBack = this.onBack.bind(this);
        this.goBack = this.goBack.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    fetchData(){
        this.getMediaModifierMockups(this.state.page, this.state.perPage);
    }

    keyPress(event) {

        if(event.key == 'Enter') {
            this.getMediaModifierMockups(this.state.page, this.state.perPage);
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
 
        if (this.state.dropdownOpen) {
            if(event.target.className != "opt" && event.target.className != "dropdown-opt"){
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    dropdownOpen: false
                });
            }
        }
    }

    onSubmit() {
        if(this.state.data.id == null){
            this.createMockup();
        }else{
            this.editMockup();
        }
    }

    createForm() {
        let formData = new FormData();
        var fileInput = document.getElementById('mockupMainImage');
        var file = fileInput.files[0];
        formData.append('mediaModifierId', this.state.data.mediaModifierId)
        formData.append('mockupMainImage',file)
        formData.append('name', this.state.data.name)
        formData.append('layers', this.state.data.layers)
        formData.append('scenes', this.state.data.scenes ? JSON.stringify(this.state.data.scenes.map(scene => { return scene.value})): [])
        formData.append('useColorFromVariation', this.state.data.useColorFromVariation)
        
        return formData
    }

    createMockup(){
        const formData = this.createForm()

        createMockup(formData).then(response => {
            this.refreshData()
        });
    }

    editMockup(){
        const formData = this.createForm()
        editMockup(this.state.data.id , formData).then(response => {
            this.refreshData()
        });
    }
    changeScenesSelectedOptions = (option) => {
        this.setState(state => {
          return {
            data: {...this.state.data, scenes: option}
          };
        });
    }
    refreshData() {
        this.setState({
            ...this.state,
            siderbar: false,
            display: 'none',
            data: {
                ...this.state.data,
                layers: null,
                name: null,
                mediaModifierId: null,
                useColorFromVariation: false,
                scenes: [],
                id: null
            },
        });

        setTimeout(() => {  
            this.fetchData();
        }, 1000);
    }

    getMediaModifierMockups(page, perPage){
        this.setState({
            ...this.state,
            loading: true,
        })
        getMediaModifierMockups({ page, perPage }).then(response => {
            if(!response.ok) {
                return;
            }
            const { paginationInfo, data } = response.data;
            const { page, perPage, total } = paginationInfo;

            this.setState({
                ...this.state,
                loading: false,
                mockups: data,
                page,
                perPage,
                totalPages: Math.ceil(total / perPage)
            })
        }).catch(() => {
            this.setState({
                ...this.state,
                loading: false,
            })
        })
    }

    openDropdown(id,e) {
        e.stopPropagation();

        if(this.state.dropdownOpen != null){
            this.setState({
                ...this.state,
                dropdownOpen: null
            })
        }else{
            this.setState({
                ...this.state,
                dropdownOpen: id
            })
        }
       
    }

    deleteItem(id){

        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        deleteMockup(id).then(response => {
    
            if(!response.ok) {
                return;
            }

            this.props.enqueueSnackbar("Mockup successfully deleted", {variant: 'success'});

            setTimeout(() => {  
                this.fetchData();
            }, 1000);
        
        });
       
    }

    openSiderbar(){
        this.setState({
            ...this.state,
            siderbar: true,
            display: 'block'
        });
    }

    goBack(){
        this.setState({
            ...this.state,
            siderbar: false,
            display: 'none',
            data: {
                ...this.state.data,
                categoryImage: null,
                parentCategory: null,
                name: null,
                id: null
            }
        });
    }


    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(page){
        this.getMediaModifierMockups(page, this.state.perPage);
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        this.getMediaModifierMockups(this.state.page, this.state.perPage);
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        this.getMediaModifierMockups(this.state.page, this.state.perPage);
    }
    changeCheckBoxUseColorFromVariation(){
        this.setState({data: { ...this.state.data, useColorFromVariation: this.state.data.useColorFromVariation} })
    }

    renderMockups() {
        if(this.state.loading)
            return (
                <div className='loader-container'>
                    <img className='my-div-loader' src={"/images/loadersp.gif"}></img>
                </div>
            )
        let result = [];
        
        for(let item of this.state.mockups)
        {
            result.push(

                <div className="item">
                    <div className="admin-id">{ item.id }</div> 
                    <div className="admin-fist-name">{ item.mediaModifierId }</div>
                    <div className="admin-last-name">{ item.name }</div>
                    <div className="admin-last-name"> {`${item.useColorFromVariation}`}  </div>
                    <div className="admin-email">{JSON.stringify(item.layers)}</div>
                    <div className="options">
                        <div className="opt" onClick={ (e) => this.openDropdown(item.id, e) }><img src="/images/tackice.png"></img></div>
                        <div className={ this.state.dropdownOpen == item.id ? "dropdown active" : "dropdown" }>
                            <div className="dropdown-opt" onClick={ () => this.editItem(item) }>Edit</div>
                            <div className="dropdown-opt" onClick={ () => this.deleteItem(item.id) }>Delete</div>
                        </div>
                    </div>
                </div>
            );
        }
        return result;
    }

    editItem(item){
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                name: item.name,
                mediaModifierId: item.mediaModifierId,
                imagePath: item.imagePath,
                id: item.id,
                layers: JSON.stringify(item.layers),
                useColorFromVariation: item.useColorFromVariation,
                scenes: item.scenes.map(scene => { return { label: scene.name, value: scene.id}})
            },
            siderbar: true,
            display: 'block',
            dropdownOpen: null
        });
    }


    render() {

        return (
            <div id="orders">
                <div id="category-overlay" className='overlay' style={{display: this.state.display}} onClick={ () => this.goBack() }></div>
                <div id="siderbar" style={{opacity : this.state.display == "block" ? "1" : "0",
                        right : this.state.display == "block" ? "0" : "-492px",
                        height: document.getElementById("root").offsetHeight + 114 + 'px'}}>
                        {
                            this.state.siderbar &&
                            <AddMockup 
                                data={this.state.data} 
                                onDropPicture={this.onDropPicture} 
                                errors = {this.state.errors} 
                                changeCheckBox={this.changeCheckBox} 
                                changeScenesSelectedOptions={this.changeScenesSelectedOptions}
                                mockups={this.state.mockups}
                                onChange={this.changeData} 
                                goBack={this.goBack} 
                                submited={this.onSubmit}/>
                            }
                </div>

                <div className={'search'}>
                    <input className={'category-search'}
                        value={this.state.data.searchTerm}
                        onChange={this.changeData}
                        name={'searchTerm'}
                        placeholder={'Type to search...'}
                        onKeyPress={ this.keyPress }
                    />
                    <img src={'/images/search.png'} />
                </div>

                <div className="button-new">
                    <Button className={"new-product"} onClick={ () => this.openSiderbar()}>Add new mockup</Button>
                </div>

                <div>

                </div>
                <div className="list">
                    <div className={'list-helper'}>
                        <div className="header">
                            <div className="admin-id">ID</div>
                            <div className="admin-fist-name">Media Modifier id </div>
                            <div className="admin-fist-name">Mockup name </div>
                            <div className="admin-last-name">Use color from variation</div>
                            <div className="admin-last-name">Layers</div>
                        </div>
                        { this.renderMockups() }
                    </div>
                </div>

                <div className={'pagination'}>
                    {
                        this.state.page > 1 &&
                        <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                    }
                    <div className={'pages'}>
                        {this.renderPagination()}
                    </div>
                    {
                        this.state.page < this.state.totalPages &&
                        <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                    }
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(MediaModifierMockups)));