import React from 'react';
import { v4 as uuid } from 'uuid';
import { arrayOf, node, shape, string } from 'prop-types';

import './style.scss';

const DesignWithEase = ({ title, options }) => (
  <div className='landing-ease'>
    <h2 className='landing-ease-title'>
      {title}
    </h2>
    <div className='landing-ease-row'>
      {options.map((x) => (
        <div className='landing-ease-container' key={uuid()}>
          <div className='landing-ease-icon-container'>
            {x.icon}
          </div>
          <div className='landing-ease-text-container'>
            <h3 className='landing-ease-text-title'>
              {x.title}
            </h3>
            <div className='landing-ease-text-description'>
              {x.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

DesignWithEase.propTypes = {
  title: string.isRequired,
  options: arrayOf(shape({
    icon: node.isRequired,
    title: string.isRequired,
    description: string.isRequired
  }))
}

export default DesignWithEase;