import React from 'react';

import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import Loader from 'src/redesign/components/Loader';
import Pagination from 'src/redesign/components/Pagination';
import Table from 'src/redesign/components/Table';
import Searchbox from 'src/redesign/components/Searchbox';
import PrimarySubmitButton from 'src/redesign/components/Buttons/SubmitPrimary';
import IconEdit from 'src/redesign/components/Icon/Edit';
import IconTrash from 'src/redesign/components/Icon/Trash';
import Menu from 'src/redesign/components/Menu';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import CreateEditDocumentSidepane from './components/CreateEditSidepane';

import { createDocument, deleteDocument, editDocument, getDocuments } from 'src/services/DocumentService';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const PER_PAGE = 8;

const DocumentsPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState(null);

  const [documents, setDocuments] = React.useState([]);

  const [isAddSidepaneOpen, setIsAddSidepaneOpen] = React.useState(false);
  const [documentToEdit, setDocumentToEdit] = React.useState(null);
  const [saving, setSaving] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, [currentPage]);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await getDocuments({ page: currentPage, perPage: PER_PAGE });

      setDocuments(data.data);
      setTotalPages(Math.ceil(data.paginationInfo.total/PER_PAGE));
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load documents', false);
    } finally {
      setLoading(false);
    }
  }

  const onAddEditDocument = async values => {
    setSaving(true);

    const formData = new FormData();
    const fileInput = document.getElementById('documentPicker');
    const file = fileInput.files[0];

    if(!file && isAddSidepaneOpen) {
      openSnackBar('Document is not selected!', false);
      setSaving(false);
      return;
    }

    formData.append('name', values.name);
    formData.append('type', values.document_type);
    formData.append('document', file);

    try {
      await (isAddSidepaneOpen ? createDocument(formData) : editDocument(documentToEdit.id, formData));

      openSnackBar(`Document successfully ${isAddSidepaneOpen ? 'added' : 'edited'}`);
      setTimeout(load, 1000);
      setDocumentToEdit(null);
      setIsAddSidepaneOpen(false);
    } catch(error){
      console.error(error);
      openSnackBar(`Failed to ${isAddSidepaneOpen ? 'add' : 'edit'} document`, false);
    } finally {
      setSaving(false);
    }
  }

  const onDeleteDocument = async id => {
    try {
      await deleteDocument(id);

      openSnackBar('Document successfully deleted');
      setTimeout(load, 1000);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to delete mockup', false);
    }
  }

  const columns = [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Document Name',
      accessor: 'name'
    },
    {
      Header: 'Document Type',
      accessor: 'type'
    },
    {
      Header: '',
      accessor: 'filePath',
      Cell: ({ row: { original }}) => (
        <Menu
          width={4}
          height={16}
          options={[
            {
              key: 'edit',
              text: <>
                Edit
                <IconEdit width={24} height={24} color={colors.primary} />
              </>,
              onSelect: () => setDocumentToEdit(original)
            },
            {
              key: 'delete',
              text: <>
                Delete
                <IconTrash width={24} height={24} color={colors.primary} />
              </>,
              onSelect: () => onDeleteDocument(original.id)
            }
          ]}
        />
      )
    }
  ];

  return (
    <ApplicationPage selectedTab='admin'>
      <ApplicationPageContent title='Documents'>
        <div className='documents-page'>
          {/*
            TODO: search not implemented on the backend
          */}
          <Searchbox
            placeholder='Search'
            className='documents-searchbox-container'
            onChange={setSearchTerm}
            disabled={loading}
          />
          <PrimarySubmitButton
            text='Add new document'
            onClick={() => setIsAddSidepaneOpen(true)}
            className='documents-add-button'
            disabled={loading}
          />
          {loading
          ? <Loader width={200} height={200} color={colors.primary} />
          : (
            <div>
              <Table
                columns={columns}
                data={documents}
              />
              <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages}/>
            </div>
          )}
        </div>
        {(isAddSidepaneOpen || documentToEdit != null) && (
          <CreateEditDocumentSidepane
            title={isAddSidepaneOpen ? 'Add new document' : 'Edit document'}
            initialValues={{ ...documentToEdit, document_type: documentToEdit?.type }}
            onSubmit={onAddEditDocument}
            onClose={() => isAddSidepaneOpen
              ? setIsAddSidepaneOpen(false)
              : setDocumentToEdit(null)
            }
            saving={saving}
          />
        )}
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default DocumentsPage;