import React from 'react';
import { number } from 'prop-types';

const IconDiscover = ({ width, height }) => (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 36 24">
    <defs>
        <rect id="a" width={width} height={height} rx="4"/>
        <path id="b" d="M9 24h23.002A3.993 3.993 0 0 0 36 20v-6.5S26.464 20.754 9 24z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <g>
            <use fill="#FFF" xlinkHref="#a"/>
            <use fill="#E6772F" xlinkHref="#b"/>
        </g>
        <path fill="#1A1919" d="M6.263 13.61c-.272.258-.62.371-1.175.371h-.23v-3.085h.23c.555 0 .89.105 1.175.377.296.28.473.713.473 1.16 0 .45-.177.898-.473 1.176zM5.26 10.104H4v4.667h1.253c.666 0 1.147-.169 1.57-.54.5-.44.797-1.104.797-1.792 0-1.378-.97-2.335-2.36-2.335zM8.016 10.105h.855v4.667h-.855zM10.97 11.894c-.516-.2-.667-.335-.667-.587 0-.293.27-.515.64-.515.257 0 .468.109.694.375l.446-.62a1.853 1.853 0 0 0-1.291-.52c-.778 0-1.372.575-1.372 1.335 0 .645.276.972 1.08 1.283.337.124.508.209.595.266a.563.563 0 0 1 .257.481c0 .38-.284.659-.667.659-.408 0-.738-.217-.936-.624l-.552.568c.395.615.869.89 1.521.89.89 0 1.518-.633 1.518-1.534 0-.742-.29-1.079-1.267-1.457M12.506 12.44c0 1.372 1.015 2.435 2.321 2.435.369 0 .685-.077 1.074-.272v-1.072c-.344.366-.646.511-1.036.511-.864 0-1.475-.663-1.475-1.609 0-.894.631-1.602 1.437-1.602.407 0 .718.153 1.074.525v-1.07c-.376-.202-.684-.286-1.056-.286-1.3 0-2.34 1.084-2.34 2.44M22.849 13.238l-1.173-3.133h-.935l1.865 4.784h.46l1.9-4.784h-.928l-1.189 3.133M25.355 14.772h2.432v-.791h-1.574v-1.26h1.514v-.79h-1.514v-1.035h1.574v-.79h-2.432v4.666M29.462 12.251h-.249V10.84h.263c.536 0 .823.238.823.693 0 .467-.287.718-.837.718zm1.72-.769c0-.874-.565-1.377-1.555-1.377h-1.274v4.667h.86v-1.876h.112l1.184 1.876h1.056l-1.384-1.967c.647-.14 1.001-.609 1.001-1.323z"/>
        <path fill="#E6772F" d="M20.862 12.45c0 1.344-1.026 2.433-2.292 2.433-1.267 0-2.293-1.089-2.293-2.432 0-1.344 1.026-2.433 2.293-2.433 1.266 0 2.292 1.089 2.292 2.433"/>
    </g>
</svg>

);

IconDiscover.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
}

export default IconDiscover;