import React from 'react';
import { number, string } from 'prop-types';

const IconTrash = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_476_10556)">
      <path d="M7.25 8V16.75C7.25 17.8546 8.14543 18.75 9.25 18.75H14.75C15.8546 18.75 16.75 17.8546 16.75 16.75V8M5.25 8H18.75" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M9 5.25H15" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5 11.5V15.25" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M13.5 11.5V15.25" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0_476_10556">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

IconTrash.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconTrash;