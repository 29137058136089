import React, {Component} from 'react'

import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import {dateToString} from "../../util/DateUtil";
import {getShippingMethodString} from "../../constants/ShippingMethod";
import {Button, TextField} from "@material-ui/core";
import {
    changeShippingMethod,
    setTrackingLinkToOrder,
    setTrackingLinkToOrderItem
} from "../../services/OrderService";
import {getOrderStatusString, isNewOrder} from "../../constants/OrderStatus";
import {getUserFromLocalStorage} from "../../base/OAuth";
import UserType from '../../constants/UserType';
import update from "immutability-helper";
import OrderSource from "../../constants/OrderSource";
import OrderStatus from "../../constants/OrderStatus";

class Shipment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order,
            editItems: false,
            trackingLinks: {},
            data: this.props.data,
            editOrder: false,
            userType: getUserFromLocalStorage().user_type
        }

        this.changeShippingMethod = this.changeShippingMethod.bind(this);
        this.changeDataRegular = this.changeDataRegular.bind(this);
    }

    componentDidMount(){
        let trackingLinks = {};

        this.state.order.items.forEach(item => {
            if (item.trackingLink) {
                trackingLinks[item.id] = item.trackingLink;
            } else {
                trackingLinks[item.id] = this.state.order.trackingLink;
            }
        });

        this.setState({
            ...this.state,
            trackingLinks: trackingLinks
        });
    }

    editItems() {
        this.setState({
            ...this.state,
            editItems: !this.state.editItems
        });
    }

    editOrder() {
        this.setState({
            ...this.state,
            editOrder: !this.state.editOrder
        });
    }

    changeDataRegular(event, data = 'data') {

        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} })
        });
    }

    changeData(event, id)
    {
        let trackingLinks = this.state.trackingLinks;
        trackingLinks[id] = event.target.value; 

        this.setState({
            ...this.state,
            trackingLinks: trackingLinks
        })
    }

    changeShippingMethod(value) {
        this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                shippingMethod: value
            }
        })
    }

    changeOrderTrackingLink(event) {
        this.setState({
            ...this.state,
            order: {
                ...this.state.order,
                trackingLink: event.target.value
            }
        })
    }

    save() {
        for (let item of this.state.order.items) {
            if(this.state.trackingLinks[item.id] != undefined){
                setTrackingLinkToOrderItem({
                    orderItemId: item.id,
                    trackingLink: this.state.trackingLinks[item.id]
                }).then(response => {
    
                    if(!response.ok) {
                        return;
                    }

                    let order = this.state.order;
                    if (order.trackingLink == undefined) {
                        order.trackingLink = this.state.trackingLinks[item.id];
                    }

                    this.setState({
                        ...this.state,
                        order: order
                    });
                });
            }
        }

        setTimeout(() => {
            this.props.enqueueSnackbar("Successfully edited tracking links!", {variant: 'success'});

            this.setState({
                ...this.state,
                editItems: false
            })
        }, 300)
        
    }

    saveOrder() {
        if (this.state.order.orderStatus === OrderStatus[0]) {
            changeShippingMethod({
                orderId: this.state.order.id,
                shippingMethod: this.state.order.shippingMethod
            }).then(response => {
                if(!response.ok) {
                    this.props.enqueueSnackbar("Cannot change shipping method", {variant: 'error'});
                    return;
                }
    
                this.setState({
                    ...this.state,
                    editOrder: false
                });
    
                this.props.refreshOrder();
            });
    
            if (this.state.order.orderSource === OrderSource[1].value && !this.state.order.trackingLink){
                return;
            }
    
            if (this.state.order.orderSource === OrderSource[2].value && (!this.state.data.trackingCode || !this.state.data.carrierName)){
                return
            }
        }

        setTrackingLinkToOrder({
            orderId: this.state.order.id,
            trackingLink: this.state.order.trackingLink ? this.state.order.trackingLink : ''
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                editOrder: false
            });

        });
    }

    renderItems() {
        let result = [];
        let i = 1;
        for(let item of this.state.order.items)
        {   
            result.push(
                <div className="tracking">
                    <div className="tracking-number">{ i }</div>
                    <div className="sku-name">{ item.sku }</div>
                </div>
            );

            i++;

            
            let trackinLink;
            if (!this.state.editItems) {
                trackinLink = <div onClick={(event)=> window.open(this.state.trackingLinks[item.id], "_blank", 'noopener,noreferrer')} className="tracking-link">{ this.state.trackingLinks[item.id] == undefined ? '' : this.state.trackingLinks[item.id]}</div>;
            } else {
                trackinLink = <TextField
                    className="tracking-link-input"
                    name={item.id}
                    onChange={ (e) => this.changeData(e, item.id) }
                    margin="normal"
                    type='text'
                    value={ this.state.trackingLinks[item.id] }
                    placeholder={'Tracking link'}
                    variant="outlined"
                />
            }

            result.push(
                trackinLink 
            );
        }
        return result;
    }

    isUSShipping() {
        return this.state.order.country && this.state.order.country.countryCode === 'US';
    }

    orderHasItemWithoutIntExpeditedOption() {
        for (let item of this.state.order.items) {
            if (!item.hasInternationalShipping) {
                return true;
            }
        }
        return false;
    }

    render() {

        return (
            <div id="item">
                <div className="row">
                    <div className="details shipment-order-details">
                        <div className="details-header">
                            Order {this.state.order.id}
                            {
                                <a onClick={ () => this.editOrder() }>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3802 1.9648C12.3856 1.47526 12.1955 1.00379 11.852 0.654932C11.5027 0.309511 11.0333 0.11266 10.5421 0.105623C10.0595 0.103521 9.59577 0.293538 9.25342 0.633783L11.852 3.25351C12.2006 2.91675 12.3921 2.44932 12.3802 1.9648Z" fill="#C4C4C4"/>
                                        <path d="M11.2607 3.84501L8.66211 1.24645L2.64103 7.26753L5.23959 9.86609L11.2607 3.84501Z" fill="#C4C4C4"/>
                                        <path d="M2.30299 8.11269L1.71143 10.7958L4.39452 10.2042L2.30299 8.11269Z" fill="#C4C4C4"/>
                                        <path d="M14.5775 14.0493H0.422536C0.189169 14.0493 0 14.2385 0 14.4718C0 14.7052 0.189169 14.8944 0.422536 14.8944H14.5775C14.8108 14.8944 15 14.7052 15 14.4718C15 14.2385 14.8108 14.0493 14.5775 14.0493Z" fill="#C4C4C4"/>
                                    </svg>
                                </a>
                            }
                        </div>
                        <div className="info">
                            <div className="left">
                                Tracking
                            </div>
                            {
                                this.state.editOrder && this.state.userType === UserType.ADMIN &&
                                <TextField
                                    className="order-input-traking-link"
                                    name={"trackingLink"}
                                    onChange={ (e) => this.changeOrderTrackingLink(e) }
                                    margin="normal"
                                    type='text'
                                    value={ this.state.order.trackingLink }
                                    placeholder={'Tracking link'}
                                    variant="outlined"
                                />
                            }
                            {
                                (!this.state.editOrder) &&
                                <a href={this.state.order.trackingLink} target="_blank">
                                    {this.state.order.trackingLink}
                                </a>
                            }   
                        </div>
                        <div className="info">
                            <div className="left">
                                Status
                            </div>
                            <div className="right">
                                { getOrderStatusString(this.state.order.status) } 
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Created
                            </div>
                            <div className="right">
                                { dateToString(this.state.order.createdAt) }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Last time updated
                            </div>
                            <div className="right">
                                { dateToString(this.state.order.updatedAt) }
                            </div>
                        </div>
                        <div className="info">
                            <div className="left">
                                Quantity
                            </div>
                            <div className="right">
                                { this.state.order.quantity }
                            </div>
                        </div>
                        <div className="info input">
                            <div className="left">
                                Shipping method
                            </div>
                            <div className="right">
                                {
                                    (this.state.editOrder && isNewOrder(this.state.order.status)) ?
                                        <div className="text">
                                            <div
                                                className={ this.state.order.shippingMethod == 0 ? "type active": "type"}
                                                 onClick={ () => this.changeShippingMethod(0)}
                                            >
                                                Standard
                                            </div>
                                            <div
                                                className={ !this.isUSShipping() && this.orderHasItemWithoutIntExpeditedOption() ? "type disabled" : "" + this.state.order.shippingMethod == 1 ? "type active": "type"}
                                                onClick={ () => this.changeShippingMethod(1)}
                                            >
                                                Expedited
                                            </div>
                                            <div
                                                className={ !this.isUSShipping() ? "type disabled" : "" + this.state.order.shippingMethod == 2 ? "type active": "type"}
                                                onClick={ () => this.changeShippingMethod(2)}
                                            >
                                                Overnight
                                            </div>
                                        </div>
                                        :
                                        getShippingMethodString(this.state.order.shippingMethod)
                                }
                            </div>
                        </div>
                        {
                            this.state.editOrder && 
                            <Button className="save-button" onClick={ () => this.saveOrder() }>Save</Button>
                        }
                    </div>

                    <div className="details">
                        <div className="details-header">
                            Variants - SKU Name & Tracking links
                            {
                                this.state.userType == UserType.ADMIN &&
                                <a onClick={ () => this.editItems() }>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3802 1.9648C12.3856 1.47526 12.1955 1.00379 11.852 0.654932C11.5027 0.309511 11.0333 0.11266 10.5421 0.105623C10.0595 0.103521 9.59577 0.293538 9.25342 0.633783L11.852 3.25351C12.2006 2.91675 12.3921 2.44932 12.3802 1.9648Z" fill="#C4C4C4"/>
                                        <path d="M11.2607 3.84501L8.66211 1.24645L2.64103 7.26753L5.23959 9.86609L11.2607 3.84501Z" fill="#C4C4C4"/>
                                        <path d="M2.30299 8.11269L1.71143 10.7958L4.39452 10.2042L2.30299 8.11269Z" fill="#C4C4C4"/>
                                        <path d="M14.5775 14.0493H0.422536C0.189169 14.0493 0 14.2385 0 14.4718C0 14.7052 0.189169 14.8944 0.422536 14.8944H14.5775C14.8108 14.8944 15 14.7052 15 14.4718C15 14.2385 14.8108 14.0493 14.5775 14.0493Z" fill="#C4C4C4"/>
                                    </svg>
                                </a>
                            }
                        
                        </div>

                        { this.renderItems() }

                        {
                            this.state.editItems && 
                            <Button className="save-button" onClick={ this.save }>Save</Button>
                        }
                       
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Shipment)));
