import React from 'react';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import { Form as FinalForm } from 'react-final-form';
import { v4 as uuid } from 'uuid';

import './style.scss';

const Overview = ({ items, showEditButton = false, initialValues, onSubmit }) => {
  const [editMode, setEditMode] = React.useState(false);
  
  return (
    <div className='overview'>
      {editMode
        ?
          <FinalForm onSubmit={onSubmit} initialValues={initialValues}
            render={({ handleSubmit, valid, submitting, form }) => (
              <form className='form' onSubmit={e => {
                handleSubmit(e);
                setEditMode(false);
              }}>
              {items.map((x, i) => {
                if(!x) return;

                return (<div key={i} className='row'>
                  <div className='name'>
                    {x.name}
                  </div>
                  <div className='value'>
                    {x.edit ?? x.value}
                  </div>
                </div>);
              })}
              <SubmitButton text='Save' type='submit' className='submit-button' width={77} disabled={!valid || submitting} loading={submitting}/>
              </form>
            )}>
          </FinalForm>
        : items.map((x, i) => (
          <div key={i} className='row'>
            <div className='name'>
              {x.name}
            </div>
            <div className='value'>
              {x.value}
            </div>
          </div>
      ))
      }
      {showEditButton && !editMode && <SubmitButton text='Edit' className="edit-button" width={77} onClick={() => setEditMode(true)}/>}
    </div>
  )
}

export default Overview;