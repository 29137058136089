import React from 'react';
import { withRouter } from 'react-router';
import { LogoHeader as Logo } from 'src/redesign/components/Logo';

import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import PrimaryButton from 'src/redesign/components/Buttons/Primary';
import Avatar from 'src/redesign/components/Avatar';
import { getUserFromLocalStorage } from 'src/base/HTTP';
import Link from 'src/redesign/components/Link';
import IconHome from 'src/redesign/components/Icon/Home';
import IconCatalog from 'src/redesign/components/Icon/Catalog';
import IconAffiliate from 'src/redesign/components/Icon/Affiliate';
import IconWarehouse from 'src/redesign/components/Icon/Warehouse';
import useMobile from 'src/redesign/hooks/useMobile';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const Header = ({ history }) => {
  const { isMobile } = useMobile();
  const isLoggedIn = !!getUserFromLocalStorage();

  return (
    <div className="redesign-header">
      <Logo />
      <div className='redesign-header-left'>

        <Link to="/home">
          {isMobile 
            ? <IconHome width={24} height={24} color={colors.primary} />
            : 'Home'
          }
        </Link>
        <Link to="/product-catalog-website">
          {isMobile
            ? (<div style={{ marginTop: 5, marginLeft: 5 }}>
                <IconCatalog  width={28} height={28} color={colors.primary} />
              </div>)
            : 'Catalog'}  
        </Link>
        <Link to="/faq" >
          {isMobile
            ? (<div style={{ marginBottom: 2 }}>
                <IconAffiliate width={28} height={28} color={colors.primary}/>
              </div>)
            : 'FAQ'}
        </Link>
        <Link to="/contact" >
          {isMobile
            ? <IconWarehouse width={24} height={24} color={colors.primary}/>
            : 'Contact us'}
        </Link>
      </div>
      <div className='redesign-header-right'>
        {isLoggedIn
        ? (
          <Avatar />
        ) : (
          <>
            <SecondaryButton text='Log in' onClick={() => history.push('/login')} isResponsive/>
            <PrimaryButton text='Sign up' onClick={() => history.push('/registration')} isResponsive/>
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(Header);