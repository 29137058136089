import React from 'react';
import { number, string } from 'prop-types';

const IconExit = ({ width, height, color }) => (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2626 16.7374C14.946 17.4209 16.054 17.4209 16.7374 16.7374C17.4209 16.054 17.4209 14.946 16.7374 14.2626L14.2626 16.7374ZM3.23744 0.762563C2.55402 0.0791457 1.44598 0.0791457 0.762563 0.762563C0.0791457 1.44598 0.0791457 2.55402 0.762563 3.23744L3.23744 0.762563ZM16.7374 14.2626L3.23744 0.762563L0.762563 3.23744L14.2626 16.7374L16.7374 14.2626Z" fill={color}/>
    <path d="M0.762563 14.2626C0.0791457 14.946 0.0791457 16.054 0.762563 16.7374C1.44598 17.4209 2.55402 17.4209 3.23744 16.7374L0.762563 14.2626ZM16.7374 3.23744C17.4209 2.55402 17.4209 1.44598 16.7374 0.762563C16.054 0.0791457 14.946 0.0791457 14.2626 0.762563L16.7374 3.23744ZM3.23744 16.7374L16.7374 3.23744L14.2626 0.762563L0.762563 14.2626L3.23744 16.7374Z" fill={color}/>
  </svg>

);

IconExit.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconExit;