import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import PriceContainer from 'src/redesign/components/PriceContainer';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import IconEdit from 'src/redesign/components/Icon/Edit';
import IconCheckmark from 'src/redesign/components/Icon/CheckmarkBold';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const PriceCalculation = ({ surgeprintPrice, earnedPrice, onEdit, showOnlyIcons }) => {
  const [editMode, setEditMode] = React.useState(false);

  return (
    <FinalForm
      onSubmit={({ price }) => {
        if(editMode) {
          onEdit(Number.parseFloat(price));
        }
        setEditMode(prev => !prev);
      }}
      initialValues={{ price: earnedPrice }}
      render={({ handleSubmit }) => (
        <form className='template-prices' onSubmit={handleSubmit}>
          <PriceContainer price={surgeprintPrice} description='Your Price' />
          +
          <PriceContainer price={earnedPrice} description='Your Profit' editMode={editMode}/>
          =
          <PriceContainer price={surgeprintPrice + earnedPrice} description='End-Client Price' withBorder/>
          {showOnlyIcons ? (
            <button type='submit' className='template-prices-edit-icon'>
              {
                editMode
                ? <IconCheckmark width={24} height={24} color={colors.primary} />
                : <IconEdit width={24} height={24} color={colors.primary} />
              }
            </button>
          ) : (
            <SubmitButton
              type="submit"
              className='template-prices-edit-button'
              text={
                editMode
                ? 'Save' 
                : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconEdit width={24} height={24} color={colors.white} />
                    Edit
                  </div>
                )
              }
            />
          )}
        </form>
      )}
    />
  )
}

export default PriceCalculation;