import React from 'react';
import { withRouter } from 'react-router';
import { getUserFromLocalStorage } from 'src/base/OAuth';
import UserType from 'src/constants/UserType';
import { createDraftFromOrder, getOrderById } from 'src/services/OrderService';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import SubmitButton from 'src/redesign/components/Buttons/Submit';
import DraftDialog from 'src/redesign/components/Dialog/Draft';
import Navigation from 'src/redesign/components/Navigation/LinkNavigation';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import OrderDetailsItems from './tabs/Items';
import OrderDetailsShipment from './tabs/Shipment';
import OrderDetailsStatus from './tabs/Status';
import OrderDetailsSummary from './tabs/Summary';

import './style.scss';
import OrderDetailsHistory from './tabs/History';
import OrderDetailsPrintReady from './tabs/PrintReady';

const OrderDetails = ({ match } ) => {
  const orderId = match.params.id;
  const user = getUserFromLocalStorage();
  const isCustomer = user.user_type === UserType.CUSTOMER;
  
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [isDraftDialogOpen, setIsDraftDialogOpen] = React.useState(false);

  const [order, setOrder] = React.useState(undefined);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await getOrderById(orderId);
      
      setOrder(data);
    } catch (error) {
      openSnackBar('Failed to load order.', false)
    } finally {
      setLoading(false);
    }
  }

  const createDraft = async ({ name }) => {
    try {
      await createDraftFromOrder({
        orderId,
        name
      });
      openSnackBar('Successfully created draft');
      setIsDraftDialogOpen(false);
    } catch (error) {
      console.error(error);
      openSnackBar('Failed to create draft', false);
    }
  }

  const adminTabsMaybe = isCustomer
  ? []
  : 
  [
    {
      text: 'History',
      to: `/order-details/${orderId}/history`,
      onRender: () => <OrderDetailsHistory order={order} />
    },
    {
      text: 'Print Ready',
      to: `/order-details/${orderId}/print-ready`,
      onRender: () => <OrderDetailsPrintReady order={order} />
    }
  ];

  return (
    <ApplicationPage selectedTab='orders'>
      <ApplicationPageContent title={`Order number: ${orderId}`}>
          {isCustomer && (
            <SubmitButton text='Make a Draft' className='draft-button' width={162} onClick={() => setIsDraftDialogOpen(true)} />
          )}
          {order && 
          <>
          <div className='order-details-content'>
              <Navigation tabs={[
                {
                  text: 'Summary',
                  to: `/order-details/${orderId}/summary`,
                  onRender: () => <OrderDetailsSummary order={order} onUpdate={setOrder}/>
                },
                {
                  text: 'Items',
                  to: `/order-details/${orderId}/items`,
                  onRender: () => <OrderDetailsItems order={order} onUpdate={setOrder}/>
                },
                {
                  text: 'Status',
                  to: `/order-details/${orderId}/status`,
                  onRender: () => <OrderDetailsStatus order={order} onUpdate={setOrder}/>
                },
                {
                  text: 'Shipment',
                  to: `/order-details/${orderId}/shipment`,
                  onRender: () => <OrderDetailsShipment order={order} onUpdate={setOrder}/>
                },
                ...adminTabsMaybe
              ]}
                loading={loading}
              />
            </div>
            {isDraftDialogOpen && (
              <DraftDialog
                text='Make a draft'
                onClose={() => setIsDraftDialogOpen(false)}
                onSubmit={createDraft}
              />
            )}
            </>}
      </ApplicationPageContent>
    </ApplicationPage>
  )
};

export default withRouter(OrderDetails);