import React from 'react';
import Page from 'src/redesign/components/Page';
import ContactUsForm from 'src/redesign/components/ContactUsForm';
import WhyChooseUs from 'src/redesign/components/WhyChooseUs';
import SignupLogin from 'src/redesign/components/SignupLogin';

import './style.scss';

const ContactUs = () => {

  return <Page
    seoTitle='Contact Us | Surge Print'
    seoDescription='Contact Us Page. SurgePrint offers high-quality, customizable printing solutions for a lasting impression. Unleash the power of print excellence!'
  >
    <div className='contact-us-container'>
      <ContactUsForm />
      <WhyChooseUs />
      <SignupLogin />
    </div>
  </Page>
}

export default ContactUs;