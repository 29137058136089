import React from 'react';
import classNames from 'classnames';
import IconCopy from 'src/redesign/components/Icon/CopyLink';
import { copyToClipboard } from 'src/redesign/helpers/form';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const TextCopy = ({ text, className }) => (
  <div className={classNames('text-copy', className)}>
    {text}
    {text && <div onClick={() => copyToClipboard(text)} style={{ cursor: 'pointer' }}>
        <IconCopy width={14} height={14} color={colors.primary}/>
      </div>} 
  </div>
);

export default TextCopy;