import React from 'react';

import './style.scss';
import { CardElement } from '@stripe/react-stripe-js';


const CardInput = () => {
  const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      width: '317px',
      color: "#32325d",
      fontFamily: '"DM Sans", "DM Sans", "DM Sans"',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      fontWeight: 400,
      "::placeholder": {
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
    invalid: {
      color: "#FF6A55",
      iconColor: "#FF6A55",
    },
  },
  };

  return (
    <div className='card-input'>
      <div className="label">
        Card Number
      </div>
      <div className='field'>
        <CardElement options={CARD_ELEMENT_OPTIONS} id={"card-element"}/>
      </div>
    </div>
  )
}

export default CardInput;