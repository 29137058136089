import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function createBrand(data) {
    return await request('/api/brand' , data,  HttpMethod.POST);
}
export async function getBrands() {
    return await request('/api/get_brands' , [],  HttpMethod.GET);
}
export async function getBrandsForCategory(id) {
    return await request('/api/get_brands_for_category/' + id , [],  HttpMethod.GET);
}
export async function getProductsForCategoryAndBrand(categoryId, brandId) {
    return await request('/api/get_brands_for_category_and_brand/' + categoryId + '/' + brandId, [],  HttpMethod.GET);
}
