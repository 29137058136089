import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';

import TextInput from 'src/redesign/components/Form/Sidepane/TextInput';
import PriceInput from 'src/redesign/components/Form/Sidepane/PriceInput';
import PricesInput from 'src/redesign/components/Form/Sidepane/PricesInput';
import SelectInput from 'src/redesign/components/Form/Sidepane/SelectInput';
import ToggleSwitch from 'src/redesign/components/Form/ToggleSwitch';
import CheckboxInput from 'src/redesign/components/Form/Checkbox';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import Loader from 'src/redesign/components/Loader';
import IconTrash from 'src/redesign/components/Icon/Trash';
import SecondaryButton from 'src/redesign/components/Buttons/Secondary';
import CreateAttributeSidepane from './components/CreateAttributeSidepane';
import AboutProductAttribute from './components/Attribute';
import CreatableSelect from 'src/redesign/components/Form/CreatableSelect';
import Image from 'src/redesign/components/Image';
import { getCategories } from 'src/services/CategoryService';
import { createBrand, getBrands } from 'src/services/admin/BrandService';
import { getVendors } from 'src/services/VendorService';
import { getAttributeValuesForAttribute } from 'src/services/admin/AttributeService';
import { createProduct, deleteProduct, generateProductVariations, getProduct, getProductAttributes, getShippingPricingProduct, setVariationAvailability, updateProduct, uploadProductImage } from 'src/services/admin/ProductService';
import { getImageAlt } from 'src/redesign/helpers';
import { mapAttributeValues, mapProduct, mapShippingPrices } from './helpers';
import * as Validators from 'src/redesign/util/validators';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const AboutProduct = ({ history, match: { params: { id }} }) => {
  const [productLading, setProductLoading] = React.useState(false);
  const [categoriesLoading, setCategoriesLoading] = React.useState(false);
  const [brandsLoading, setBrandsLoading] = React.useState(false);
  const [vendorsLoading, setVendorsLoading] = React.useState(false);

  const [categoryOptions, setCategoryOptions] = React.useState([]);

  const [brandOptions, setBrandOptions] = React.useState([]);
  const [selectedBrand, setSelectedBrand] = React.useState(null);

  const [vendorOptions ,setVendorOptions] = React.useState([]);

  const [product, setProduct] = React.useState({});
  const [attributeValues, setAttributeValues] = React.useState([]);
  const [newAttributeValues, setNewAttributeValues] = React.useState([]);

  const [productImage, setProductImage] = React.useState(null);
  const [imageUploadInProgress, setImageUploadInProgress] = React.useState(false);
  
  const [isAddAttributeOpen, setIsAddAttributeOpen] = React.useState(false);
  const [isAddAttributeLoading, setIsAddAttributeLoading] = React.useState(false);

  const [toggleAvailabilityInProgress, setToggleAvailabilityInProgress] = React.useState(false);

  const { openSnackBar } = useSnackbar();

  const initiallySelectedBrand = brandOptions.find(x => x.value == product?.brandId);

  const loading = productLading || categoriesLoading || brandsLoading || vendorsLoading;

  const isNew = id == -1;
  const image = productImage ? process.env.REACT_APP_baseUrl + productImage.url : null;

  //#region load

  React.useEffect(() => {
    load();
  }, [id]);
  
  const load = async () => {
    await loadProduct();
    await loadCategories();
    await loadBrands();
    await loadVendors();
  }

  const loadProduct = async () => {
    if(isNew) return;
    setProductLoading(true);
    try {
      const { data } = await getProduct(id);

      const { data: attributesValues } = await getProductAttributes(id);
      const attributeValuesMapped = mapAttributeValues(attributesValues);

      const { data: shippingPrices } = await getShippingPricingProduct(id);
      const shippingPricesMapped = mapShippingPrices(shippingPrices);

      setProduct(mapProduct(data, shippingPricesMapped));
      setProductImage(data?.product_image);
      setAttributeValues(attributeValuesMapped);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load product', false);
    } finally {
      setProductLoading(false);
    }
  }

  const loadCategories = async () => {
    setCategoriesLoading(true);
    try {
      const { data } = await getCategories();

      const categoryOptions = data.reduce((acc, x) => {
        if(data.some(p => p.parent_category?.id == x.id)) {
          return acc;
        }
        return [...acc, { key: x.id, text: x.name }];
      }, []);

      setCategoryOptions(categoryOptions);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load categories', false);
    } finally {
      setCategoriesLoading(false);
    }
  }

  const loadBrands = async () => {
    setBrandsLoading(true);
    try {
      const { data } = await getBrands();

      const brandOptions = data.map(x => ({ value: x.id, label: x.name }));
      setBrandOptions(brandOptions);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load brands', false);
    } finally {
      setBrandsLoading(false);
    }
  }

  const loadVendors = async () => {
    setVendorsLoading(true);
    try {
      const { data } = await getVendors({ page: 1, perPage: 1000 });

      const vendorOptions = data.result.map(x => ({ key: x.id, text: x.name }));

      setVendorOptions(vendorOptions);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load vendors', false);
    } finally {
      setVendorsLoading(false);
    }
  }

  //#endregion

  //#region save

  const onFilePick = async event => {
    setImageUploadInProgress(true);

    try {
      const file = event.target.files[0];
      const formData = new FormData();
  
      formData.append('image', file);
  
      const { data } = await uploadProductImage(formData);
      setProductImage(data);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to upload product image', false);
    } finally {
      setImageUploadInProgress(false);
    }
  }

  const onCreateSelectBrandOption = async brand => {
    if(brand.__isNew__) {
      try {
        const { data } = await createBrand({ name: brand.label });
        const newBrand = { value: data.id, label: data.name };

        setBrandOptions(prev => [...prev, newBrand]);
        setSelectedBrand(newBrand);
      } catch(e) {
        openSnackBar('Failed to create brand', false);
      }
    } else {
      setSelectedBrand(brand);
    }
  }

  // handle newAttributeValues
  // za novi proizvod uvek je samo newAttributes
  // za postojeci new su samo dodati preko edit

  const onAttributeAdd = async (newAttributeValues, type, attributeId) => {
    setIsAddAttributeLoading(true);
    try {
      const { data } = await getAttributeValuesForAttribute({
        new: true,
        attributeId: newAttributeValues[0].id,
        alreadyHave: []
      });

      const isAttributeAdded = attributeValues?.find(x => x?.attributeValues?.[0]?.attribute?.id == attributeId);

      const attributeToAdd =
      {
        attributeValues: data,
        type: type,
        new: true
       };

      const updatedAttributeValues = isAttributeAdded 
      ? [...attributeValues?.filter(x => x?.attributeValues?.[0]?.attribute?.id == attributeId), attributeToAdd]
      : [...attributeValues, attributeToAdd];

      setAttributeValues(updatedAttributeValues);
      setIsAddAttributeOpen(false);
    } catch(e) {
      console.error(e);
      openSnackBar('Failed to add attribute', false);
    } finally {
      setIsAddAttributeLoading(false);
    }
  }

  const onToggleProductAvailability = async () => {
    if(toggleAvailabilityInProgress) return;
    setToggleAvailabilityInProgress(true);
    try {
      await setVariationAvailability(product.id, !product.isAvailable);

      setProduct(prev => ({ ...prev, isAvailable: !prev.isAvailable }));
    } catch(error) {
      console.error(error);
      openSnackBar(`Failed to ${product.isAvailable ? 'deactivate': 'activate'} product availability`, false);
    } finally {
      setToggleAvailabilityInProgress(false);
    }
  }

  const onDeleteProduct = async () => {
    try {
      const response = await deleteProduct(id);

      if(response.ok) {
        openSnackBar(`Successfully deleted ${response.data} product`);
        history.push('/category/-1');
      }
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to delete product', false);
    } finally {

    }
  }

  const onCreateEditProduct = async values => {
    const data = {
      ...values,
      imageId: productImage.id,
      brandId: selectedBrand?.value ?? initiallySelectedBrand?.value,
      attributeValues,
      newAttributeValues
    };

    try {
      const { data: { id, imageId }} = await (isNew ? createProduct(data) : updateProduct(data));

      if(isNew) {
        const response = await generateProductVariations({ id: id, image: imageId });

        if(response.ok) {
          openSnackBar('Successfully saved');
          history.push('/product/about/' + id);
        } else {
          openSnackBar('Failed to create product', false);
        }
      }
      loadProduct();
    } catch(error) {
      openSnackBar(`Failed to ${isNew ? 'create' : 'update'} product`, false);
    }
  }

  //#endregion

  return (
    loading
    ? (
      <div className='about-product-page-loader-container'>
        <Loader width={200} height={200} color={colors.primary} />
      </div>
      )
    :
    <>
      <FinalForm onSubmit={onCreateEditProduct} initialValues={product} render={({ handleSubmit, valid, submitting }) => (
        <form id="aboutProductForm" className='about-product-page' onSubmit={handleSubmit}>
          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Image
            </div>
            <div className='about-product-field-column-container'>
              {imageUploadInProgress
              ? (
                <div className='about-product-field-image'>
                  <Loader className='about-product-field-image-loader' width={75} height={75} color={colors.primary} />
                </div>
                )
              : (
                <Image alt={getImageAlt()} className='about-product-field-image' src={image ?? '/images/image-placeholder.png'} />
              )}
              <input type='file' id='productImage' name='productImage' onChange={onFilePick} disabled={imageUploadInProgress} />
            </div>
            {!isNew && (
              <div className='about-product-field-column-container'>
                <div
                  className='about-product-field-toggle-container'
                  onClick={(e) => {
                    e.preventDefault();
                    onToggleProductAvailability();
                  }}
                >
                  Deactivate
                  <ToggleSwitch checked={product.isAvailable} disabled={toggleAvailabilityInProgress} onChange={()=>{}} />
                </div>
                <SecondaryButton
                  text={<div className='delete-product-button'>Delete Product <IconTrash width={24} height={24} color={colors.red}/></div>}
                  onClick={(e) => {
                    e.preventDefault();
                    onDeleteProduct();
                  }}
                />
              </div>
            )}
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Product Name
            </div>
            <TextInput
              name='name'
              placeholder='Product Name'
              className='about-product-field-text'
              validate={Validators.required(' ')}
            />
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Product Category
            </div>
            <SelectInput
              name='categoryId'
              options={categoryOptions}
              placeholder='Select...'
              className='about-product-select'
              // validate={Validators.required(' ')}
            />
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Brand
            </div>
            <CreatableSelect
              name='brandId'
              options={brandOptions}
              placeholder='Select...'
              className='about-product-select'
              onChange={onCreateSelectBrandOption}
              value={selectedBrand || initiallySelectedBrand}
              // validate={Validators.required(' ')}
            />
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Description
            </div>
            <TextInput
              name='description'
              type='textarea'
              className='about-product-description'
              placeholder='Enter description...'
              validate={Validators.required(' ')}
            />
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Attributes
            </div>
            <div className='about-product-field-column-container'>
              {isNew && (
                <SecondaryButton
                  text='Add new attribute'
                  onClick={(e) => {
                    e.preventDefault();
                    setIsAddAttributeOpen(prev => !prev);
                  }}
                />
              )}
              {attributeValues.map(x => {
                const attribute = x.attributeValues?.[0]?.attribute;

                return (
                  <AboutProductAttribute
                    name={attribute.name}
                    onDeleteAttribute={() => setAttributeValues(prev => prev.filter(a => !a.attributeValues.every(v => v.attribute.id == attribute.id)))}
                    type={x.type}
                    values={x.attributeValues}
                    // onAddValue = kad nije new i kad klikne na '+'
                    onDeleteValue={(v) =>
                      setAttributeValues(prev =>
                        x.attributeValues.length == 1
                            ? prev.filter(a => a != x)
                            : prev.map(a => a == x ? { ...x, attributeValues: x.attributeValues.filter(aV => aV != v)} : a))
                    }
                    isNew={isNew}
                  />
                )})}
            </div>
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Price
            </div>
            <div className='about-product-field-column-container'>
              {!isNew && (
                <div className='about-product-field-checkbox'>
                  <div className='about-product-field-checkbox-label'>
                    Change Variation Price
                  </div>
                  <CheckboxInput name="changePrice" />
                </div>
              )}
              <PriceInput
                name='price'
                placeholder='Price'
                className='about-product-field-price'
                validate={Validators.required(' ')}
              />
            </div>
          </div>

          {/* TODO: uncomment when it is handled on backend */}
          {/* <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Vendor
            </div>
            <SelectInput
              name='vendorId'
              options={vendorOptions}
              placeholder='Select...'
              className='about-product-select'
            />
          </div>*/}
          
          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Vendor Price
            </div>
            <div className='about-product-field-column-container'>
              {!isNew && (
                <div className='about-product-field-checkbox'>
                  <div className='about-product-field-checkbox-label'>
                    Change Variation Vendor Price
                  </div>
                  <CheckboxInput name="changeVendorPrice" />
                </div>
              )}
              <PriceInput
                name='vendorPrice'
                placeholder='Vendor Price'
                className='about-product-field-price'
              />
            </div>
          </div> 

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Shipping Prices
            </div>
            <div className='about-product-field-column-container'>
              {!isNew && (
                <div className='about-product-field-checkbox'>
                  <div className='about-product-field-checkbox-label'>
                    Change Shipping Price
                  </div>
                  <CheckboxInput name="changeShipping" />
                </div>
              )}
              <PricesInput 
                isUS
                standard={{
                  first: 'usFirstStandard',
                  eachAdditional: 'usOtherStandard',
                }}
                expedited={{
                  first: 'usFirstExpedited',
                  eachAdditional: 'usOtherExpedited',
                }}
                overnight={{
                  first: 'usFirstOvernight',
                  eachAdditional: 'usOtherOvernight',
                }}
              />
            </div>
          </div>
          <div className='about-product-field-container'>
            <div className='about-product-label-container' />
            <PricesInput 
              isUS={false}
              standard={{
                first: 'intFirstStandard',
                eachAdditional: 'intOtherStandard',
              }}
              expedited={{
                first: 'intFirstExpedited',
                eachAdditional: 'intOtherExpedited',
              }}
            />
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Vendor Shipping Prices
            </div>
            <div className='about-product-field-column-container'>
              {!isNew && (
                <div className='about-product-field-checkbox'>
                  <div className='about-product-field-checkbox-label'>
                    Change Vendor Shipping Price
                  </div>
                  <CheckboxInput name="changeVendorShipping" />
                </div>
              )}
              <PricesInput 
                isUS
                standard={{
                  first: 'vendorUsFirstStandard',
                  eachAdditional: 'vendorUsOtherStandard',
                }}
                expedited={{
                  first: 'vendorUsFirstExpedited',
                  eachAdditional: 'vendorUsOtherExpedited',
                }}
                overnight={{
                  first: 'vendorUsFirstOvernight',
                  eachAdditional: 'vendorUsOtherOvernight',
                }}
              />
            </div>
          </div>
          <div className='about-product-field-container'>
            <div className='about-product-label-container' />
            <PricesInput 
              isUS={false}
              standard={{
                first: 'vendorIntFirstStandard',
                eachAdditional: 'vendorIntOtherStandard',
              }}
              expedited={{
                first: 'vendorIntFirstExpedited',
                eachAdditional: 'vendorIntOtherExpedited',
              }}
            />
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Guidelines link
            </div>
            <TextInput
              name='guidelinesLink'
              className='about-product-field-text'
              placeholder='Guidelines link'
            />
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Seo title
            </div>
            <TextInput
              name='seoTitle'
              className='about-product-field-text'
              placeholder='Seo title'
            />
          </div>

          <div className='about-product-field-container'>
            <div className='about-product-label-container'>
              Deo description
            </div>
            <TextInput
              name='seoDescription'
              className='about-product-field-text'
              placeholder='Seo description'
            />
          </div>

          <SubmitPrimaryButton
            text='SAVE'
            type='submit'
            className='product-details-submit-button'
            width={64}
            disabled={!valid || submitting || !productImage}
            loading={submitting}
            />
        </form>
      )}
      />
      {isAddAttributeOpen && (
        <CreateAttributeSidepane
          onClose={() => setIsAddAttributeOpen(false)}
          onAdd={onAttributeAdd}
          isAddAttributeLoading={isAddAttributeLoading}
        />
      )}
    </>
  )
}

export default withRouter(AboutProduct);