import React from 'react';
import { number, string } from 'prop-types';

const IconCircleExit = ({ width, height, color, secondColor }) => (
  <svg width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5.75" cy="6.5" r="5.75" fill={color}/>
    <path d="M2.46967 8.46967C2.17678 8.76256 2.17678 9.23744 2.46967 9.53033C2.76256 9.82322 3.23744 9.82322 3.53033 9.53033L2.46967 8.46967ZM8.53033 4.53033C8.82322 4.23744 8.82322 3.76256 8.53033 3.46967C8.23744 3.17678 7.76256 3.17678 7.46967 3.46967L8.53033 4.53033ZM3.53033 9.53033L8.53033 4.53033L7.46967 3.46967L2.46967 8.46967L3.53033 9.53033Z" fill={secondColor}/>
    <path d="M3.53033 3.46967C3.23744 3.17678 2.76256 3.17678 2.46967 3.46967C2.17678 3.76256 2.17678 4.23744 2.46967 4.53033L3.53033 3.46967ZM7.46967 9.53033C7.76256 9.82322 8.23744 9.82322 8.53033 9.53033C8.82322 9.23744 8.82322 8.76256 8.53033 8.46967L7.46967 9.53033ZM2.46967 4.53033L7.46967 9.53033L8.53033 8.46967L3.53033 3.46967L2.46967 4.53033Z" fill={secondColor}/>
</svg>
);

IconCircleExit.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconCircleExit;