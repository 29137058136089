import React from 'react';

import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const ProductDetailsProduction = ({ }) => {

  return (
    <div className='product-details-production'>
      {/* <div className='product-details-production-title'>
        Technology & Inks
      </div>
      <div className='product-details-production-description'>
        Monster Digital uses only top of the line equipment; they currently run Kornit Avalanche HD6 and the brand new Atlas machines. Monster Digital has built proprietary software and technology that runs throughout our operation. It is the secret sauce that makes their equipment and operation teams run at optimal efficiency. The entire process is eco-friendly; inks are water based, bio-degradable and non-toxic; their production is fully automated and paperless. Monster Digital uses the newest inks and pre-sprays and there is absolutely no odour.
      </div> */}

      <div className='product-details-production-title'>
        Quality control
      </div>
      <div className='product-details-production-description'>
        The difference is in the details. Every garment is meticulously R&D’d for best print and color results. Checkpoints throughout the process insure your garments are picked, printed, packed and shipped correctly. Ongoing software and system advances help us to stay ahead of the curve.
      </div>

      <div className='product-details-production-title'>
        Packaging control
      </div>
      <div className='product-details-production-description'>
        All garments are professionally folded and carefully inserted into self sealing poly mailers with UV protective coating inside. Large orders are packaged in a corrugated box.
      </div>
      <Image alt={getImageAlt()} src='/images/package.png' className='product-details-production-image' />
    </div>
  )
}

export default ProductDetailsProduction;