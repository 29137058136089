import React, {Component} from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import SettingsPage from "../constants/SettingsPage";
import MyProfile from "../components/settings/MyProfile";
import BusinessInformation from "../components/settings/BusinessInformation";
import BillingInformation from "../components/settings/BillingInformation";
import {getCurrentUserAction} from "../services/UserService"
import {setUserToLocalStorage} from "../base/OAuth";

class Settings extends Page {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: props.match.params.type == "billing" ?  SettingsPage.BILLING_INFORMATION : SettingsPage.MY_PROFILE,
        }

        this.changePage = this.changePage.bind(this);
    }

    componentDidMount(){
        getCurrentUserAction().then(response => {
            if (!response.ok) {
                return;
            }

            setUserToLocalStorage(response.data.user);

            this.setState({
                ...this.state,
                user: response.data,
            })
        });
    }

    changePage(page){
        this.setState({
            ...this.state,
            currentPage: page
        });
    }


    render() {
        return (
            <div id="settings">

                <div className="header">
                    <div className={this.state.currentPage == SettingsPage.MY_PROFILE ? "header-item active" : "header-item"} onClick={ () => this.changePage(SettingsPage.MY_PROFILE)}>My profile</div>
                    <div className={this.state.currentPage == SettingsPage.BUSINESS_INFORMATION ? "header-item active" : "header-item"} onClick={ () => this.changePage(SettingsPage.BUSINESS_INFORMATION)}>Bussines information</div>
                    <div className={this.state.currentPage == SettingsPage.BILLING_INFORMATION ? "header-item active" : "header-item"} onClick={ () => this.changePage(SettingsPage.BILLING_INFORMATION)}>Billing information</div>
                </div>

                {
                    this.state.currentPage == SettingsPage.MY_PROFILE &&
                    <MyProfile />
                }
                {
                    this.state.currentPage == SettingsPage.BUSINESS_INFORMATION &&
                    <BusinessInformation />
                }
                {
                    this.state.currentPage == SettingsPage.BILLING_INFORMATION &&
                    <BillingInformation />
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings)));
