import React from 'react'

import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSnackbar} from "notistack";
import {Button, TextField, InputAdornment} from "@mui/material";
import { CreditCard as CreditCardIcon } from "@mui/icons-material";
import SelectControl from "../controls/SelectControl";
import Validators from "../../constants/ValidatorTypes";
import CheckoutForm from "../stripe/CheckoutForm";
import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { getPrimaryMethod,
        editBillingInformation } from "../../services/BillingInformation";
import {getCountryList} from "../../services/CountryService";
import {chargeOrderDeclined} from "../../services/OrderService";

const stripePromise = loadStripe(process.env.REACT_APP_pk_live);

class BillingInformation extends Page {

    validationList = {
        nameOnCard: [ {type: Validators.REQUIRED } ],
        billingAddress: [ {type: Validators.REQUIRED } ],
        city: [ {type: Validators.REQUIRED } ],
        stateProvince: [ {type: Validators.REQUIRED } ],
        country: [ {type: Validators.REQUIRED } ],
        postal: [ {type: Validators.REQUIRED } ]
    };


    constructor(props) {
        super(props);

        this.state = {
            edit: false,
            data: {
                nameOnCard: null,
                billingSameAsBusiness: null,
                lastFourDigits: null,
            },
            countries: []
        }

        this.submitToken = this.submitToken.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(){
        getPrimaryMethod().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                data: {
                    id: response.data.id,
                    nameOnCard: response.data.name_on_card,
                    billingSameAsBusiness: response.data.billing_address_same_as_business,
                    lastFourDigits: "**** **** **** " + response.data.last_four_digits != undefined ? response.data.last_four_digits : "****",
                    expiration: (response.data.expiration_month != undefined ? response.data.expiration_month : "") + "/" + (response.data.expiration_year != undefined ? response.data.expiration_year : "") ,
                    billingAddress: response.data.billing_address,
                    addressLineTwo: response.data.address_line_two,
                    city: response.data.city,
                    stateProvince: response.data.state_province,
                    postal: response.data.postal,
                    country: response.data.country
                },
                edit: response.data.last_four_digits == undefined
            })
        });
        this.getCountryList();
    }

    save(){

        document.getElementById("submit-card").click();

        setTimeout(() => {
            if (this.state.validCard == false) {
                return;
            }

            if (!this.validate()) {
                this.props.enqueueSnackbar("Please fill all required fields!", {variant: 'error'});
                return;
            }

            editBillingInformation(this.state.data).then(response => {

                if (response.data.message === 'Card Declined') {
                    this.props.enqueueSnackbar("Payment Failed", {variant: 'error'});
                } else {
                    window.location.reload();
                    this.props.enqueueSnackbar("Successfully saved!", {variant: 'success'});
                }
            });
        }, 1000);
    }

    submitToken(token){

        if(token == null){
            this.props.enqueueSnackbar("Your card number is invalid!", {variant: 'error'});
            this.state.validCard = false;
            return;
        }

        this.setState({
            ...this.state,
            validCard : true,
            data: {
                ...this.state.data,
                tokenId: token.id,
                expMonth: token.card.exp_month,
                expYear: token.card.exp_year,
                last4: token.card.last4
            }
           
        })
    }

    edit(){
        this.setState({
            ...this.state,
            edit: true
        })
    }

    getCountryList(){
        getCountryList().then(response => {
           
             this.setState({
                 ...this.state,
                countries: response.data.countries
            })
        });
    }

    chargeDeclined(){
        this.setState({
            ...this.state,
            loader : true
        });

        chargeOrderDeclined().then((response)=> {
            if (!response.ok) {
                return
            }

            this.setState({
                ...this.state,
                loader : false
            });
        });
    }

    render() {

        return (
            <div id="business-information">
                {
                    this.state.loader &&
                    <div className={'loader-cont'}>
                        <img src={'/images/loader.gif'} />
                    </div>
                }
                <div className="content">
                    <div className="my-profile-header">Billing information</div>
                    <TextField
                        className={"search-field"}
                        autoFocus
                        name='nameOnCard'
                        onChange={ this.changeData }
                        margin="normal"
                        value={ this.state.data.nameOnCard }
                        placeholder = { "Name on card" }
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        label="Name on card"
                        InputProps={{
                            readOnly: this.state.edit ? false : true,
                        }}
                    />
                     {
                        !this.state.edit && 
                        <div className="row-card">
                            <TextField
                                className={"card-number"}
                                name='searchTerm'
                                margin="normal"
                                value={ this.state.data.lastFourDigits }
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                          <CreditCardIcon />
                                        </InputAdornment>
                                      ),
                                }}
                                InputLabelProps={{ shrink: true }}
                                label="Card number"
                            />
                            <TextField
                                className={"search-field"}
                                //name='searchTerm'
                                margin="normal"
                                value={ this.state.data.expiration }
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                                label="MM / YYYY"
                            />
                            <TextField
                                className={"search-field"}
                                name='searchTerm'
                                margin="normal"
                                value={ "***" }
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                                label="CVC"
                            />
                        </div>
                    }
                    {
                        this.state.edit && 
                        <Elements stripe={stripePromise}>
                            <CheckoutForm submitToken={this.submitToken}/>
                        </Elements>
                    }
                    <TextField
                        className={"search-field"}
                        name='billingAddress'
                        onChange={ this.changeData }
                        margin="normal"
                        value={ this.state.data.billingAddress }
                        placeholder = { "Billing Address" }
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        label="Billing address"
                        InputProps={{
                            readOnly: this.state.edit ? false : true,
                        }}
                    />
                    <TextField
                        className={"search-field"}
                        name='addressLineTwo'
                        onChange={ this.changeData }
                        margin="normal"
                        value={ this.state.data.addressLineTwo }
                        placeholder = { "Address Line 2" }
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        label="Address line 2"
                        InputProps={{
                            readOnly: this.state.edit ? false : true,
                        }}
                    />
                    <TextField
                        className={"search-field"}
                        name='city'
                        onChange={ this.changeData }
                        margin="normal"
                        value={ this.state.data.city }
                        placeholder = { "City" }
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        label="City"
                        InputProps={{
                            readOnly: this.state.edit ? false : true,
                        }}
                    />
                    <div className="row">
                        <TextField
                            className={"search-field"}
                            name='stateProvince'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.stateProvince }
                            placeholder = { "State/Province" }
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            label="State/Province"
                            InputProps={{
                                readOnly: this.state.edit ? false : true,
                            }}
                        />
                        <TextField
                            className={"search-field"}
                            name='postal'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.postal }
                            placeholder = { "Postal Code" }
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            label="Postal code"
                            InputProps={{
                                readOnly: this.state.edit ? false : true,
                            }}
                        />
                    </div>
                    <div className="row-country">
                        <SelectControl
                            className='payment-country'
                            options={this.state.countries}
                            valueKey = { 'id' }
                            onChange={ this.changeData }
                            nameKey= { 'name' }
                            name = {'country'}
                            selected={this.state.data.country}
                            placeholder = {'Country'}
                            InputLabelProps={{ shrink: true }}
                            label="Country"
                            disabled = {this.state.edit ? false : true}
                        />
                    </div>
                    {
                        this.state.edit && 
                        <div className="row">
                            <Button onClick={ () => this.save() }>Save changes</Button>
                        </div>
                    }
                    {
                        !this.state.edit && 
                        <div className="row">
                            <Button onClick={ () => this.edit() }>Edit</Button>
                        </div>
                    }
                </div>

                <label>
                    To reinitiate charging of the orders in payment failed
                <Button style={{marginLeft: '10px'}} variant="contained" color="primary" onClick={()=> {
                    this.chargeDeclined();
                }}>click here</Button>
                </label>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingInformation)));
