import React from 'react';
import LibraryItem from '../LibraryItem';
import { v4 as uuid } from 'uuid';

import './style.scss';

const LibraryList = ({ items, selectedTemplate, ...rest }) => {

  return (
    <div className='library-templates'>
      <div className='grid-container'>
        {items.map(x => (
          <LibraryItem
            selected={x.template ? x?.template?.id === selectedTemplate?.template?.id : false}
            key={uuid()}
            item={x} 
            {...rest}
          />
        ))}
      </div>
      {selectedTemplate && (
        <div className='selected-container'>
          {selectedTemplate.templateVariations?.map(x => (
            <LibraryItem
              {...rest}
              key={uuid()}
              item={x}
              selected={false}
              onClick={() => {}}
              isSubVariation
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default LibraryList;