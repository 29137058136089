import classNames from 'classnames';
import React from 'react';
import { withRouter } from 'react-router';
import { CATEGORIES_TABS } from 'src/redesign/constants';
import { v4 as uuid } from 'uuid';

import './style.scss';

const TabsCatalog = ({ history, selectedTab, location: { state: { importedProduct } = {}} }) => (
  <div className='tab-catalog-container'>
    {CATEGORIES_TABS.map(x => (
      <div className={classNames('tab-catalog', {'tab-catalog-selected': selectedTab === x.key})} onClick={() => history.push(`/product-catalog-in-app/${x.key}`, { importedProduct })} key={uuid()}>
        {x.text}
      </div>
    ))}
  </div>
);

export default withRouter(TabsCatalog);