import React from 'react'

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button, TextField} from "@material-ui/core";
import {withSnackbar} from "notistack";
import { getOrdersDeprecated,
         getNumberOfOrders,
         advancedSearch } from "../services/OrderService";
import {dateToString} from "../util/DateUtil";
import OrderStatus, {getOrderStatusString} from "../constants/OrderStatus";
import ReactDOM from 'react-dom';
import { getAllCustomers } from "../services/UserService";
import {getUserFromLocalStorage} from "../base/OAuth";
import UserType from '../constants/UserType';
import SelectControl from "../components/controls/SelectControl";
import OrderEnum from '../constants/OrderEnum';
import {getCountryList} from "../services/CountryService";
import DateTimePicker from '@mui/lab/DateTimePicker';
import update from "immutability-helper";

class Orders extends Page {

    params = [ { name: 'page', default: 1 },
               { name: 'perPage', default: 12 }];


    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            perPage: 15,
            orders: [],
            dropdownOpen: null,
            data: {
                searchTerm: null,
                customer: null,
                advancedOrderId: null,
                advancedPartnerOrderId: null,
                advancedPartener: null,
                advancedCountry: null,
                advancedName: null,
                advancedStatus: null,
                advancedFrom: null,
                advancedTo: null,
                page: 1,
                perPage: 15,
                orderStatus: null,
                customerId: null,
                advancedCustomerEmail: null,
                advancedVendor: null,
                advancedVendorOrderId: null,
                advancedShippingName: null,
                advancedFromString: null,
                advancedToString: null
            },
            userType: getUserFromLocalStorage().user_type,
            customers: [],
            new: null,
            all: null,
            readyForPrinting: null,
            pending: null,
            orderStatus: null,
            advancedSearchActive: false,
            display: 'none',
            countries: []
        }

        this.onPageClick = this.onPageClick.bind(this);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.changeStateOfAdvancedSearch = this.changeStateOfAdvancedSearch.bind(this);
        this.advancedSearch = this.advancedSearch.bind(this);
        this.discardAdvanced = this.discardAdvanced.bind(this);
        this.customChangeData = this.customChangeData.bind(this);
    }

    fetchData(){
        this.getOrders(this.state.page, this.state.perPage);
        setTimeout(()=>{
            this.getOrders(this.state.page, this.state.perPage);
        },500)
    }

    componentDidMount() {
        if(this.state.userType == UserType.ADMIN){
            this.getAllCustomers();
        }
        this.getNumberOfOrders();
        this.getCountryList();
        document.addEventListener('click', this.handleClickOutside, true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    getCountryList(){
        getCountryList().then(response => {
           
             this.setState({
                 ...this.state,
                countries: response.data.countries
            })
        });
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
 
        if (this.state.dropdownOpen) {
            if(event.target.className != "opt" && event.target.className != "dropdown-opt"){
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    dropdownOpen: false
                });
            }
        }
    }

    keyPress(event) {

        if(event.key == 'Enter') {
            this.getOrders(1, this.state.perPage);
        }
    }

    getNumberOfOrders(){
        getNumberOfOrders({
            customerId: this.state.data.customer ? (this.state.data.customer.id != undefined ?  this.state.data.customer.id : null) : null
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                all: response.data.all,
                new: response.data.new,
                pending: response.data.pending,
                inProduction: response.data.inProduction,
                shipped: response.data.shipped,
                issues: response.data.issues
            })
        });
    }

    getAllCustomers(){
        getAllCustomers().then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                customers: response.data
            })
        });
    }

    getOrders(page, perPage){
        if (this.state.disabled) {
            return;
        }

        this.state.disabled = true;
        getOrdersDeprecated({
            page : page, 
            perPage : perPage,
            searchTerm: this.state.data.searchTerm != "" ? this.state.data.searchTerm : null,
            customerId: this.state.data.customer ? this.state.data.customer.id : null,
            status: this.state.orderStatus
        }).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                orders: response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page,
                disabled : false
            })
        });
    }

    openDropdown(id,e) {
        e.stopPropagation();

        if(this.state.dropdownOpen != null){
            this.setState({
                ...this.state,
                dropdownOpen: null
            })
        }else{
            this.setState({
                ...this.state,
                dropdownOpen: id
            })
        }
       
    }

    editItem(item){

        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        // if(this.state.type == "Category"){
        //     this.setState({
        //         ...this.state,
        //         data: {
        //             ...this.state.data,
        //             parentCategory: item.parent_category,
        //             categoryImage: item.category_image,
        //             name: item.name,
        //             id: item.id
        //         },
        //         siderbar: true,
        //         display: 'block',
        //         dropdownOpen: null
        //     });
        // }else{
        //     this.props.history.push('/old/products/' + item.id);
        // }

    }

    deleteItem(id){
        this.setState({
            ...this.state,
            dropdownOpen: null
        });

        // if(this.state.type == "Category"){
        //     deleteCategory({
        //         id: id
        //     }).then(response => {
    
        //         if(!response.ok) {
        //             this.props.enqueueSnackbar("Category can't be deleted", {variant: 'error'});
        //             return;
        //         }
    
        //         setTimeout(() => {  
        //             this.fetchData();
        //             this.getRootCategories();
        //         }, 1000);
    
        //     });
        // }
       
    }

    openOrderDetails(item){
        this.props.history.push('/order-details/' + item.id);
    }

    renderPagination(){
        let pages = [];
        let index = 0;
        let start = 1;

        if (this.state.totalPages === 1){
            return;
        }

        if(this.state.page > 5){
            start = this.state.page - 4;
        }

        for (let i = start; i <= this.state.totalPages; i++){
            if(index === 9 && ((this.state.page + 4) < this.state.totalPages)){
                pages.push(
                    <div className={'three-dots'} key={-1}>...</div>
                );
                break;
            }
            pages.push(
                <div onClick={() =>this.onPageClick(i)} className={this.state.page == i ? 'page current-page' : 'page'} key={i}>
                    {i}
                </div>
            );
            index++;
        }

        return pages;
    }

    onPageClick(i){
        this.state.page = i;
        if(this.state.advancedSearchActive){
            this.advancedSearch(i);
        }else{
            this.getOrders(i, this.state.perPage);
        }
        
    }

    onBack(){
        this.state.page = Number(this.state.page) - 1;
        //this.getOrders(this.state.page, this.state.perPage);
        if(this.state.advancedSearchActive){
            this.advancedSearch(this.state.page);
        }else{
            this.getOrders(this.state.page, this.state.perPage);
        }
    }

    onForward(){
        this.state.page = Number(this.state.page) + 1;
        //this.getOrders(this.state.page, this.state.perPage);
        if(this.state.advancedSearchActive){
            this.advancedSearch(this.state.page);
        }else{
            this.getOrders(this.state.page, this.state.perPage);
        }
    }

    customChangeData(event, data = 'data') {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                customer: event.target.value
            }
        });

        setTimeout( () => { 
            this.getOrders(1,this.state.perPage) 
            this.getNumberOfOrders();
        }, 100);
    }

    getOrdersWithState(status){
        this.setState({
            ...this.state,
            orderStatus: status
        });

        setTimeout(() => {
            this.getOrders(1, this.state.perPage);
        }, 100)
    }


    changeStateOfAdvancedSearch(){
        this.setState({
            ...this.state,
            advancedSearchActive: true,
            display: 'block'
        })
    }

    goBack(){
        this.setState({
            ...this.state,
            advancedSearchActive: false,
            display: 'none'
        });
    }

    advancedSearch(page){
        this.state.data.page = page;
        this.state.data.orderStatus = this.state.orderStatus;
        this.state.data.customerId = this.state.data.customer ? this.state.data.customer.id : null;
        advancedSearch(this.state.data).then(response => {

            if(!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                orders: response.data.result,
                totalPages : Math.ceil(response.data.total / response.data.perPage),
                page : response.data.page,
                disabled : false,
            })
        });
    }

    discardAdvanced(){
        this.setState({
            ...this.state,
            advancedSearchActive: false,
            data: {
                ...this.state.data,
                advancedOrderId: null,
                advancedPartnerOrderId: null,
                advancedPartener: null,
                advancedCountry: null,
                advancedName: null,
                advancedStatus: null,
                advancedFrom: null,
                advancedTo: null,
                page: 1,
                perPage: 15,
            }
        });
    }

    changeDataTime(e, name){

        if(e == null){
            this.state.data[name+"String"] = null;
        }else{
            let strintDateTime = e._d.toLocaleDateString() + " " + e._d.toLocaleTimeString();
            this.state.data[name+"String"] = strintDateTime;
        }

        this.setState({
            ['data']: update(this.state['data'], { [name]: {$set: e} }),
        });
    }


    renderOrders() {
        let result = [];
        
        for(let item of this.state.orders) {
            result.push(
                <div className="item" onClick={(e) => this.openOrderDetails(item,e)}>
                    <div className={ this.state.userType == UserType.ADMIN ? "admin-order-id" : "id" }>{ item.id }</div>
                    <div className={ this.state.userType == UserType.ADMIN ? "admin-order-partner-order-id" : "partner-order-id"}>{ item.partner_id ? item.partner_id : item.shopify_id}</div>
                    <div className={ this.state.userType == UserType.ADMIN ? "admin-order-created" : "created"}>{ dateToString(item.date_created) }</div>
                    <div className={ this.state.userType == UserType.ADMIN ? "admin-order-number" : "sku"}>{ item.quantity }</div>
                    <div className="partner">{ item.customer.first_name } { item.customer.last_name }</div>
                    {
                        this.state.userType == UserType.ADMIN &&
                        <div className="vendor">{ item.vendor ? item.vendor.name : ""}</div>
                    }
                    {
                        this.state.userType == UserType.ADMIN &&
                        <div className="vendor-id">{ item.vendor ? item.vendor.id : ""}</div>
                    }
                    <div className="buyer-email">{ item.buyer_email ?  item.buyer_email : item.phone_number }</div>
                    {
                        this.state.userType == UserType.ADMIN &&
                        <div className="admin-order-shipping-name">{ item.first_name }</div>
                    }
                    <div className={ this.state.userType == UserType.ADMIN ? "admin-order-country" : "country"}>{ item.country?.name }</div>
                    <div className={ this.state.userType == UserType.ADMIN ? "admin-order-name" : "name"}>{item.first_name + " " + item.last_name }</div>
                    <div className={ this.state.userType == UserType.ADMIN ? "admin-order-payment" : "payment"}>USD { item.total }</div>
                    <div className="status">{ getOrderStatusString(item.status) }</div>
                    <div className={ this.state.userType == UserType.ADMIN ? "admin-order-tracking" : "tracking"} onClick={(event)=> {
                        if (!item.tracking_link){
                            return
                        }
                        event.stopPropagation();
                        window.open(item.tracking_link, "_blank");
                    }}
                        style={{ textDecorationLine: "underline", color: "#1E76B6", cursor: "pointer"}}>
                        { item.tracking_link }</div>
                </div>
            );
        }
        return result;
    }


    render() {

        return (
            <div id="orders">

                <div className="search-customer-select">
                    <div className={'search'}>
                        <input className={'category-search'}
                            value={this.state.data.searchTerm}
                            onChange={this.customChangeData}
                            name={'searchTerm'}
                            placeholder={'Type to search...'}
                            onKeyPress={ this.keyPress }
                        />
                        <img src={'/images/search.png'} />
                    </div>
                    <Button className="advanced-search" onClick={this.changeStateOfAdvancedSearch}>Advanced</Button>
                    { this.state.userType == UserType.ADMIN && 
                        <SelectControl
                            className='select'
                            options={this.state.customers}
                            valueKey = { 'id' }
                            onChange={ this.customChangeData }
                            nameKey= { 'displayName' }
                            name = {'customer'}
                            selected={this.state.data.customer}
                            placeholder = {'Select customer'}
                        />
                    }
                </div>

                {
                    this.state.advancedSearchActive &&
                    <div className="row">
                        {this.state.userType == UserType.CUSTOMER &&
                            <TextField
                                name='advancedOrderId'
                                onChange={this.changeData}
                                className={"category-name"}
                                type='number'
                                value={this.state.data.advancedOrderId}
                                placeholder={"Order ID"}
                                variant="outlined"
                            />
                        }

                        {this.state.userType == UserType.CUSTOMER &&
                        <TextField
                            name='advancedCustomerEmail'
                            onChange={ this.changeData }
                            className={"category-name"}
                            type='text'
                            value={ this.state.data.advancedCustomerEmail }
                            placeholder = { "Customer Email" }
                            variant="outlined"
                        />
                        }

                        {this.state.userType == UserType.CUSTOMER &&
                            <SelectControl
                                options={OrderStatus}
                                valueKey = { 'id' }
                                onChange={ this.changeData }
                                nameKey= { 'name' }
                                valueKey = { 'value' }
                                name = {'advancedStatus'}
                                name = {'advancedStatus'}
                                selected={ this.state.data.advancedStatus }
                                placeholder={"Status"}
                                isClearable={true}
                            />
                        }
                    </div>
                }

                {
                    this.state.advancedSearchActive && 
                    <div className="row">
                        {this.state.userType == UserType.ADMIN &&
                            <TextField
                                name='advancedOrderId'
                                onChange={this.changeData}
                                className={"category-name"}
                                type='number'
                                value={this.state.data.advancedOrderId}
                                placeholder={"Order ID"}
                                variant="outlined"
                            />
                        }

                        {this.state.userType == UserType.ADMIN &&
                            <TextField
                                name='advancedPartnerOrderId'
                                onChange={this.changeData}
                                className={"category-name"}
                                type='number'
                                value={this.state.data.advancedPartnerOrderId}
                                placeholder={"Partner Order ID"}
                                variant="outlined"
                            />
                        }

                        {this.state.userType == UserType.ADMIN &&
                        <SelectControl
                            options={OrderStatus}
                            valueKey = { 'id' }
                            onChange={ this.changeData }
                            nameKey= { 'name' }
                            valueKey = { 'value' }
                            name = {'advancedStatus'}
                            selected={ this.state.data.advancedStatus }
                            placeholder={"Status"}
                            isClearable={true}
                            />
                        }

                        {/*{this.state.userType == UserType.ADMIN &&*/}
                        {/*    <TextField*/}
                        {/*        name='advancedPartener'*/}
                        {/*        onChange={ this.changeData }*/}
                        {/*        className={"category-name"}*/}
                        {/*        type='text'*/}
                        {/*        value={ this.state.data.advancedPartener }*/}
                        {/*        placeholder = { "Partner" }*/}
                        {/*        variant="outlined"*/}
                        {/*    />*/}
                        {/*}*/}

                    </div>
                }
               
               {
                    this.state.advancedSearchActive && 
                    <div className="row">
                        <SelectControl
                            options={this.state.countries}
                            valueKey = { 'id' }
                            onChange={ this.changeData }
                            nameKey= { 'name' }
                            name = {'advancedCountry'}
                            selected={ this.state.data.advancedCountry }
                            placeholder={"Country"}
                            isClearable={true}
                        />

                        <TextField
                            name='advancedName'
                            onChange={ this.changeData }
                            className={"category-name"}
                            type='text'
                            value={ this.state.data.advancedName }
                            placeholder = { "Name" }
                            variant="outlined"
                        />

                    </div>
                }

                {
                    this.state.advancedSearchActive &&
                    <div className="row">

                        {this.state.userType == UserType.ADMIN &&
                            <TextField
                                name='advancedVendor'
                                onChange={this.changeData}
                                className={"category-name"}
                                type='text'
                                value={this.state.data.advancedVendor}
                                placeholder={"Vendor"}
                                variant="outlined"
                            />
                        }

                        {this.state.userType == UserType.ADMIN &&
                            <TextField
                                name='advancedVendorOrderId'
                                onChange={this.changeData}
                                className={"category-name"}
                                type='number'
                                value={this.state.data.advancedVendorOrderId}
                                placeholder={"Vendor Order Id"}
                                variant="outlined"
                            />
                        }

                        {this.state.userType == UserType.ADMIN &&
                            <TextField
                                name='advancedCustomerEmail'
                                onChange={ this.changeData }
                                className={"category-name"}
                                type='text'
                                value={ this.state.data.advancedCustomerEmail }
                                placeholder = { "Customer Email" }
                                variant="outlined"
                            />
                        }

                    </div>
                }

                {
                    this.state.advancedSearchActive && 
                    <div className="row timepicker">
                        {/*<TextField*/}
                        {/*    name='advancedFrom'*/}
                        {/*    onChange={ this.changeData }*/}
                        {/*    className={"category-name from"}*/}
                        {/*    type='datetime-local'*/}
                        {/*    value={ this.state.data.advancedFrom }*/}
                        {/*    variant="outlined"*/}
                        {/*    label={"from"}*/}
                        {/*    InputLabelProps={{*/}
                        {/*        shrink: true,*/}
                        {/*    }}*/}
                        {/*/>*/}

                        <DateTimePicker
                            value={ this.state.data.advancedFrom }
                            onChange={ (e) => this.changeDataTime(e, "advancedFrom") }
                            name={"advancedFrom"}
                            inputVariant={"outlined"}
                            label="From"
                            className={"category-name from"}
                            clearable={true}
                        />

                        <DateTimePicker
                            value={ this.state.data.advancedTo }
                            onChange={ (e) => this.changeDataTime(e, "advancedTo") }
                            name={"advancedTo"}
                            inputVariant={"outlined"}
                            label="To"
                            className={"category-name"}
                            clearable={true}
                        />


                        {/*<TextField*/}
                        {/*    name='advancedShippingName'*/}
                        {/*    onChange={ this.changeData }*/}
                        {/*    className={"category-name"}*/}
                        {/*    type='text'*/}
                        {/*    value={ this.state.data.advancedShippingName }*/}
                        {/*    placeholder = { "Partner" }*/}
                        {/*    variant="outlined"*/}
                        {/*/>*/}
                    </div>
                }

                {
                    this.state.advancedSearchActive &&
                    <div className="row timepicker buttons-filter ">
                        <Button className="discard" onClick={this.discardAdvanced}>Discard</Button>
                        <Button className="save" onClick={() => this.advancedSearch(1)}>Search</Button>
                    </div>
                }


                <div className="options-buttons">
                    {/* <Button className="active">New | 65 656</Button>
                    <Button>In Production | 65 656</Button>
                    <Button>Shipped | 65 656</Button>
                    <Button>Issues | 65 656</Button>
                    <Button>All | 65 656</Button> */}
                    <Button className={ this.state.orderStatus == null ? "active" : ""}  onClick={ () => this.getOrdersWithState(null) }>All | { this.state.all }</Button>
                    <Button className={ this.state.orderStatus == OrderEnum.NewOrder ? "active" : ""} onClick={ () => this.getOrdersWithState(OrderEnum.NewOrder) }>New | { this.state.new }</Button>
                    {/*<Button className={ this.state.orderStatus == OrderEnum.ReadyForPrint ? "active" : ""} onClick={ () => this.getOrdersWithState(OrderEnum.ReadyForPrint) }>Ready For Printing | { this.state.readyForPrinting }</Button>*/}
                    <Button className={ this.state.orderStatus == OrderEnum.Pending ? "active" : ""} onClick={ () => this.getOrdersWithState(OrderEnum.Pending) }>Pending | { this.state.pending }</Button>
                    <Button className={ this.state.orderStatus == OrderEnum.InProduction ? "active" : ""} onClick={ () => this.getOrdersWithState(OrderEnum.InProduction) }>In Production | { this.state.inProduction }</Button>
                    <Button className={ this.state.orderStatus == OrderEnum.Shipped ? "active" : ""} onClick={ () => this.getOrdersWithState(OrderEnum.Shipped) }>Shipped | { this.state.shipped }</Button>
                    <Button className={ this.state.orderStatus == OrderEnum.Issues ? "active" : ""} onClick={ () => this.getOrdersWithState(OrderEnum.Issues) }>Issues | { this.state.issues }</Button>
                </div>

                <div className="list">
                    <div className={'list-helper'}>
                        <div className="header">
                            <div className={ this.state.userType == UserType.ADMIN ? "admin-order-id" : "id" }>Order ID</div>
                            <div className={ this.state.userType == UserType.ADMIN ? "admin-order-partner-order-id" : "partner-order-id"}>Partner Order ID</div>
                            <div className={ this.state.userType == UserType.ADMIN ? "admin-order-created" : "created"}>Created</div>
                            <div className={ this.state.userType == UserType.ADMIN ? "admin-order-number" : "sku"}># of items</div>
                            <div className="partner">Partner</div>
                            {
                                this.state.userType == UserType.ADMIN &&
                                <div className="vendor">Vendor</div>
                            }
                            {
                                this.state.userType == UserType.ADMIN &&
                                <div className="vendor-id">Vendor Order ID</div>
                            }
                            <div className={"bayer-email"}>Customer Email</div>
                            {
                                this.state.userType == UserType.ADMIN &&
                                <div className="admin-order-shipping-name">Shipping name</div>
                            }
                            <div className={ this.state.userType == UserType.ADMIN ? "admin-order-country" : "country"}>Country</div>
                            <div className={ this.state.userType == UserType.ADMIN ? "admin-order-name" : "name"}>Name</div>
                            <div className={ this.state.userType == UserType.ADMIN ? "admin-order-payment" : "payment"}>Payment</div>
                            <div className="status">Status</div>
                            <div className={ this.state.userType == UserType.ADMIN ? "admin-order-tracking" : "tracking"}>Tracking</div>
                        </div>

                        { this.renderOrders() }
                    </div>

                </div>

                <div className={'pagination'}>
                    {
                        this.state.page > 1 &&
                        <img onClick={this.onBack} src={'/images/leftFilter.png'} />
                    }
                    <div className={'pages'}>
                        {this.renderPagination()}
                    </div>
                    {
                        this.state.page < this.state.totalPages &&
                        <img onClick={this.onForward} src={'/images/rightFilter.png'} />
                    }
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders)));
