import React from 'react';

const FailShieldIcon = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_333_7683)">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.19 1.36L4.19 4.47C3.47 4.79 3 5.51 3 6.3V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V6.3C21 5.51 20.53 4.79 19.81 4.47L12.81 1.36C12.3 1.13 11.7 1.13 11.19 1.36ZM15.4575 14.4565C15.1646 14.7494 14.6897 14.7494 14.3968 14.4565L12.2507 12.3104L10.1045 14.4565C9.81166 14.7494 9.33678 14.7494 9.04389 14.4565C8.751 14.1636 8.751 13.6887 9.04389 13.3959L11.19 11.2497L9.04389 9.10357C8.751 8.81068 8.751 8.33581 9.04389 8.04291C9.33678 7.75002 9.81166 7.75002 10.1045 8.04291L12.2507 10.1891L14.3968 8.04291C14.6897 7.75002 15.1646 7.75002 15.4575 8.04291C15.7504 8.33581 15.7504 8.81068 15.4575 9.10357L13.3113 11.2497L15.4575 13.3959C15.7504 13.6887 15.7504 14.1636 15.4575 14.4565Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_333_7683">
    <rect width={width} height={height} fill={color}/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default FailShieldIcon;