import React from 'react';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';

import './style.scss';

const AffiliatePage = () => {
  const items = [
    'There is no cap! Refer as much as you want as there is no ceiling on how much you can earn',
    'ZERO upfront cost! No investment needed.',
    'Simple registration, takes 5 min to get set up - please email support@surgeprint.com for details.',
    'Earn on every order your customers make.',
    'Keep earning for 12 straight months from each customer you refer.'
  ];
  return (
    <ApplicationPage selectedTab='affiliate'>
      <ApplicationPageContent title='Affiliate'>
        <div className='affiliate-page'>
          <div className='title'>
            Let's partner and earn together!
            <br />
            <br />
            <div className='sub-title'>
              Surge Print will send you a commission for every order your referrals create. We create custom commissions for every partner and every product in order to ensure that no other print on demand company can match our percentages!
            </div>
          </div>
          <div className='list'>
            {items.map((text, i) => (
              <div className='item' key={i}>
                <div className='bullet'/>
                <div className='text'>
                  {text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default AffiliatePage;