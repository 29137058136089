import React from 'react';
import { withRouter } from 'react-router';
import { v4 as uuid } from 'uuid';
import Page from 'src/redesign/components/Page';
import Loader from 'src/redesign/components/Loader';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Breadscrumb from 'src/redesign/components/Breadcrumb';
// import Searchbox from 'src/redesign/components/Searchbox';
import ProductItem from 'src/redesign/components/ProductItem';
import FilterPanel from 'src/redesign/components/FilterPanel';
import { getCategoriesTree, getSubCategoriesFilters } from 'src/services/CategoryService';
import { mapBrandsFromFilters, mapNameToUrl, mapProducts, roundStringPrice } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ProductCatalogCategoryBrand = ({ match: { params: { id } } }) => {
  const [loading, setLoading] = React.useState(false);
  const [filtersLoading, setFiltersLoading] = React.useState(false);
  // const [searchboxText, setSearchboxText] = React.useState('');

  const [parentCategory, setParentCategory] = React.useState({});
  const [subCategory, setSubCategory] = React.useState({});

  const [products, setProducts] = React.useState([]);
  const [filteredProducts, setFilteredProducts] = React.useState([]);

  const [filters, setFilters] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [brandFilters, setBrandFilters] = React.useState([]);
  const [brandSelectedFilters, setBrandSelectedFilters] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  // React.useEffect(() => {
  //   setFilteredProducts(products.filter(x => {
  //     const name = x?.name?.toLowerCase();
  //     return name.includes(searchboxText);
  //   }));
  // }, [searchboxText]);

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setFiltersLoading(true);
    setLoading(true);
    try {
      const { data: filters } = await getSubCategoriesFilters({
        categories: [id]
      });
      const { data: categories } = await getCategoriesTree();

      const category = categories.find(x => x.id == id);
      const products = mapProducts(filters);
      const brands = mapBrandsFromFilters(filters);
      
      setFilters(filters);
      setProducts(products);
      setFilteredProducts(products);
      setBrandFilters(brands);
      setSubCategory(category);
      setParentCategory(category?.parent_category);
    } catch (error) {
      openSnackBar('Failed to load categories', false);
      console.error(error);
    } finally {
      setFiltersLoading(false);
      setLoading(false);
    }
  }

  function filterByAttributes(selectedFilters) {
    setSelectedFilters(selectedFilters);
    setFilteredProducts(
      products.filter(x =>
        selectedFilters.every(f => x.attributeValues.includes(f)) &&
        (brandSelectedFilters.length === 0 || brandSelectedFilters.some(id => x.brand.id === id))
      )
    );
  }

  function filterByBrand(selectedBrands) {
    setBrandSelectedFilters(selectedBrands);
    setFilteredProducts(
      products.filter(x =>
        (selectedBrands.length === 0 || selectedBrands.some(f => x.brand.id === f)) &&
        selectedFilters.every(f => x.attributeValues.includes(f))
      )
    );
  }

  return <Page
      seoTitle={subCategory?.seo_title}
      seoDescription={subCategory?.seo_description}
    >
      <div className='product-catalog-category-container'>
        <div className='product-catalog-category-breadscrumb-searchbox-container'>
          <Breadscrumb
            loading={loading}
            links={[
              {
                to: '/product-catalog-website', text: 'Catalog',
              },
              {
                to: `/product-catalog-website/${parentCategory?.name?.toLowerCase()?.replaceAll(' ','-')}`,
                text: parentCategory?.name ?? '',
              },
              {
                to: `/product-catalog-category/${mapNameToUrl(subCategory?.name)}/${subCategory?.id}`, text: subCategory?.name ?? '',
              }
            ]}
          />
          {/* <Searchbox
            placeholder='Type to search'
            className='product-catalog-category-all-search-box'
            inputClassName='product-catalog-category-all-search-input'
            onChange={setSearchboxText}
          /> */}
        </div>
        <h1 className='catalog-category-brand-name'>
          {subCategory?.name}
        </h1>
        <div className='catalog-category-product-brand-container'>
          <FilterPanel
            loading={filtersLoading}
            isDataLoading={loading}
            filters={filters}
            selectedFilters={selectedFilters}
            setSelectedFilters={selectedFilters =>
              filterByAttributes(selectedFilters)
            }
            brandFilters={brandFilters}
            selectedBrandFilters={brandSelectedFilters}
            setSelectedBrandFilters={selectedBrandFilters =>
              filterByBrand(selectedBrandFilters)
            }
          />
          {loading ? (
          <Loader
            width={300}
            height={300}
            color={colors.primary}
          />
          ) : (
            <div className='catalog-category-product-brand-list-container'>
                {filteredProducts.map(x => <ProductItem key={uuid()} name={x?.name} price={`$${roundStringPrice(x?.price)}`} brandName={x?.brand?.name} url={process.env.REACT_APP_baseUrl + x?.productImage} to={`/product/${mapNameToUrl(x.name)}/${id}/${x.id}`} />)}
            </div>
          )}
        </div>
      </div>
    </Page>
}

export default withRouter(ProductCatalogCategoryBrand);