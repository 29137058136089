import React from 'react';
import { number, string } from 'prop-types';

const IconRotate = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 10.5005C1.33333 7.00046 4.5 0.900463 14.5 4.50046M14.5 4.50046L14 1M14.5 4.50046L11.5 7" stroke={color}  strokeOpacity="0.8"/>
    <path d="M16.5 9.49954C16.1667 12.9995 13 19.0995 3 15.4995M3 15.4995L3.5 19M3 15.4995L6 13" stroke={color}  strokeOpacity="0.8"/>
  </svg>
);

IconRotate.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
}

export default IconRotate;