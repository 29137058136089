import React from 'react';
import ReactDOM from 'react-dom';
import IconExit from 'src/redesign/components/Icon/Exit';
import { getProductVariationsAdmin, getShippingPricingForProductVariation } from 'src/services/admin/ProductVariationService';
import Loader from 'src/redesign/components/Loader';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import useSnackbar from 'src/redesign/hooks/useSnackbar';

const BrandDetailsDialog = ({ onClose, product }) => {
  const { id } = product;
  const [isLoading, setIsLoading] = React.useState(false);
  const [dimensions, setDimensions] = React.useState({});
  const [pricing, setPricing] = React.useState({});

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setIsLoading(true);
    try {
      const { data: variations } = await getProductVariationsAdmin(id);
      const variation = variations[0];

      const { data: pricing } = await getShippingPricingForProductVariation(variation?.id);
      
      setDimensions({ 
        width: variation?.dropzone_width,
        height: variation?.dropzone_height
      });
      setPricing(pricing);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load product info', false);
    } finally {
      setIsLoading(false);
    }
  }

  return ReactDOM.createPortal(
    <div className='brand-details-dialog-container'>
      <div className='brand-dialog-content'>
        {isLoading ? (
          <Loader width={250} height={250} color={colors.primary}/>
        ) : (
          <>
            <div className='brand-dialog-print-container'>
              <div className='brand-dialog-print-row'>
                <div className='print-area-text'>
                  Print area:
                </div>
                <div className='print-area-text'>
                  {dimensions?.width} x {dimensions?.height}
                </div>
              </div>
              <div className='print-area-width-height'>
                (Width x Height)
              </div>
            </div>

            <div className='brand-printed-text'>
              <span className='brand-printed-us'>Printed and shipped from US</span> - average
              <br/>
              Production time: 1.4 business days
              <br />
              Shipping methods available:
            </div>

            <div className='brand-printed-text'>
              <span className='domestic-standard'>Domestic Standard:</span> 5-2 business days, ${pricing?.us_standard_first} first item, ${pricing?.us_standard_n} each additional
            </div>

            <div className='brand-printed-text'>
              <span className='domestic-expedited'>Domestic expedited:</span> 2 to 5 business days, ${pricing?.us_expedited_first} first item, ${pricing?.us_expedited_n} each additional
            </div>

            <div className='brand-printed-text'>
              <span className='domestic-overnight'>Domestic overnight:</span> 1 to 2 business days, ${pricing?.us_overnight_first} first item, ${pricing?.us_overnight_n} each additional
            </div>

            <div className='brand-printed-text'>
              <span className='international'>International:</span> up to 12 business days, ${pricing?.int_standard_first} first item, ${pricing?.int_standard_n} each additional
            </div>

            <div className="icon-close" onClick={onClose}>
              <IconExit width={18} height={18} color={colors.primary}/>
            </div>
          </>
        )}
      </div>
    </div>
  , document.getElementById('dialog-root'))
}

export default BrandDetailsDialog;