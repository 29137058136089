import React from 'react';
import { getUserFromLocalStorage } from 'src/base/OAuth';
import { getShippingMethodString } from 'src/constants/ShippingMethod';
import { changeShippingMethod, setTrackingLinkToOrder } from 'src/services/OrderService';
import { dateToString } from 'src/util/DateUtil';
import Status from 'src/redesign/components/Status';
import TextInput from 'src/redesign/components/Form/TextInput';
import SelectInput from 'src/redesign/components/Form/SelectInput';
import Overview from 'src/redesign/components/Overview';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import * as Validators from 'src/redesign/util/validators';
import { SHIPPING_METHOD_OPTIONS } from 'src/redesign/constants';

const OrderShipmentDetails = ({ order, onUpdate }) => {
  const { id, trackingLink, trackingCode, status, createdAt, updatedAt, quantity, shippingMethod } = order;
  const user = getUserFromLocalStorage();
  const isAdmin = user?.roles?.includes('SUPER_ADMIN');
  // Shipping method should be editable only for new orders
  const isOrderNew = status === 0;

  const { openSnackBar } = useSnackbar();

  const save = async ({ trackingLink, shippingMethod }) => {
    try {
      let trackingLinkUpdatedMaybe = {};
      if(trackingLink) {
        await setTrackingLinkToOrder({
          orderId: id,
          trackingLink
        });
        trackingLinkUpdatedMaybe = { trackingLink };
      }

      let shippingMethodUpdatedMaybe = {};
      if(shippingMethod !== undefined && isOrderNew) {
        await changeShippingMethod({
          orderId: id,
          shippingMethod: parseInt(shippingMethod)
        });
        shippingMethodUpdatedMaybe = parseInt(shippingMethod);
      }

      onUpdate({ ...order, ...trackingLinkUpdatedMaybe, ...shippingMethodUpdatedMaybe });
      openSnackBar('Successfully changed tracking link');
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to edit tracking link', false);
    }
  }
  // Tracking Link should be editable only by admin
  const trackingLinkFieldEditMaybe = isAdmin
  ? {
    edit: <TextInput
      name='trackingLink'
      placeholder='Tracking link'
      validate={Validators.required(' ')}
    />
  }
  : {};
  // Shopping method should be editable only if order is not In Production status
  const shippingMethodFieldEditMaybe = isOrderNew
  ? {
      edit: <SelectInput
      name="shippingMethod"
      options={SHIPPING_METHOD_OPTIONS}
    />
  }
  : {};

  return (
    <Overview
      initialValues={{shippingMethod}}
      items={[
        {
          name: 'Tracking',
          value: <a href={trackingLink} target="_blank">{trackingLink !== '' ? trackingLink : trackingCode}</a>,
          ...trackingLinkFieldEditMaybe
        },
        {
          name: 'Status',
          value: <Status statusNumber={status} />
        },
        {
          name: 'Created',
          value: dateToString(createdAt)
        },
        {
          name: 'Last time updated',
          value: dateToString(updatedAt)
        },
        {
          name: 'Quantity',
          value: quantity
        },
        {
          name: 'Shipping method',
          value: getShippingMethodString(shippingMethod),
          ...shippingMethodFieldEditMaybe
        }
      ]}
      showEditButton={isAdmin || isOrderNew}
      onSubmit={save}
    />
  )
}

export default OrderShipmentDetails;