import React from 'react';
import classNames from 'classnames';
import { func, string } from 'prop-types';

import BasicTextInput from '../../TextInput';

import './style.scss';

const TextInput = (props) => {
  return (
    <BasicTextInput {...props} labelClassName={classNames('sidepane-input-label', props.labelClassName)} className={classNames('sidepane-input', props.className)} />
  );
}

TextInput.propTypes = {
  label: string,
  name: string.isRequired,
  placeholder: string,
  validate: func,
  className: string
}

export default TextInput;