import React, {Component} from 'react'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from '../localization';
import {Button} from "@material-ui/core";
import LoginForm from "../components/forms/user/LoginForm";
import RegistrationForm from "../components/forms/user/RegistrationForm";
import {getCountryList} from "../services/CountryService";
import MenuItem from '@material-ui/core/MenuItem';
import Validators from "../constants/ValidatorTypes";
import {registration} from "../services/UserService";
import update from 'immutability-helper';
import {
    getUserFromLocalStorage,
    setDataForRegistrationLocalStorage,
    getDataForRegistrationLocalStorage,
    clearDataForRegistrationLocalStorage, login
} from "../base/OAuth";


class SignUp extends Page {

    validationList = {
        email: [ {type: Validators.EMAIL } ],
        password: [ {type: Validators.REQUIRED } ],
        firstName: [ {type: Validators.REQUIRED }],
        lastName: [ {type: Validators.REQUIRED } ],
        country: [ {type: Validators.REQUIRED } ],
        companyName: [ {type: Validators.REQUIRED } ],
        city: [ {type: Validators.REQUIRED } ],
        storeUrl: [ {type: Validators.REQUIRED } ],
        phoneNumber: [ {type: Validators.REQUIRED } ]
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {
                agree: false,
                agreeColorRed: false,
                email: this.props.history.location.state ? this.props.history.location.state.email : null,
                password: this.props.history.location.state ? this.props.history.location.state.password : null,
                phoneNumber: null,
                shopify: false,
                etsy: false,
                wooCommerce: false,
                magento: false,
                amazon: false,
                ebay: false,
                bigCommerce: false,
                wayfair: false,
                wix: false,
                squarespace: false,
                physicalRetail: false,
                other: false
            },
            errors: {},
            redirectUrl: '/login',
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);

        this.reportWindowSize = this.reportWindowSize.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.props.changeFullScreen(true);

    }

    componentDidMount() {

        if(this.props.auth.user) {
            if(getUserFromLocalStorage() == null){
                this.props.auth.user = null
            }else{
                this.props.history.push('/home');
            }
        }

        this.reportWindowSize();
        window.addEventListener("resize", this.reportWindowSize);

        if(getDataForRegistrationLocalStorage() != null){
            this.setState({
                ...this.state,
                data: getDataForRegistrationLocalStorage().data
            });
        }
       
    }


    toggleMenu(){
        this.setState({
            ...this.state,
            menuOpen : !this.state.menuOpen
        })
    }

    reportWindowSize(){
        this.setState({
            ...this.state,
            mobile: window.innerWidth < 768
        })
    }

    fetchData(){
        this.fetchCountries();
    }

    keyPress(event) {

        if(event.key == 'Enter') {
            this.registration()
        }
    }

    registration() {
        if(!this.validate()) {
            return;
        }

        if(this.state.data.agree != true){
            this.setState({
                ['data']: update(this.state['data'], { ["agreeColorRed"]: {$set: true} })
            });
            return;
        }

        registration(this.state.data).then(response => {

            if(!response.ok) {

                this.setError('email', 'Email exists');
                return;
            }

            clearDataForRegistrationLocalStorage();

            this.loginAfterRegistration();
        });

    }

    loginAfterRegistration() {
        login(this.state.data.email, this.state.data.password).then(response => {

            if(!response.ok) {

                this.setError('email', 'Wrong Credentials');
                return;
            }

            // this.props.login(response.data.user);

            this.props.history.push('/stores');
        });
    }

    fetchCountries() {
        getCountryList().then(response => {
           
            var countries = [];

            response.data.countries.forEach(country => {
                countries.push( <MenuItem value={country.id}>{country.name}</MenuItem>)
            });
             this.setState({
                 ...this.state,
                countries: countries
            })
        });
    }

    handleSelectChange(event) {
        this.setState({
            ['data']: update(this.state['data'], { [event.target.name]: {$set: event.target.value} })
        });
    }

    goToLogin() {
        this.props.history.push('/login');
    }

    goToHowItWorks(){
        this.props.history.push('/', {goTo : '#how-it-works'});
    }

    goToHowAbout(){
        this.props.history.push('/', {goTo : '#about'});
    }

    goToRegistration(){
        this.props.history.push('/registration');
    }

    setData(){
        setDataForRegistrationLocalStorage(this.state);
    }

    render() {

        return (

            <div id="login">
                <div id={"top-header"} className="header">
                    <div className={'header-help'}>
                        <img className={'logo'} onClick={() => {
                            this.props.history.push('/');
                        }} src="./images/SurgePrintLogo1.png"></img>
                        {
                            !this.state.mobile &&
                            <div className="menu">
                                <div className="item" onClick={ () => this.goToHowItWorks()}>How it works</div>
                                <div className="item" onClick={ () => this.goToHowAbout()}>About</div>
                                <div className="item" onClick={ () => this.goToRegistration() }>Start Designing</div>
                                <div className="item" onClick={()  => {
                                    this.props.history.push('/contact');
                                }}>Contact</div>
                            </div>
                        }
                        {
                            !this.state.mobile &&
                            <div className="buttons">
                                <Button className="sign-up" onClick={ () => this.goToRegistration() }>SIGN UP</Button>
                                <Button className="login" onClick={ () => this.props.history.push('/login') }>LOGIN</Button>
                            </div>
                        }
                        {
                            this.state.mobile &&
                            <button onClick={()=> {
                                this.toggleMenu();
                            }} className={this.state.menuOpen ? "hamburger hamburger--collapse is-active" : "hamburger hamburger--collapse"} type="button">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner" />
                                </span>
                            </button>
                        }
                        {
                            this.state.mobile &&
                            <div className={this.state.menuOpen ? 'mobile-home-menu active' : 'mobile-home-menu'}>
                                <div className="menu">
                                    <div className="item" onClick={ () => this.goToHowItWorks()}>How it works</div>
                                    <div className="item" onClick={ () => this.goToHowAbout()}>About</div>
                                    <div className="item" onClick={ () => this.goToRegistration() }>Start Designing</div>
                                    <div className="item" onClick={()  => {
                                        this.props.history.push('/contact');
                                    }}>Contact</div>
                                </div>
                                <div className="buttons">
                                    <Button className="sign-up" onClick={ () => this.goToRegistration()}>SIGN UP</Button>
                                    <Button className="login" onClick={ () => this.props.history.push('/login') }>LOGIN</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="left" />
                <div className="center">
                    <RegistrationForm data={ this.state.data } countries={this.state.countries}
                        onSubmit={ () => this.registration() } onChange={ this.changeData } 
                        keyPress={ this.keyPress } handleSelectChange={ this.handleSelectChange }
                        errors={ this.state.errors } changeCheckBox={ this.changeCheckBox } 
                        setData = { () => this.setData() } props = {this.props }/>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        login: Actions.login
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, auth: authReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
